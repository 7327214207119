import { Tutorial } from "./tutorials-data";
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "lucide-react";

export function TutorialCard({ tutorial }: { tutorial: Tutorial }) {
    const navigate = useNavigate();

    const tutorialPath = tutorial.link;

    return (
        <div
            onClick={() =>
                navigate(tutorialPath || "/help", {
                    state: { tab: "tutorials" },
                })
            }
            className="block group cursor-pointer"
        >
            <div className="bg-white rounded-lg shadow-md p-6 h-full flex flex-col justify-between transition-all duration-300 group-hover:shadow-lg group-hover:-translate-y-1">
                <div>
                    <h3 className="text-xl font-semibold mb-2 text-primary group-hover:text-secondary transition-colors duration-300">
                        {tutorial.title}
                    </h3>
                    <p className="text-gray-600 mb-4">{tutorial.description}</p>
                </div>
                <div className="flex items-center text-secondary font-medium">
                    <span className="mr-2">Learn more</span>
                    <ArrowRight className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1" />
                </div>
            </div>
        </div>
    );
}
