import type React from "react";
import { useRef } from "react";
import { Textarea } from "./Textarea";

type MarkdownEditorProps = {
    value: string;
    onChange: (value: string) => void;
};

export default function MarkdownEditor({
    value,
    onChange,
}: MarkdownEditorProps) {
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            const cursorPosition = textareaRef.current?.selectionStart || 0;
            const textBeforeCursor = value.slice(0, cursorPosition);
            const textAfterCursor = value.slice(cursorPosition);
            const newLine = textBeforeCursor.endsWith("\n") ? "- " : "\n- ";
            const newMarkdown = textBeforeCursor + newLine + textAfterCursor;
            onChange(newMarkdown);

            setTimeout(() => {
                if (textareaRef.current) {
                    textareaRef.current.selectionStart =
                        textareaRef.current.selectionEnd =
                            cursorPosition + newLine.length;
                }
            }, 0);
        } else if (
            (e.key === "Backspace" &&
                textareaRef.current?.selectionStart === 2) ||
            (e.key === "Delete" && textareaRef.current?.selectionStart === 0)
        ) {
            e.preventDefault();
        }
    };

    const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let input = e.target.value;
        if (!input.startsWith("- ")) {
            input = "- " + input.replace(/^-?\s*/, "");
        }
        onChange(input);
    };

    return (
        <div className="container mx-auto flex-[0.75]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <Textarea
                        ref={textareaRef}
                        value={value}
                        onChange={handleInput}
                        onKeyDown={handleKeyDown}
                        className="w-full h-[200px] p-2 border rounded"
                        placeholder="Start with a - for a bullet point list"
                    />
                </div>
            </div>
        </div>
    );
}
