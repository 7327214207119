import React, { useState } from "react";
import {
    PaymentElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { Button } from "../../misc/Button";
import CustomToast from "../../misc/CustomToast";
import { toast } from "react-toastify";
import axios from "axios";
import { auth } from "../../../firebase/firebase-config";

interface PaymentInfoProps {}

const PaymentInfo: React.FC<PaymentInfoProps> = () => {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const stripe = useStripe();
    const elements = useElements();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!stripe || !elements) {
            toast.error(
                <CustomToast
                    message="Stripe.js has not yet loaded. Please try again."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }

        setIsProcessing(true);

        const { setupIntent, error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                // Optionally specify redirect URL
            },
            redirect: "if_required",
        });

        if (error) {
            toast.error(
                <CustomToast
                    message={`Failed to update payment method: ${error.message}`}
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } else if (setupIntent) {
            try {
                const paymentMethodId = setupIntent.payment_method;
                const token = await auth.currentUser?.getIdToken(true);

                // Send the PaymentMethod ID to your backend
                await axios.post(
                    `${backendUrl}/stripe/update-payment-method`,
                    { paymentMethodId: paymentMethodId },
                    { headers: { Authorization: `Bearer ${token}` } }
                );

                toast.success(
                    <CustomToast
                        message="Payment method updated successfully!"
                        type="success"
                    />,
                    { autoClose: 3000 }
                );
            } catch (backendError) {
                console.error(
                    "Error updating payment method on backend:",
                    backendError
                );
                toast.error(
                    <CustomToast
                        message="Failed to update payment method on the server."
                        type="error"
                    />,
                    { autoClose: 3000 }
                );
            }
        }

        setIsProcessing(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <Button
                className="w-full mt-2"
                type="submit"
                disabled={isProcessing}
            >
                {isProcessing ? "Processing..." : "Update Card"}
            </Button>
        </form>
    );
};

export default PaymentInfo;
