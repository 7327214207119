import SocialConnections from "../components/settings/Connections/SocialConnections";

const ConnectSocialsScreen = () => {
    return (
        <div className="w-full h-screen flex flex-col items-center justify-center">
            <SocialConnections isOnboarding={true} />
        </div>
    );
};
export default ConnectSocialsScreen;
