import { useIsMobile } from "../../hooks/useIsMobile";
import { Tabs, TabsList, TabsTrigger } from "../../components/misc/Tabs";
import { useState } from "react";
import ContactForm from "../../components/help/contact-form/ContactForm";
import Tutorials from "../../components/help/tutorials/Tutorials";
import FAQ from "../../components/help/faq/FAQ";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";

const HelpScreen: React.FC = () => {
    const location = useLocation();

    const state = location.state;
    const tab = state?.tab;

    const isMobile = useIsMobile(1300);
    const [activeTab, setActiveTab] = useState<
        "faqs" | "tutorials" | "contact"
    >(tab ? (tab as "faqs" | "tutorials" | "contact") : "faqs");
    return (
        <div
            className={`relative w-full min-h-full mb-4 ${
                isMobile ? "py-4 px-4" : "py-6 px-10"
            }`}
        >
            <div className="flex flex-col">
                <h1
                    className={`font-bold text-primary ${
                        isMobile ? "text-2xl mb-2" : "text-3xl mb-4"
                    }`}
                >
                    Help Center
                </h1>
                <Tabs
                    value={activeTab}
                    onValueChange={(value: string) =>
                        setActiveTab(value as "faqs" | "tutorials" | "contact")
                    }
                >
                    <TabsList
                        isMobile={isMobile}
                        className={`${isMobile ? "mb-2" : ""}`}
                    >
                        <TabsTrigger id="faq" value="faqs">
                            FAQs
                        </TabsTrigger>
                        <TabsTrigger id="tutorials" value="tutorials">
                            Tutorials
                        </TabsTrigger>
                        <TabsTrigger id="contact" value="contact">
                            Contact Us
                        </TabsTrigger>
                    </TabsList>

                    {/* FAQs */}
                    <FAQ />
                    {/* Tutorials */}
                    <Tutorials />
                    {/* Contact Form */}
                    <ContactForm />
                </Tabs>

                <Routes>
                    <Route
                        path="/connect-social-media"
                        element={<div>Connect Social Media</div>}
                    />
                </Routes>
                <Outlet />
            </div>
        </div>
    );
};
export default HelpScreen;
