import React, { useState } from "react";
import VerificationMessage from "../components/Email-Verification/VerificationMessage";
import { Button } from "../components/misc/Button";
import { handleLogoutGenerator } from "../global-actions/handleLogout";
import { auth } from "../firebase/firebase-config";
import { useNavigate } from "react-router-dom";
import { getIdToken } from "firebase/auth";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../components/misc/CustomToast";
import LoadingScreen from "./LoadingScreen";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import logo from "../assets/images/logo.png";
import { useIsMobile } from "../hooks/useIsMobile";

const VerifyEmailScreen: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const isMobile = useIsMobile(700);

    const navigate = useNavigate();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const handleResendEmail = async () => {
        try {
            setLoading(true);
            const currentUser = auth.currentUser;
            if (!currentUser) throw new Error("User not authenticated");
            const token = await getIdToken(currentUser, true);
            await axios
                .get(`${backendUrl}/onboarding/send-verification-email`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .catch((error: Error) => {
                    console.error(error);
                });
        } catch (error) {
            console.error("Error resending email verification: ", error);
            toast.error(
                <CustomToast
                    message="Error resending email verification"
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        } finally {
            setLoading(false);
        }
    };

    const user = useSelector((state: RootState) => state.auth.user);

    return (
        <main className="flex overflow-hidden flex-col justify-center items-center h-screen bg-gray-300 shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-md:px-5">
            {loading && <LoadingScreen />}
            <section
                className={`flex relative gap-2.5 items-start px-14 py-24 max-w-full ${
                    isMobile ? "h-screen" : "min-h-[833px]"
                } w-[605px] max-md:px-5`}
            >
                <div
                    className={`flex absolute right-0 bottom-0 z-0 flex-col self-start ${
                        !isMobile && "rounded-2xl"
                    } h-full min-w-[240px] w-[605px] max-md:max-w-full`}
                >
                    <div
                        className={`flex shrink-0 gap-2.5 bg-white ${
                            !isMobile && "rounded-2xl"
                        } h-full max-md:max-w-full`}
                    />
                </div>
                <div className="flex z-0 flex-col items-center my-auto h-[645px] min-w-[240px] w-[499px] max-md:max-w-full">
                    <div className="flex flex-col max-w-full w-[395px]">
                        <img
                            loading="lazy"
                            src={logo}
                            alt="Agent Registration Logo"
                            className="object-contain self-center max-w-full w-[395px] aspect-[8.77] max-md:w-3/4"
                        />
                    </div>
                    <VerificationMessage />
                    <div className="flex mt-11 max-w-full min-h-[159px] w-[402px] max-md:mt-10" />
                    <Button
                        type="button"
                        onClick={handleLogoutGenerator(auth, navigate, user)}
                        className="w-full mt-4"
                    >
                        Log Out
                    </Button>
                    <Button
                        type="button"
                        onClick={handleResendEmail}
                        className="w-full mt-4"
                    >
                        Resend Verification Email
                    </Button>
                    <div className="flex mt-11 max-w-full min-h-[159px] w-[402px] max-md:mt-10" />
                </div>
            </section>
        </main>
    );
};

export default VerifyEmailScreen;
