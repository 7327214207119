import React from "react";
import Select, {
    ActionMeta,
    MultiValue,
    SingleValue,
    StylesConfig,
} from "react-select";

interface OptionType {
    label: string;
    value: string;
}

interface CustomSelectProps<IsMulti extends boolean = false> {
    id: string;
    label?: string;
    options: OptionType[];
    value?: IsMulti extends true
        ? MultiValue<OptionType>
        : SingleValue<OptionType>;
    onChange: (
        value: IsMulti extends true
            ? MultiValue<OptionType>
            : SingleValue<OptionType>,
        actionMeta: ActionMeta<OptionType>
    ) => void;
    placeholder?: string;
    isClearable?: boolean;
    className?: string;
    isMulti?: IsMulti;
    bg?: string;
    errorMessage?: string | null;
}

const CustomSelect = <IsMulti extends boolean = false>({
    id,
    label,
    options,
    value,
    onChange,
    placeholder = "Select...",
    isClearable = true,
    className,
    isMulti = false as IsMulti,
    bg,
    errorMessage = null,
}: CustomSelectProps<IsMulti>) => {
    const customSelectStyles: StylesConfig<OptionType, boolean> = {
        control: (base, state) => ({
            ...base,
            backgroundColor: bg || "#FFFFFF",
            borderColor: state.isFocused ? "#193456" : "#B0BEC5",
            borderWidth: 1,
            borderRadius: 8,
            boxShadow: state.isFocused
                ? "0 0 5px rgba(25, 52, 86, 0.5)"
                : "none",
            "&:hover": {
                borderColor: "#193456",
            },
            minHeight: "48px",
            height: "48px",
        }),
        valueContainer: (base) => ({
            ...base,
            height: "48px",
            padding: "0 8px",
        }),
        input: (base) => ({
            ...base,
            margin: "0px",
            padding: "0px",
            height: "48px",
        }),
        indicatorsContainer: (base) => ({
            ...base,
            height: "48px",
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected
                ? "#193456"
                : state.isFocused
                ? "#E9C468"
                : "#FFFFFF",
            color: state.isSelected ? "#FFFFFF" : "#193456",
            "&:hover": {
                backgroundColor: "#F0DFAF",
                color: "#193456",
            },
        }),
        menu: (base) => ({
            ...base,
            borderRadius: 8,
            marginTop: 5,
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            position: "relative",
        }),
        singleValue: (base) => ({
            ...base,
            color: "#193456",
        }),
        placeholder: (base) => ({
            ...base,
            color: "#90A4AE",
        }),
        indicatorSeparator: () => ({ display: "none" }),
        dropdownIndicator: (base, state) => ({
            ...base,
            color: state.isFocused ? "#193456" : "#B0BEC5",
            "&:hover": {
                color: "#193456",
            },
        }),
    };

    const handleChange = (
        option: IsMulti extends true
            ? MultiValue<OptionType>
            : SingleValue<OptionType>,
        actionMeta: ActionMeta<OptionType>
    ) => {
        onChange(option, actionMeta);
    };

    return (
        <div className={className}>
            {label && (
                <label className="block text-sm font-medium text-primary">
                    {label}
                </label>
            )}
            <Select<OptionType, IsMulti>
                id={id}
                options={options}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                styles={customSelectStyles}
                isClearable={isClearable}
                isMulti={isMulti}
            />
            {errorMessage && (
                <p
                    id={`${id}-error`}
                    className="mt-1 text-sm text-red-600"
                    role="alert"
                >
                    {errorMessage}
                </p>
            )}
        </div>
    );
};

export default CustomSelect;
