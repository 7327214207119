import { Assets } from "../../../../types/asset-type";
import { Images } from "../../../../types/listingImages";
import { ListingVideos } from "../../../../types/listingVideos";
import { CombinedAsset, CombinedMedia } from "../types";

// Define the number of items per page
const ITEMS_PER_PAGE = 10;

// Define a union type for activeTab to ensure type safety
type ActiveTab = "images" | "videos" | "assets";

/**
 * Overloaded function signatures for calculateTotalPages.
 * Each signature corresponds to a specific activeTab and its data type.
 */

// When activeTab is "images", only images array is passed
export function calculateTotalPages(
    activeTab: "images",
    images: Images[],
    isMobile: boolean
): number;

// When activeTab is "videos", only videos array is passed
export function calculateTotalPages(
    activeTab: "videos",
    videos: ListingVideos[],
    isMobile: boolean
): number;

// When activeTab is "assets", only assets array is passed
export function calculateTotalPages(
    activeTab: "assets",
    assets: Assets[],
    isMobile: boolean
): number;

/**
 * Implementation of the overloaded function.
 * It handles the calculation based on the activeTab and the corresponding data array.
 */

export function calculateTotalPages(
    activeTab: ActiveTab,
    data: Images[] | ListingVideos[] | Assets[],
    isMobile: boolean
): number {
    let itemsCount: number;

    switch (activeTab) {
        case "images":
            itemsCount = (data as Images[]).length;
            break;
        case "videos":
            itemsCount = (data as ListingVideos[]).length;
            break;
        case "assets":
            itemsCount = (data as Assets[]).length;
            break;
        default:
            throw new Error(`Unknown activeTab: ${activeTab}`);
    }

    return Math.ceil(itemsCount / (isMobile ? 4 : ITEMS_PER_PAGE));
}

export function isCombinedAsset(media: CombinedMedia): media is CombinedAsset {
    return (
        (media.type === "image" || media.type === "video") &&
        "assetType" in media
    );
}
