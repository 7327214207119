import React, { useEffect, useState } from "react";
import { ChoosePlatformsProps, SocialsConnected } from "./types";
import { Label } from "../misc/Label";
import { FaCheck } from "react-icons/fa6";
import facebookLogo from "../../assets/images/facebook_logo.png";
import instagramLogo from "../../assets/images/instagram_logo.png";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { Button } from "../misc/Button";
import { Card } from "../misc/Card";
import { Avatar, AvatarImage, AvatarFallback } from "../misc/Avatar";
import LoadingScreen from "../../screens/LoadingScreen";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../../context/SocketContext";
import { SocialConnectionsData } from "../settings/types";

interface DisplayNameData {
    platform: string;
    userImage: string;
    displayName: string;
    pageName?: string;
    username?: string;
}

const ChoosePlatforms: React.FC<ChoosePlatformsProps> = ({
    setCurrentStep,
    selectedPlatforms,
    setSelectedPlatforms,
    from,
    listingId,
}) => {
    const navigate = useNavigate();
    const { socket } = useSocket(); // Destructure socket from the context

    const [connections, setConnections] = useState<SocialsConnected>({
        facebook: false,
        instagram: false,
    });

    const [connectionData, setConnectionData] = useState<
        SocialConnectionsData[]
    >([]);

    const [loading, setLoading] = useState<boolean>(true);

    const [socialLoading, setSocialLoading] = useState<SocialsConnected>({
        facebook: false,
        instagram: false,
    });

    const socialPlatforms = [
        {
            id: "facebook",
            name: "Facebook",
            icon: (
                <img
                    src={facebookLogo}
                    alt="Facebook Logo"
                    className="h-6 w-6"
                />
            ),
            color: "bg-[#0866FF] text-white",
            hoverColor: "hover:bg-[#1877F2]",
        },
        {
            id: "instagram",
            name: "Instagram",
            icon: (
                <img
                    src={instagramLogo}
                    alt="Instagram Logo"
                    className="h-6 w-6"
                />
            ),
            color: "bg-gradient-to-br from-[#F58529] via-[#DD2A7B] to-[#8134AF] text-white",
            hoverColor:
                "hover:from-[#E1731F] hover:via-[#C9246D] hover:to-[#722B9A]",
        },
    ];

    useEffect(() => {
        const fetchConnections = async () => {
            setLoading(true);
            try {
                const headers = await getAuthHeader();
                const backendUrl = process.env.REACT_APP_BACKEND_URL;

                const response = await axios.get(
                    `${backendUrl}/socials/profiles`,
                    { headers }
                );

                const responseData = response.data.data || response.data;

                const activeSocialAccounts =
                    responseData.activeSocialAccounts || [];

                // Transform displayNames array to object
                const allConnectionDataArray = responseData.displayNames || [];
                const allConnectionData = allConnectionDataArray.reduce(
                    (
                        acc: Record<string, DisplayNameData>,
                        curr: DisplayNameData
                    ) => {
                        acc[curr.platform.toLowerCase()] = curr;
                        return acc;
                    },
                    {}
                );

                const fetchedConnections = [
                    {
                        platform: "facebook",
                        connected: activeSocialAccounts.includes("facebook"),
                        userData: allConnectionData["facebook"] || {
                            userImage: "",
                            displayName: "",
                            pageName: "",
                        },
                    },
                    {
                        platform: "instagram",
                        connected: activeSocialAccounts.includes("instagram"),
                        userData: allConnectionData["instagram"] || {
                            userImage: "",
                            displayName: "",
                            username: "",
                        },
                    },
                ];

                setConnections({
                    facebook: activeSocialAccounts.includes("facebook"),
                    instagram: activeSocialAccounts.includes("instagram"),
                });
                setConnectionData(fetchedConnections);
            } catch (error) {
                console.error("Fetch Connections Error:", error);
                let errorMessage =
                    "Error fetching social profiles. Please try again later.";
                if (axios.isAxiosError(error) && error.response) {
                    errorMessage += ` Server responded with status ${error.response.status}.`;
                }

                toast.error(
                    <CustomToast message={errorMessage} type="error" />,
                    {
                        autoClose: 3000,
                    }
                );

                // Redirect to the previous page
                setTimeout(() => {
                    navigate(from || "/");
                }, 300);
            } finally {
                setLoading(false);
            }
        };

        fetchConnections();
    }, [navigate, from]);

    // Integrate Socket Connection
    useEffect(() => {
        if (!socket) return;

        // Join the same room as SocialConnections
        socket.emit("joinSocialConnectionsRoom");

        // Handler for socialsUpdate event
        const handleSocialsUpdate = (
            updatedConnections: SocialConnectionsData[]
        ) => {
            console.log("Received socialsUpdate:", updatedConnections);

            const defaultUserData: Record<
                string,
                SocialConnectionsData["userData"]
            > = {
                facebook: {
                    userImage: "",
                    displayName: "",
                    pageName: "",
                },
                instagram: {
                    userImage: "",
                    displayName: "",
                    username: "",
                },
            };

            const supportedPlatforms = ["facebook", "instagram"];

            const mergedConnections: SocialsConnected = {
                facebook: false,
                instagram: false,
            };

            const updatedConnectionData: SocialConnectionsData[] = [];

            supportedPlatforms.forEach((platform) => {
                const updated = updatedConnections.find(
                    (conn) =>
                        conn.platform.toLowerCase() === platform.toLowerCase()
                );

                if (updated) {
                    mergedConnections[platform as keyof SocialsConnected] =
                        updated.connected;

                    // If userData exists, use it; otherwise, use default
                    const userData =
                        updated.userData || defaultUserData[platform];
                    updatedConnectionData.push({
                        platform,
                        connected: updated.connected,
                        userData,
                    });
                } else {
                    // Platform not present in updatedConnections; use default values
                    mergedConnections[platform as keyof SocialsConnected] =
                        false;
                    updatedConnectionData.push({
                        platform,
                        connected: false,
                        userData: defaultUserData[platform],
                    });
                }
            });

            setConnections(mergedConnections);
            setConnectionData(updatedConnectionData);
            setLoading(false);
        };

        socket.on("socialsUpdate", handleSocialsUpdate);

        socket.on("connect_error", (err: any) => {
            console.error("WebSocket connection error:", err);
        });

        return () => {
            socket.off("socialsUpdate", handleSocialsUpdate);
        };
    }, [socket]);

    const handlePlatformToggle = (platformId: keyof SocialsConnected) => {
        setSocialLoading((prev) => ({ ...prev, [platformId]: true }));

        setSelectedPlatforms({
            ...selectedPlatforms,
            [platformId]: !selectedPlatforms[platformId],
        });

        setSocialLoading((prev) => ({ ...prev, [platformId]: false }));
    };

    const handleNextStep = () => {
        // Check if at least one platform is selected and all selected platforms are connected
        const hasSelectedPlatforms = Object.values(selectedPlatforms).some(
            (isSelected) => isSelected
        );

        const hasUnconnectedSelectedPlatforms = Object.keys(
            selectedPlatforms
        ).some(
            (platformId) =>
                selectedPlatforms[platformId as keyof SocialsConnected] &&
                !connections[platformId as keyof SocialsConnected]
        );

        if (!hasSelectedPlatforms) {
            toast.error(
                <CustomToast
                    message="Please select at least one social platform."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
            return;
        }

        if (hasUnconnectedSelectedPlatforms) {
            toast.error(
                <CustomToast
                    message="Please connect all selected social platforms."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
            return;
        }

        setCurrentStep(2);
    };

    const handleBackStep = () => {
        sessionStorage.removeItem("from");
        sessionStorage.removeItem("listingId");
        sessionStorage.removeItem("listingType");

        if (from) {
            navigate(from, { state: { listingId: listingId } });
        } else {
            navigate("/");
        }
    };

    // Check if any selected platform is not connected
    const hasUnconnectedSelectedPlatforms = Object.keys(selectedPlatforms).some(
        (platformId) =>
            selectedPlatforms[platformId as keyof SocialsConnected] &&
            !connections[platformId as keyof SocialsConnected]
    );

    // Disable Next button if any selected platform is not connected or no platform is selected
    const selectedPlatformNum = Object.values(selectedPlatforms).filter(
        (isSelected) => isSelected
    ).length;

    const isNextDisabled =
        hasUnconnectedSelectedPlatforms || selectedPlatformNum <= 0;

    return (
        <div className="flex flex-col justify-between h-full">
            <Label className="font-bold text-xl text-primary mb-4">
                Choose Your Social Platforms
            </Label>
            {loading ? (
                <LoadingScreen />
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    {/*awaitingConnection && (
                        <div className="flex items-center justify-center space-x-2">
                            <span className="text-primary">
                                Waiting for connection
                            </span>
                            <BiDotsHorizontalRounded
                                className="animate-ping text-secondary"
                                size={25}
                            />
                        </div>
                    )*/}
                    {connectionData.map((connection) => {
                        const platformId =
                            connection.platform as keyof SocialsConnected;
                        const isSelected = selectedPlatforms[platformId];
                        const isConnected = connection.connected;
                        const userData = connection.userData;

                        return (
                            <Card
                                key={`${connection.platform}-${userData.displayName}`} // Ensure unique key
                                className={`p-4 flex flex-col items-center cursor-pointer ${
                                    isSelected
                                        ? `${
                                              socialPlatforms.find(
                                                  (p) =>
                                                      p.id ===
                                                      connection.platform
                                              )?.color
                                          }`
                                        : "bg-white text-primary"
                                } rounded-lg shadow-md transition-colors duration-300 relative`}
                                onClick={() => handlePlatformToggle(platformId)}
                            >
                                {socialLoading[platformId] && <LoadingScreen />}

                                {isSelected && isConnected && (
                                    <FaCheck className="absolute top-2 right-2 text-green-500 h-5 w-5" />
                                )}

                                <div className="flex flex-col items-center w-full">
                                    <div className="flex items-center justify-center mb-2 rounded-full bg-white p-1">
                                        {
                                            socialPlatforms.find(
                                                (p) =>
                                                    p.id === connection.platform
                                            )?.icon
                                        }
                                    </div>
                                    <h3 className="text-lg font-semibold">
                                        {
                                            socialPlatforms.find(
                                                (p) =>
                                                    p.id === connection.platform
                                            )?.name
                                        }
                                    </h3>
                                    {isConnected ? (
                                        <div className="mt-2 w-full">
                                            <div
                                                key={`${connection.platform}-${userData.displayName}`}
                                                className="flex items-center space-x-3 mb-2"
                                            >
                                                <Avatar>
                                                    {userData.userImage ? (
                                                        <AvatarImage
                                                            src={
                                                                userData.userImage
                                                            }
                                                            alt={
                                                                userData.displayName
                                                            }
                                                        />
                                                    ) : (
                                                        <AvatarFallback>
                                                            {userData.displayName.charAt(
                                                                0
                                                            )}
                                                        </AvatarFallback>
                                                    )}
                                                </Avatar>
                                                <div>
                                                    <div className="font-semibold">
                                                        {userData.displayName}
                                                    </div>

                                                    {connection.platform ===
                                                    "facebook" ? (
                                                        <div className="text-xs text-muted-foreground">
                                                            Page Name:{" "}
                                                            {userData.pageName}
                                                        </div>
                                                    ) : (
                                                        <div className="text-xs text-muted-foreground">
                                                            Username:{" "}
                                                            {userData.username}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <p
                                            className={`${
                                                isSelected
                                                    ? "text-white"
                                                    : "text-red-600"
                                            } text-sm mt-1`}
                                        >
                                            Not Connected
                                        </p>
                                    )}
                                </div>
                            </Card>
                        );
                    })}
                </div>
            )}

            {/* Connect Accounts button */}
            {hasUnconnectedSelectedPlatforms && (
                <Button
                    onClick={() => navigate("/settings/connections")}
                    className="flex items-center justify-center mt-3"
                >
                    Manage Connections
                </Button>
            )}

            {/* Navigation Buttons */}
            <div className="flex justify-between mt-6">
                <Button
                    variant="secondary"
                    type="button"
                    onClick={handleBackStep}
                >
                    Back
                </Button>
                <Button
                    type="button"
                    onClick={handleNextStep}
                    disabled={isNextDisabled}
                >
                    Next
                </Button>
            </div>
        </div>
    );
};

export default ChoosePlatforms;
