import React, { useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../misc/Dialog";
import InputField from "../misc/InputField";
import { Button } from "../misc/Button";
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { auth } from "../../firebase/firebase-config";
import CustomToast from "../misc/CustomToast";
import { isValidPassword } from "../../utils/validationUtil";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import {toast} from "react-toastify";

interface ChangePasswordOverlayProps {
    isOpen: boolean;
    onClose: () => void;
}

const PasswordChangeOverlay: React.FC<ChangePasswordOverlayProps> = ({
    isOpen,
    onClose,
}) => {
    const [passwordRequirements, setPasswordRequirements] = useState({
        char: false,
        charCount: false,
        special: false,
      });
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const isValid = passwordRequirements.char && passwordRequirements.charCount && passwordRequirements.special;
        if (!isValid) {
            toast.error(
                CustomToast({
                    message:
                        "Error: Password must contain at least 8 characters, a number, a special character, and an uppercase letter",
                    type: "error",
                })
            )
            return;
        }
        const user = auth.currentUser;
        if (!user){
            toast.error(
                CustomToast({message: "Error: User not authenticated", type: "error"})
            )
        }
        if (newPassword !== confirmPassword) {
            toast.error(
                CustomToast({message: "Error: Passwords do not match", type: "error"})
            );
            return;
        }
        const credential = EmailAuthProvider.credential(user!.email!, currentPassword);
        await reauthenticateWithCredential(user!, credential).then(() => {
            updatePassword(user!, newPassword);
        }).then(() =>{
            onClose();
            toast.success(
                CustomToast({message: "Password changed successfully", type: "success"})
            )
        })
        .catch((error) => {
            toast.error(
                CustomToast({message: "Error: Password change failed", type: "error"})
            )
        });
    };
    const checkAndSetNewPassword = (newInput: string) => {
        setShowPasswordRequirements(true);
        setPasswordRequirements(isValidPassword(newInput));
        setNewPassword(newInput);
    }
    const handleConfirmPasswordChange = (newInput: string) => {
        setConfirmPassword(newInput);
        setPasswordsMatch(newInput === newPassword);
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[425px] bg-white">
                <DialogHeader>
                    <DialogTitle>Change Password</DialogTitle>
                    <DialogDescription>
                        Enter your current password and a new password to change
                        your login credentials.
                    </DialogDescription>
                </DialogHeader>
                <form onSubmit={handleSubmit}>
                    <div className="grid gap-4 py-4">
                        <div className="grid gap-2">
                            <InputField
                                label="Current Password"
                                type="password"
                                value={currentPassword}
                                onChange={(e) =>
                                    setCurrentPassword(e.target.value)
                                }
                                placeholder="Enter your current password"
                                required={true}
                                showPasswordToggle
                            />
                        </div>
                        <div className="grid gap-2">
                            <InputField
                                label="New Password"
                                type="password"
                                value={newPassword}
                                onChange={(e) => checkAndSetNewPassword(e.target.value)}
                                placeholder="Enter your new password"
                                required={true}
                                showPasswordToggle
                            />
                        </div>
                        {showPasswordRequirements && (
                            <div className="flex flex-col space-y-2">
                            <div className="flex items-center gap-2">
                                {passwordRequirements.char ? (
                                <FaCheckCircle className="text-green-600" />
                                ) : (
                                <FaTimesCircle className="text-red-600" />
                                )}
                                <span className="text-sm text-neutral-700">
                                Password must contain at least 1 letter.
                                </span>
                            </div>
                            <div className="flex items-center gap-2">
                                {passwordRequirements.special ? (
                                <FaCheckCircle className="text-green-600" />
                                ) : (
                                <FaTimesCircle className="text-red-600" />
                                )}
                                <span className="text-sm text-neutral-700">
                                Password must contain at least 1 number or special character.
                                </span>
                            </div>
                            <div className="flex items-center gap-2">
                                {passwordRequirements.charCount ? (
                                <FaCheckCircle className="text-green-600" />
                                ) : (
                                <FaTimesCircle className="text-red-600" />
                                )}
                                <span className="text-sm text-neutral-700">
                                Password must be at least 10 characters long.
                                </span>
                            </div>
                            </div>
                        )}
                        <div className="grid gap-2">
                            <InputField
                                label="Confirm Password"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) =>
                                    handleConfirmPasswordChange(e.target.value)
                                }
                                placeholder="Confirm your new password"
                                required={true}
                                showPasswordToggle
                            />
                        </div>
                        {showPasswordRequirements && (
                            <div className="flex flex-col space-y-2">
                            <div className="flex items-center gap-2">
                                {passwordsMatch ? (
                                <FaCheckCircle className="text-green-600" />
                                ) : (
                                <FaTimesCircle className="text-red-600" />
                                )}
                                <span className="text-sm text-neutral-700">
                                Password must match.
                                </span>
                            </div>
                            </div>
                        )}
                    </div>
                    <DialogFooter>
                        <Button onClick={handleSubmit}>Change Password</Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default PasswordChangeOverlay;
