import React, { ChangeEvent, useState } from "react";
import { LogoSelectorProps } from "./types";
import { FaImage } from "react-icons/fa6";
import { Button } from "../../misc/Button";
import { useIsMobile } from "../../../hooks/useIsMobile";
import OverlayThumbnails from "./OverlayThumbnail";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const LogoSelector: React.FC<LogoSelectorProps> = ({
    logos,
    selectedLogo,
    onSelectLogo,
    onUploadLogo,
}) => {
    const isMobile = useIsMobile(900);

    const [currentPage, setCurrentPage] = useState(1);
    const perPage = isMobile ? 4 : 10;
    const totalPages = Math.ceil(logos.length / perPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    // Slice overlays array for pagination
    const paginatedLogos = logos.slice(
        (currentPage - 1) * perPage,
        currentPage * perPage
    );

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            onUploadLogo(file);
        }
    };

    return (
        <div className="space-y-4">
            <h4 className="text-sm font-medium">Select Existing Logo</h4>
            {logos.length > 0 ? (
                <div>
                    <OverlayThumbnails
                        overlays={paginatedLogos}
                        selectedOverlay={selectedLogo}
                        onSelectOverlay={onSelectLogo}
                        isMobile={isMobile}
                    />
                    <div className="flex justify-between items-center mt-4">
                        <Button
                            variant="outline"
                            onClick={() =>
                                handlePageChange(Math.max(currentPage - 1, 1))
                            }
                            disabled={currentPage === 1}
                        >
                            <FaArrowLeft className="mr-2 h-4 w-4" /> Previous
                        </Button>
                        <span>
                            {isMobile ? "" : "Page "} {currentPage} of{" "}
                            {totalPages}
                        </span>
                        <Button
                            variant="outline"
                            onClick={() =>
                                handlePageChange(
                                    Math.min(currentPage + 1, totalPages)
                                )
                            }
                            disabled={currentPage === totalPages}
                        >
                            Next <FaArrowRight className="ml-2 h-4 w-4" />
                        </Button>
                    </div>
                </div>
            ) : (
                <p className="text-neutral-500 col-span-full text-center">
                    No logos available. Upload a new one below.
                </p>
            )}
            <div className="flex items-center space-x-4">
                <Button asChild variant="outline">
                    <label className="cursor-pointer">
                        <input
                            type="file"
                            className="hidden"
                            accept="image/png, image/jpeg, image/svg+xml"
                            onChange={handleFileChange}
                        />
                        <FaImage className="mr-2 h-4 w-4" />
                        Upload New Logo
                    </label>
                </Button>
            </div>
        </div>
    );
};

export default LogoSelector;
