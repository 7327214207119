import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../types/user";
// auth characteristics of current user relevant to client
interface AuthState {
    user: User | null;
    role: string | null;
    // whether the user has completed onboarding
    isOnboarded: boolean;
    // whether the user is in admin view / has admin rights
    isAdminView: boolean;
    // whether the user is authenticated by login service
    isAuthenticated: boolean;
    loading: boolean;
    error: string | null;
    //whether the user has verified their email
    emailVerified: boolean;
    // Whether the user has realtor access
    mlsApproved: boolean;
    // Whether the user status is active
    isActive: boolean;
    // Whether the user subscription status is active
    isSubscribed: boolean;
    // Whether the site is in maintenance mode
    maintenance: boolean;
    // Whether the user has connected social accounts
    socialsConnected: boolean;
    // Whether the user is an admin
    isAdmin: boolean;
    // Whether the user created their first listing
    listingCreated: boolean;
}

const initialState: AuthState = {
    user: null,
    role: null,
    isOnboarded: false,
    isAdminView: false,
    isAuthenticated: false,
    loading: true,
    error: null,
    emailVerified: false,
    mlsApproved: false,
    isActive: false,
    isSubscribed: false,
    maintenance: false,
    socialsConnected: false,
    isAdmin: false,
    listingCreated: false,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<User | null>) {
            state.user = action.payload;
            state.isAuthenticated = !!action.payload;
            state.loading = false;
            state.mlsApproved = action.payload?.mlsApproved || false;
        },
        setRole(state, action: PayloadAction<string | null>) {
            state.role = action.payload;
        },
        setIsOnboarded(state, action: PayloadAction<boolean>) {
            state.isOnboarded = action.payload;
        },
        setIsAdminView(state, action: PayloadAction<boolean>) {
            state.isAdminView = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setError(state, action: PayloadAction<string | null>) {
            state.error = action.payload;
            state.loading = false;
        },
        setAdmin(state, action: PayloadAction<boolean>) {
            state.isAdmin = action.payload;
        },
        setEmailVerified(state, action: PayloadAction<boolean>) {
            state.emailVerified = action.payload;
        },
        setIsActive(state, action: PayloadAction<boolean>) {
            state.isActive = action.payload;
        },
        setIsSubscribed(state, action: PayloadAction<boolean>) {
            state.isSubscribed = action.payload;
        },
        setListingCreated(state, action: PayloadAction<boolean>) {
            state.listingCreated = action.payload;
        },
        logout(state) {
            state.user = null;
            state.role = null;
            state.isAuthenticated = false;
            state.isAdminView = false;
            state.isOnboarded = false;
            state.loading = false;
            sessionStorage.removeItem("isAdminView");
        },
        setMaintenance(state, action: PayloadAction<boolean>) {
            state.maintenance = action.payload;
        },
        setSocialsConnected(state, action: PayloadAction<boolean>) {
            state.socialsConnected = action.payload;
        },
    },
});

export const {
    setUser,
    setRole,
    setIsOnboarded,
    setIsAdminView,
    setLoading,
    setError,
    setEmailVerified,
    setIsActive,
    setIsSubscribed,
    setMaintenance,
    logout,
    setSocialsConnected,
    setAdmin,
    setListingCreated,
} = authSlice.actions;

export default authSlice.reducer;
