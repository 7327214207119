import React, {
    useMemo,
    useState,
    useEffect,
    useRef,
    Dispatch,
    SetStateAction,
} from "react";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../listings/slideshow/Tooltip";
import { FaCheck } from "react-icons/fa6";
import { cn } from "../../utils/classMerger";
import CustomToast from "../misc/CustomToast";
import { MediaSelectorProps, UnifiedMedia } from "../listings/slideshow/types";
import { toast } from "react-toastify";
import { useIsMobile } from "../../hooks/useIsMobile";
import { Assets, AssetType } from "../../types/asset-type";
import { ArchiveMedia } from "../post/types";
import AssetsUploader from "../misc/AssetsUploader";
import { ResourceType } from "../content/types";
import CustomSelect from "../misc/CustomSelect";
import { PromotionType } from "../promotions/types";

interface EnhancedMediaSelectorProps extends MediaSelectorProps {
    showAssetsUploader?: boolean;
    onAssetsUploaded?: () => void;
    listingId?: string;
    hasVideoSelected: boolean;
    hasImageSelected: boolean;
    setSelectedMedia: (media: string[]) => void;
    activeResourceType: ResourceType | null;
    setActiveResourceType: Dispatch<SetStateAction<ResourceType | null>>;
    activePromotionType: PromotionType | null;
    setActivePromotionType: Dispatch<SetStateAction<PromotionType | null>>;
}

const PostMediaSelector: React.FC<EnhancedMediaSelectorProps> = ({
    images = [],
    videos = [],
    promotionImages = [],
    assets = [],
    archived = [],
    selectedMedia,
    onSelect,
    maxMedia,
    minMedia,
    showAssetsUploader = false,
    onAssetsUploaded,
    listingId,
    hasVideoSelected,
    hasImageSelected,
    activeTab,
    setSelectedMedia,
    type,
    activeResourceType,
    setActiveResourceType,
    activePromotionType,
    setActivePromotionType,
}) => {
    const isMobile = useIsMobile(950);

    const [uploadOccurred, setUploadOccurred] = useState(false);

    const mapAssetsToUnifiedMedia = (asset: Assets): UnifiedMedia => ({
        ...asset,
        mediaType:
            asset.assetType === "Video"
                ? "video"
                : asset.assetType === "Logo"
                ? "logo"
                : "image",
    });

    const mapArchiveToUnifiedMedia = (archive: ArchiveMedia): UnifiedMedia => ({
        ...archive,
        mediaType: archive.type === "Removed Video" ? "video" : "image",
    });

    const mapPromotionToUnifiedMedia = (promotion: any): UnifiedMedia => ({
        ...promotion,
        mediaType: "image",
    });

    const combinedMedia: UnifiedMedia[] = useMemo(() => {
        if (activeTab === "promotionImages") {
            let mediaList = promotionImages.map((pi) =>
                mapPromotionToUnifiedMedia(pi)
            );
            if (activePromotionType) {
                mediaList = mediaList.filter(
                    (item) => (item as any).assetType === activePromotionType
                );
            }
            return mediaList;
        } else if (activeTab === "assets") {
            let mediaList = assets.map((a) => mapAssetsToUnifiedMedia(a));
            if (activeResourceType) {
                mediaList = mediaList.filter((item) => {
                    if (
                        item.mediaType === "image" &&
                        activeResourceType === ResourceType.Logo
                    ) {
                        return (item as Assets).assetType === ResourceType.Logo;
                    }
                    return item.mediaType === activeResourceType.toLowerCase();
                });
            }
            return mediaList;
        } else if (activeTab === "images") {
            return images.map(
                (i) => ({ ...i, mediaType: "image" } as UnifiedMedia)
            );
        } else if (activeTab === "videos") {
            return videos.map(
                (v) => ({ ...v, mediaType: "video" } as UnifiedMedia)
            );
        } else if (activeTab === "archived") {
            return archived.map((a) => mapArchiveToUnifiedMedia(a));
        }
        return [];
    }, [
        activeTab,
        promotionImages,
        activePromotionType,
        assets,
        activeResourceType,
        images,
        videos,
        archived,
    ]);

    const prevCombinedMediaRef = useRef<UnifiedMedia[]>(combinedMedia);

    const assetImagesLength = useMemo(
        () =>
            assets.filter((item) => item.assetType === ResourceType.Image)
                .length,
        [assets]
    );
    const assetVideosLength = useMemo(
        () =>
            assets.filter((item) => item.assetType === ResourceType.Video)
                .length,
        [assets]
    );
    const assetLogosLength = useMemo(
        () =>
            assets.filter((item) => item.assetType === ResourceType.Logo)
                .length,
        [assets]
    );

    const promotionTypeOptions = useMemo(() => {
        const types = new Set<string>();
        promotionImages.forEach((pi) => {
            if (pi.assetType) {
                types.add(pi.assetType);
            }
        });
        return Array.from(types).map((t) => ({
            value: t as PromotionType,
            label: t,
        }));
    }, [promotionImages]);

    useEffect(() => {
        if (!activeResourceType) {
            if (assetImagesLength > 0) {
                setActiveResourceType(ResourceType.Image);
            } else if (assetVideosLength > 0) {
                setActiveResourceType(ResourceType.Video);
            } else if (assetLogosLength > 0) {
                setActiveResourceType(ResourceType.Logo);
            } else {
                setActiveResourceType(null); // Reset if no assets
            }
        }
    }, [
        assetImagesLength,
        assetVideosLength,
        assetLogosLength,
        setActiveResourceType,
        activeResourceType,
    ]);

    useEffect(() => {
        if (promotionTypeOptions.length > 0) {
            setActivePromotionType(promotionTypeOptions[0].value);
        } else {
            setActivePromotionType(null);
        }
    }, [promotionTypeOptions, setActivePromotionType]);

    const isImage = (item: UnifiedMedia): boolean =>
        item.mediaType === "image" || item.mediaType === "logo";
    const isVideo = (item: UnifiedMedia): boolean => item.mediaType === "video";

    const handleSelect = (item: UnifiedMedia) => {
        if (isVideo(item)) {
            if (hasImageSelected) {
                toast.error(
                    <CustomToast
                        message="You can select either one video or up to max images."
                        type="error"
                    />
                );
                return;
            }
            if (selectedMedia.includes(item.id)) {
                onSelect(item.id);
            } else if (selectedMedia.length === 0) {
                onSelect(item.id);
            } else {
                toast.error(
                    <CustomToast
                        message="You can only select one video."
                        type="error"
                    />
                );
            }
        } else if (isImage(item)) {
            if (hasVideoSelected) {
                toast.error(
                    <CustomToast
                        message="You can select either one video or up to max images."
                        type="error"
                    />
                );
                return;
            }
            if (
                !selectedMedia.includes(item.id) &&
                selectedMedia.length >= maxMedia
            ) {
                toast.error(
                    <CustomToast
                        message={`You can select up to ${maxMedia} images.`}
                        type="error"
                    />
                );
                return;
            }
            onSelect(item.id);
        }
    };

    const isSelectionDisabled = (item: UnifiedMedia): boolean => {
        if (item.mediaType === "video" && hasImageSelected) {
            return true;
        }
        if (
            (item.mediaType === "image" || item.mediaType === "logo") &&
            hasVideoSelected
        ) {
            return true;
        }
        if (
            (item.mediaType === "image" || item.mediaType === "logo") &&
            !hasVideoSelected &&
            selectedMedia.length >= maxMedia &&
            !selectedMedia.includes(item.id)
        ) {
            return true;
        }
        return false;
    };

    // Build the selected media text (customize as needed)
    const getSelectedMediaText = () => {
        if (selectedMedia.length > 0) {
            return `Selected ${selectedMedia.length} / ${
                hasVideoSelected ? "1 video" : `${maxMedia} images`
            }`;
        }

        switch (activeTab) {
            case "images":
                return `Selected 0 / ${maxMedia} images`;
            case "videos":
                return "Selected 0 / 1 video";
            case "assets":
                return activeResourceType === ResourceType.Image ||
                    activeResourceType === ResourceType.Logo
                    ? "Selected 0 / 10 images."
                    : "Selected 0 / 1 Video";
            case "promotionImages":
                return `Selected 0 / ${maxMedia} images`;
            default:
                return "other media";
        }
    };

    const handleAssetsUploadedWrapper = async () => {
        if (onAssetsUploaded) {
            await onAssetsUploaded();
            setUploadOccurred(true);
        }
    };

    useEffect(() => {
        if (uploadOccurred) {
            // Get the previously known media IDs.
            const oldIds = new Set(
                prevCombinedMediaRef.current.map((item) => item.id)
            );
            // Find media items that were not present before.
            const newMediaItems = combinedMedia.filter(
                (item) => !oldIds.has(item.id)
            );

            // Separate new items by type.
            const newVideos = newMediaItems.filter(
                (item) => item.mediaType === "video"
            );
            const newImages = newMediaItems.filter(
                (item) =>
                    item.mediaType === "image" || item.mediaType === "logo"
            );

            // Determine the media type of any items already selected.
            let currentSelectionType: "video" | "image" | null = null;
            if (selectedMedia.length > 0) {
                const selectedItem = combinedMedia.find((item) =>
                    selectedMedia.includes(item.id)
                );
                if (selectedItem) {
                    if (selectedItem.mediaType === "video") {
                        currentSelectionType = "video";
                    } else if (
                        selectedItem.mediaType === "image" ||
                        selectedItem.mediaType === "logo"
                    ) {
                        currentSelectionType = "image";
                    }
                }
            }

            // If a new video was uploaded, always replace the selection with the new video.
            if (newVideos.length > 0) {
                setSelectedMedia([newVideos[0].id]);
            }
            // Else if new images were uploaded…
            else if (newImages.length > 0) {
                // If the current selection is a video, then replace it with the new images.
                if (currentSelectionType === "video") {
                    setSelectedMedia(
                        newImages.map((item) => item.id).slice(0, maxMedia)
                    );
                } else {
                    // Otherwise, append new images to the existing image selection.
                    // (Make sure not to include duplicates.)
                    const currentImageSelection = selectedMedia.filter((id) => {
                        const item = combinedMedia.find(
                            (media) => media.id === id
                        );
                        return (
                            item &&
                            (item.mediaType === "image" ||
                                item.mediaType === "logo")
                        );
                    });
                    const newImageIds = newImages
                        .map((item) => item.id)
                        .filter((id) => !currentImageSelection.includes(id));
                    const updatedSelection = [
                        ...currentImageSelection,
                        ...newImageIds,
                    ];
                    // Enforce the max limit for images.
                    if (updatedSelection.length > maxMedia) {
                        setSelectedMedia(updatedSelection.slice(0, maxMedia));
                    } else {
                        setSelectedMedia(updatedSelection);
                    }
                }
            }
            // Reset the flag.
            setUploadOccurred(false);
        }
        // Always update our stored list.
        prevCombinedMediaRef.current = combinedMedia;
    }, [
        combinedMedia,
        uploadOccurred,
        selectedMedia,
        maxMedia,
        setSelectedMedia,
    ]);

    return (
        <div className="space-y-4">
            <h3 className="text-lg font-semibold text-primary">Select Media</h3>
            <span className="text-sm text-gray-500">
                Please select up to {maxMedia} images or one video.
            </span>

            {/* Render a select box for filtering when on the assets tab */}
            {activeTab === "assets" && (
                <div className="flex justify-center">
                    <div className={isMobile ? "w-[80%]" : "w-[30%]"}>
                        <CustomSelect
                            id="resource-type-select-dropdown"
                            options={[
                                ...(assetImagesLength > 0
                                    ? [
                                          {
                                              value: ResourceType.Image,
                                              label: "Image",
                                          },
                                      ]
                                    : []),
                                ...(assetVideosLength > 0
                                    ? [
                                          {
                                              value: ResourceType.Video,
                                              label: "Video",
                                          },
                                      ]
                                    : []),
                                ...(assetLogosLength > 0
                                    ? [
                                          {
                                              value: ResourceType.Logo,
                                              label: "Logo",
                                          },
                                      ]
                                    : []),
                            ]}
                            value={
                                activeResourceType
                                    ? {
                                          value: activeResourceType,
                                          label: activeResourceType,
                                      }
                                    : null
                            }
                            onChange={(selectedOption) => {
                                setActiveResourceType(
                                    selectedOption?.value as ResourceType
                                );
                            }}
                            placeholder="Select Resource Type"
                            isClearable={false}
                            className="mb-6 w-full"
                            bg="#F5F5F5"
                        />
                    </div>
                </div>
            )}

            {/* Render a similar select box for promotion images */}
            {activeTab === "promotionImages" && (
                <div className="flex justify-center">
                    <div className={isMobile ? "w-[80%]" : "w-[30%]"}>
                        <CustomSelect
                            id="promotion-type-select-dropdown"
                            options={promotionTypeOptions}
                            value={
                                activePromotionType
                                    ? promotionTypeOptions.find(
                                          (option) =>
                                              option.value ===
                                              activePromotionType
                                      )
                                    : null
                            }
                            onChange={(selectedOption) => {
                                setActivePromotionType(
                                    selectedOption?.value as PromotionType
                                );
                            }}
                            placeholder="Select Promotion Type"
                            isClearable={false}
                            className="mb-6 w-full"
                            bg="#F5F5F5"
                        />
                    </div>
                </div>
            )}

            {showAssetsUploader && listingId && onAssetsUploaded && (
                <div className="my-4">
                    <AssetsUploader
                        listingId={listingId}
                        onAssetsUploaded={handleAssetsUploadedWrapper}
                        assetType={
                            (activePromotionType as AssetType) ||
                            (activeResourceType as AssetType)
                        }
                        type={
                            type === "asset"
                                ? activeResourceType === ResourceType.Video
                                    ? "assetVideo"
                                    : "assetImage"
                                : type
                        }
                    />
                </div>
            )}

            <div className="mb-2 text-sm text-gray-600">
                {getSelectedMediaText()}
            </div>

            <div
                className={cn(
                    "grid gap-4",
                    isMobile ? "grid-cols-2" : "grid-cols-3"
                )}
            >
                {combinedMedia.map((item) => (
                    <TooltipProvider key={item.id}>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <div
                                    className={cn(
                                        "group relative w-full h-48 cursor-pointer overflow-hidden rounded-lg border transition-transform duration-200",
                                        selectedMedia.includes(item.id) &&
                                            "ring-2 ring-primary",
                                        !selectedMedia.includes(item.id) &&
                                            isSelectionDisabled(item)
                                            ? "opacity-50 cursor-not-allowed"
                                            : ""
                                    )}
                                    onClick={() => {
                                        // Prevent selection if disabled
                                        if (isSelectionDisabled(item)) {
                                            toast.info(
                                                <CustomToast
                                                    message={
                                                        item.mediaType ===
                                                        "video"
                                                            ? "Cannot select video when images are selected."
                                                            : "Cannot select images when a video is selected."
                                                    }
                                                    type="error"
                                                />,
                                                {
                                                    autoClose: 3000,
                                                }
                                            );
                                            return;
                                        }
                                        handleSelect(item);
                                    }}
                                >
                                    {isVideo(item) ? (
                                        <video
                                            muted
                                            src={item.signedUrl}
                                            controls
                                            className="object-cover w-full h-full transition-transform group-hover:scale-105"
                                        />
                                    ) : isImage(item) ? (
                                        <img
                                            src={item.signedUrl}
                                            alt="Selectable Media"
                                            className="object-fill w-full h-full transition-transform group-hover:scale-105"
                                        />
                                    ) : null}
                                    {selectedMedia.includes(item.id) && (
                                        <div className="absolute inset-0 bg-primary/20 flex items-center justify-center">
                                            <span className="absolute bottom-2 right-2 bg-primary text-white text-xs px-2 py-1 rounded">
                                                {selectedMedia.indexOf(
                                                    item.id
                                                ) + 1}
                                            </span>
                                            <FaCheck className="h-6 w-6 text-white" />
                                        </div>
                                    )}
                                </div>
                            </TooltipTrigger>
                            <TooltipContent>
                                <span className="text-white">
                                    {selectedMedia.includes(item.id)
                                        ? "Click to deselect"
                                        : "Click to select"}
                                </span>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                ))}
            </div>

            {selectedMedia.length < minMedia && !hasVideoSelected && (
                <div className="text-red-500 text-sm">
                    Please select at least {minMedia} images to continue.
                </div>
            )}
        </div>
    );
};

export default PostMediaSelector;
