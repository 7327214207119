import React from "react";
import { Route } from "react-router-dom";
import AdminPanelScreen from "../screens/admin/AdminPanelScreen";
import ManageUsersScreen from "../screens/admin/ManageUsersScreen";
import ManagePlanScreen from "../screens/admin/ManagePlanScreen";
import StatisticsScreen from "../screens/admin/StatisticsScreen";
import MaintenanceScreen from "../screens/admin/MaintenanceScreen";

const adminRoutes = (
    <>
        <Route path="/admin" element={<AdminPanelScreen />} />
        <Route path="/admin/manage-users" element={<ManageUsersScreen />} />
        <Route path="/admin/manage-plans" element={<ManagePlanScreen />} />
        <Route path="/admin/statistics" element={<StatisticsScreen />} />
        <Route path="/admin/maintenance" element={<MaintenanceScreen />} />
        <Route path="*" element={<AdminPanelScreen />} />
    </>
);

export default adminRoutes;
