import React, { useRef, useEffect, useCallback } from "react";
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselApi,
} from "./Carousel";
import { MediaCarouselProps } from "../listings/listing/types";
import LeafletMap from "../listings/LeafletMap";
import Media from "./Media";

const MediaCarousel: React.FC<MediaCarouselProps> = ({
    setCarouselApi,
    combinedMedia,
    latitude,
    longitude,
    address,
    isMobile,
}) => {
    const carouselHeight = isMobile ? "h-64" : "h-80";

    const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

    useEffect(() => {
        videoRefs.current = combinedMedia.map(
            (_, i) => videoRefs.current[i] || null
        );
    }, [combinedMedia]);

    const pauseAllVideos = useCallback(() => {
        videoRefs.current.forEach((video) => {
            if (video && !video.paused) {
                video.pause();
            }
        });
    }, []);

    const handleVideoPlay = useCallback((playingIndex: number) => {
        videoRefs.current.forEach((video, index) => {
            if (index !== playingIndex && video && !video.paused) {
                video.pause();
            }
        });
    }, []);

    const handleSelect = useCallback(() => {
        pauseAllVideos();
    }, [pauseAllVideos]);

    const onCarouselInit = useCallback(
        (api: CarouselApi) => {
            api?.on("select", handleSelect);
        },
        [handleSelect]
    );

    return (
        <div className="z-0">
            {combinedMedia.length > 0 ? (
                <div
                    className={`${
                        isMobile ? "mb-12" : "mb-8"
                    } ${carouselHeight} z-0`}
                >
                    <Carousel
                        className={`w-full ${carouselHeight} relative`}
                        setApi={(api) => {
                            setCarouselApi?.(api);
                            onCarouselInit(api);
                        }}
                        isMobile={isMobile}
                    >
                        <CarouselContent>
                            {combinedMedia.map((media, index) => (
                                <CarouselItem key={index}>
                                    <Media
                                        // Conditionally store a ref for videos
                                        ref={
                                            media.type === "video"
                                                ? (el: HTMLVideoElement) =>
                                                      (videoRefs.current[
                                                          index
                                                      ] = el)
                                                : null
                                        }
                                        src={media.url}
                                        type={media.type as "image" | "video"}
                                        alt={`Media ${index}`}
                                        isMobile={isMobile}
                                        // On video play, pause all others
                                        onPlay={
                                            media.type === "video"
                                                ? () => handleVideoPlay(index)
                                                : undefined
                                        }
                                    />
                                </CarouselItem>
                            ))}
                        </CarouselContent>
                    </Carousel>
                </div>
            ) : latitude && longitude && address ? (
                <div className={`z-0 my-4 ${isMobile ? "h-60" : "h-80"}`}>
                    <LeafletMap
                        latitude={latitude}
                        longitude={longitude}
                        address={address}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default MediaCarousel;
