import React, { useEffect, useState } from "react";
import { Button } from "../../components/misc/Button";
import { Label } from "../../components/misc/Label";
import { Switch } from "../../components/misc/Switch";
import { Textarea } from "../../components/misc/Textarea";
import { MaintenanceFormProps } from "../types";

export const MaintenanceForm: React.FC<MaintenanceFormProps> = ({
    maintenance,
    setMaintenance,
    originalMaintenance,
    type,
    onSave,
    loading,
}) => {
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        const changed =
            maintenance.isOnline !== originalMaintenance.isOnline ||
            maintenance.reason !== originalMaintenance.reason;

        setHasChanges(changed);
    }, [maintenance, originalMaintenance]);

    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                <Label htmlFor={`${type}-mode`} className="text-base">
                    {type === "general"
                        ? "Enable Maintenance Mode"
                        : "Enable AI Maintenance Mode"}
                </Label>
                <Switch
                    id={`${type}-mode`}
                    checked={!maintenance.isOnline}
                    onCheckedChange={() =>
                        setMaintenance({
                            isOnline: !maintenance.isOnline,
                            reason: maintenance.reason || "",
                        })
                    }
                />
            </div>
            <div className="space-y-2">
                <Label htmlFor={`${type}-message`}>
                    {type === "general"
                        ? "Maintenance Message"
                        : "AI Maintenance Message"}
                </Label>
                <Textarea
                    id={`${type}-message`}
                    placeholder={
                        type === "general"
                            ? "Enter a message to display to users during maintenance..."
                            : "Enter a message to display when AI features are under maintenance..."
                    }
                    value={maintenance.reason}
                    onChange={(e) =>
                        setMaintenance({
                            isOnline: maintenance.isOnline,
                            reason: e.target.value,
                        })
                    }
                    className="min-h-[100px]"
                />
            </div>
            <Button
                onClick={onSave}
                className="bg-[#E9C46A] hover:bg-[#E9C46A]/90"
                disabled={loading || !hasChanges}
            >
                {loading ? "Saving..." : "Save Changes"}
            </Button>
        </div>
    );
};
