import { Assets, AssetType } from "../../types/asset-type";
import { Images } from "../../types/listingImages";
import { ListingVideos } from "../../types/listingVideos";
import { ListingType } from "../../types/userListing";
import { CarouselApi } from "../misc/Carousel";
import { PromotionType } from "../promotions/types";

export interface ContentTabsProps {
    promotions: PromotionsMedia | null;
    activeTab: "listing" | "promotion" | "resource";
    setActiveTab: (value: "listing" | "promotion" | "resource") => void;
    listingMedia: AllListingMedia[];
    listingTabs: string[];
    activeListingTab: string;
    setActiveListingTab: (value: string) => void;
    loading: boolean;
    mlsApproved: boolean;
    carouselApi: CarouselApi | null;
    fetchAssets: () => void;
    fetchListingMedia: () => void;
    setLoading: (value: boolean) => void;
    carouselIndexMap: { [id: string]: number };
    resources: Assets[];
    fetchResources: () => void;
    isMobile: boolean;
    activeMediaTab: "images" | "videos";
    setActiveMediaTab: React.Dispatch<
        React.SetStateAction<"images" | "videos">
    >;
    activePromotionType: PromotionType;
    setActivePromotionType: React.Dispatch<React.SetStateAction<PromotionType>>;
    activeResourceType: ResourceType;
    setActiveResourceType: React.Dispatch<React.SetStateAction<ResourceType>>;
    onThumbnailClick: (index: number) => void;
}

export const promotionTypes: PromotionType[] = [
    PromotionType.BuyerEducation,
    PromotionType.BuyerTips,
    PromotionType.ClosingProcess,
    PromotionType.FirstTimeHomeBuyer,
    PromotionType.HomeImprovement,
    PromotionType.HomeMaintenance,
    PromotionType.HomeSecurity,
    PromotionType.Mortgage,
    PromotionType.MythBusting,
    PromotionType.RealtorAdvocacy,
    PromotionType.Sustainability,
];

export enum ResourceType {
    Image = "Image",
    Video = "Video",
    Logo = "Logo",
    BuyerEducation = "BuyerEducation",
    BuyerTips = "BuyerTips",
    ClosingProcess = "ClosingProcess",
    FirstTimeHomeBuyer = "FirstTimeHomeBuyer",
    HomeImprovement = "HomeImprovement",
    HomeMaintenance = "HomeMaintenance",
    HomeSecurity = "HomeSecurity",
    Mortgage = "Mortgage",
    MythBusting = "MythBusting",
    RealtorAdvocacy = "RealtorAdvocacy",
    Sustainability = "Sustainability",
}

export const resourceTypes: ResourceType[] = [
    ResourceType.Image,
    ResourceType.Video,
    ResourceType.Logo,
];

export interface ListingsTabProps {
    listingMedia: AllListingMedia[];
    listingTabs: string[];
    activeListingTab: string;
    setActiveListingTab: (value: string) => void;
    activeTab: "listing" | "promotion" | "resource";
    loading: boolean;
    mlsApproved: boolean;
    carouselApi: CarouselApi | null;
    setLoading: (value: boolean) => void;
    fetchListingMedia: () => void;
    carouselIndexMap: { [id: string]: number };
    isEditing: boolean;
    isMobile: boolean;
    activeMediaTab: "images" | "videos";
    setActiveMediaTab: React.Dispatch<
        React.SetStateAction<"images" | "videos">
    >;
    onThumbnailClick: (index: number) => void;
    isUploading: boolean;
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface PromotionTabProps {
    assets: Assets[];
    activePromotionTab: AssetType;
    setActivePromotionTab: (value: AssetType) => void;
}

export interface ContentProps {
    mlsApproved: boolean | null;
}

export interface AllListingMedia {
    listingId: string;
    listingType: ListingType;
    address: string;
    listingStatus: string;
    images: Images[];
    videos: ListingVideos[];
    assets: Assets[];
}

export interface PromotionsMedia {
    id: string;
    assets: Assets[];
}

export interface ResourcesProps {
    resources: Assets[];
    isEditing: boolean;
    carouselApi: CarouselApi | null;
    carouselIndexMap: { [id: string]: number };
    fetchResources: () => void;
    setLoading: (value: boolean) => void;
    isMobile: boolean;
    activeResourceType: ResourceType;
    setActiveResourceType: React.Dispatch<React.SetStateAction<ResourceType>>;
    onThumbnailClick: (index: number) => void;
    isUploading: boolean;
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
}
