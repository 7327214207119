import React, { useEffect, useState } from "react";
import facebookLogo from "../../../assets/images/facebook_logo.png";
import instagramLogo from "../../../assets/images/instagram_logo.png";
import SocialCard from "./SocialCard";
import axios from "axios";
import { getAuthHeader } from "../../../utils/authHeader";
import { toast } from "react-toastify";
import CustomToast from "../../misc/CustomToast";
import LoadingScreen from "../../../screens/LoadingScreen";
import { Card, CardContent, CardHeader } from "../../misc/Card";
import { Button } from "../../misc/Button";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { useDispatch } from "react-redux";
import { setSocialsConnected } from "../../../store/authSlice";
import { useSocket } from "../../../context/SocketContext";
import {
    SocialConnectionsData,
    SocialConnectionsProps,
    SocialUserData,
} from "../types";
import ConnectSocialAccountsButton from "../../misc/ConnectSocialAccountsButton";

const SocialConnections: React.FC<SocialConnectionsProps> = ({
    isOnboarding,
}: SocialConnectionsProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [connections, setConnections] = useState<SocialConnectionsData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { socket } = useSocket();

    useEffect(() => {
        if (!socket) return;
        socket.emit("joinSocialConnectionsRoom");

        // Handler for social connections update
        const handleSocialsUpdate = async (
            updatedConnections: SocialConnectionsData[]
        ) => {
            const supportedPlatforms = ["facebook", "instagram"];

            const defaultUserDataMap: { [key: string]: SocialUserData } = {
                facebook: {
                    userImage: "",
                    displayName: "",
                    pageName: "",
                },
                instagram: {
                    userImage: "",
                    displayName: "",
                    username: "",
                },
            };

            const mergedConnections: SocialConnectionsData[] =
                supportedPlatforms.map((platform) => {
                    // Find if the updatedConnections has data for the current platform
                    const updated = updatedConnections.find(
                        (conn) =>
                            conn.platform.toLowerCase() ===
                            platform.toLowerCase()
                    );

                    if (updated) {
                        return {
                            platform,
                            connected: updated.connected,
                            userData: updated.userData,
                        };
                    } else {
                        // If no data from backend, use default values
                        return {
                            platform,
                            connected: false,
                            userData: defaultUserDataMap[platform] || {
                                userImage: "",
                                displayName: "",
                            },
                        };
                    }
                });

            setConnections(mergedConnections);
            setLoading(false);

            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            const res = await axios.patch(
                `${backendUrl}/settings/socials-connected`,
                { socialsConnected: true },
                { headers }
            );

            if (res.status === 200) {
                sessionStorage.setItem("welcome", "true");
                dispatch(setSocialsConnected(true));
            }
        };

        socket.on("socialsUpdate", handleSocialsUpdate);

        socket.on("connect_error", (err: any) => {
            console.error("WebSocket connection error:", err);
        });

        return () => {
            socket.off("socialsUpdate", handleSocialsUpdate);
        };
    }, [socket, dispatch]);

    useEffect(() => {
        const fetchConnections = async () => {
            setLoading(true);
            try {
                const headers = await getAuthHeader();
                const backendUrl = process.env.REACT_APP_BACKEND_URL;

                const response = await axios.get(
                    `${backendUrl}/socials/profiles`,
                    { headers }
                );

                const responseData = response.data.data || response.data;
                const activeSocialAccounts =
                    responseData.activeSocialAccounts || [];
                if (isOnboarding && activeSocialAccounts.length > 0) {
                    const res = await axios.patch(
                        `${backendUrl}/settings/socials-connected`,
                        { socialsConnected: true },
                        { headers }
                    );

                    if (res.status === 200) {
                        sessionStorage.setItem("welcome", "true");
                        dispatch(setSocialsConnected(true));
                    } else {
                        toast.error(
                            <CustomToast
                                message={res.data.message}
                                type="error"
                            />,
                            {
                                autoClose: 3000,
                            }
                        );
                        console.error(res.data.message);
                    }
                }
                const displayNamesArray = responseData.displayNames || [];

                // Create a map for easier access
                const displayNamesMap: { [key: string]: SocialUserData } = {};
                displayNamesArray.forEach((item: any) => {
                    displayNamesMap[item.platform] = {
                        userImage: item.userImage || "",
                        displayName: item.displayName || "",
                        pageName: item.pageName,
                        username: item.username,
                    };
                });

                const fetchedConnections: SocialConnectionsData[] = [
                    {
                        platform: "facebook",
                        connected: activeSocialAccounts.includes("facebook"),
                        userData: displayNamesMap["facebook"] || {
                            userImage: "",
                            displayName: "",
                            pageName: "",
                        },
                    },
                    {
                        platform: "instagram",
                        connected: activeSocialAccounts.includes("instagram"),
                        userData: displayNamesMap["instagram"] || {
                            userImage: "",
                            displayName: "",
                            username: "",
                        },
                    },
                ];

                setConnections(fetchedConnections);
            } catch (error) {
                console.error("Fetch Connections Error:", error);
                let errorMessage =
                    "Error fetching social profiles. Please try again later.";
                if (axios.isAxiosError(error) && error.response) {
                    errorMessage += ` Server responded with status ${error.response.status}.`;
                }

                toast.error(
                    <CustomToast message={errorMessage} type="error" />,
                    {
                        autoClose: 3000,
                    }
                );
            } finally {
                setLoading(false);
            }
        };

        // Fetch initial connections on mount
        fetchConnections();
    }, [dispatch, isOnboarding, navigate]);

    const isMobile = useIsMobile(1200);

    return (
        <Card
            className={`w-full ${
                isMobile
                    ? "m-0 rounded-none h-full"
                    : "max-w-4xl mx-auto h-auto"
            }`}
        >
            {loading && <LoadingScreen />}
            <div className={`p-4 ${isMobile ? "px-4" : ""}`}>
                {!isOnboarding && (
                    <Button
                        id="back-to-settings-btn"
                        onClick={() => navigate("/settings")}
                        className="mb-4 flex items-center"
                    >
                        <ArrowLeft className="mr-2 h-5 w-5" /> Back to Settings
                    </Button>
                )}
            </div>
            <CardHeader>
                <div
                    className={`flex ${
                        isMobile
                            ? "flex-col items-start gap-2"
                            : "flex-row items-center justify-between"
                    }`}
                >
                    <div className="flex flex-col">
                        <h2 className="text-2xl font-bold text-primary">
                            Social Media Connections
                        </h2>
                        {isOnboarding && (
                            <span className="text-sm text-neutral-500">
                                Connect your social media accounts to get
                                started.
                            </span>
                        )}
                    </div>
                    <div className={isMobile ? "w-full mt-4" : ""}>
                        <ConnectSocialAccountsButton
                            text="Manage Connections"
                            redirectUrl={`${process.env.REACT_APP_FRONTEND_URL}/connections/redirect`}
                        />
                    </div>
                </div>
            </CardHeader>
            <CardContent
                className={isMobile ? "p-0 px-4 pb-4" : "p-0 px-10 pb-10"}
            >
                <div className="space-y-6">
                    <div className="grid gap-4 md:grid-cols-2">
                        {connections.map((connection) => {
                            const { platform, userData, connected } =
                                connection;

                            let icon;
                            if (platform === "facebook") {
                                icon = (
                                    <img
                                        src={facebookLogo}
                                        alt="Facebook Logo"
                                        className="h-6 w-6"
                                    />
                                );
                            } else if (platform === "instagram") {
                                icon = (
                                    <img
                                        src={instagramLogo}
                                        alt="Instagram Logo"
                                        className="h-6 w-6"
                                    />
                                );
                            }

                            return (
                                <SocialCard
                                    key={platform}
                                    title={
                                        platform.charAt(0).toUpperCase() +
                                        platform.slice(1)
                                    }
                                    icon={icon}
                                    connected={connected}
                                    userData={userData}
                                    isOnboarding={isOnboarding}
                                />
                            );
                        })}
                    </div>
                </div>
            </CardContent>
            <div
                className={isMobile ? "px-4 pb-4" : "px-10 pb-6"}
                onClick={() => {
                    navigate("/help/connect-social-media");
                }}
            >
                <p className="text-sm text-gray-600">
                    <strong className="text-primary hover:text-secondary cursor-pointer">
                        Setup Instructions:
                    </strong>{" "}
                    Click here for a step-by-step guide to connecting your
                    social media accounts.
                </p>
            </div>
        </Card>
    );
};

export default SocialConnections;
