import React, { useState, useMemo, useEffect, useRef } from "react";
import { Promotion as ProType, PromotionProps, PromotionType } from "./types";
import CampaignCard from "./CampaignCard";
import TabsComponent from "./TabsComponent";
import MediaCarousel from "../misc/MediaCarousel";
import { CarouselApi } from "../misc/Carousel";
import UserNotesCard from "./UserNotesCard";
import { useSocket } from "../../context/SocketContext";
import { CampaignProgress } from "../campaign/types";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { useIsMobile } from "../../hooks/useIsMobile";
import { ImCross } from "react-icons/im";
import { FiAlertCircle } from "react-icons/fi";

const Promotion: React.FC<PromotionProps> = ({
    promotion,
    setPromotion,
    isEditing,
    setIsEditing,
    assets,
    setLoading,
    fetchAssets,
    fetchPromotions,
    originalPromotion,
    setOriginalPromotion,
    showSaveWarning,
    setShowSaveWarning,
    activeTab,
    setActiveTab,
    activePromotionType,
    setActivePromotionType,
}) => {
    const { socket, isAuthenticated } = useSocket();

    // State to manage the carousel API
    const [carouselApi, setCarouselApi] = useState<CarouselApi | null>(null);

    // State to store selected image indices per promotion type
    const [selectedImageIndices, setSelectedImageIndices] = useState<{
        [key in PromotionType]?: number;
    }>({});

    // Memoized carousel assets: All promotion type assets ordered by promotion.types
    const carouselAssets = useMemo(() => {
        if (!activePromotionType) return [];
        return assets.filter(
            (asset) => asset.assetType === activePromotionType
        );
    }, [assets, activePromotionType]);

    // Mapping from asset ID to index in carouselAssets
    const [carouselAssetIndexMap, setCarouselAssetIndexMap] = useState<{
        [id: string]: number;
    }>({});

    // Ref to track subscribed campaigns
    const subscribedCampaignsRef = useRef<Set<string>>(new Set());

    // Extract campaignId and campaignStatus using useMemo for stability
    const campaignId = useMemo(() => promotion?.campaignId, [promotion]);
    const campaignStatus = useMemo(
        () => promotion?.campaignStatus,
        [promotion]
    );

    // Optional: Use a ref to always have the latest promotion
    const promotionRef = useRef<ProType | null>(promotion);

    useEffect(() => {
        promotionRef.current = promotion;
    }, [promotion]);

    useEffect(() => {
        const map: { [id: string]: number } = {};
        carouselAssets?.forEach((asset, index) => {
            map[asset.id] = index;
        });
        setCarouselAssetIndexMap(map);
    }, [carouselAssets]);

    useEffect(() => {
        if (!socket || !isAuthenticated || !campaignId) return;

        const currentSubscribedCampaigns = subscribedCampaignsRef.current;

        // Only subscribe if the campaign is in "PROCESSING" status and not already subscribed
        if (
            campaignStatus === "PROCESSING" &&
            !currentSubscribedCampaigns.has(campaignId)
        ) {
            socket.emit("subscribeToCampaign", campaignId);
            currentSubscribedCampaigns.add(campaignId);
            console.log(`Subscribed to campaign: ${campaignId}`);
        }

        const handleProgressUpdate = (data: {
            entityId: string;
            entityType: "campaign";
            progress: CampaignProgress;
        }) => {
            const { entityId, entityType, progress } = data;

            if (entityType === "campaign" && entityId === campaignId) {
                if (progress.currentStep === "Completed") {
                    toast.success(
                        <CustomToast
                            message="Campaign completed successfully."
                            type="success"
                        />,
                        {
                            autoClose: 3000,
                        }
                    );
                    fetchPromotions();
                }

                setPromotion((prevPromotion) => {
                    if (!prevPromotion) return prevPromotion;

                    return {
                        ...prevPromotion,
                        campaignProgress: progress,
                        campaignStatus:
                            progress.currentStep === "Completed"
                                ? "ACTIVE"
                                : prevPromotion.campaignStatus,
                    };
                });
            }
        };

        // Listen for progress updates
        socket.on("progressUpdate", handleProgressUpdate);

        // Cleanup function
        return () => {
            // Remove the progress update listener
            socket.off("progressUpdate", handleProgressUpdate);

            // Unsubscribe only if the campaign was subscribed in this effect
            if (
                campaignStatus === "PROCESSING" &&
                currentSubscribedCampaigns.has(campaignId)
            ) {
                socket.emit("unsubscribeFromCampaign", campaignId);
                currentSubscribedCampaigns.delete(campaignId);
                console.log(`Unsubscribed from campaign: ${campaignId}`);
            }
        };
    }, [
        socket,
        isAuthenticated,
        campaignId,
        campaignStatus,
        fetchPromotions,
        setPromotion,
    ]);

    const isMobile = useIsMobile(1300);

    return (
        <div className="container mx-auto px-4 py-8">
            <div className={`flex ${isMobile ? "flex-col" : "flex-row"}`}>
                {/* Left Column */}
                <div
                    className={`w-full ${
                        isMobile ? "max-w-full" : "lg:flex-1 max-w-[70%] mr-4"
                    }`}
                >
                    <div className="flex flex-col mb-4">
                        <h1
                            className={`text-3xl font-bold text-primary ${
                                isMobile ? "text-2xl" : ""
                            }`}
                        >
                            Promotion
                        </h1>
                    </div>

                    {/* Media Carousel: Display if there are any promotion type assets */}
                    {carouselAssets?.length && carouselAssets?.length > 0 ? (
                        <div className={`${isMobile ? "px-2" : "px-0"}`}>
                            <MediaCarousel
                                setCarouselApi={setCarouselApi}
                                isMobile={isMobile}
                                combinedMedia={carouselAssets.map((asset) => ({
                                    ...asset,
                                    type: "image", // Adjust if asset types vary
                                    assetType: asset.assetType,
                                }))}
                            />
                        </div>
                    ) : null}

                    {/* Tabs Component */}
                    <TabsComponent
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        promotion={promotion}
                        assets={assets}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        activePromotionType={activePromotionType}
                        setActivePromotionType={setActivePromotionType}
                        carouselApi={carouselApi}
                        carouselAssetIndexMap={carouselAssetIndexMap}
                        setLoading={setLoading}
                        setPromotion={setPromotion}
                        fetchAssets={fetchAssets}
                        originalPromotion={originalPromotion}
                        fetchPromotions={fetchPromotions}
                        setOriginalPromotion={setOriginalPromotion}
                        setShowSaveWarning={setShowSaveWarning}
                        selectedImageIndices={selectedImageIndices}
                        setSelectedImageIndices={setSelectedImageIndices}
                    />

                    {showSaveWarning ? (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
                            <div
                                className={`relative ${
                                    isMobile ? "w-full max-w-md" : "w-1/3"
                                } rounded-lg bg-white p-6 shadow-lg`}
                            >
                                <button
                                    onClick={() => setShowSaveWarning(false)}
                                    className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 focus:outline-none"
                                    aria-label="Close"
                                >
                                    <ImCross className="h-6 w-6" />
                                </button>
                                <div className="flex flex-col items-center text-center">
                                    <FiAlertCircle className="h-16 w-16 text-yellow-500" />
                                    <h2 className="mt-4 text-xl sm:text-2xl font-bold text-primary">
                                        Changes Made
                                    </h2>
                                    <p className="mt-2 text-gray-600 text-sm sm:text-base">
                                        Any edits made to details will only
                                        apply to the next campaign renewal.
                                        Changes to details will not impact their
                                        use in any ongoing or existing campaign
                                        but will be reflected in future campaign
                                        renewals. Please review your edits
                                        carefully before saving.
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>

                {/* Right Column */}
                <div
                    className={`w-full ${
                        isMobile
                            ? "max-w-full mt-4 mb-20"
                            : "lg:w-1/3 max-w-[30%] ml-4"
                    }`}
                >
                    {/* Campaign Card */}
                    {promotion ? <CampaignCard promotion={promotion} /> : null}
                    {/* User Notes Card */}
                    {promotion ? (
                        <UserNotesCard
                            promotion={promotion}
                            setPromotion={setPromotion}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};
export default Promotion;
