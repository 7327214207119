import React from "react";
import { ImageTabProps } from "./types";
import { TabsContent } from "@radix-ui/react-tabs";
import { Card, CardContent } from "../../../misc/Card";
import { Button } from "../../../misc/Button";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import DragAndDropMedia from "../../../misc/DragAndDrop";
import { MediaItem } from "../types";
import { calculateTotalPages } from "./utils";
import MediaThumbnails from "./MediaThumbnails";
import { Images } from "../../../../types/listingImages";
import DeleteMediaButton from "./DeleteMediaButton";
import { useIsMobile } from "../../../../hooks/useIsMobile";

const ImagesTab: React.FC<ImageTabProps> = ({
    images,
    listingId,
    handleUploadComplete,
    toggleSelectMedia,
    selectedMedia,
    isEditing,
    carouselApi,
    combinedMedia,
    mlsApproved,
    setSelectedMedia,
    setIsLoading,
    itemsPerPage = 10,
    currentPage,
    setCurrentPage,
    carouselIndexMap,
    onCarouselScroll,
    propertyStatus,
    isUploading,
    setIsUploading,
    listingType,
}) => {
    const isMobile = useIsMobile(1200);

    const imageItems: MediaItem[] = images.map((image: Images) => ({
        ...image,
        type: "image",
    }));

    const totalPages = calculateTotalPages("images", images, isMobile);
    const adjustedCurrentPage = totalPages > 0 ? currentPage : 0;

    return (
        <TabsContent value="images">
            <Card>
                <CardContent className="pt-6">
                    <MediaThumbnails
                        mediaItems={imageItems}
                        currentPage={adjustedCurrentPage}
                        itemsPerPage={itemsPerPage}
                        toggleSelectMedia={toggleSelectMedia}
                        selectedMedia={selectedMedia}
                        isEditing={isEditing}
                        carouselApi={carouselApi}
                        combinedMedia={combinedMedia}
                        mlsApproved={mlsApproved || false}
                        carouselIndexMap={carouselIndexMap}
                        onMediaClick={(index: number) => {
                            if (typeof index === "number") {
                                console.log("Thumbnail clicked, index:", index);
                                if (onCarouselScroll) {
                                    onCarouselScroll(index);
                                } else {
                                    console.error(
                                        "onCarouselScroll is not a function"
                                    );
                                }
                            } else {
                                console.error(
                                    "Clicked index is not a number:",
                                    index
                                );
                            }
                        }}
                    />
                    <div className="flex justify-between items-center mt-4">
                        <Button
                            id="prev-page-btn"
                            variant="outline"
                            onClick={() =>
                                setCurrentPage(
                                    Math.max(adjustedCurrentPage - 1, 1)
                                )
                            }
                            disabled={
                                adjustedCurrentPage === 0 ||
                                adjustedCurrentPage === 1
                            }
                            className="text-primary hover:text-secondary"
                        >
                            <FaArrowLeft className="mr-2 h-4 w-4" /> Previous
                        </Button>
                        <span className="text-primary">
                            {isMobile ? "" : "Page"} {adjustedCurrentPage} of{" "}
                            {totalPages}
                        </span>
                        <Button
                            id="next-page-btn"
                            variant="outline"
                            onClick={() =>
                                setCurrentPage(
                                    Math.min(
                                        adjustedCurrentPage + 1,
                                        totalPages
                                    )
                                )
                            }
                            disabled={adjustedCurrentPage === totalPages}
                            className="text-primary hover:text-secondary"
                        >
                            Next <FaArrowRight className="ml-2 h-4 w-4" />
                        </Button>
                    </div>
                    {isEditing && selectedMedia.length > 0 ? (
                        <div className="my-2 flex justify-end">
                            <DeleteMediaButton
                                selectedMedia={selectedMedia}
                                setIsLoading={setIsLoading}
                                mlsApproved={mlsApproved || false}
                                listingId={listingId}
                                handleDeleteComplete={handleUploadComplete}
                                setSelectedMedia={setSelectedMedia}
                                listingType={listingType}
                            />
                        </div>
                    ) : null}
                    {propertyStatus?.toLowerCase() === "active" ? (
                        <DragAndDropMedia
                            type="image"
                            listingId={listingId}
                            onUploadComplete={handleUploadComplete}
                            mlsApproved={mlsApproved || false}
                            isUploading={isUploading}
                            setIsUploading={setIsUploading}
                            listingType={listingType}
                        />
                    ) : null}
                </CardContent>
            </Card>
        </TabsContent>
    );
};

export default ImagesTab;
