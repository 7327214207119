import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "../misc/Button";
import { X, Plus } from "lucide-react";
import { RiHomeGearFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

interface MLSWelcomeProps {
    setCancelMlsWelcome: React.Dispatch<React.SetStateAction<boolean>>;
}

const MLSWelcome: React.FC<MLSWelcomeProps> = ({ setCancelMlsWelcome }) => {
    const navigate = useNavigate();

    useEffect(() => {
        // Prevent scrolling on the body when the modal is open
        const originalStyle = window.getComputedStyle(document.body).overflow;
        document.body.style.overflow = "hidden";
        return () => {
            // Restore original overflow when the modal is closed
            document.body.style.overflow = originalStyle;
        };
    }, []);

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm flex items-center justify-center z-40"
            >
                <motion.div
                    initial={{ scale: 0.9, y: 50 }}
                    animate={{ scale: 1, y: 0 }}
                    exit={{ scale: 0.9, y: 50 }}
                    transition={{ type: "spring", stiffness: 300, damping: 30 }}
                    className="bg-white rounded-2xl p-8 max-w-md w-full relative shadow-2xl"
                >
                    <motion.div
                        initial={{ y: 0, opacity: 1 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.2 }}
                    >
                        <X
                            className="h-6 w-6 text-red-500 absolute top-4 right-4 cursor-pointer"
                            onClick={() => setCancelMlsWelcome(true)}
                        />
                    </motion.div>
                    <motion.div
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.2 }}
                    >
                        <h2 className="text-3xl font-bold mb-6 text-center text-primary">
                            Welcome to MLS Integration
                        </h2>
                    </motion.div>
                    <motion.p
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.3 }}
                        className="text-center mb-8 text-gray-600"
                    >
                        Enhance your listings with automatic updates from the
                        Multiple Listing Service. Stay current with real-time
                        data and streamline your real estate workflow.
                    </motion.p>
                    <motion.div
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.4 }}
                        className="space-y-4"
                    >
                        <Button
                            className="w-full"
                            onClick={() => {
                                navigate("/create-listing");
                            }}
                        >
                            <Plus className="mr-2 h-5 w-5" />
                            Create Your First MLS Listing
                        </Button>
                        <Button
                            variant="secondary"
                            className="w-full"
                            onClick={() => setCancelMlsWelcome(true)}
                        >
                            <RiHomeGearFill className="mr-2 h-5 w-5" />
                            View My Listings
                        </Button>
                    </motion.div>
                </motion.div>
            </motion.div>
        </AnimatePresence>
    );
};

export default MLSWelcome;
