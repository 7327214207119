import React, { useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { Button } from "../misc/Button";
import StepProgressBar from "../progress-bar";
import logo from "../../assets/images/logo.png";
import { auth } from "../../firebase/firebase-config";
import { getIdToken } from "firebase/auth";
import axios from "axios";
import LoadingScreen from "../../screens/LoadingScreen";
import { Card, CardContent } from "../misc/Card";
import Divider from "../misc/Divider";
import { Link } from "react-router-dom";
import { useIsMobile } from "../../hooks/useIsMobile";

interface PaymentFormProps {
    advanceStep: () => void;
    goBackStep: () => void;
    isOnboarding: boolean;
    details: {
        name: string;
        price: number;
        amountDue: number;
        nextDate: number;
    };
}

const PaymentForm: React.FC<PaymentFormProps> = ({
    advanceStep,
    goBackStep,
    isOnboarding,
    details,
}) => {
    // Use our custom hook to determine if the screen is mobile (e.g. width <= 700px)
    const isMobile = useIsMobile(700);

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const stripe = useStripe();
    const elements = useElements();

    const handleBack = () => {
        goBackStep();
    };

    const handleNext = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!stripe || !elements) {
            toast.error(
                <CustomToast
                    message="Stripe.js has not yet loaded. Please try again."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }

        setIsProcessing(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {},
            redirect: "if_required",
        });

        if (error) {
            toast.error(
                <CustomToast
                    message={`Payment failed: ${error.message}`}
                    type="error"
                />,
                { autoClose: 3000 }
            );
            setIsProcessing(false);
        } else {
            toast.success(
                <CustomToast message="Payment succeeded!" type="success" />,
                { autoClose: 3000 }
            );

            try {
                const currentUser = auth.currentUser;
                if (!currentUser) throw new Error("User not authenticated");
                const token = await getIdToken(currentUser, true);
                const backendUrl = process.env.REACT_APP_BACKEND_URL;

                const response = await axios.get(
                    `${backendUrl}/onboarding/activate-user`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                if (isOnboarding) {
                    await axios
                        .get(
                            `${backendUrl}/onboarding/send-verification-email`,
                            {
                                headers: { Authorization: `Bearer ${token}` },
                            }
                        )
                        .catch((error: Error) => {
                            console.error(error);
                        });
                }
                console.log(response);

                if (response.status === 200) {
                    toast.success(
                        <CustomToast
                            message="Account activated successfully!"
                            type="success"
                        />,
                        { autoClose: 3000 }
                    );

                    if (response.data) {
                        window.location.reload();
                    } else {
                        advanceStep();
                    }
                }
            } catch (error) {
                console.error("Error activating account:", error);
                toast.error(
                    <CustomToast
                        message="Account activation failed. Please contact support."
                        type="error"
                    />,
                    { autoClose: 3000 }
                );
            } finally {
                setIsProcessing(false);
            }
        }
    };

    if (!stripe || !elements) {
        return <LoadingScreen />;
    }

    return (
        <main
            className={`flex flex-col justify-center items-center min-h-screen bg-background shadow-[0px_4px_4px_rgba(0,0,0,0.25)] ${
                isMobile ? "overflow-y-auto" : "overflow-hidden"
            }`}
        >
            <section
                className={`flex flex-col items-center px-8 py-20 bg-white ${
                    isMobile
                        ? "w-full min-h-full pb-24"
                        : "w-[905px] min-h-[833px] rounded-2xl"
                }`}
            >
                <div className="w-full flex flex-col items-center mb-8">
                    <img
                        loading="lazy"
                        src={logo}
                        alt="Agent Registration Logo"
                        className="object-contain self-center max-w-full aspect-[8.77] w-[395px]"
                    />
                    <h1 className="my-2 text-primary text-2xl font-bold leading-none text-center">
                        {isOnboarding
                            ? "User Onboarding"
                            : "Resubscribe to Marabot"}
                    </h1>
                    {isOnboarding && (
                        <StepProgressBar
                            steps={[
                                {
                                    label: "Agent Details",
                                    isActive: true,
                                    isCompleted: true,
                                },
                                {
                                    label: "Broker Details",
                                    isActive: true,
                                    isCompleted: true,
                                },
                                {
                                    label: "Choose MLS",
                                    isActive: true,
                                    isCompleted: true,
                                },
                                {
                                    label: "Select Plan",
                                    isActive: true,
                                    isCompleted: true,
                                },
                                {
                                    label: "Payment",
                                    isActive: true,
                                    isCompleted: false,
                                },
                            ]}
                        />
                    )}
                </div>

                <div
                    className={`w-full ${
                        isMobile
                            ? "flex flex-col space-y-4"
                            : "flex justify-center space-x-4"
                    }`}
                >
                    <Card
                        className={`${
                            isMobile ? "w-full" : "w-1/3 self-start"
                        }`}
                    >
                        <CardContent className="flex flex-col items-start space-y-2 mb-4">
                            <h2 className="text-xl font-bold text-primary">
                                Payment Information:
                            </h2>
                            <div className="text-neutral-500 text-base space-y-2">
                                <p>{`Plan: ${details.name}`}</p>
                                <p>{`Monthly: $${details.price / 100}`}</p>
                                <p>{`Prorated Discount: -$${
                                    (details.price - details.amountDue) / 100
                                }`}</p>
                                <Divider />
                                <p>{`Total Due Today: $${
                                    details.amountDue / 100
                                }`}</p>
                                <p className="text-sm">
                                    {`Automatic monthly renewal begins on ${new Date(
                                        details.nextDate * 1000 + 86400000
                                    ).toLocaleDateString("en-US", {
                                        month: "long",
                                        day: "numeric",
                                        year: "numeric",
                                    })} for $${details.price / 100}`}
                                </p>
                            </div>
                        </CardContent>
                    </Card>

                    <Card className={`${isMobile ? "w-full" : "w-2/3"}`}>
                        <CardContent>
                            <div className="flex-grow flex items-center w-full">
                                <form onSubmit={handleNext} className="w-full">
                                    <div className="w-full">
                                        <PaymentElement />
                                    </div>
                                    {isProcessing && <LoadingScreen />}
                                    <div
                                        className={`w-full mt-8 ${
                                            isMobile
                                                ? "flex flex-col gap-4"
                                                : "flex justify-between gap-20"
                                        }`}
                                    >
                                        <Button
                                            className={`${
                                                isMobile ? "w-full" : "w-1/3"
                                            }`}
                                            onClick={handleBack}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            type="submit"
                                            disabled={
                                                !stripe ||
                                                !elements ||
                                                isProcessing
                                            }
                                            className={`${
                                                isMobile ? "w-full" : ""
                                            }`}
                                        >
                                            {isProcessing
                                                ? "Processing..."
                                                : "Pay"}
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                <div className="mt-10 text-neutral-500 text-sm text-center">
                    *Charges will appear as ISPEAK.AI, our corporate entity. For
                    questions, contact{" "}
                    <Link
                        to="mailto://support@ispeak.ai"
                        className="text-primary hover:text-secondary cursor-pointer"
                        target="_blank"
                    >
                        customer-support@ispeak.ai
                    </Link>
                </div>
            </section>
        </main>
    );
};

export default PaymentForm;
