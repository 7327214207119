import React from "react";
import { Outlet } from "react-router-dom";
import TopBar from "../components/navigation-bars/TopBar";
import SideBar from "../components/navigation-bars/SideBar";
import { User } from "../types/user";
import { useIsMobile } from "../hooks/useIsMobile";

interface Props {
    isAdmin: boolean;
    user: User | null;
    isAdminView: boolean;
    maintenance: boolean;
}

const UserLayout: React.FC<Props> = ({
    isAdmin,
    user,
    isAdminView,
    maintenance,
}) => {
    const isMobile = useIsMobile(900);

    return (
        <div className="flex flex-col h-screen">
            {/* TopBar */}
            <header className="h-16 flex items-center px-4 flex-shrink-0">
                <TopBar
                    isAdmin={isAdmin}
                    user={user}
                    isAdminView={isAdminView}
                />
            </header>
            {maintenance && isAdmin ? (
                <header>
                    <div className="flex items-center justify-center h-16 bg-red-700">
                        <h1 className="text-center text-2xl font-bold text-white">
                            Maintenance Mode
                        </h1>
                    </div>
                </header>
            ) : null}

            {/* Main Content Area */}
            <div className="flex flex-1 overflow-hidden">
                {/* SideBar on Desktop */}
                {!isMobile && (
                    <aside className="bg-background text-white flex-shrink-0">
                        <SideBar />
                    </aside>
                )}

                {/* Page Content */}
                <main
                    className={`relative flex-1 bg-background overflow-auto ${
                        isMobile ? "pb-20" : ""
                    }`}
                >
                    <Outlet />
                </main>
            </div>

            {/* SideBar on Mobile */}
            {isMobile && <SideBar />}
        </div>
    );
};

export default UserLayout;
