import React from "react";
import {
    Link,
    Routes,
    Route,
    useNavigate,
    useLocation,
} from "react-router-dom";
import SocialConnections from "./Connections/SocialConnections";
import PersonalInformation from "./PersonalInformation";
import Subscription from "./Subscription/Subscription";
import {
    BellDot,
    CreditCard,
    Settings as SettingsIcon,
    Share2,
    User,
    Users,
} from "lucide-react";
import { Card, CardContent } from "../misc/Card";
import { useIsMobile } from "../../hooks/useIsMobile";
import ManageTeam from "./ManageTeam";
import NotificationPreferences from "./NotificationPreferences";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const settingsSections = [
    {
        id: "connections",
        title: "Connections",
        component: SocialConnections,
        icon: Share2,
        description: "Manage your social media connections",
    },
    {
        id: "notification-preferences",
        title: "Notification Preferences",
        component: NotificationPreferences,
        icon: BellDot,
        description: "Manage your notification preferences",
    },
    {
        id: "manage-team",
        title: "Manage Team",
        component: ManageTeam,
        icon: Users,
        description: "Manage your team members",
    },
    {
        id: "personal-information",
        title: "Personal Information",
        component: PersonalInformation,
        icon: User,
        description: "Update your personal information",
    },
    {
        id: "subscription",
        title: "Subscription",
        component: Subscription,
        icon: CreditCard,
        description: "Manage your subscription plan and billing",
    },
];

const Settings = () => {
    console.log("Settings");
    const user = useSelector((state: RootState) => state.auth.user);
    const isMobile = useIsMobile(900);
    const navigate = useNavigate();
    const location = useLocation();

    // Determine if a specific section is active based on the URL
    const pathParts = location.pathname.split("/");
    const activeSectionId = pathParts[pathParts.length - 1];

    // Redirect to the main settings page if the route is invalid
    React.useEffect(() => {
        if (
            activeSectionId &&
            !settingsSections.some((section) => section.id === activeSectionId)
        ) {
            navigate("/settings", { replace: true });
        }
    }, [activeSectionId, navigate]);

    return (
        <div
            className={`container mx-auto px-4 py-6 sm:py-10 ${
                isMobile ? "bg-white min-h-full" : "bg-background h-auto"
            }`}
        >
            <Card className="w-full max-w-4xl mx-auto">
                <CardContent className="p-0">
                    <div className="p-4 sm:p-6">
                        <div>
                            <h1 className="flex items-center text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-primary">
                                <SettingsIcon className="h-6 w-6 sm:h-8 sm:w-8 mr-3 sm:mr-4 text-secondary" />
                                Settings
                            </h1>
                            <div
                                className={`grid gap-4 ${
                                    isMobile ? "grid-cols-1" : "grid-cols-2"
                                }`}
                            >
                                {settingsSections.map((section) => {
                                    // Conditional rendering based on user role
                                    if (
                                        section.id === "manage-team" &&
                                        user?.role !== "TEAM_ADMIN"
                                    ) {
                                        return null;
                                    }
                                    if (
                                        section.id === "subscription" &&
                                        user?.role === "TEAM_USER"
                                    ) {
                                        return null;
                                    }
                                    return (
                                        <Card
                                            id={section.id}
                                            key={section.id}
                                            className="cursor-pointer hover:bg-secondary hover:text-white text-neutral-500 transition-colors"
                                        >
                                            <Link
                                                to={`/settings/${section.id}`}
                                                className="flex items-center p-4 sm:p-6"
                                            >
                                                <section.icon className="h-6 w-6 sm:h-8 sm:w-8 mr-3 sm:mr-4 text-primary" />
                                                <div>
                                                    <h2 className="text-base sm:text-lg font-semibold text-primary">
                                                        {section.title}
                                                    </h2>
                                                    <p className="text-xs sm:text-sm">
                                                        {section.description}
                                                    </p>
                                                </div>
                                            </Link>
                                        </Card>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    {/* Define nested routes for settings sections */}
                    <Routes>
                        {settingsSections.map((section) => {
                            // Conditional rendering based on user role
                            if (
                                section.id === "manage-team" &&
                                user?.role !== "TEAM_ADMIN"
                            ) {
                                return null;
                            }
                            if (
                                section.id === "subscription" &&
                                user?.role === "TEAM_USER"
                            ) {
                                return null;
                            }
                            return (
                                <Route
                                    key={section.id}
                                    path={section.id}
                                    element={<section.component />}
                                />
                            );
                        })}
                    </Routes>
                </CardContent>
            </Card>
        </div>
    );
};

export default Settings;
