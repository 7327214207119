import React from "react";
import "../assets/styles/loading-animation.css";

interface LoadingScreenProps {
  loadingMessage?: string;
}
const LoadingScreen: React.FC<LoadingScreenProps> = ({loadingMessage}) => {
  return (
    <div className="h-full loader-overlay text-center flex flex-col items-center justify-center text-2xl">
            {loadingMessage && <h1 className="text-primary font-bold">{loadingMessage}</h1>}
            <div className="mt-6 loader"></div>
    </div>
  );
};

export default LoadingScreen;
