import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { FaArrowLeft, FaClock } from "react-icons/fa";
import { Card, CardContent, CardHeader } from "../misc/Card";
import { Badge } from "../calendar/Badge";
import { Button } from "../misc/Button";
import { Check, ImageIcon, RefreshCw, Trash2 } from "lucide-react";
import { toast } from "react-toastify";
import axios from "axios";
import FacebookPost from "./FacebookPost";
import InstagramPost from "./InstagramPost";
import LoadingScreen from "../../screens/LoadingScreen";
import { useIsMobile } from "../../hooks/useIsMobile";
import { FaPencil } from "react-icons/fa6";
import PostMediaPicker from "./PostMediaPicker";
import { PostRelatedMedia } from "./types";
import ReconfirmOverlay from "../misc/ReconfirmOverlay";
import EditPostDialog from "./EditPostDialog";
import RegeneratePostDialog from "./RegeneratePostDialog";
import { Post as PostType } from "../../types/posts";
import { getAuthHeader } from "../../utils/authHeader";
import CustomToast from "../misc/CustomToast";

type ConfirmAction = "approve" | "pause" | "delete" | null;

const Post: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { postId } = useParams<{ postId: string }>();

    useEffect(() => {
        if (!postId && !location.state?.postId) {
            navigate(-1);
        }
    }, [postId, location.state, navigate]);

    const actualPostId = postId || location.state?.postId;

    const [post, setPost] = useState<PostType | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
    const [isRegenerateDialogOpen, setIsRegenerateDialogOpen] =
        useState<boolean>(false);
    const [isMediaPickerOpen, setIsMediaPickerOpen] = useState<boolean>(false);
    const [media, setMedia] = useState<PostRelatedMedia | null>(null);
    const [actionToConfirm, setActionToConfirm] = useState<ConfirmAction>(null);
    const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);

    const getPostData = useCallback(async () => {
        if (!actualPostId) return;

        setLoading(true);
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.get(
                `${backendUrl}/post/${actualPostId}`,
                {
                    headers,
                }
            );

            if (!response || response.status !== 200) {
                throw new Error("Failed to fetch post data.");
            }

            setPost(response.data);
            console.log(response.data);
        } catch (error: unknown) {
            console.error("Error fetching post data", error);
            toast.error(
                <CustomToast
                    message="Failed to fetch post data."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        } finally {
            setLoading(false);
        }
    }, [actualPostId]);

    useEffect(() => {
        getPostData();
    }, [getPostData]);

    const handleBack = () => {
        navigate(-1);
    };

    const handleSaveEdit = async (updatedFields: Partial<PostType>) => {
        setLoading(true);
        setIsEditDialogOpen(false);

        if (!actualPostId || !post) {
            toast.error(
                <CustomToast message="Invalid post data." type="error" />,
                { autoClose: 3000 }
            );
            return;
        }

        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.patch(
                `${backendUrl}/post/`,
                { postIds: [actualPostId], ...updatedFields },
                { headers }
            );

            if (!response || response.status !== 200) {
                throw new Error("Failed to update post.");
            }

            setPost({ ...post, ...updatedFields });
            toast.success(
                <CustomToast
                    message="Post updated successfully."
                    type="success"
                />,
                { autoClose: 3000 }
            );
            getPostData();
        } catch (error: unknown) {
            console.error("Error updating post", error);
            toast.error(
                <CustomToast message="Failed to update post." type="error" />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    const handleRegenerate = async (instructions: string) => {
        setIsRegenerateDialogOpen(false);
        setLoading(true);

        if (!actualPostId || !post) {
            toast.error(
                <CustomToast message="Invalid post data." type="error" />,
                { autoClose: 3000 }
            );
            return;
        }

        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.post(
                `${backendUrl}/post/regenerate/`,
                {
                    postId: actualPostId,
                    text: instructions,
                },
                { headers }
            );

            if (!response || response.status !== 200 || !response.data) {
                throw new Error("Failed to regenerate post content.");
            }

            setPost({ ...post, content: response.data });
            toast.success(
                <CustomToast
                    message="Post content regenerated successfully."
                    type="success"
                />,
                { autoClose: 3000 }
            );
        } catch (error: unknown) {
            console.error("Error regenerating post content", error);
            toast.error(
                <CustomToast
                    message="Failed to regenerate post content."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    const handleEditMedia = async () => {
        setLoading(true);
        try {
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            const response = await axios.get(
                `${backendUrl}/post/all-media/${actualPostId}`,
                {
                    headers,
                }
            );

            if (!response || response.status !== 200) {
                throw new Error("Failed to fetch media data.");
            }

            setMedia(response.data);
        } catch (error: unknown) {
            console.error("Error fetching media data", error);
            toast.error(
                <CustomToast
                    message="Failed to fetch media data."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }

        setIsMediaPickerOpen(true);
    };

    const isMobile = useIsMobile(900);

    const confirmAction = async () => {
        if (!actionToConfirm) return;

        if (actionToConfirm === "approve") {
            await handleApprovePost();
        } else if (actionToConfirm === "pause") {
            await handlePausePost();
        } else if (actionToConfirm === "delete") {
            await handleDeletePost();
        }
    };

    const handleApprovePost = async () => {
        setIsOverlayOpen(false);
        setLoading(true);
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.patch(
                `${backendUrl}/post`,
                { postIds: [actualPostId], status: "APPROVED" },
                { headers }
            );

            if (!response || response.status !== 200) {
                throw new Error("Failed to approve post.");
            }

            setPost({ ...post!, status: "Scheduled" });
            getPostData();
            toast.success(
                <CustomToast
                    message="Post approved and scheduled successfully."
                    type="success"
                />,
                { autoClose: 3000 }
            );
        } catch (error: unknown) {
            console.error("Error approving post", error);
            toast.error(
                <CustomToast message="Failed to approve post." type="error" />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    const handlePausePost = async () => {
        setIsOverlayOpen(false);
        setLoading(true);
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.patch(
                `${backendUrl}/post`,
                { postIds: [actualPostId], status: "PAUSED" },
                { headers }
            );

            if (!response || response.status !== 200) {
                throw new Error("Failed to pause post.");
            }

            setPost({ ...post!, status: "Paused" });
            toast.success(
                <CustomToast
                    message="Post paused successfully."
                    type="success"
                />,
                { autoClose: 3000 }
            );
            getPostData();
        } catch (error: unknown) {
            console.error("Error pausing post", error);
            toast.error(
                <CustomToast message="Failed to pause post." type="error" />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    const handleDeletePost = async () => {
        setIsOverlayOpen(false);
        setLoading(true);
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.delete(
                `${backendUrl}/post/${actualPostId}`,
                { headers }
            );

            if (!response || response.status !== 200) {
                throw new Error("Failed to delete post.");
            }

            toast.success(
                <CustomToast
                    message="Post deleted successfully."
                    type="success"
                />,
                { autoClose: 3000 }
            );
            navigate(-1);
        } catch (error: unknown) {
            console.error("Error deleting post", error);
            toast.error(
                <CustomToast message="Failed to delete post." type="error" />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    const getOverlayContent = () => {
        switch (actionToConfirm) {
            case "approve":
                return {
                    title: "Approve Post",
                    message: "Are you sure you want to approve this Post?",
                    messageTwo:
                        "Approving this post will schedule the post for publishing.",
                    confirmText: "Approve Post",
                };
            // case "pause":
            //     return {
            //         title: "Pause Post",
            //         message: "Are you sure you want to pause this Post?",
            //         messageTwo: "",
            //         confirmText: "Pause Post",
            //     };
            case "delete":
                return {
                    title: "Delete Post",
                    message: "Are you sure you want to delete this Post?",
                    messageTwo: "This action cannot be undone.",
                    confirmText: "Delete Post",
                };
            default:
                return {
                    title: "",
                    message: "",
                    messageTwo: "",
                    confirmText: "",
                };
        }
    };

    const { title, message, messageTwo, confirmText } = getOverlayContent();

    const handleAction = (action: ConfirmAction) => {
        setActionToConfirm(action);
        setIsOverlayOpen(true);
    };

    const closeOverlay = () => {
        setIsOverlayOpen(false);
        setActionToConfirm(null);
    };

    return (
        <div className={`p-4 ${isMobile ? "p-2" : "p-4"}`}>
            {loading && <LoadingScreen />}

            <ReconfirmOverlay
                isOpen={isOverlayOpen}
                onClose={closeOverlay}
                onConfirm={confirmAction}
                title={title}
                message={message}
                messageTwo={messageTwo}
                confirmText={confirmText}
            />

            <Button
                id="back-btn"
                onClick={handleBack}
                aria-label="Go Back"
                className={`flex items-center mb-4 ${
                    isMobile ? "px-2 py-1 text-sm" : "px-4 py-2"
                }`}
            >
                <FaArrowLeft
                    className={`mr-2 ${isMobile ? "w-4 h-4" : "w-5 h-5"}`}
                />
                Back
            </Button>

            {post && (
                <EditPostDialog
                    isOpen={isEditDialogOpen}
                    onClose={() => setIsEditDialogOpen(false)}
                    post={post}
                    onSave={handleSaveEdit}
                />
            )}

            <RegeneratePostDialog
                isOpen={isRegenerateDialogOpen}
                onClose={() => setIsRegenerateDialogOpen(false)}
                onRegenerate={handleRegenerate}
            />
            {post?.status === "ERROR" ? (
                <div className="max-w-3xl mx-auto shadow-lg bg-gray-100 p-3 rounded-md mt-4">
                    <p className="text-sm text-gray-600">
                        <strong className="text-[#d50000]">Error :</strong> Post
                        error is being investigated by our team. We will reach
                        out to you with more information soon.
                    </p>
                    <p className="text-sm text-gray-600">
                        In the meantime, if you have any questions or concerns,
                        please don't hesitate to reach out to our support team
                        through our{" "}
                        <Link
                            className="text-primary hover:text-secondary font-semibold"
                            to="/"
                        >
                            contact form.
                        </Link>
                    </p>
                </div>
            ) : null}
            <Card
                className={`max-w-3xl mx-auto shadow-lg ${
                    isMobile ? "w-full" : ""
                }`}
            >
                <CardHeader className="space-y-2">
                    <div className="flex items-center justify-between flex-wrap">
                        {post?.scheduledFor && (
                            <Badge className="flex gap-2 text-sm mb-2">
                                <FaClock className="w-4 h-4" />
                                {new Date(post.scheduledFor).toLocaleString()}
                            </Badge>
                        )}
                        {post?.type && (
                            <Badge
                                className={`text-white ${
                                    isMobile ? "text-xs" : "text-sm"
                                }`}
                            >
                                {post.type}
                            </Badge>
                        )}
                    </div>
                </CardHeader>
                <CardContent className="space-y-6 pt-0">
                    <div className="space-y-4">
                        <div
                            className={`flex justify-between items-center flex-wrap ${
                                isMobile ? "" : "mb-4"
                            }`}
                        >
                            <div className="w-full flex justify-between items-center">
                                <div className="flex flex-col">
                                    <h3
                                        className={`text-lg font-semibold text-primary ${
                                            isMobile ? "text-md" : "text-lg"
                                        }`}
                                    >
                                        Post Preview
                                        {post?.type && post?.address && (
                                            <> - {post.address}</>
                                        )}
                                        {post?.contentType && (
                                            <>
                                                {" "}
                                                -{" "}
                                                {post.contentType
                                                    .replace(/([A-Z])/g, " $1")
                                                    .replace(/\b\w/g, (c) =>
                                                        c.toUpperCase()
                                                    )}
                                            </>
                                        )}
                                    </h3>
                                    <span className="text-neutral-500 text-sm">
                                        Preview your social media post
                                    </span>
                                </div>
                                {isMobile &&
                                    post?.status.toLowerCase() !== "posted" && (
                                        <Button
                                            id="edit-post-btn"
                                            size="sm"
                                            onClick={() =>
                                                setIsEditDialogOpen(true)
                                            }
                                            className="flex items-center justify-center"
                                        >
                                            <FaPencil className="w-4 h-4 mr-2" />
                                            Edit
                                        </Button>
                                    )}
                            </div>
                            <div
                                className={`space-x-2 mt-2 flex flex-row ${
                                    post?.status.toLowerCase() === "posted"
                                        ? "justify-end"
                                        : "justify-evenly"
                                } w-full`}
                            >
                                {!isMobile &&
                                    post?.status.toLowerCase() !== "posted" && (
                                        <Button
                                            id="edit-post-btn"
                                            size="sm"
                                            onClick={() =>
                                                setIsEditDialogOpen(true)
                                            }
                                            className={`flex items-center justify-center ${
                                                isMobile ? "w-full mb-2" : ""
                                            }`}
                                        >
                                            <FaPencil className="w-4 h-4 mr-2" />
                                            Edit
                                        </Button>
                                    )}
                                {post?.status.toLowerCase() === "draft" ? (
                                    <Button
                                        id="approve-post-btn"
                                        variant="secondary"
                                        size="sm"
                                        onClick={() => {
                                            handleAction("approve");
                                        }}
                                        className={`flex items-center justify-center ${
                                            isMobile ? "w-full" : ""
                                        }`}
                                    >
                                        <Check
                                            className={`w-4 h-4 ${
                                                isMobile ? "m-0" : "mr-2"
                                            }`}
                                        />
                                        {!isMobile && "Approve Post"}
                                    </Button>
                                ) : null}

                                {/* post?.status.toLowerCase() ===
                                  "scheduled" ? (
                                    <Button
                                        variant="secondary"
                                        size="sm"
                                        onClick={() => {
                                            handleAction("pause");
                                        }}
                                        className={`flex items-center justify-center ${
                                            isMobile ? "w-full" : ""
                                        }`}
                                    >
                                        <Pause
                                            className={`w-4 h-4 ${
                                                isMobile ? "m-0" : "mr-2"
                                            }`}
                                        />
                                        {!isMobile && "Pause Post"}
                                    </Button>
                                )  */}
                                {post?.status.toLowerCase() !== "posted" && (
                                    <>
                                        <Button
                                            id="regenerate-post-btn"
                                            size="sm"
                                            onClick={() =>
                                                setIsRegenerateDialogOpen(true)
                                            }
                                            className={`flex items-center justify-center ${
                                                isMobile ? "w-full mb-2" : ""
                                            }`}
                                        >
                                            <RefreshCw
                                                className={`w-4 h-4 ${
                                                    isMobile ? "m-0" : "mr-2"
                                                }`}
                                            />
                                            {!isMobile && "Regenerate"}
                                        </Button>
                                        <Button
                                            id="edit-media-btn"
                                            size="sm"
                                            variant="secondary"
                                            onClick={handleEditMedia}
                                            className={`flex items-center justify-center ${
                                                isMobile ? "w-full" : ""
                                            }`}
                                        >
                                            <ImageIcon
                                                className={`w-4 h-4 ${
                                                    isMobile ? "m-0" : "mr-2"
                                                }`}
                                            />
                                            {!isMobile && "Edit Media"}
                                        </Button>
                                    </>
                                )}
                                <Button
                                    id="delete-post-btn"
                                    variant="destructive"
                                    size="sm"
                                    onClick={() => {
                                        handleAction("delete");
                                    }}
                                    className={`flex items-center justify-center ${
                                        isMobile ? "w-full" : ""
                                    }`}
                                >
                                    <Trash2
                                        className={`w-4 h-4 ${
                                            isMobile ? "m-0" : "mr-2"
                                        }`}
                                    />
                                    {!isMobile && "Delete Post"}
                                </Button>
                            </div>
                        </div>
                        {post && post.platform.toLowerCase() === "facebook" ? (
                            <FacebookPost post={post} setLoading={setLoading} />
                        ) : post &&
                          post.platform.toLowerCase() === "instagram" ? (
                            <InstagramPost
                                post={post}
                                setLoading={setLoading}
                            />
                        ) : (
                            <div className="text-red-500">
                                Unsupported platform: {post?.platform}
                            </div>
                        )}
                    </div>
                </CardContent>

                <PostMediaPicker
                    isMobile={isMobile}
                    isMediaPickerOpen={isMediaPickerOpen}
                    setIsMediaPickerOpen={setIsMediaPickerOpen}
                    media={media}
                    platform={post?.platform || ""}
                    postMedia={post?.media || []}
                    setLoading={setLoading}
                    setPost={setPost}
                    postId={actualPostId}
                    getPostData={getPostData}
                />
            </Card>
        </div>
    );
};

export default Post;
