export enum UserRole {
    USER = "USER",
    TEAM_ADMIN = "TEAM_ADMIN",
    TEAM_USER = "TEAM_USER",
    ADMIN = "ADMIN",
}

export type Address = {
    street: string;
    city: string;
    state: string;
    zipCode: string;
};

export type User = {
    id: string;
    nameFirst: string;
    nameLast: string;
    email: string;
    role: UserRole;
    mlsApproved: boolean;
    onboarded: boolean;
    licenseNumber: string;
    companyAddress: Address;
    companyName: string;
    phoneNumber: string;
    broker: string;
    brokerLicense: string;
    brokerEmail: string;
    mls?: string | MLS;
    limits?: Limits;
    otherMLS: string;
    timeZone: string;
    verified: boolean;
    status: string;
    accountId?: string;
    plan?: Plan;
    account: Account;
};

export interface Account {
    activeSubscription: Subscription | null;
}

export interface Subscription {
    plan: Plan;
    startDate: Date;
    endDate: Date;
    status: SubscriptionStatus;
    expiresAt: Date;
}

export enum SubscriptionStatus {
    Active = "ACTIVE",
    Inactive = "INACTIVE",
    PendingAgreement = "PENDING",
    Paused = "PAUSED",
}

export interface EditUserOverlayProps {
    editingUser: Partial<User> | null;
    setEditingUser: (user: User | null) => void;
    teamAdmins: User[];
    onSave: () => void;
    isAdd: boolean;
}

export enum planFrequency {
    Monthly = "Monthly",
}

export interface Limits {
    postWarn: number;
    postLimit: number;
    activeListingLimit: number;
    listingWarn: number;
    listingLimit: number;
    imageTagsWarn: number;
    imageTagsLimit: number;
    imageVideosWarn: number;
    imageVideosLimit: number;
    userLimit: number;
}

export interface Plan {
    id?: string;
    name: string;
    description: string;
    userNotes: string;
    price: number | string;
    frequency: planFrequency;
    public: boolean;
    limits: Limits;
}

export const defaultLimits: Limits = {
    postWarn: 1,
    postLimit: 1,
    activeListingLimit: 1,
    listingWarn: 1,
    listingLimit: 1,
    imageTagsWarn: 1,
    imageTagsLimit: 1,
    imageVideosWarn: 1,
    imageVideosLimit: 1,
    userLimit: 1,
};

export interface PlanFormProps {
    plan: Plan;
    setEditingPlan: (plan: Plan | null) => void;
    onClose: () => void;
}

export interface DashboardData {
    totalUsers: number;
    lastMonthUsers: number;
    percent: string;
}

export interface MLS {
    _id: string;
    name: string;
    description: string;
    link: string;
}

export interface MLSUsage {
    _id: string;
    userId?: string;
    sync: boolean;
    type: MLSUsageType;
    timestamp: number;
    createdAt: string;
}

export enum MLSUsageType {
    Data = "Data",
    Media = "Media",
}

export interface HourlyMLSUsage {
    dataUsage: ExtededType[];

    mediaUsage: ExtededType[];
}

interface ExtededType {
    timestamp: number;
    count: number;
    createdAt: string;
}
export interface RentcastUsage {
    _id: string;
    userId: string;
    timestamp: number;
    createdAt: string;
    updatedAt: string;
}

export interface MonthlyRentcastUsage {
    timestamp: number;
    count: number;
}

export interface OpenAIUsage {
    _id: string;
    userId: string;
    model: OpenAIModel;
    inputTokens: number;
    outputTokens: number;
    cost: number;
    createdAt: string;
    updatedAt: string;
    timestamp: number;
}

export enum OpenAIModel {
    GPT4o = "GPT4o",
    GPT4 = "GPT4",
    GPT4oVision = "GPT4oVision",
    GPT4oBatch = "GPT4oBatch",
}

export interface MonthlyOpenAIUsage {
    _id: string;
    totalInputTokens: number;
    totalOutputTokens: number;
    totalCost: number;
    userId: string;
    year: number;
    month: number;
    model: OpenAIModel;
}

export interface Maintenance {
    isOnline: boolean;
    reason: string;
}

export const defaultMaintenance: Maintenance = {
    isOnline: true,
    reason: "",
};

export interface MaintenanceFormProps {
    maintenance: Maintenance;
    setMaintenance: React.Dispatch<React.SetStateAction<Maintenance>>;
    originalMaintenance: Maintenance;
    type: "general" | "ai";
    onSave: () => void;
    loading: boolean;
}

export interface LimitsTabProps {
    limits: Limits | undefined;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}
