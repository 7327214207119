import React, { useEffect, useRef, useState } from "react";
import {
    Dialog,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogContent,
} from "../../misc/Dialog";
import { JobStatus, SelectedImages, SlideshowProps } from "./types";
import SlideshowOptions from "./SlideshowOptions";
import LoadingScreen from "../../../screens/LoadingScreen";
import { Button } from "../../misc/Button";
import { toast } from "react-toastify";
import CustomToast from "../../misc/CustomToast";
import axios from "axios";
import { getAuthHeader } from "../../../utils/authHeader";
import { FaTimes } from "react-icons/fa";
import { Loader2 } from "lucide-react";
import { MAX_IMAGES, MIN_IMAGES } from "./utils";
import SlideshowMediaSelector from "./SlideshowMediaSelector";
import { useSocket } from "../../../context/SocketContext";

const Slideshow: React.FC<SlideshowProps> = ({
    listingId,
    listingType,
    images,
    isOpen,
    onClose,
    videos,
    setVideos,
    formattedAddress,
    isGenerating,
    setIsGenerating,
    assets,
    jobId,
    setJobId,
    setJobStatus,
    fetchListing,
    isJobInProgress,
}) => {
    const { socket } = useSocket();

    const [step, setStep] = useState(1);
    const [selectedImages, setSelectedImages] = useState<SelectedImages[]>([]);
    const [selectedOverlay, setSelectedOverlay] = useState<string | null>(null);
    const [newOverlayHasLogo, setNewOverlayHasLogo] = useState(false);
    const [includeMusic, setIncludeMusic] = useState(false);
    const [music, setMusic] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [includeOverlay, setIncludeOverlay] = useState(false);
    const [notSeleted, setNotSelected] = useState(false);

    const subscribedSlideshowRef = useRef<Set<string>>(new Set());

    useEffect(() => {
        if (!socket) return;
        if (!jobId) return;

        // Capture the current jobId to use in cleanup
        const currentJobId = jobId;

        console.log(`Emitting subscribeToSlideshow for jobId: ${currentJobId}`);
        socket.emit("subscribeToSlideshow", currentJobId);
        subscribedSlideshowRef.current.add(currentJobId);

        const handleProgressUpdate = (data: {
            entityId: string;
            entityType: "slideshow";
            progress: JobStatus;
        }) => {
            const { entityId, entityType, progress } = data;
            if (entityType === "slideshow" && currentJobId === entityId) {
                console.log(
                    `Received progress update for jobId ${currentJobId}: ${progress}`
                );
                setJobStatus(progress);

                if (progress === JobStatus.COMPLETED) {
                    setJobId(null);
                    setJobStatus(null);
                    fetchListing();
                    toast.success(
                        <CustomToast
                            message="Slideshow generation completed."
                            type="success"
                        />,
                        { autoClose: 3000 }
                    );
                } else if (progress === JobStatus.FAILED) {
                    setJobId(null);
                    setJobStatus(null);
                    toast.error(
                        <CustomToast
                            message="Slideshow generation failed. Please try again."
                            type="error"
                        />,
                        { autoClose: 3000 }
                    );
                }
            }
        };

        socket.on("progressUpdate", handleProgressUpdate);

        const localSubscribedSlideshow = subscribedSlideshowRef.current;

        return () => {
            socket.off("progressUpdate", handleProgressUpdate);

            // Use the captured currentJobId in cleanup
            if (localSubscribedSlideshow.has(currentJobId)) {
                socket.emit("unsubscribeFromSlideshow", currentJobId);
                localSubscribedSlideshow.delete(currentJobId);
                console.log(
                    `Emitted unsubscribeFromSlideshow for jobId: ${currentJobId}`
                );
            }
        };
    }, [socket, jobId, setJobStatus, setJobId, fetchListing]);

    const handleMediaSelect = (id: string, type: "image" | "asset") => {
        setSelectedImages((prevSelectedImages) => {
            // If the image is already selected, remove it
            if (prevSelectedImages.some((image) => image.id === id)) {
                return prevSelectedImages.filter((image) => image.id !== id);
            } else {
                // Check if maximum limit is reached
                if (prevSelectedImages.length >= MAX_IMAGES) {
                    toast.error(
                        <CustomToast
                            message={`You can select up to ${MAX_IMAGES} images.`}
                            type="error"
                        />
                    );
                    return prevSelectedImages;
                }
                // Add the new image ID to the end of the array
                return [...prevSelectedImages, { id, type }];
            }
        });
    };

    const handleGenerate = async () => {
        setIsGenerating(true);
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.post(
                `${backendUrl}/listing/slideshow/`,
                {
                    listingId,
                    listingType,
                    imageList: selectedImages,
                    audioLink: includeMusic ? music : null,
                    overlay: selectedOverlay,
                },
                { headers }
            );

            if (response.status === 201) {
                setJobId(response.data);
                setJobStatus(JobStatus.QUEUED);
                setIsGenerating(false);
                setStep(1);
                setSelectedImages([]);
                setSelectedOverlay(null);
                setIncludeMusic(false);
                onClose();
                toast.success(
                    <CustomToast
                        message="Slideshow generation started. You will be notified once it's ready."
                        type="success"
                    />,
                    { autoClose: 3000 }
                );
            } else {
                throw new Error("Error generating slideshow");
            }
        } catch (error) {
            setIsGenerating(false);
            toast.error(
                <CustomToast
                    message="An error occurred while generating the slideshow. Please try again."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            onClose();
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            {/* DialogTrigger can be omitted if not needed */}
            <DialogContent className="relative flex flex-col space-y-6 p-4 sm:p-6 md:p-8">
                {loading && <LoadingScreen />}

                <button
                    className="absolute top-4 right-4 text-secondary hover:text-primary focus:outline-none"
                    onClick={onClose}
                    aria-label="Close"
                >
                    <FaTimes className="h-6 w-6" />
                </button>
                <DialogHeader>
                    <DialogTitle className="text-lg sm:text-xl md:text-2xl">
                        Create Slideshow
                    </DialogTitle>
                    <DialogDescription className="text-sm sm:text-base md:text-lg">
                        Create a custom slideshow from your images
                    </DialogDescription>
                </DialogHeader>

                {step === 1 && (
                    <SlideshowMediaSelector
                        media={{ images, assets }}
                        selectedImages={selectedImages}
                        handleMediaSelect={handleMediaSelect}
                    />
                )}

                {step === 2 && (
                    <SlideshowOptions
                        includeOverlay={includeOverlay}
                        setIncludeOverlay={setIncludeOverlay}
                        includeMusic={includeMusic}
                        setIncludeMusic={setIncludeMusic}
                        selectedOverlay={selectedOverlay}
                        setSelectedOverlay={setSelectedOverlay}
                        newOverlayHasLogo={newOverlayHasLogo}
                        setNewOverlayHasLogo={setNewOverlayHasLogo}
                        formattedAddress={formattedAddress}
                        toggleMusic={() => setIncludeMusic((prev) => !prev)}
                        setLoading={setLoading}
                        setNotSelected={setNotSelected}
                        music={music}
                        setMusic={setMusic}
                        listingId={listingId}
                        listingType={listingType}
                        image={images[0]}
                    />
                )}

                {/* Navigation Buttons */}
                <div
                    className={`flex ${
                        step === 2
                            ? "flex-col sm:flex-row justify-between space-y-4 sm:space-y-0"
                            : "justify-end"
                    }`}
                >
                    {step === 2 && (
                        <Button
                            variant="secondary"
                            onClick={() => setStep(1)}
                            className="w-full sm:w-auto"
                        >
                            Back to Image Selection
                        </Button>
                    )}
                    {step === 1 && (
                        <Button
                            onClick={() => setStep(2)}
                            disabled={selectedImages.length < MIN_IMAGES}
                            className="w-full sm:w-auto"
                        >
                            Continue to Overlay & Music
                        </Button>
                    )}
                    {step === 2 && (
                        <Button
                            onClick={handleGenerate}
                            disabled={
                                isGenerating ||
                                selectedImages.length === 0 ||
                                (includeOverlay && notSeleted)
                            }
                            className="w-full sm:w-auto"
                        >
                            {isGenerating ? (
                                <div className="flex items-center justify-center">
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Generating...
                                </div>
                            ) : (
                                "Generate Slideshow"
                            )}
                        </Button>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default Slideshow;
