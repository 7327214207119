import React, { useEffect, useState } from "react";
import { ListingsTabProps } from "./types";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../misc/Tabs";
import { useNavigate } from "react-router-dom";
import {
    CombinedAsset,
    CombinedImage,
    CombinedMedia,
    CombinedVideo,
} from "../listings/listing/types";
import ImagesTab from "../listings/listing/tabs/ImagesTab";
import VideosTab from "../listings/listing/tabs/VideosTab";
import CustomSelect from "../misc/CustomSelect";
import { SingleValue } from "react-select";
import { ListingType } from "../../types/userListing";

interface OptionType {
    label: string;
    value: string;
}

const ListingsTab: React.FC<ListingsTabProps> = ({
    listingMedia,
    activeListingTab,
    listingTabs,
    setActiveListingTab,
    activeTab,
    loading,
    mlsApproved,
    carouselApi,
    setLoading,
    fetchListingMedia,
    carouselIndexMap,
    isEditing,
    isMobile,
    activeMediaTab,
    setActiveMediaTab,
    onThumbnailClick,
    isUploading,
    setIsUploading,
}) => {
    const navigate = useNavigate();

    const [selectedMedia, setSelectedMedia] = useState<CombinedMedia[]>([]);

    const [currentPage, setCurrentPage] = React.useState(1);

    const showNoItems =
        activeTab === "listing" && listingTabs.length <= 0 && !loading;

    const createListingHandler = () => {
        navigate("/create-listing");
    };

    const listing = listingMedia.find(
        (listing) => listing.address === activeListingTab
    );

    useEffect(() => {
        setCurrentPage(1);
        setSelectedMedia([]);
    }, [listing]);

    useEffect(() => {
        setCurrentPage(1);
    }, [activeMediaTab]);

    const combinedMedia: CombinedMedia[] = [
        ...(listing?.images || []).map(
            (image) => ({ ...image, type: "image" } as CombinedImage)
        ),
        ...(listing?.videos || []).map(
            (video) => ({ ...video, type: "video" } as CombinedVideo)
        ),
        ...(listing?.assets || []).map((asset) => {
            let mediaType: "image" | "video" = "image";
            if (asset.assetType === "Video") {
                mediaType = "video";
            } else if (asset.assetType === "Image") {
                mediaType = "image";
            }
            return { ...asset, type: mediaType } as CombinedAsset;
        }),
    ];

    const toggleSelectMedia = (media: CombinedMedia) => {
        setSelectedMedia((prev) =>
            prev.some((m) => m.id === media.id)
                ? prev.filter((m) => m.id !== media.id)
                : [...prev, media]
        );
    };

    // Create options for the select box
    const listingOptions: OptionType[] = listingTabs.map((tab) => {
        return {
            value: tab,
            label: `${tab}`,
        };
    });

    return (
        <div className="relative">
            {activeTab === "listing" && listingTabs.length > 0 ? (
                <TabsContent
                    value="listing"
                    className="bg-neutral-100 p-4 sm:p-6"
                >
                    <Tabs
                        value={activeMediaTab}
                        onValueChange={(value: string) =>
                            setActiveMediaTab(value as "images" | "videos")
                        }
                    >
                        {/* Combine CustomSelect and TabsList in the same flex container */}
                        <div
                            className={`relative flex ${
                                isMobile ? "flex-col" : "flex-row"
                            } items-center justify-center mb-4`}
                        >
                            <div
                                className={`flex ${
                                    isMobile
                                        ? "flex-col justify-center"
                                        : "flex-row justify-between"
                                } items-center  w-full`}
                            >
                                <div
                                    className={`${
                                        isMobile ? "mb-2" : "w-[30%]"
                                    }`}
                                >
                                    <CustomSelect
                                        id="#select-listing-dropdown"
                                        options={listingOptions}
                                        value={
                                            activeListingTab
                                                ? listingOptions.find(
                                                      (option) =>
                                                          option.value ===
                                                          activeListingTab
                                                  )
                                                : null
                                        }
                                        onChange={(
                                            selectedOption: SingleValue<OptionType>
                                        ) => {
                                            setActiveListingTab(
                                                selectedOption
                                                    ? selectedOption.value
                                                    : ""
                                            );
                                        }}
                                        placeholder="Select Listing"
                                        isClearable={false}
                                        className="w-full"
                                        bg="#F5F5F5"
                                    />
                                </div>
                                <TabsList
                                    className={`${isMobile ? "mt-2" : "ml-4"}`}
                                >
                                    <TabsTrigger id="images-tab" value="images">
                                        Images ({listing?.images.length || 0})
                                    </TabsTrigger>
                                    <TabsTrigger id="videos-tab" value="videos">
                                        Videos ({listing?.videos.length || 0})
                                    </TabsTrigger>
                                </TabsList>
                            </div>
                        </div>

                        <div className="flex justify-center items-center">
                            <div className="w-full">
                                <ImagesTab
                                    images={listing?.images || []}
                                    listingId={listing?.listingId || ""}
                                    toggleSelectMedia={toggleSelectMedia}
                                    selectedMedia={selectedMedia}
                                    isEditing={isEditing}
                                    carouselApi={carouselApi}
                                    combinedMedia={combinedMedia}
                                    mlsApproved={mlsApproved}
                                    setSelectedMedia={setSelectedMedia}
                                    setIsLoading={setLoading}
                                    handleUploadComplete={fetchListingMedia}
                                    itemsPerPage={10}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    carouselIndexMap={carouselIndexMap}
                                    onCarouselScroll={(index: number) => {
                                        onThumbnailClick(index);
                                    }}
                                    propertyStatus={
                                        listing?.listingStatus || ""
                                    }
                                    isUploading={isUploading}
                                    setIsUploading={setIsUploading}
                                    listingType={
                                        listing?.listingType ||
                                        ListingType.MLSListing
                                    }
                                />

                                <VideosTab
                                    videos={listing?.videos || []}
                                    listingId={listing?.listingId || ""}
                                    address1={listing?.address || ""}
                                    toggleSelectMedia={toggleSelectMedia}
                                    selectedMedia={selectedMedia}
                                    isEditing={isEditing}
                                    carouselApi={carouselApi}
                                    combinedMedia={combinedMedia}
                                    mlsApproved={mlsApproved}
                                    setSelectedMedia={setSelectedMedia}
                                    setIsLoading={setLoading}
                                    handleUploadComplete={fetchListingMedia}
                                    itemsPerPage={10}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    carouselIndexMap={carouselIndexMap}
                                    onCarouselScroll={(index: number) => {
                                        onThumbnailClick(index);
                                    }}
                                    propertyStatus={
                                        listing?.listingStatus || ""
                                    }
                                    isUploading={isUploading}
                                    setIsUploading={setIsUploading}
                                    listingType={
                                        listing?.listingType ||
                                        ListingType.MLSListing
                                    }
                                />
                            </div>
                        </div>
                    </Tabs>
                </TabsContent>
            ) : showNoItems ? (
                <>
                    <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                        <h2 className="text-2xl font-semibold mb-4 text-primary">
                            No Listings Created
                        </h2>
                        <p className="mb-2 text-gray-600">
                            Create a new listing to view listings media here.
                        </p>
                        <p className="mb-6 text-gray-600">
                            Click the button below to create a new listing.
                        </p>
                        <button
                            className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-secondary transition-colors"
                            onClick={createListingHandler}
                        >
                            Create Listing
                        </button>
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default ListingsTab;
