import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import StepProgressBar from "../progress-bar";
import { Button } from "../misc/Button";
import PricingPlans from "./PricingPlans";
import { auth } from "../../firebase/firebase-config";
import { getIdToken } from "firebase/auth";
import axios from "axios";
import LoadingScreen from "../../screens/LoadingScreen";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import "react-toastify/dist/ReactToastify.css";
import { Plan } from "../../Admin/types";
import { Link } from "react-router-dom";

interface PlanDetailsProps {
    advanceStep: () => void;
    goBackStep: () => void;
    role: string | null;
    isOnboarding: boolean;
}

const PlanDetails: React.FC<PlanDetailsProps> = ({
    advanceStep,
    goBackStep,
    role,
    isOnboarding,
}) => {
    const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
    const [userPlanId, setUserPlanId] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [plans, setPlans] = useState<Plan[]>([]);

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                setLoading(true);
                const currentUser = auth.currentUser;
                if (!currentUser) throw new Error("User not authenticated");
                const token = await getIdToken(currentUser, true);
                const headers = { Authorization: `Bearer ${token}` };
                const backendUrl = process.env.REACT_APP_BACKEND_URL;

                const response = await axios.get(
                    `${backendUrl}/onboarding/plans`,
                    {
                        headers,
                        withCredentials: true,
                    }
                );
                console.log("Plans response:", response.data);
                setPlans(
                    response.data.map((plan: Plan) => ({
                        ...plan,
                        price:
                            plan.price === undefined || plan.price === null
                                ? "Custom"
                                : plan.price,
                    }))
                );

                const userResponse = await axios.get(
                    `${backendUrl}/onboarding/plan-details`,
                    {
                        headers,
                        withCredentials: true,
                    }
                );

                console.log("User plan response:", userResponse.data);
                setUserPlanId(userResponse.data);
                setSelectedPlanId(userResponse.data);
            } catch (error) {
                console.error("Error fetching plans:", error);
                toast.error(
                    <CustomToast
                        message="Failed to fetch plans. Please try again."
                        type="error"
                    />,
                    {
                        autoClose: 3000,
                    }
                );
            } finally {
                setLoading(false);
            }
        };

        if (role === "TEAM_USER" && isOnboarding) {
            goBackStep();
            return;
        }

        fetchPlans();
    }, [role, goBackStep, isOnboarding]);

    const handleNext = async () => {
        if (selectedPlanId === null) {
            toast.error(
                <CustomToast
                    message="Please select a plan before proceeding."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
            return;
        }

        setLoading(true);

        if (selectedPlanId === userPlanId) {
            advanceStep();
            setLoading(false);
            return;
        }

        try {
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            const currentUser = auth.currentUser;
            if (!currentUser) throw new Error("User not authenticated");
            const token = await getIdToken(currentUser, true);
            const headers = { Authorization: `Bearer ${token}` };

            const response = await axios.patch(
                `${backendUrl}/onboarding/plan-details`,
                { planId: selectedPlanId },
                {
                    headers: headers,
                    withCredentials: true,
                }
            );

            if (response.status === 200) {
                advanceStep();
            }
        } catch (error) {
            console.error("Error selecting plan:", error);
            toast.error(
                <CustomToast
                    message="Failed to select plan. Please try again."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        } finally {
            setLoading(false);
        }
    };

    const handleBack = () => {
        goBackStep();
    };

    return (
        <main
            className={`flex flex-col justify-${
                isOnboarding ? "between" : "center"
            } items-center min-h-screen bg-white shadow-[0px_4px_4px_rgba(0,0,0,0.25)]`}
        >
            <section className="flex flex-col items-center px-8 py-16 bg-white min-h-[833px] w-full md:px-14 md:py-20">
                <div className="w-full flex flex-col items-center mb-8">
                    <img
                        loading="lazy"
                        src={logo}
                        alt="Agent Registration Logo"
                        className="object-contain self-center max-w-full aspect-[8.77] w-[395px]"
                    />
                    <h1 className="my-2 text-primary text-2xl font-bold leading-none text-center">
                        {isOnboarding
                            ? "User Onboarding"
                            : "Resubscribe To Marabot"}
                    </h1>
                    {isOnboarding && (
                        <StepProgressBar
                            steps={[
                                {
                                    label: "Agent Details",
                                    isActive: true,
                                    isCompleted: true,
                                },
                                {
                                    label: "Broker Details",
                                    isActive: true,
                                    isCompleted: true,
                                },
                                {
                                    label: "Choose MLS",
                                    isActive: true,
                                    isCompleted: true,
                                },
                                {
                                    label: "Select Plan",
                                    isActive: true,
                                    isCompleted: false,
                                },
                                {
                                    label: "Payment",
                                    isActive: false,
                                    isCompleted: false,
                                },
                            ]}
                        />
                    )}
                </div>
                {loading && <LoadingScreen />}
                <div className="flex-grow flex items-center w-full">
                    <PricingPlans
                        plans={plans}
                        selectedPlanId={selectedPlanId}
                        setSelectedPlanId={setSelectedPlanId}
                    />
                </div>
                <div className="mt-10">
                    <span className="text-xs text-neutral-500">
                        * Usage limits apply, please see our{" "}
                        <Link
                            to="https://marabot.ai/terms"
                            target="_blank"
                            className="text-primary hover:text-secondary"
                        >
                            Terms of Service
                        </Link>
                    </span>
                </div>
                <div className="w-full flex justify-center mt-8 gap-4 md:gap-20">
                    {isOnboarding && (
                        <Button
                            id="plan-back-btn"
                            className="w-1/3"
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                    )}
                    <Button
                        id="plan-next-btn"
                        disabled={!selectedPlanId}
                        className="w-1/3"
                        onClick={handleNext}
                    >
                        Next
                    </Button>
                </div>
            </section>
        </main>
    );
};

export default PlanDetails;
