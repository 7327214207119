import React, { useEffect, useRef, useState } from "react";
import LeafletMap from "./LeafletMap";
import { IoIosAlert } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { AllListings } from "../../types/allListings";
import { Images, Loader, Video } from "lucide-react";
import { useSocket } from "../../context/SocketContext";
import { Progress } from "./types";
import { CampaignProgress } from "../campaign/types";
import CampaignStatusCard from "../campaign/CampaignStatusCard";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";
import { CampaignCardListingProps } from "./listing/types";
import { useLocation } from "react-router-dom";
import { Button } from "../misc/Button";
import SimpleTooltip from "../misc/SimpleTooltip";
import { ListingType } from "../../types/userListing";
import LoadingScreen from "../../screens/LoadingScreen";

interface ListingDisplayProps {
    listings: AllListings[];
    mlsApproved: boolean;
    fetchListings: () => void;
    listingsLimit: number;
    listingsWarn: number;
    listingsActiveLimit: number;
}

const ListingDisplay: React.FC<ListingDisplayProps> = ({
    listings,
    mlsApproved,
    fetchListings,
    listingsLimit,
    listingsWarn,
    listingsActiveLimit,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { socket, isAuthenticated } = useSocket();
    const [loading, setLoading] = useState(false);

    const disabled = listingsLimit <= 0 || listingsActiveLimit <= 0 || false;

    const [currentListings, setCurrentListings] =
        useState<AllListings[]>(listings);

    const subscribedListingsRef = useRef<Set<string>>(new Set());
    const subscribedCampaignsRef = useRef<Set<string>>(new Set());

    useEffect(() => {
        setCurrentListings(listings);
    }, [listings]);

    console.log("listingsLimit:", listingsLimit);

    useEffect(() => {
        if (!socket || !isAuthenticated) return;

        const subscribedListings = subscribedListingsRef.current;
        const subscribedCampaigns = subscribedCampaignsRef.current;

        // Function to subscribe to a listing
        const subscribeToListing = (listingId: string) => {
            if (!subscribedListings.has(listingId)) {
                socket.emit("subscribeToListing", listingId);
                subscribedListings.add(listingId);
                console.log(`Subscribed to listing: ${listingId}`);
            }
        };

        // Function to subscribe to a campaign
        const subscribeToCampaign = (campaignId: string) => {
            if (!subscribedCampaigns.has(campaignId)) {
                socket.emit("subscribeToCampaign", campaignId);
                subscribedCampaigns.add(campaignId);
                console.log(`Subscribed to campaign: ${campaignId}`);
            }
        };

        // Subscribe to all initial listings and campaigns
        listings.forEach((listing) => {
            if (listing.status === "Processing") {
                subscribeToListing(listing.id);
            }

            if (listing.campaignId && listing.campaignStatus === "PROCESSING") {
                subscribeToCampaign(listing.campaignId);
            }
        });

        // Cleanup function to unsubscribe on unmount
        return () => {
            // Unsubscribe from all listings
            subscribedListings.forEach((listingId) => {
                socket.emit("unsubscribeFromListing", listingId);
                console.log(`Unsubscribed from listing: ${listingId}`);
            });
            subscribedListings.clear();

            // Unsubscribe from all campaigns
            subscribedCampaigns.forEach((campaignId) => {
                socket.emit("unsubscribeFromCampaign", campaignId);
                console.log(`Unsubscribed from campaign: ${campaignId}`);
            });
            subscribedCampaigns.clear();
        };
    }, [socket, isAuthenticated, listings]);

    useEffect(() => {
        if (!socket || !isAuthenticated) return;

        const subscribedListings = subscribedListingsRef.current;
        const subscribedCampaigns = subscribedCampaignsRef.current;

        // Function to subscribe to a listing
        const subscribeToListing = (listingId: string) => {
            if (!subscribedListings.has(listingId)) {
                socket.emit("subscribeToListing", listingId);
                subscribedListings.add(listingId);
                console.log(`Subscribed to listing: ${listingId}`);
            }
        };

        // Function to subscribe to a campaign
        const subscribeToCampaign = (campaignId: string) => {
            if (!subscribedCampaigns.has(campaignId)) {
                socket.emit("subscribeToCampaign", campaignId);
                subscribedCampaigns.add(campaignId);
                console.log(`Subscribed to campaign: ${campaignId}`);
            }
        };

        // Subscribe to all initial listings and campaigns
        listings.forEach((listing) => {
            if (listing.status === "Processing") {
                subscribeToListing(listing.id);
            }

            if (listing.campaignId && listing.campaignStatus === "PROCESSING") {
                subscribeToCampaign(listing.campaignId);
            }
        });

        // Cleanup function to unsubscribe on unmount
        return () => {
            // Unsubscribe from all listings
            subscribedListings.forEach((listingId) => {
                socket.emit("unsubscribeFromListing", listingId);
                console.log(`Unsubscribed from listing: ${listingId}`);
            });
            subscribedListings.clear();

            // Unsubscribe from all campaigns
            subscribedCampaigns.forEach((campaignId) => {
                socket.emit("unsubscribeFromCampaign", campaignId);
                console.log(`Unsubscribed from campaign: ${campaignId}`);
            });
            subscribedCampaigns.clear();
        };
    }, [socket, isAuthenticated, listings]);

    useEffect(() => {
        if (!socket || !isAuthenticated) return;

        // Function to subscribe to a listing
        const subscribeToListing = (listingId: string) => {
            if (!subscribedListingsRef.current.has(listingId)) {
                socket.emit("subscribeToListing", listingId);
                subscribedListingsRef.current.add(listingId);
                console.log(`Subscribed to listing: ${listingId}`);
            }
        };

        // Function to subscribe to a campaign
        const subscribeToCampaign = (campaignId: string) => {
            if (!subscribedCampaignsRef.current.has(campaignId)) {
                socket.emit("subscribeToCampaign", campaignId);
                subscribedCampaignsRef.current.add(campaignId);
                console.log(`Subscribed to campaign: ${campaignId}`);
            }
        };

        // Subscribe to any new listings and campaigns
        listings.forEach((listing) => {
            if (listing.status === "Processing") {
                subscribeToListing(listing.id);
            }

            if (listing.campaignId && listing.campaignStatus === "PROCESSING") {
                subscribeToCampaign(listing.campaignId);
            }
        });
    }, [socket, isAuthenticated, listings]);

    useEffect(() => {
        if (!socket) return;

        const handleProgressUpdate = (data: {
            entityId: string;
            entityType: "listing" | "campaign";
            progress: Progress | CampaignProgress;
        }) => {
            const { entityId, entityType, progress } = data;
            setCurrentListings((prevListings) =>
                prevListings.map((listing) => {
                    if (entityType === "listing" && listing.id === entityId) {
                        if (
                            (progress as Progress).currentStep === "Completed"
                        ) {
                            fetchListings();
                        }
                        return {
                            ...listing,
                            progress: progress as Progress,
                            status:
                                (progress as Progress).currentStep ===
                                "Completed"
                                    ? "Active"
                                    : listing.status,
                        };
                    } else if (
                        entityType === "campaign" &&
                        listing.campaignId === entityId
                    ) {
                        if (
                            (progress as CampaignProgress).currentStep ===
                            "Completed"
                        ) {
                            fetchListings();
                        }
                        return {
                            ...listing,
                            campaignProgress: progress as CampaignProgress,
                            campaignStatus:
                                (progress as CampaignProgress).currentStep ===
                                "Completed"
                                    ? "ACTIVE"
                                    : listing.campaignStatus,
                        };
                    }
                    return listing;
                })
            );
        };

        socket.on("progressUpdate", handleProgressUpdate);

        // Cleanup on unmount or socket change
        return () => {
            socket.off("progressUpdate", handleProgressUpdate);
        };
    }, [socket, fetchListings]);

    const viewListingHandler = (id: string) => {
        navigate("/listing", { state: { listingId: id } });
    };

    const createListingHandler = () => {
        navigate("/create-listing");
    };

    console.log("limits: ", listingsWarn, listingsLimit, listingsActiveLimit);
    const handleCampaignRetry = async (campaignId: string | undefined) => {
        if (!campaignId) return;
        setLoading(true);
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.get(
                `${backendUrl}/listing/restart-campaign/${campaignId}`,
                { headers: headers }
            );

            if (response.status === 200) {
                toast.success(
                    <CustomToast
                        message="Campaign generation process restarted."
                        type="success"
                    />,
                    {
                        autoClose: 3000,
                    }
                );
            }

            navigate("/listing", { state: { listingId: response.data.id } });
        } catch (error: unknown) {
            console.error(error);
            toast.error(
                <CustomToast
                    message="Failed to retry campaign."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mx-auto h-full px-4 py-8">
            {loading && <LoadingScreen />}
            {/* Header Section */}
            <div className="flex items-center justify-between mb-8">
                <h1 className="w-full text-2xl font-bold text-primary">
                    My Listings
                </h1>
                {/* Wrap the Button with Tooltip only if it's disabled and needs a tooltip */}
                <div>
                    <SimpleTooltip
                        message="Please upgrade your plan to add more listings"
                        visible={
                            (listingsLimit <= 0 || listingsActiveLimit <= 0) &&
                            disabled
                        }
                    >
                        <Button
                            variant="secondary"
                            id="add-listing-btn"
                            onClick={createListingHandler}
                            disabled={disabled}
                        >
                            Add Listing
                        </Button>
                    </SimpleTooltip>
                </div>
            </div>
            {/* {listingsWarn <= 0 ? (
                <div className="bg-gray-100 p-3 rounded-md my-4">
                    <p className="text-sm text-red-500">
                        <strong className="text-primary">Note:</strong> You have
                        reached the maximum number of listings allowed under
                        your current plan. To continue creating new listings
                        without interruption, please consider upgrading your
                        plan.
                    </p>
                </div>
            ) : null} */}
            {/* Listings Grid */}
            <div className="flex flex-wrap justify-center gap-6">
                {currentListings.map((listing, idx) => {
                    // Define CampaignCardListing inside the map function
                    const CampaignCardListing: CampaignCardListingProps = {
                        campaignProgress: listing?.campaignProgress,
                        campaignStatus: listing?.campaignStatus,
                        campaignType: listing?.campaignType,
                        generatedPosts: listing?.generatedPosts,
                        publishedPosts: listing?.publishedPosts,
                        scheduledPosts: listing?.scheduledPosts,
                        unapprovedPosts: listing?.unapprovedPosts,
                        contentRefresh: listing?.contentRefresh,
                    };

                    return (
                        <div
                            id={`listing-${idx}`}
                            key={listing.id}
                            className="w-full sm:w-1/2 lg:w-1/3 max-w-sm bg-white rounded-lg shadow-2xl flex flex-col relative border-b-4"
                            style={{ borderColor: listing.color }}
                        >
                            {listing.status !== "Active" &&
                                listing.status !== "ComingSoon" && (
                                    <div className="absolute top-0 bg-gray-500 bg-opacity-50 w-full h-full z-10 rounded-lg" />
                                )}

                            {/* Image/Map Section */}
                            <div className="relative w-full h-48 z-0">
                                {listing.primaryImage &&
                                listing.primaryImage.length > 0 ? (
                                    <img
                                        src={listing.primaryImage}
                                        alt={listing.formattedAddress}
                                        className="w-full h-full object-cover rounded-t-lg"
                                    />
                                ) : (
                                    <LeafletMap
                                        latitude={listing.latitude}
                                        longitude={listing.longitude}
                                        address={listing.formattedAddress}
                                    />
                                )}
                            </div>

                            {/* Content Section */}
                            <div className="p-4 flex flex-col flex-grow">
                                {/* Address Section */}
                                <h2
                                    className="text-lg font-bold text-primary text-center mb-4 line-clamp-2 h-14 overflow-hidden"
                                    title={listing.formattedAddress}
                                >
                                    {listing.formattedAddress}
                                </h2>

                                {/* Details Section */}
                                <dl
                                    className={`space-y-2 text-sm flex flex-col flex-grow ${
                                        listing.status === "Processing" ||
                                        listing.campaignStatus === "PROCESSING"
                                            ? "justify-between"
                                            : "justify-start"
                                    }`}
                                >
                                    {/* Listing Status */}
                                    <div className="flex items-center justify-between">
                                        <dt className="flex items-center font-medium text-accent">
                                            <IoIosAlert className="mr-2 h-5 w-5 text-secondary" />
                                            Listing Status
                                        </dt>
                                        <dd className="font-medium text-primary">
                                            {listing.status}
                                        </dd>
                                    </div>
                                    {/* Listing Creation Updates */}
                                    {listing.status === "Processing" ? (
                                        <>
                                            <div className="flex items-center justify-center mt-4">
                                                <dt className="flex items-center justify-center font-medium text-accent">
                                                    <Loader className="mr-2 h-5 w-5 animate-spin text-secondary" />
                                                    {
                                                        listing.progress
                                                            ?.currentStep
                                                    }
                                                </dt>
                                            </div>
                                            {listing.progress?.totalImages
                                                ? listing.progress
                                                      ?.totalImages > 0 && (
                                                      <div className="flex items-center justify-between">
                                                          <dt className="flex items-center font-medium text-accent">
                                                              <Images className="mr-2 h-5 w-5 text-secondary" />
                                                              Images Processed:
                                                          </dt>
                                                          <dd className="font-medium text-primary">
                                                              {
                                                                  listing
                                                                      .progress
                                                                      ?.imagesProcessed
                                                              }
                                                              /
                                                              {
                                                                  listing
                                                                      .progress
                                                                      ?.totalImages
                                                              }
                                                          </dd>
                                                      </div>
                                                  )
                                                : null}
                                            {listing.progress?.totalVideos &&
                                            listing.progress?.totalVideos >
                                                0 ? (
                                                <div className="flex items-center justify-between">
                                                    <dt className="flex items-center font-medium text-accent">
                                                        <Video className="mr-2 h-5 w-5 text-secondary" />
                                                        Videos Processed:
                                                    </dt>
                                                    <dd className="font-medium text-primary">
                                                        {
                                                            listing.progress
                                                                ?.videosProcessed
                                                        }
                                                        /
                                                        {
                                                            listing.progress
                                                                ?.totalVideos
                                                        }
                                                    </dd>
                                                </div>
                                            ) : null}
                                        </>
                                    ) : (
                                        <CampaignStatusCard
                                            listing={CampaignCardListing}
                                        />
                                    )}
                                </dl>
                            </div>

                            {/* Button Section */}
                            {listing.status !== "Processing" && (
                                <div className="p-4 flex flex-col space-y-2">
                                    {!listing.campaignStatus && (
                                        <Button
                                            id={`create-campaign-${idx}`}
                                            onClick={() => {
                                                if (listing.imageCount <= 0) {
                                                    toast.error(
                                                        <CustomToast
                                                            message="Please upload images before creating a campaign."
                                                            type="error"
                                                        />,
                                                        {
                                                            autoClose: 3000,
                                                        }
                                                    );
                                                } else {
                                                    navigate(
                                                        "/create-campaign",
                                                        {
                                                            state: {
                                                                listingId:
                                                                    listing.id,
                                                                listingType:
                                                                    listing.listingType ===
                                                                    ListingType.MLSListing
                                                                        ? "MLSListing"
                                                                        : "Listing",
                                                                from: location.pathname,
                                                            },
                                                        }
                                                    );
                                                }
                                            }}
                                        >
                                            Create Campaign
                                        </Button>
                                    )}
                                    {listing.campaignStatus &&
                                        listing.campaignStatus === "ERROR" && (
                                            <Button
                                                id={`retry-campaign-${idx}`}
                                                onClick={() => {
                                                    handleCampaignRetry(
                                                        listing.campaignId
                                                    );
                                                }}
                                            >
                                                Retry Campaign
                                            </Button>
                                        )}
                                    {listing.campaignStatus &&
                                        listing.campaignStatus ===
                                            "SOCIAL_PAUSE" && (
                                            <span className="text-center text-red-500">
                                                Connect Social Media platform(s)
                                                from Settings to resume campaign
                                            </span>
                                        )}
                                    <Button
                                        id={`view-listing-${idx}`}
                                        variant="secondary"
                                        onClick={() => {
                                            viewListingHandler(listing.id);
                                        }}
                                        className="z-30"
                                    >
                                        View Listing
                                    </Button>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ListingDisplay;
