import { useEffect, useState } from "react";
import { auth } from "../firebase/firebase-config";
import { onAuthStateChanged, getIdToken } from "firebase/auth";
import axios from "axios";
import useAppDispatch from "../hooks/useAppDispatch";
import { User as FirebaseUser } from "firebase/auth";
import {
    setUser,
    setRole,
    setIsOnboarded,
    setIsAdminView,
    setLoading,
    setError,
    setEmailVerified,
    setIsActive,
    setIsSubscribed,
    setMaintenance,
    setSocialsConnected,
    setAdmin,
    setListingCreated,
} from "../store/authSlice";
import { addImage, getImage } from "../utils/dexieImageCache";

const useAuth = () => {
    const dispatch = useAppDispatch();
    const [authCheckCompleted, setAuthCheckCompleted] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(
            auth,
            async (currentUser: FirebaseUser | null) => {
                dispatch(setLoading(true));
                dispatch(setError(null));

                if (currentUser) {
                    try {
                        const token = await getIdToken(currentUser, true);
                        const authPayload = {
                            authId: currentUser.uid,
                            token,
                        };
                        const response = await axios.post(
                            `${process.env.REACT_APP_BACKEND_URL}/validateUser`,
                            authPayload,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            }
                        );

                        console.log("User validation response:", response.data);

                        if (!response.data || !response.data.user) {
                            throw new Error("User validation failed");
                        }

                        console.log("currentUser", response.data.user);
                        const userId = response.data.user._id?.toString();

                        let cachedProfileImageKey: string | undefined;
                        if (currentUser.photoURL && userId) {
                            try {
                                const cachedBlob = await getImage(
                                    `profileImage-${userId}`
                                );
                                console.log("cachedBlob", cachedBlob);
                                if (cachedBlob) {
                                    cachedProfileImageKey = `profileImage-${userId}`;
                                } else {
                                    const response = await fetch(
                                        currentUser.photoURL,
                                        {
                                            cache: "no-cache",
                                        }
                                    );

                                    if (response.status === 429) {
                                        console.error(
                                            "Rate limited while fetching profile image."
                                        );
                                        cachedProfileImageKey = undefined;
                                    } else if (response.ok) {
                                        const blob = await response.blob();
                                        await addImage(
                                            `profileImage-${userId}`,
                                            blob
                                        );
                                        cachedProfileImageKey = `profileImage-${userId}`;
                                    } else {
                                        console.error(
                                            "Failed to fetch image:",
                                            response.statusText
                                        );
                                        cachedProfileImageKey = undefined;
                                    }
                                }
                            } catch (imageError) {
                                console.error(
                                    "Image caching failed:",
                                    imageError
                                );
                                cachedProfileImageKey = undefined;
                            }
                        } else {
                            cachedProfileImageKey = undefined;
                        }

                        const userRole = response.data.user.role || "USER";
                        const userData = {
                            ...response.data.user,
                            ...currentUser,
                            providerId:
                                currentUser.providerData[0]?.providerId || "",
                            phoneNumber: response.data.user.phoneNumber || "",
                            profileImageKey: cachedProfileImageKey,
                        };
                        dispatch(setUser(userData));
                        dispatch(
                            setSocialsConnected(
                                userData.socialsConnected || false
                            )
                        );
                        dispatch(setRole(userRole));
                        dispatch(
                            setIsOnboarded(
                                (response.data.user.onboarded &&
                                    response.data.user.status !==
                                        "ONBOARDING") ||
                                    false
                            )
                        );
                        dispatch(
                            setEmailVerified(currentUser.emailVerified || false)
                        );
                        dispatch(
                            setIsActive(
                                (response.data.user.status !== "PAUSED" &&
                                    response.data.user.status !== "INACTIVE") ||
                                    false
                            )
                        );
                        dispatch(
                            setIsSubscribed(
                                response.data.user.account.activeSubscription
                                    .status === "ACTIVE" || false
                            )
                        );
                        dispatch(
                            setMaintenance(response.data.maintenance || false)
                        );
                        dispatch(
                            setListingCreated(
                                response.data.user.listingCreated || false
                            )
                        );

                        // Automatically set `isAdminView` to true for admins and store in sessionStorage if not already present
                        const localIsAdminView =
                            sessionStorage.getItem("isAdminView");

                        dispatch(setAdmin(response.data.user.isAdmin));

                        console.log("localIsAdminView", localIsAdminView);

                        if (localIsAdminView === null) {
                            if (response.data.user.isAdmin) {
                                dispatch(setIsAdminView(true));
                                sessionStorage.setItem("isAdminView", "true");
                            } else {
                                dispatch(setIsAdminView(false));
                                sessionStorage.setItem("isAdminView", "false");
                            }
                        } else {
                            dispatch(
                                setIsAdminView(
                                    localIsAdminView === "true" &&
                                        response.data.user.isAdmin
                                )
                            );
                        }
                    } catch (error) {
                        console.error("User validation error:", error);
                        dispatch(
                            setError(
                                "User validation failed. Please try again later."
                            )
                        );
                        dispatch(setUser(null));
                        dispatch(setRole(null));
                        dispatch(setIsOnboarded(false));
                        dispatch(setIsAdminView(false));
                    }
                } else {
                    dispatch(setUser(null));
                    dispatch(setRole(null));
                    dispatch(setIsOnboarded(false));
                    dispatch(setIsAdminView(false));
                }

                // Ensure loading is false after the process is complete
                setTimeout(() => {
                    dispatch(setLoading(false));
                    setAuthCheckCompleted(true);
                }, 500);
            }
        );

        return () => unsubscribe();
    }, [dispatch]);

    return { authCheckCompleted };
};

export default useAuth;
