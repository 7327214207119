import React from "react";
import { motion } from "framer-motion";
import { Button } from "../../components/misc/Button";
import { LogOut } from "lucide-react";
import { handleLogoutGenerator } from "../../global-actions/handleLogout";
import { auth } from "../../firebase/firebase-config";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsAdminView } from "../../store/authSlice";
import { RootState } from "../../store/store";

interface MaintenanceScreenProps {
    isAdmin: boolean;
}

const MaintenanceScreen: React.FC<MaintenanceScreenProps> = ({ isAdmin }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth.user);

    const handleLogout = handleLogoutGenerator(auth, navigate, user);

    const handleAdmin = () => {
        dispatch(setIsAdminView(true));
        sessionStorage.setItem("isAdminView", "true");
        navigate("/admin");
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-primary to-secondary flex flex-col items-center justify-center p-4">
            <Button
                size="sm"
                className="absolute top-4 right-4 flex items-center space-x-2"
                onClick={handleLogout}
            >
                <LogOut className="w-4 h-4" />
                <span>Logout</span>
            </Button>
            {isAdmin && (
                <Button
                    size="sm"
                    variant="secondary"
                    className="absolute top-16 right-4"
                    onClick={handleAdmin}
                >
                    Admin Dashboard
                </Button>
            )}
            <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="max-w-md w-full bg-white rounded-lg shadow-xl overflow-hidden"
            >
                <div className="p-8">
                    <div className="flex justify-center mb-6">
                        <motion.svg
                            className="w-16 h-16 text-secondary"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            initial={{ rotate: 0 }}
                            animate={{ rotate: 360 }}
                            transition={{
                                duration: 2,
                                repeat: Infinity,
                                ease: "linear",
                            }}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                            />
                        </motion.svg>
                    </div>
                    <h1 className="text-2xl font-bold text-center text-primary mb-2">
                        We&apos;re under maintenance
                    </h1>
                    <p className="text-center text-gray-600 mb-6">
                        We&apos;re working hard to improve our website and
                        we&apos;ll be back soon.
                    </p>
                    {/* <div className="bg-indigo-100 rounded-lg p-4 mb-6">
              <p className="text-sm text-indigo-800 font-medium text-center">
                Estimated completion time: <span className="font-bold">2 hours</span>
              </p>
            </div> */}
                </div>
                <div className="px-8 py-4 bg-gray-50 border-t border-gray-100">
                    <p className="text-xs text-gray-600 text-center">
                        © {new Date().getFullYear()} iSpeal AI. All rights
                        reserved.
                    </p>
                </div>
            </motion.div>
        </div>
    );
};

export default MaintenanceScreen;
