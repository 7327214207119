import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../misc/Card";
import { Button } from "../misc/Button";
import { ArrowLeft, X } from "lucide-react";
import InputField from "../misc/InputField";
import { useIsMobile } from "../../hooks/useIsMobile";
import axios from "axios";
import { getAuthHeader } from "../../utils/authHeader";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { isValidEmail } from "../../utils/validationUtil";
import { InviteUserDto } from "../../types/invite-user.dto";
import LoadingScreen from "../../screens/LoadingScreen";
import { useNavigate } from "react-router-dom";
import ReconfirmOverlay from "../misc/ReconfirmOverlay";

type ActionType = "delete" | null;

type TeamMember = {
    id: string;
    name: string;
    email: string;
    role: string;
};

const ManageTeam = () => {
    const navigate = useNavigate();

    const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [newMemberEmail, setNewMemberEmail] = useState("");
    const [maxTeamSize, setMaxTeamSize] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedUser, setSelectedUser] = useState<TeamMember>();
    const [actionToConfirm, setActionToConfirm] = useState<ActionType>(null);

    const confirmAction = () => {
        if (actionToConfirm === "delete") {
            removeMember();
        }
        setActionToConfirm(null);
    };

    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const fetchTeamMembers = useCallback(async () => {
        setLoading(true);

        const headers = await getAuthHeader();

        try {
            const response = await axios.get(
                `${backendUrl}/settings/team-members`,
                { headers }
            );

            if (response.status !== 200) {
                throw new Error("Error fetching team members");
            }

            setTeamMembers(response.data);
        } catch (error) {
            toast.error(
                <CustomToast
                    message="Error fetching team members"
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    }, [backendUrl]);

    const fetchMaxTeamSize = useCallback(async () => {
        setLoading(true);

        const headers = await getAuthHeader();

        try {
            const response = await axios.get(
                `${backendUrl}/settings/team-size`,
                {
                    headers,
                }
            );
            if (response.status !== 200) {
                throw new Error("Error fetching team size");
            }

            setMaxTeamSize(response.data);
        } catch (error) {
            toast.error(
                <CustomToast message="Error fetching team size" type="error" />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    }, [backendUrl]);

    useEffect(() => {
        fetchTeamMembers();
        fetchMaxTeamSize();
    }, [fetchTeamMembers, fetchMaxTeamSize]);

    const availableSlots = maxTeamSize - teamMembers.length;

    const addMember = async () => {
        if (!firstName || !lastName || !newMemberEmail) {
            toast.error(
                <CustomToast message="All fields are required." type="error" />,
                { autoClose: 3000 }
            );
            return;
        }
        if (!isValidEmail(newMemberEmail)) {
            toast.error(
                <CustomToast
                    message="Please enter a valid email address."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }

        if (availableSlots <= 0) {
            toast.error(
                <CustomToast
                    message={`You can only add up to ${availableSlots} members.`}
                    type="error"
                />,
                { autoClose: 3000 }
            );
        }
        const newMember: InviteUserDto = {
            nameFirst: firstName,
            nameLast: lastName,
            email: newMemberEmail,
        };
        const headers = await getAuthHeader();
        try {
            setLoading(true);

            await axios
                .post(`${backendUrl}/onboarding/add-team-users`, [newMember], {
                    headers,
                })
                .then(() => {
                    fetchTeamMembers();
                })
                .then(() => {
                    setNewMemberEmail("");
                    setFirstName("");
                    setLastName("");
                    toast.success(
                        <CustomToast
                            message="User added successfully."
                            type="success"
                        />,
                        { autoClose: 3000 }
                    );
                })
                .catch((error) => {
                    toast.error(
                        <CustomToast message={error.message} type="error" />,
                        { autoClose: 3000 }
                    );
                });
        } catch (error: unknown) {
            toast.error(
                <CustomToast
                    message="Error Adding User, Please try again later or contact support."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    const removeMember = async () => {
        if (!selectedUser) {
            toast.error(
                <CustomToast message="Error Removing User" type="error" />,
                { autoClose: 3000 }
            );
            return;
        }

        const headers = await getAuthHeader();
        try {
            setLoading(true);
            const response = await axios.delete(
                `${backendUrl}/settings/remove-team-member/${selectedUser.id}`,
                { headers }
            );

            if (response.status !== 200) {
                throw new Error("Error removing user");
            }

            fetchTeamMembers();
            toast.success(
                <CustomToast
                    message="User removed successfully."
                    type="success"
                />,
                { autoClose: 3000 }
            );
        } catch (error) {
            toast.error(
                <CustomToast message="Error removing user" type="error" />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveUser = (user: TeamMember) => {
        setActionToConfirm("delete");
        setSelectedUser(user);
    };

    const overlayProps = () => {
        switch (actionToConfirm) {
            case "delete":
                return {
                    title: "Remove user from your plan",
                    message: "Are you sure you want to remove this user?",
                    messageTwo:
                        "This user services will be stopped immediately.",
                    confirmText: "Remove User",
                };
            default:
                return null;
        }
    };

    const isMobile = useIsMobile(900);
    const currentUser = useSelector((state: RootState) => state.auth.user);
    const currentOverlayProps = overlayProps();

    return (
        <div className="space-y-6 mx-10 mt-4">
            {loading && <LoadingScreen />}
            {currentOverlayProps && (
                <ReconfirmOverlay
                    isOpen={true}
                    onClose={() => setActionToConfirm(null)}
                    onConfirm={confirmAction}
                    title={currentOverlayProps!.title}
                    message={currentOverlayProps!.message}
                    messageTwo={currentOverlayProps!.messageTwo}
                    confirmText={currentOverlayProps!.confirmText}
                />
            )}
            <h2 className="text-2xl font-bold text-primary">Manage Team</h2>
            <Card className="w-full max-w-4xl mx-auto">
                <div className="p-4">
                    <Button
                        id="back-to-settings-btn"
                        onClick={() => navigate("/settings")}
                        className="mb-4 flex items-center"
                    >
                        <ArrowLeft className="mr-2 h-5 w-5" /> Back to Settings
                    </Button>
                </div>
                <CardHeader className="py-0">
                    <CardTitle className="mb-2">Team Members</CardTitle>
                    <CardDescription>
                        You can add up to {maxTeamSize} team members.{" "}
                        {availableSlots} slot(s) available.
                    </CardDescription>
                </CardHeader>
                <CardContent>
                    <div className="space-y-4">
                        {teamMembers.map((member) => (
                            <div
                                key={member.id}
                                className="flex items-center justify-between p-2 border border-secondary rounded"
                            >
                                <div className="flex items-center space-x-4">
                                    <div className="flex items-center justify-center w-10 h-10 bg-secondary hover:bg-secondary hover:text-primary text-white rounded-full">
                                        <span className="text-lg font-semibold">
                                            {member &&
                                                member.name &&
                                                member.name
                                                    .split(" ")[0]
                                                    .charAt(0) +
                                                    member.name
                                                        .split(" ")[1]
                                                        .charAt(0)}
                                        </span>
                                    </div>
                                    <div>
                                        <p className="font-semibold text-primary">
                                            {member.name}
                                        </p>
                                        <p className="text-sm text-muted-foreground text-neutral-500">
                                            {member.email}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center space-x-2">
                                    {currentUser?._id !== member.id && (
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={() =>
                                                handleRemoveUser(member)
                                            }
                                        >
                                            <X className="h-4 w-4 text-red-600" />
                                        </Button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    {availableSlots > 0 && (
                        <div
                            className={`mt-8 flex gap-2 items-center ${
                                isMobile ? "flex-col" : "flex-row"
                            } `}
                        >
                            <form className="flex flex-col mt-2.5 w-full gap-4 justify-center">
                                <div className="flex flex-col md:flex-row justify-between gap-4 w-full">
                                    <InputField
                                        label="First Name"
                                        placeholder="Ex: John"
                                        type="text"
                                        value={firstName}
                                        onChange={(
                                            e: ChangeEvent<
                                                | HTMLInputElement
                                                | HTMLTextAreaElement
                                            >
                                        ) => setFirstName(e.target.value)}
                                        className="w-full"
                                    />
                                    <InputField
                                        label="Last Name"
                                        placeholder="Ex: Doe"
                                        type="text"
                                        value={lastName}
                                        onChange={(
                                            e: ChangeEvent<
                                                | HTMLInputElement
                                                | HTMLTextAreaElement
                                            >
                                        ) => setLastName(e.target.value)}
                                        className="w-full"
                                    />
                                </div>
                                <InputField
                                    label="Email"
                                    placeholder="Enter email"
                                    type="email"
                                    value={newMemberEmail}
                                    onChange={(
                                        e: ChangeEvent<
                                            | HTMLInputElement
                                            | HTMLTextAreaElement
                                        >
                                    ) => setNewMemberEmail(e.target.value)}
                                />

                                <Button type="button" onClick={addMember}>
                                    <FaPlus />
                                    <span className="ml-2">Add User</span>
                                </Button>
                            </form>
                        </div>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default ManageTeam;
