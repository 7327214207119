import { Edit, Plus, Search } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { Input } from "../../components/misc/Input";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../components/misc/Dialog";
import { Button } from "../../components/misc/Button";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/misc/Table";
import { Badge } from "../../components/calendar/Badge";
import { defaultLimits, Plan, planFrequency } from "../types";
import { PlanForm } from "./PlanForm";
import { toast } from "react-toastify";
import CustomToast from "../../components/misc/CustomToast";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";
import LoadingScreen from "../../screens/LoadingScreen";

const ManagePlans = () => {
    const [plans, setPlans] = useState<Plan[] | null>(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [editingPlan, setEditingPlan] = useState<Plan | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchPlans = useCallback(async () => {
        setLoading(true);
        try {
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            const response = await axios.get(`${backendUrl}/admin/get-plan`, {
                headers,
            });

            if (response.status !== 200) {
                throw new Error("Failed to fetch plans");
            }

            console.log("Plans response:", response.data);

            setPlans(response.data);
        } catch (error: unknown) {
            toast.error(
                <CustomToast message="Failed to fetch plans" type="error" />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchPlans();
    }, [fetchPlans]);

    const filteredPlans = plans?.filter(
        (plan) =>
            plan.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            plan.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleCreateOrUpdatePlan = async (plan: Plan) => {
        setIsDialogOpen(false);

        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        setLoading(true);

        if (plan.id) {
            // Update existing plan
            try {
                const response = await axios.patch(
                    `${backendUrl}/admin/plan`,
                    {
                        planId: plan.id,
                        name: plan.name,
                        description: plan.description,
                        userNotes: plan.userNotes,
                        price: plan.price,
                        frequency: plan.frequency,
                        public: plan.public,
                        limits: {
                            postWarn: plan.limits.postWarn,
                            postLimit: plan.limits.postLimit,
                            activeListingLimit: plan.limits.activeListingLimit,
                            listingWarn: plan.limits.listingWarn,
                            listingLimit: plan.limits.listingLimit,
                            imageTagsWarn: plan.limits.imageTagsWarn,
                            imageTagsLimit: plan.limits.imageTagsLimit,
                            imageVideosWarn: plan.limits.imageVideosWarn,
                            imageVideosLimit: plan.limits.imageVideosLimit,
                            userLimit: plan.limits.userLimit,
                        },
                    },
                    {
                        headers,
                    }
                );

                console.log("Update plan response:", response);
                if (response.status !== 200) {
                    throw new Error("Failed to update plan");
                }
            } catch (error: unknown) {
                toast.error(
                    <CustomToast
                        message="Failed to update plan"
                        type="error"
                    />,
                    { autoClose: 3000 }
                );
            } finally {
                setLoading(false);
                onClose();
            }
        } else {
            // Create new plan
            try {
                const response = await axios.post(
                    `${backendUrl}/admin/create-plan`,
                    {
                        name: plan.name,
                        description: plan.description,
                        userNotes: plan.userNotes,
                        price: plan.price,
                        frequency: plan.frequency,
                        public: plan.public,
                        limits: {
                            postWarn: plan.limits.postWarn,
                            postLimit: plan.limits.postLimit,
                            activeListingLimit: plan.limits.activeListingLimit,
                            listingWarn: plan.limits.listingWarn,
                            listingLimit: plan.limits.listingLimit,
                            imageTagsWarn: plan.limits.imageTagsWarn,
                            imageTagsLimit: plan.limits.imageTagsLimit,
                            imageVideosWarn: plan.limits.imageVideosWarn,
                            imageVideosLimit: plan.limits.imageVideosLimit,
                            userLimit: plan.limits.userLimit,
                        },
                    },
                    {
                        headers,
                    }
                );

                if (response.status !== 201) {
                    throw new Error("Failed to create plan");
                }

                const newPlan = response.data;
                console.log("New plan:", newPlan);

                setPlans([...(plans ?? []), newPlan]);
            } catch (error: unknown) {
                toast.error(
                    <CustomToast
                        message="Failed to create plan"
                        type="error"
                    />,
                    { autoClose: 3000 }
                );
            } finally {
                setLoading(false);
                onClose();
            }
        }
    };

    const onClose = () => {
        setIsDialogOpen(false);
        setEditingPlan(null);
        fetchPlans();
    };

    return (
        <div className="flex flex-col h-full p-4 space-y-6">
            {loading && <LoadingScreen />}
            <h2 className="text-2xl font-bold text-primary">Manage Plans</h2>
            <div className="bg-white p-6">
                <div className="flex justify-between items-center">
                    <div className="relative">
                        <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                        <Input
                            placeholder="Search plans..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="pl-8"
                        />
                    </div>
                    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                        <DialogTrigger asChild>
                            <Button
                                onClick={() =>
                                    setEditingPlan({
                                        name: "",
                                        description: "",
                                        userNotes: "",
                                        price: 0,
                                        frequency: planFrequency.Monthly,
                                        public: false,
                                        limits: { ...defaultLimits },
                                    })
                                }
                            >
                                <Plus className="mr-2 h-4 w-4" /> Create New
                                Plan
                            </Button>
                        </DialogTrigger>
                        <DialogContent className="max-w-[800px]">
                            <DialogHeader>
                                <DialogTitle>
                                    {editingPlan?.id
                                        ? "Edit Plan"
                                        : "Create New Plan"}
                                </DialogTitle>
                            </DialogHeader>
                            {editingPlan && (
                                <PlanForm
                                    plan={editingPlan}
                                    setEditingPlan={setEditingPlan}
                                    onClose={onClose}
                                />
                            )}
                            <Button
                                onClick={() =>
                                    handleCreateOrUpdatePlan(editingPlan!)
                                }
                                className="mt-2"
                            >
                                {editingPlan?.id
                                    ? "Update Plan"
                                    : "Create Plan"}
                            </Button>
                        </DialogContent>
                    </Dialog>
                </div>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Name</TableHead>
                            <TableHead>Description</TableHead>
                            <TableHead>Price</TableHead>
                            <TableHead>Frequency</TableHead>
                            <TableHead>Public</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {filteredPlans?.map((plan) => (
                            <TableRow key={plan.id}>
                                <TableCell>{plan.name}</TableCell>
                                <TableCell>{plan.description}</TableCell>
                                <TableCell>
                                    ${Number(plan.price) / 100}
                                </TableCell>
                                <TableCell>{plan.frequency}</TableCell>
                                <TableCell>
                                    <Badge
                                        variant={
                                            plan.public
                                                ? "default"
                                                : "secondary"
                                        }
                                    >
                                        {plan.public ? "Public" : "Private"}
                                    </Badge>
                                </TableCell>
                                <TableCell>
                                    <Dialog>
                                        <DialogTrigger asChild>
                                            <Button
                                                variant="outline"
                                                size="sm"
                                                onClick={() =>
                                                    setEditingPlan({ ...plan })
                                                }
                                            >
                                                <Edit className="mr-2 h-4 w-4" />{" "}
                                                Edit
                                            </Button>
                                        </DialogTrigger>
                                        <DialogContent className="max-w-[800px]">
                                            <DialogHeader>
                                                <DialogTitle>
                                                    Edit Plan
                                                </DialogTitle>
                                            </DialogHeader>
                                            {editingPlan && (
                                                <PlanForm
                                                    plan={editingPlan}
                                                    setEditingPlan={
                                                        setEditingPlan
                                                    }
                                                    onClose={onClose}
                                                />
                                            )}
                                            <Button
                                                className="mt-2"
                                                onClick={() =>
                                                    handleCreateOrUpdatePlan(
                                                        editingPlan!
                                                    )
                                                }
                                            >
                                                Update Plan
                                            </Button>
                                        </DialogContent>
                                    </Dialog>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

export default ManagePlans;
