import { Images } from "../../../types/listingImages";
import { Overlay } from "../../../types/overlay";
import { Logo } from "../../../types/logo";
import { ListingVideos } from "../../../types/listingVideos";
import { Assets } from "../../../types/asset-type";
import { ArchiveMedia, PromotionImages } from "../../post/types";
import { ListingType } from "../../../types/userListing";
import { MediaType } from "../../../types/mediaType";

export interface SlideshowProps {
    listingId: string;
    listingType: ListingType;
    images: Images[];
    assets: Assets[];
    isOpen: boolean;
    onClose: () => void;
    videos: ListingVideos[];
    setVideos: (videos: ListingVideos[]) => void;
    formattedAddress: string;
    isGenerating: boolean;
    setIsGenerating: (value: boolean) => void;
    jobId: string | null;
    setJobId: (value: string | null) => void;
    jobStatus: JobStatus | null;
    setJobStatus: (value: JobStatus | null) => void;
    fetchListing: () => void;
    isJobInProgress: boolean;
}

export type UnifiedMedia =
    | (Images & { mediaType: "image"; slideshow?: string })
    | (ListingVideos & { mediaType: "video"; slideshow?: string })
    | (PromotionImages & { mediaType: "image"; slideshow?: string })
    | (Assets & { mediaType: "image" | "video" | "logo"; slideshow?: string })
    | (ArchiveMedia & { mediaType: "image" | "video"; slideshow?: string });

export interface MediaSelectorProps {
    images?: Images[];
    videos?: ListingVideos[];
    promotionImages?: PromotionImages[];
    assets?: Assets[];
    archived?: ArchiveMedia[];
    selectedMedia: string[];
    onSelect: (id: string) => void;
    maxMedia: number;
    minMedia: number;
    type: MediaType;
    activeTab: "images" | "promotionImages" | "videos" | "assets" | "archived";
}
export interface SlideshowOptionsProps {
    includeOverlay: boolean;
    setIncludeOverlay: (value: boolean) => void;
    includeMusic: boolean;
    setIncludeMusic: (value: boolean) => void;
    selectedOverlay: string | null;
    setSelectedOverlay: (id: string | null) => void;
    toggleMusic: () => void;
    newOverlayHasLogo: boolean;
    setNewOverlayHasLogo: (value: boolean) => void;
    formattedAddress: string;
    setLoading: (value: boolean) => void;
    setNotSelected: (value: boolean) => void;
    music: string;
    setMusic: (value: string) => void;
    listingId: string;
    listingType: ListingType;
    image: Images;
}

export interface CreateOverlayProps {
    logos: Logo[];
    newOverlayHasLogo: boolean;
    setNewOverlayHasLogo: (value: boolean) => void;
    formattedAddress: string;
    overlays: Overlay[];
    setOverlays: (overlays: Overlay[]) => void;
    setSelectedOverlay: (id: string | null) => void;
    setLogos: (logos: Logo[]) => void;
    setCreateOverlayModalOpen: (value: boolean) => void;
    listingId: string;
    listingType: ListingType;
    setNotSelected: (value: boolean) => void;
    image: string;
}

export interface OverlaySelectorProps {
    overlays: Overlay[];
    selectedOverlay: string | null;
    onSelectOverlay: (id: string) => void;
}

export interface LogoSelectorProps {
    logos: Logo[];
    selectedLogo: string | null;
    onSelectLogo: (id: string) => void;
    onUploadLogo: (file: File) => void;
}

export interface PreviewOverlayProps {
    formattedAddress: string;
    newOverlayHasLogo: boolean;
    logoFile: File | null;
    logoUrl: string | null;
    selectedLogo: string | null;
    logos: Logo[];
    theme: "light" | "dark";
    image: string;
}

export interface MusicSelectorProps {
    includeMusic: boolean;
    setIncludeMusic: (value: boolean) => void;
    setLoading: (value: boolean) => void;
    music: string;
    setMusic: (value: string) => void;
}

export interface OverlayThumbnailsProps {
    overlays: Overlay[] | Logo[];
    selectedOverlay: string | null;
    onSelectOverlay: (id: string) => void;
    isMobile: boolean;
}

export interface SlideshowMediaSelectorProps {
    media: SlideshowMedia;
    handleMediaSelect: (id: string, type: "image" | "asset") => void;
    selectedImages: SelectedImages[];
}

interface SlideshowMedia {
    images?: Images[];
    assets?: Assets[];
}

export interface SelectedImages {
    id: string;
    type: "image" | "asset";
}

export enum JobStatus {
    QUEUED = "queued",
    PROCESSING = "processing",
    COMPLETED = "completed",
    FAILED = "failed",
}
