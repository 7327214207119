import { Assets } from "../../types/asset-type";
import { CampaignProgress } from "../campaign/types";
import { CarouselApi } from "../misc/Carousel";
import { Users, BookOpen, Wrench, Shield, Zap, Leaf } from "lucide-react";
import { RiContractLine, RiHomeGearLine } from "react-icons/ri";
import { BiPurchaseTag } from "react-icons/bi";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { BsExclamationTriangle } from "react-icons/bs";
import { Dispatch, SetStateAction } from "react";

export interface Promotion {
    id: string;
    types: PromotionType[];
    color: string;
    campaignStatus?: string;
    campaignType?: string;
    generatedPosts?: number;
    publishedPosts?: number;
    scheduledPosts?: number;
    unapprovedPosts: number;
    contentRefresh: number;
    campaignId?: string;
    campaignProgress?: CampaignProgress;
    userNotes?: string;
}

export enum PromotionType {
    Mortgage = "Mortgage",
    FirstTimeHomeBuyer = "FirstTimeHomeBuyer",
    ClosingProcess = "ClosingProcess",
    RealtorAdvocacy = "RealtorAdvocacy",
    BuyerTips = "BuyerTips",
    BuyerEducation = "BuyerEducation",
    HomeMaintenance = "HomeMaintenance",
    HomeImprovement = "HomeImprovement",
    HomeSecurity = "HomeSecurity",
    MythBusting = "MythBusting",
    Sustainability = "Sustainability",
}

export interface PromotionProps {
    promotion: Promotion | null;
    setPromotion: Dispatch<SetStateAction<Promotion | null>>;
    isEditing: boolean;
    setIsEditing: Dispatch<SetStateAction<boolean>>;
    assets: any[];
    setLoading: Dispatch<SetStateAction<boolean>>;
    fetchAssets: () => void;
    fetchPromotions: () => void;
    originalPromotion: Promotion | null;
    setOriginalPromotion: Dispatch<SetStateAction<Promotion | null>>;
    showSaveWarning: boolean;
    setShowSaveWarning: Dispatch<SetStateAction<boolean>>;
    activeTab: "categories" | "promotion";
    setActiveTab: Dispatch<SetStateAction<"categories" | "promotion">>;
    activePromotionType: PromotionType | undefined;
    setActivePromotionType: Dispatch<SetStateAction<PromotionType>>;
}

export interface CampaignCardProps {
    promotion: Promotion;
}

export interface PromotionTabsComponentProps {
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
    promotion: Promotion | null;
    assets: Assets[];
    activeTab: "categories" | "promotion";
    setActiveTab: (activeTab: "categories" | "promotion") => void;
    carouselApi: CarouselApi | null;
    setLoading: (loading: boolean) => void;
    setPromotion: Dispatch<SetStateAction<Promotion | null>>;
    fetchAssets: () => void;
    activePromotionType: PromotionType | undefined;
    setActivePromotionType: Dispatch<SetStateAction<PromotionType>>;
    carouselAssetIndexMap: { [id: string]: number };
    originalPromotion: Promotion | null;
    fetchPromotions: () => void;
    setOriginalPromotion: Dispatch<SetStateAction<Promotion | null>>;
    setShowSaveWarning: React.Dispatch<React.SetStateAction<boolean>>;
    selectedImageIndices: { [key in PromotionType]?: number };
    setSelectedImageIndices: React.Dispatch<
        React.SetStateAction<{ [key in PromotionType]?: number }>
    >;
}
export interface PromotionTabProps {
    isEditing: boolean;
    selectedCategories: PromotionType[];
    setSelectedCategories: (selectedCategories: PromotionType[]) => void;
    promotion: Promotion | null;
    setPromotion: (promotion: Promotion) => void;
}

export interface PromotionTabButtonProps {
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
    promotion: Promotion | null;
    setLoading: (loading: boolean) => void;
    selectedCategories: PromotionType[];
    setSelectedCategories: (selectedCategories: PromotionType[]) => void;
    setPromotion: Dispatch<SetStateAction<Promotion | null>>;
    selectedMedia: Assets[];
    setSelectedMedia: (selectedMedia: Assets[]) => void;
    fetchAssets: () => void;
    originalPromotion: Promotion | null;
    fetchPromotions: () => void;
    setOriginalPromotion: Dispatch<SetStateAction<Promotion | null>>;
    isMobile: boolean;
    handleSave: () => void;
    mediaLength: number;
}

export interface PromotionAssetsTabProps {
    isEditing: boolean;
    assets: Assets[] | undefined;
    promotionTypes: PromotionType[];
    promotionId: string | undefined;
    selectedMedia: Assets[];
    setSelectedMedia: (selectedMedia: Assets[]) => void;
    carouselApi: CarouselApi | null;
    fetchAssets: () => void;
    activePromotionType: PromotionType | undefined;
    setActivePromotionType: Dispatch<SetStateAction<PromotionType>>;
    carouselAssetIndexMap: { [id: string]: number };
    value: string;
    setLoading: (loading: boolean) => void;
    isMobile: boolean;
    onThumbnailClick?: (index: number) => void;
    selectedImageIndices?: { [key in PromotionType]?: number };
    setSelectedImageIndices?: React.Dispatch<
        React.SetStateAction<{ [key in PromotionType]?: number }>
    >;
    isUploading: boolean;
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const categories = [
    { id: "Mortgage", label: "Mortgage", icon: RiContractLine },
    { id: "FirstTimeHomeBuyer", label: "First Time Home Buyer", icon: Users },
    { id: "ClosingProcess", label: "Closing Process", icon: BiPurchaseTag },
    {
        id: "RealtorAdvocacy",
        label: "Realtor Advocacy",
        icon: MdOutlineRealEstateAgent,
    },
    { id: "BuyerTips", label: "Buyer Tips", icon: Zap },
    { id: "BuyerEducation", label: "Buyer Education", icon: BookOpen },
    { id: "HomeMaintenance", label: "Home Maintenance", icon: Wrench },
    { id: "HomeImprovement", label: "Home Improvement", icon: RiHomeGearLine },
    { id: "HomeSecurity", label: "Home Security", icon: Shield },
    { id: "MythBusting", label: "Myth Busting", icon: BsExclamationTriangle },
    { id: "Sustainability", label: "Sustainability", icon: Leaf },
];

export interface MediaThumbnailsProps {
    assets: Assets[] | undefined;
    currentPage: number;
    toggleSelectMedia: (asset: Assets) => void;
    selectedMedia: Assets[];
    isEditing: boolean;
    carouselApi: CarouselApi | null;
    carouselAssetIndexMap: { [id: string]: number };
    isMobile: boolean;
    onThumbnailClick?: (index: number) => void;
}

export const itemsPerPage = 10;

export interface UserNotesCardProps {
    promotion: Promotion;
    setPromotion: (promotion: Promotion) => void;
}

export const samplePosts = [
    {
        label: "Mortgage",
        img: `https://storage.googleapis.com/${process.env.REACT_APP_ASSETS_BUCKET}/mortgages_sample.png`,
        text: `🏠 When it comes to buying a home, mortgage interest rates play a 
        crucial role in the affordability and overall cost of a home. As a Realtor, 
        it's vital to understand how the Federal Reserve (often referred to as the Fed) 
        influences these rates. Understanding this connection can empower homebuyers 
        and sellers to make informed decisions...`,
    },
    {
        label: "First Time Home Buyer",
        img: `https://storage.googleapis.com/${process.env.REACT_APP_ASSETS_BUCKET}/first_time_home_buyers_sample.jpg`,
        text: `😀 Buying a home for the first time is one of the most exciting and 
        monumental decisions you can make. However, it can also be a nerve-wracking 
        process filled with questions and uncertainties. There’s a lot to consider, 
        from financing to understanding the real estate market and knowing your needs...`,
    },
    {
        label: "Closing Process",
        img: `https://storage.googleapis.com/${process.env.REACT_APP_ASSETS_BUCKET}/closing_process_sample.png`,
        text: `🏠 Closing costs can sometimes feel like a mystery for many sellers. 
        Let's demystify this crucial part of the selling process! When you’re 
        ready to sell your home, understanding the breakdown of closing costs is 
        vital, as it informs you about what you’ll need to pay or negotiate at the 
        closing table. These costs might vary, but knowing each component helps 
        avoid surprises...`,
    },
    {
        label: "Realtor Advocacy",
        img: `https://storage.googleapis.com/${process.env.REACT_APP_ASSETS_BUCKET}/realtor_advocacy_sample.png`,
        text: `🏠 As a Realtor, one of my key roles is advocating for better housing 
        policies that not only benefit buyers and sellers but also improve the overall 
        quality and accessibility of housing. Realtors have the unique opportunity 
        to witness firsthand the challenges and hurdles that both home buyers and...`,
    },
    {
        label: "Buyer Tips",
        img: `https://storage.googleapis.com/${process.env.REACT_APP_ASSETS_BUCKET}/home_buyer_tips_sample.png`,
        text: `🔍Buying a home can be an exhilarating journey! But before 
        finalizing your purchase, one crucial step stands between you and 
        your new home: the home inspection. Knowing what to expect can 
        ease your nerves and prepare you for this important event...`,
    },
    {
        label: "Buyer Education",
        img: `https://storage.googleapis.com/${process.env.REACT_APP_ASSETS_BUCKET}/buyer_education_sample.jpg`,
        text: `🏡 Buying or selling a house is an exciting experience, 
        but it often involves complex processes that can make many people 
        nervous. One of these processes is the appraisal. If you're a 
        buyer, it's essential to understand what an appraisal is, why 
        it's important, and how it can affect your purchase...`,
    },
    {
        label: "Home Maintenance",
        img: `https://storage.googleapis.com/${process.env.REACT_APP_ASSETS_BUCKET}/home_maintenance_sample.jpg`,
        text: `🔥 Using a wood-burning stove can be a wonderful experience. 
        The cozy warmth and the hypnotic dance of flames can make any home 
        feel like a cabin retreat. However, to enjoy these benefits for 
        years to come, it's crucial to maintain your wood-burning stove 
        properly. Here’s a guide on how to keep...`,
    },
    {
        label: "Home Improvement",
        img: `https://storage.googleapis.com/${process.env.REACT_APP_ASSETS_BUCKET}/home_improvement_sample.png`,
        text: `🏡 Buying or selling a house is an exciting experience, but 
        it often involves complex processes that can make many people nervous. 
        One of these processes is the appraisal. If you're a buyer, it's 
        essential to understand what an appraisal is, why it's important, 
        and how it can affect your purchase...`,
    },
    {
        label: "Home Security",
        img: `https://storage.googleapis.com/${process.env.REACT_APP_ASSETS_BUCKET}/home_security_sample.png`,
        text: `🏡 In today's digital age, securing your personal information 
        is just as important as securing your home. Identity theft is a growing 
        concern and can have devastating effects on your financial and personal 
        life. As a trusted Realtor, I want to provide not only guidance on 
        finding or selling a home...`,
    },
    {
        label: "Myth Busting",
        img: `https://storage.googleapis.com/${process.env.REACT_APP_ASSETS_BUCKET}/faq_myths_sample.jpg`,
        text: `🌟 MythBusting: Do I Need to Get a Mortgage from My Bank to Get 
        the Best Rate?\nWhen shopping for a mortgage, many believe that going 
        directly to their bank is the best way to secure the ideal interest 
        rate. While banks are a familiar and easy choice, they may not always 
        provide the best rates...`,
    },
    {
        label: "Sustainability",
        img: `https://storage.googleapis.com/${process.env.REACT_APP_ASSETS_BUCKET}/sustainability_sample.png`,
        text: `🌿 In recent years, more homebuyers have become interested in sustainable 
        living. The concept of green homes has been gaining traction, not just as a trend, 
        but as a necessity. More than ever, people are realizing the impact that 
        traditional homes have on our planet and are making conscious...`,
    },
];

export interface DeleteMediaProps {
    selectedMedia: Assets[];
    setLoading: (value: boolean) => void;
    promotionId?: string | undefined;
    fetchAssets: () => void;
    setSelectedMedia: (value: Assets[]) => void;
}
