export const faqData = [
    {
        question: "How do I reset my password?",
        answer: "To reset your password, go to the Settings page and click on the 'Change Password' button. Follow the prompts to set a new password.",
    },
    {
        question: "Can I change my username?",
        answer: "Yes, you can change your username once every 30 days. Go to the Profile section in your Settings and look for the 'Edit Username' option.",
    },
    {
        question: "How do I cancel my subscription?",
        answer: "To cancel your subscription, navigate to the Billing section in your account settings. Click on 'Cancel Subscription' and follow the steps to confirm cancellation.",
    },
    {
        question: "Is my data secure?",
        answer: "Yes, we take data security very seriously. We use industry-standard encryption protocols and regularly update our security measures to ensure your data is protected.",
    },
    {
        question: "How can I contact customer support?",
        answer: "You can contact our customer support team through the 'Help' section in the app, or by emailing support@ourapp.com. We aim to respond to all queries within 24 hours.",
    },
];
