import React from "react";

interface TooltipProps {
    message: string;
    visible: boolean;
    children: React.ReactNode;
}

const SimpleTooltip: React.FC<TooltipProps> = ({
    message,
    children,
    visible,
}) => {
    return (
        <div className="relative group inline-block w-full">
            {children}
            {visible ? (
                <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none z-50">
                    <div className="bg-secondary text-primary text-xs font-semibold rounded py-1 px-2 whitespace-nowrap">
                        {message}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default SimpleTooltip;
