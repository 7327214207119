import React from "react";
import StepItem from "./StepItem";
import { useIsMobile } from "../../hooks/useIsMobile";

interface Step {
    label: string;
    isActive: boolean;
    isCompleted: boolean;
}

interface StepProgressBarProps {
    steps: Step[];
}

interface LineConnectorProps {
    isCompleted: boolean;
}

const StepProgressBar: React.FC<StepProgressBarProps> = ({ steps }) => {
    const isMobile = useIsMobile(900);

    const LineConnector: React.FC<LineConnectorProps> = ({ isCompleted }) => (
        <div
            className={`${isMobile ? "hidden" : "block"} w-full h-0.5 ${
                isCompleted ? "bg-secondary" : "bg-stone-300"
            }`}
        ></div>
    );

    return (
        <nav
            className={`flex ${
                isMobile ? "justify-between px-2" : "justify-center px-6"
            } items-center py-4 bg-white rounded-lg w-full`}
            aria-label="Progress"
        >
            {steps.map((step, index) => (
                <React.Fragment key={index}>
                    <StepItem
                        label={step.label}
                        isActive={step.isActive}
                        isMobile={isMobile}
                    />
                    {index < steps.length - 1 && (
                        <LineConnector isCompleted={step.isCompleted} />
                    )}
                </React.Fragment>
            ))}
        </nav>
    );
};

export default StepProgressBar;
