import React from "react";
import { TabsContent } from "../../misc/Tabs";
import { TutorialsList } from "./TutorialsList";
import { tutorialsData } from "./tutorials-data";

interface TutorialsProps {
    innerTab?: string;
}

const Tutorials: React.FC<TutorialsProps> = ({ innerTab }) => {
    return (
        <TabsContent value="tutorials">
            <div className="container mx-auto px-4 py-12">
                <h1 className="text-4xl font-bold mb-2 text-center text-gray-800">
                    Tutorials
                </h1>
                <p className="text-xl text-gray-600 mb-12 text-center">
                    Learn how to make the most of our app with these helpful
                    guides
                </p>
                <TutorialsList initialTutorials={tutorialsData} />
            </div>
        </TabsContent>
    );
};

export default Tutorials;
