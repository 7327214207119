import React from "react";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../listings/slideshow/Tooltip";
import { FaCheck } from "react-icons/fa6";
import { cn } from "../../utils/classMerger";
import CustomToast from "./CustomToast";
import { MediaSelectorProps, UnifiedMedia } from "../listings/slideshow/types";
import { toast } from "react-toastify";
import { useIsMobile } from "../../hooks/useIsMobile";
import { Assets } from "../../types/asset-type";
import { ArchiveMedia } from "../post/types";
import { Images } from "../../types/listingImages";
import { ListingVideos } from "../../types/listingVideos";

const MediaSelector: React.FC<
    MediaSelectorProps & {
        showAssetsUploader?: boolean;
        onAssetsUploaded?: (newAssets: UnifiedMedia[]) => void;
        listingId?: string;
    }
> = ({
    images = [],
    videos = [],
    promotionImages = [],
    assets = [],
    archived = [],
    selectedMedia,
    onSelect,
    maxMedia,
    minMedia,
    showAssetsUploader = false,
    onAssetsUploaded,
    listingId,
    type,
}) => {
    const isMobile = useIsMobile(950);

    const mapAssetsToUnifiedMedia = (asset: Assets): UnifiedMedia => ({
        ...asset,
        mediaType: asset.assetType === "Video" ? "video" : "image",
    });

    const mapArchiveToUnifiedMedia = (archive: ArchiveMedia): UnifiedMedia => ({
        ...archive,
        mediaType: archive.type === "Removed Video" ? "video" : "image",
    });

    const combinedMedia: UnifiedMedia[] = [
        ...images.map(
            (image) => ({ ...image, mediaType: "image" } as UnifiedMedia)
        ),
        ...videos.map(
            (video) => ({ ...video, mediaType: "video" } as UnifiedMedia)
        ),
        ...promotionImages.map(
            (promotionImage) =>
                ({ ...promotionImage, mediaType: "image" } as UnifiedMedia)
        ),
        ...assets.map(mapAssetsToUnifiedMedia),
        ...archived.map(mapArchiveToUnifiedMedia),
    ];

    const isImage = (
        item: UnifiedMedia
    ): item is
        | (Images & { mediaType: "image" })
        | (Assets & { mediaType: "image" })
        | (ArchiveMedia & { mediaType: "image" }) => {
        return item.mediaType === "image";
    };

    const isVideo = (
        item: UnifiedMedia
    ): item is
        | (ListingVideos & { mediaType: "video" })
        | (Assets & { mediaType: "video" })
        | (ArchiveMedia & { mediaType: "video" }) => {
        return item.mediaType === "video";
    };

    return (
        <div className="space-y-4">
            <h3 className="text-lg font-semibold text-primary">
                Select Images
            </h3>
            <span className="text-sm text-gray-500">
                Please select a minimum of {minMedia} images and a maximum of{" "}
                {maxMedia} {type}
            </span>

            <div className="mb-2 text-sm text-gray-600">
                Selected {selectedMedia.length} / {maxMedia} images
            </div>

            <div
                className={cn(
                    "grid gap-4",
                    isMobile ? "grid-cols-2" : "grid-cols-3"
                )}
            >
                {combinedMedia.map((item) => (
                    <TooltipProvider key={item.id}>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <div
                                    className={cn(
                                        "group relative w-full h-48 cursor-pointer overflow-hidden rounded-lg border transition-transform duration-200",
                                        selectedMedia.includes(item.id) &&
                                            "ring-2 ring-primary",
                                        !selectedMedia.includes(item.id) &&
                                            selectedMedia.length >= maxMedia &&
                                            "opacity-50 cursor-not-allowed"
                                    )}
                                    onClick={() => {
                                        if (
                                            !selectedMedia.includes(item.id) &&
                                            selectedMedia.length >= maxMedia
                                        ) {
                                            toast.error(
                                                <CustomToast
                                                    message={`You can select up to ${maxMedia} images.`}
                                                    type="error"
                                                />
                                            );
                                            return;
                                        }
                                        onSelect(item.id);
                                    }}
                                >
                                    {isVideo(item) ? (
                                        <video
                                            muted
                                            src={item.url}
                                            controls
                                            className="object-cover w-full h-full transition-transform group-hover:scale-105"
                                        />
                                    ) : isImage(item) ? (
                                        <img
                                            src={item.slideshow || item.url}
                                            alt="Selectable Media"
                                            className="object-fill w-full h-full transition-transform group-hover:scale-105"
                                        />
                                    ) : null}
                                    {selectedMedia.includes(item.id) && (
                                        <div className="absolute inset-0 bg-primary/20 flex items-center justify-center">
                                            <span className="absolute bottom-2 right-2 bg-primary text-white text-xs px-2 py-1 rounded">
                                                {selectedMedia.indexOf(
                                                    item.id
                                                ) + 1}
                                            </span>
                                            <FaCheck className="h-6 w-6 text-white" />
                                        </div>
                                    )}
                                </div>
                            </TooltipTrigger>
                            <TooltipContent>
                                <span className="text-white">
                                    Click to select
                                </span>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                ))}
            </div>

            {selectedMedia.length < minMedia && (
                <div className="text-red-500 text-sm">
                    Please select at least {minMedia} images to continue.
                </div>
            )}
        </div>
    );
};

export default MediaSelector;
