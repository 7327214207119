import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "../../components/misc/Card";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../../components/misc/Tabs";
import { getAuthHeader } from "../../utils/authHeader";
import { toast } from "react-toastify";
import CustomToast from "../../components/misc/CustomToast";
import axios from "axios";
import { defaultMaintenance, Maintenance } from "../types";
import LoadingScreen from "../../screens/LoadingScreen";
import { MaintenanceForm } from "./MaintenanceForm";

const MaintenanceMode = () => {
    const [generalMaintenance, setGeneralMaintenance] = useState<Maintenance>({
        isOnline: true,
        reason: "",
    });
    const [aiMaintenance, setAiMaintenance] =
        useState<Maintenance>(defaultMaintenance);
    const [orgGeneralMaintenance, setOrgGeneralMaintenance] =
        useState<Maintenance>(defaultMaintenance);
    const [orgAiMaintenance, setOrgAiMaintenance] =
        useState<Maintenance>(defaultMaintenance);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchMaintenanceData = async () => {
            setLoading(true);

            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            try {
                const maintenaceResponse = await axios.get(
                    `${backendUrl}/admin/maintenance`,
                    { headers }
                );

                if (maintenaceResponse.status !== 200) {
                    throw new Error("Failed to fetch maintenance data");
                }

                if (
                    maintenaceResponse.data?.isOnline?.toString() === "true" ||
                    maintenaceResponse.data?.isOnline?.toString() === "false"
                ) {
                    setGeneralMaintenance(maintenaceResponse.data);
                    setOrgGeneralMaintenance(maintenaceResponse.data);
                }

                const aiResponse = await axios.get(
                    `${backendUrl}/admin/ai-maintenance/`,
                    { headers }
                );

                if (aiResponse.status !== 200) {
                    throw new Error("Failed to fetch maintenance data");
                }

                if (
                    aiResponse.data?.isOnline?.toString() === "true" ||
                    aiResponse.data?.isOnline?.toString() === "false"
                ) {
                    setAiMaintenance(aiResponse.data);
                    setOrgAiMaintenance(aiResponse.data);
                }
            } catch (error: unknown) {
                console.error(error);
                toast.error(
                    <CustomToast
                        message="Failed to fetch maintenance data"
                        type="error"
                    />,
                    {
                        autoClose: 3000,
                    }
                );
            } finally {
                setLoading(false);
            }
        };

        fetchMaintenanceData();
    }, []);

    const handleSaveChanges = async (type: "general" | "ai") => {
        setLoading(true);
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        let maintenanceData, originalData, endpoint;

        if (type === "general") {
            maintenanceData = generalMaintenance;
            originalData = orgGeneralMaintenance;
            endpoint = `${backendUrl}/admin/maintenance`;
        } else {
            maintenanceData = aiMaintenance;
            originalData = orgAiMaintenance;
            endpoint = `${backendUrl}/admin/ai-maintenance`;
        }

        // Check if there are changes
        const hasChanged =
            maintenanceData.isOnline !== originalData.isOnline ||
            maintenanceData.reason !== originalData.reason;

        if (!hasChanged) {
            toast.info(
                <CustomToast message="No changes to save." type="success" />,
                { autoClose: 3000 }
            );
            setLoading(false);
            return;
        }

        // Validate maintenance data
        if (!maintenanceData.isOnline && maintenanceData.reason.trim() === "") {
            toast.error(
                <CustomToast
                    message="Please provide a reason for maintenance mode."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post(
                endpoint,
                {
                    isOnline: maintenanceData.isOnline,
                    reason: maintenanceData.reason,
                },
                { headers }
            );

            if (response.status === 201) {
                toast.success(
                    <CustomToast
                        message={`${
                            type === "general" ? "General" : "AI"
                        } maintenance settings updated successfully.`}
                        type="success"
                    />,
                    { autoClose: 3000 }
                );

                if (type === "general") {
                    setOrgGeneralMaintenance(response.data);
                } else {
                    setOrgAiMaintenance(response.data);
                }
            } else {
                throw new Error("Failed to update maintenance settings.");
            }
        } catch (error: unknown) {
            console.error(error);
            toast.error(
                <CustomToast
                    message={`Failed to update ${
                        type === "general" ? "general" : "AI"
                    } maintenance settings.`}
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col h-full p-4 space-y-6">
            {loading && <LoadingScreen />}
            <h2 className="text-2xl font-bold text-primary">
                Maintenance Mode
            </h2>
            <Card className="w-full">
                <CardHeader>
                    <CardTitle>System Maintenance</CardTitle>
                </CardHeader>
                <CardContent>
                    <Tabs defaultValue="general" className="space-y-4">
                        <TabsList>
                            <TabsTrigger value="general">
                                General Maintenance
                            </TabsTrigger>
                            <TabsTrigger value="ai">AI Maintenance</TabsTrigger>
                        </TabsList>

                        <TabsContent value="general" className="space-y-4">
                            <span className="text-sm text-gray-500">
                                Enable or disable maintenance mode for the
                                entire website.
                            </span>
                            <MaintenanceForm
                                maintenance={generalMaintenance}
                                setMaintenance={setGeneralMaintenance}
                                originalMaintenance={orgGeneralMaintenance}
                                type="general"
                                onSave={() => handleSaveChanges("general")}
                                loading={loading}
                            />
                        </TabsContent>

                        <TabsContent value="ai" className="space-y-4">
                            <span className="text-sm text-gray-500">
                                Enable or disable maintenance mode for campaign
                                generation and post regeneration
                            </span>
                            <MaintenanceForm
                                maintenance={aiMaintenance}
                                setMaintenance={setAiMaintenance}
                                originalMaintenance={orgAiMaintenance}
                                type="ai"
                                onSave={() => handleSaveChanges("ai")}
                                loading={loading}
                            />
                        </TabsContent>
                    </Tabs>
                </CardContent>
            </Card>
        </div>
    );
};

export default MaintenanceMode;
