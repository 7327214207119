import React, { useEffect, useRef, useMemo } from "react";
import { Post } from "../../types/posts";
import {
    FaBars,
    FaCalendarAlt,
    FaChevronLeft,
    FaChevronRight,
    FaTimes,
    FaFilter,
} from "react-icons/fa";
import Select, {
    MultiValue,
    SingleValue,
    ActionMeta,
    StylesConfig,
} from "react-select";
import { ScrollArea } from "./ScrollArea";
import WeeklyView from "./WeeklyView";
import {
    format,
    addWeeks,
    subWeeks,
    startOfWeek,
    addDays,
    addMonths,
    subMonths,
    subDays,
} from "date-fns";
import FourWeekView from "./FourWeekView";
import MonthlyView from "./MonthlyView";

import { useSelector, useDispatch } from "react-redux";
import {
    setSelectedView,
    setSelectedListingAddress,
    setSelectedPlatform,
    setSelectedApprovalStatus,
    setCurrentDate,
    resetCalendarState,
} from "../../store/calendarSlice";
import { RootState } from "../../store/store";
import { useLocation, useNavigate } from "react-router-dom";
import NewPost from "../post/NewPost";

interface CalendarProps {
    posts: Post[];
    postType: string | null;
}

interface OptionType {
    label: string;
    value: string;
}

const Calendar: React.FC<CalendarProps> = ({ posts, postType }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Reset calendar state if navigated with resetCalendar flag
    useEffect(() => {
        if (location.state?.resetCalendar) {
            dispatch(resetCalendarState());

            // Optionally, clear the reset flag from location state to prevent repeated resets
            navigate(location.pathname, { replace: true, state: {} });
        }
    }, [location.state, dispatch, navigate, location.pathname]);

    const MOBILE_BREAKPOINT = 900;

    // Access Redux state
    const selectedView = useSelector(
        (state: RootState) => state.calendar.selectedView
    );
    const selectedListingAddress = useSelector(
        (state: RootState) => state.calendar.selectedListingAddress
    );
    const selectedPlatform = useSelector(
        (state: RootState) => state.calendar.selectedPlatform
    );
    const selectedApprovalStatus = useSelector(
        (state: RootState) => state.calendar.selectedApprovalStatus
    );
    const currentDate = useSelector(
        (state: RootState) => state.calendar.currentDate
    );

    // Local state for UI components
    const [isMobile, setIsMobile] = React.useState(
        window.innerWidth <= MOBILE_BREAKPOINT
    );
    const [isOverlayOpen, setIsOverlayOpen] = React.useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const filterButtonRef = useRef<HTMLButtonElement>(null);

    const [addPostOverlay, setAddPostOverlay] = React.useState(false);

    useEffect(() => {
        if (location.state?.addPost) {
            setAddPostOverlay(true);
        }
    }, [location.state?.addPost]);

    // Handle window resize for mobile responsiveness
    useEffect(() => {
        const handleResize = () => {
            const mobile = window.innerWidth <= MOBILE_BREAKPOINT;
            setIsMobile(mobile);
            if (!mobile) {
                setIsOverlayOpen(false); // Close overlay if switching to desktop
            }
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(target) &&
                filterButtonRef.current &&
                !filterButtonRef.current.contains(target) &&
                !target.closest(".react-select__menu")
            ) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Reset view based on window width
    useEffect(() => {
        const handleViewReset = () => {
            if (window.innerWidth <= 1450) {
                dispatch(
                    setSelectedView({ label: "Weekly View", value: "weekly" })
                );
            }
        };

        window.addEventListener("resize", handleViewReset);
        handleViewReset(); // Check on mount

        return () => {
            window.removeEventListener("resize", handleViewReset);
        };
    }, [dispatch]);

    // Options for Select components
    const calendarViewOptions: OptionType[] = [
        { label: "Weekly", value: "weekly" },
        { label: "4 Weeks", value: "fourWeekly" },
        { label: "Monthly", value: "monthly" },
    ];

    console.log("Post platform", posts[0]);

    const listingTypeOptions = useMemo<OptionType[]>(() => {
        const types = Array.from(
            new Set(
                posts
                    .map((post) => post.address?.split(",")[0].trim())
                    .filter((type): type is string => !!type)
            )
        );

        return types.map((type) => ({
            label: type.charAt(0).toUpperCase() + type.slice(1),
            value: type,
        }));
    }, [posts]);

    const platformOptions = useMemo<OptionType[]>(() => {
        const platforms = Array.from(
            new Set(
                posts
                    .map((post) => post.platform?.toLowerCase())
                    .filter((platform): platform is string => !!platform)
            )
        );

        return platforms.map((platform) => ({
            label: platform.charAt(0).toUpperCase() + platform.slice(1),
            value: platform,
        }));
    }, [posts]);

    const approvalStatusOptions = useMemo<OptionType[]>(() => {
        const statuses = Array.from(
            new Set(
                posts
                    .map((post) => post.status?.trim().toLowerCase())
                    .filter((status): status is string => !!status)
            )
        );

        return statuses.map((status) => {
            let label = "";
            switch (status) {
                case "approved":
                    label = "Approved";
                    break;
                case "draft":
                    label = "Pending Approval";
                    break;
                case "posted":
                    label = "Posted";
                    break;
                case "error":
                    label = "Error";
                    break;
                default:
                    label = status.charAt(0).toUpperCase() + status.slice(1);
            }
            return { label, value: status };
        });
    }, [posts]);

    // Custom styles for react-select
    const customSelectStyles: StylesConfig<OptionType, boolean> = {
        control: (base) => ({
            ...base,
            border: "none",
            boxShadow: "none",
            minHeight: "unset",
            "&:hover": { cursor: "pointer" },
        }),
        valueContainer: (base) => ({
            ...base,
            padding: "0",
            margin: "0rem 0.5rem",
        }),
        indicatorsContainer: (base) => ({
            ...base,
            padding: "0",
        }),
        dropdownIndicator: (base, state) => ({
            ...base,
            color: state.isFocused ? "#193456" : "#E9C468",
            "&:hover": {
                color: "#193456",
            },
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected
                ? "#193456"
                : state.isFocused
                ? "#E9C468"
                : "#FFFFFF",
            color: state.isSelected ? "#FFFFFF" : "#193456",
            "&:hover": {
                backgroundColor: "#F0DFAF",
                color: "#193456",
            },
        }),
        menu: (base) => ({
            ...base,
            borderRadius: 8,
            marginTop: 5,
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }),
    };

    const filterSelectStyles: StylesConfig<OptionType, true> = {
        ...customSelectStyles,
        control: (base) => ({
            ...base,
            boxShadow: "none",
            minHeight: "unset",
            "&:hover": { cursor: "pointer" },
            border: "1px solid #E9E9E9", // Adding specific border for filters
        }),
    };

    // Handler for single select (View Change)
    const handleViewChange = (
        option: SingleValue<OptionType>,
        _: ActionMeta<OptionType>
    ) => {
        if (option) {
            dispatch(setSelectedView(option));
            // Reset currentDate when view changes to ensure correct date range
            dispatch(setCurrentDate(new Date()));
        }
    };

    // Handler for multi-selects (Filters)
    const handleMultiSelectChangeRedux =
        (actionCreator: (value: OptionType[]) => any) =>
        (newValue: MultiValue<OptionType>, _: ActionMeta<OptionType>) => {
            dispatch(actionCreator(newValue as OptionType[]));
        };

    // Navigation Handlers
    const handleNext = () => {
        if (selectedView.value === "weekly") {
            dispatch(setCurrentDate(addWeeks(currentDate, 1)));
        } else if (selectedView.value === "fourWeekly") {
            dispatch(setCurrentDate(addWeeks(currentDate, 4)));
        } else if (selectedView.value === "monthly") {
            dispatch(setCurrentDate(addMonths(currentDate, 1)));
        }
    };

    const handlePrevious = () => {
        if (selectedView.value === "weekly") {
            dispatch(setCurrentDate(subWeeks(currentDate, 1)));
        } else if (selectedView.value === "fourWeekly") {
            dispatch(setCurrentDate(subWeeks(currentDate, 4)));
        } else if (selectedView.value === "monthly") {
            dispatch(setCurrentDate(subMonths(currentDate, 1)));
        }
    };

    // Display Date Range
    const getDisplayDate = () => {
        if (selectedView.value === "weekly") {
            const weekStart = startOfWeek(currentDate, { weekStartsOn: 1 });
            const weekEnd = addDays(weekStart, 6);
            return `${format(weekStart, "d MMM")} - ${format(
                weekEnd,
                "d MMM"
            )}`;
        } else if (selectedView.value === "fourWeekly") {
            const weekStart = startOfWeek(currentDate, { weekStartsOn: 1 });
            const rangeEnd = addWeeks(weekStart, 4);
            const rangeEndAdjusted = subDays(rangeEnd, 1); // Subtract 1 day to get the last day
            return `${format(weekStart, "d MMM")} - ${format(
                rangeEndAdjusted,
                "d MMM"
            )}`;
        } else if (selectedView.value === "monthly") {
            return format(currentDate, "MMMM yyyy");
        } else {
            return "";
        }
    };

    useEffect(() => {
        if (postType === "pending-approval") {
            dispatch(
                setSelectedApprovalStatus([
                    { label: "Pending Approval", value: "draft" },
                ])
            );
        }
    }, [postType, dispatch]);

    // Filter Posts Based on Selected Filters
    const filteredPosts = useMemo(() => {
        return posts.filter((post) => {
            const normalizedPostAddress = post.address
                ? post.address.split(",")[0].trim().toLowerCase()
                : "";

            const normalizedPlatformName = post.platform.toLowerCase() || "";

            const normalizedStatus = post.status?.trim().toLowerCase() || "";

            const matchesListingAddress =
                selectedListingAddress.length === 0 ||
                selectedListingAddress.some(
                    (address) =>
                        address.value.toLowerCase() === normalizedPostAddress
                );

            const matchesPlatform =
                selectedPlatform.length === 0 ||
                selectedPlatform.some(
                    (platform) =>
                        platform.value.toLowerCase() === normalizedPlatformName
                );

            const matchesApprovalStatus =
                selectedApprovalStatus.length === 0 ||
                selectedApprovalStatus.some(
                    (status) => status.value.toLowerCase() === normalizedStatus
                );

            const isMatch =
                matchesListingAddress &&
                matchesPlatform &&
                matchesApprovalStatus;

            if (!isMatch) {
                console.log(
                    `Post excluded: ${post.id} - ${post.type}, ${post.platform}, ${post.status}`
                );
            }

            return isMatch;
        });
    }, [
        posts,
        selectedListingAddress,
        selectedPlatform,
        selectedApprovalStatus,
    ]);

    // Render the appropriate view based on selection
    const renderView = () => {
        switch (selectedView.value) {
            case "weekly":
                return (
                    <WeeklyView
                        posts={filteredPosts}
                        currentDate={currentDate}
                        selectedListingAddress={selectedListingAddress}
                        selectedPlatform={selectedPlatform}
                        selectedApprovalStatus={selectedApprovalStatus}
                        postType={postType}
                    />
                );
            case "fourWeekly":
                return (
                    <FourWeekView
                        posts={filteredPosts}
                        currentDate={currentDate}
                        selectedListingAddress={selectedListingAddress}
                        selectedPlatform={selectedPlatform}
                        selectedApprovalStatus={selectedApprovalStatus}
                        postType={postType}
                    />
                );
            case "monthly":
                return (
                    <MonthlyView
                        posts={filteredPosts}
                        currentDate={currentDate}
                        selectedListingAddress={selectedListingAddress}
                        selectedPlatform={selectedPlatform}
                        selectedApprovalStatus={selectedApprovalStatus}
                        postType={postType}
                    />
                );
            default:
                return null;
        }
    };

    // Toggle Overlay for Mobile
    const handleOverlayToggle = () => {
        setIsOverlayOpen(!isOverlayOpen);
        setIsDropdownOpen(false); // Close dropdown when toggling overlay
    };

    // Toggle Dropdown for Filters
    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    // Apply Filters Handler
    const handleApplyFilters = () => {
        setIsDropdownOpen(false);
        setIsOverlayOpen(false);
    };

    const handleCloseFilters = () => {
        setIsDropdownOpen(false);
    };

    return (
        <div className="w-full h-full flex flex-col overflow-y-auto relative">
            {/* Overlay */}
            {addPostOverlay ? (
                <NewPost setAddPostOverlay={setAddPostOverlay} />
            ) : null}
            {/* Header Section */}
            {!isMobile || (isMobile && !addPostOverlay) ? (
                <>
                    <div className="bg-primary text-white py-4 px-8 sm:px-10 flex items-center justify-between">
                        {isMobile ? (
                            // Mobile Header Layout
                            <div className="w-full flex items-center justify-between">
                                {/* Left: Title */}
                                <h2 className="text-xl font-bold">
                                    My Schedule
                                </h2>

                                {/* Center: Date and Controls */}
                                <div className="flex items-center space-x-2">
                                    <FaChevronLeft
                                        id="previous-period"
                                        onClick={handlePrevious}
                                        className="text-white font-bold cursor-pointer hover:text-secondary"
                                    />
                                    <span className="text-lg font-semibold">
                                        {getDisplayDate()}
                                    </span>
                                    <FaChevronRight
                                        id="next-period"
                                        onClick={handleNext}
                                        className="text-white font-bold cursor-pointer hover:text-secondary"
                                    />
                                </div>

                                {/* Right: Hamburger Icon */}
                                <button
                                    className="text-white text-2xl focus:outline-none"
                                    onClick={handleOverlayToggle}
                                    aria-label="Open Menu"
                                >
                                    <FaBars />
                                </button>
                            </div>
                        ) : (
                            // Desktop Header Layout
                            <>
                                <h2 className="text-xl font-bold">
                                    My Schedule
                                </h2>

                                {window.innerWidth > 1450 && (
                                    <div
                                        id="select-view-container"
                                        className="flex items-center bg-white rounded-lg px-2 py-1 ml-10"
                                    >
                                        <FaCalendarAlt className="text-secondary" />
                                        <Select<OptionType, false>
                                            options={calendarViewOptions}
                                            value={selectedView}
                                            onChange={handleViewChange}
                                            styles={customSelectStyles}
                                            isSearchable={false}
                                            className="min-w-[120px] z-20"
                                        />
                                    </div>
                                )}

                                <div className="flex items-center justify-center mx-auto space-x-4">
                                    <FaChevronLeft
                                        id="previous-period"
                                        onClick={handlePrevious}
                                        className="text-white font-bold cursor-pointer hover:text-secondary"
                                    />
                                    <span className="text-lg font-semibold">
                                        {getDisplayDate()}
                                    </span>
                                    <FaChevronRight
                                        id="next-period"
                                        onClick={handleNext}
                                        className="text-white font-bold cursor-pointer hover:text-secondary"
                                    />
                                </div>

                                <button
                                    id="add-post-btn"
                                    className="mr-10 bg-secondary text-white px-4 py-2 rounded-lg border border-secondary hover:bg-primary hover:border-secondary"
                                    onClick={() => {
                                        setAddPostOverlay(true);
                                    }}
                                >
                                    Add Post
                                </button>

                                <button
                                    id="filter-btn"
                                    ref={filterButtonRef}
                                    className="relative bg-primary border border-secondary text-white px-4 py-2 mr-0.5 rounded-lg hover:bg-secondary flex items-center"
                                    onClick={handleDropdownToggle}
                                >
                                    <FaFilter />
                                    <span className="ml-2">Filter</span>
                                </button>
                            </>
                        )}
                    </div>

                    {/* Mobile Overlay */}
                    {isMobile && isOverlayOpen && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start z-50">
                            <div className="w-full h-full bg-white p-6 relative flex flex-col space-y-4 overflow-auto">
                                {/* Close Button */}
                                <button
                                    className="absolute top-4 right-4 text-2xl text-secondary focus:outline-none"
                                    onClick={handleOverlayToggle}
                                    aria-label="Close Menu"
                                >
                                    <FaTimes />
                                </button>

                                {/* Overlay Content */}
                                <div className="mt-12 flex flex-col items-center space-y-4">
                                    <button
                                        className="w-3/4 bg-secondary text-white px-4 py-2 rounded-lg hover:bg-primary"
                                        onClick={() => {
                                            console.log("Add Post Clicked");
                                            setIsOverlayOpen(false);
                                            setAddPostOverlay(true);
                                        }}
                                    >
                                        Add Post
                                    </button>
                                    <button
                                        ref={filterButtonRef}
                                        className="w-3/4 bg-primary text-secondary border border-secondary px-4 py-2 rounded-lg hover:bg-secondary hover:text-primary flex items-center justify-center"
                                        onClick={handleDropdownToggle}
                                    >
                                        <FaFilter size={20} />
                                        <span className="ml-2">Filter</span>
                                    </button>
                                </div>

                                {/* Mobile Filter Options */}
                                {isDropdownOpen && (
                                    <div className="mt-6 flex flex-col space-y-4 p-4 border border-secondary rounded">
                                        <h3 className="text-lg font-semibold text-primary">
                                            Filters
                                        </h3>
                                        <Select<OptionType, true>
                                            options={listingTypeOptions}
                                            isMulti
                                            placeholder="Filter by Listing/Promotion"
                                            styles={filterSelectStyles}
                                            value={selectedListingAddress}
                                            onChange={handleMultiSelectChangeRedux(
                                                setSelectedListingAddress
                                            )}
                                            closeMenuOnSelect={false} // Prevent dropdown from closing on select
                                            className="w-full"
                                        />
                                        <Select<OptionType, true>
                                            options={platformOptions}
                                            isMulti
                                            placeholder="Filter by Platform"
                                            styles={filterSelectStyles}
                                            value={selectedPlatform}
                                            onChange={handleMultiSelectChangeRedux(
                                                setSelectedPlatform
                                            )}
                                            closeMenuOnSelect={false} // Prevent dropdown from closing on select
                                            className="w-full"
                                        />
                                        <Select<OptionType, true>
                                            options={approvalStatusOptions}
                                            isMulti
                                            placeholder="Filter by Approval Status"
                                            styles={filterSelectStyles}
                                            value={selectedApprovalStatus}
                                            onChange={handleMultiSelectChangeRedux(
                                                setSelectedApprovalStatus
                                            )}
                                            closeMenuOnSelect={false} // Prevent dropdown from closing on select
                                            className="w-full"
                                        />
                                        <button
                                            className="w-full bg-secondary text-white px-4 py-2 rounded-lg hover:bg-primary"
                                            onClick={handleApplyFilters}
                                        >
                                            Apply Filters
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {/* Dropdown Filters (for desktop) */}
                    {!isMobile && isDropdownOpen && (
                        <div
                            className="absolute top-[70px] right-5 bg-white rounded-md p-6 z-50 space-y-4 w-80"
                            style={{
                                boxShadow:
                                    "0 10px 30px rgba(0, 0, 0, 0.25), 0 8px 10px rgba(0, 0, 0, 0.1)",
                            }}
                            ref={dropdownRef}
                        >
                            <h3 className="text-lg font-semibold text-primary">
                                Filters
                            </h3>
                            <Select<OptionType, true>
                                options={listingTypeOptions}
                                isMulti
                                placeholder="Filter by Listing/Promotion"
                                styles={filterSelectStyles}
                                value={selectedListingAddress}
                                onChange={handleMultiSelectChangeRedux(
                                    setSelectedListingAddress
                                )}
                                closeMenuOnSelect={false} // Prevent dropdown from closing on select
                                className="w-full"
                            />
                            <Select<OptionType, true>
                                options={platformOptions}
                                isMulti
                                placeholder="Filter by Platform"
                                styles={filterSelectStyles}
                                value={selectedPlatform}
                                onChange={handleMultiSelectChangeRedux(
                                    setSelectedPlatform
                                )}
                                closeMenuOnSelect={false} // Prevent dropdown from closing on select
                                className="w-full"
                            />
                            <Select<OptionType, true>
                                options={approvalStatusOptions}
                                isMulti
                                placeholder="Filter by Status"
                                styles={filterSelectStyles}
                                value={selectedApprovalStatus}
                                onChange={handleMultiSelectChangeRedux(
                                    setSelectedApprovalStatus
                                )}
                                closeMenuOnSelect={false} // Prevent dropdown from closing on select
                                className="w-full"
                            />
                            <button
                                className="bg-secondary text-white px-4 py-2 rounded-lg hover:bg-primary w-full"
                                onClick={handleCloseFilters}
                            >
                                Close
                            </button>
                        </div>
                    )}

                    {/* Main Content */}
                    <div className="flex-1 overflow-auto">
                        <ScrollArea className="h-full w-full">
                            {/* Overlay when filters are open to prevent interaction with background */}
                            {isDropdownOpen && !isMobile && (
                                <div className="absolute inset-0 bg-white opacity-60" />
                            )}

                            <div className="bg-white rounded-lg p-6 h-full">
                                {renderView()}
                            </div>
                        </ScrollArea>
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default Calendar;
