import React from "react";
import UserListings from "../../components/listings/UserListings";

interface ListingsScreenProps {
    mlsApproved: boolean | null;
    mlsWelcome: boolean;
}

const ListingsScreen: React.FC<ListingsScreenProps> = ({
    mlsApproved,
    mlsWelcome,
}) => {
    return (
        <div>
            <UserListings mlsApproved={mlsApproved} mlsWelcome={mlsWelcome} />
        </div>
    );
};

export default ListingsScreen;
