import React, { useEffect, useMemo, useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "../misc/Dialog";
import { ArchiveMedia, MediaData, PostMediaPickerProps } from "./types";
import { Button } from "../misc/Button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../misc/Tabs";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { X } from "lucide-react";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";
import { Post } from "../../types/posts";
import PostMediaSelector from "./PostMediaSelector";
import { UnifiedMedia } from "../listings/slideshow";
import { Assets } from "../../types/asset-type";
import { ResourceType } from "../content/types";
import { PromotionType } from "../promotions/types";

const PostMediaPicker: React.FC<PostMediaPickerProps> = ({
    isMobile,
    isMediaPickerOpen,
    setIsMediaPickerOpen,
    media,
    platform,
    postMedia = [],
    setLoading,
    setPost,
    postId,
    getPostData,
}) => {
    const [activeResourceType, setActiveResourceType] =
        useState<ResourceType | null>(null);

    const [activePromotionType, setActivePromotionType] =
        useState<PromotionType | null>(null);

    const [activeTab, setActiveTab] = useState<
        "images" | "videos" | "promotionImages" | "assets" | "archived"
    >(
        media?.images?.length
            ? "images"
            : media?.videos?.length
            ? "videos"
            : media?.promotionImages?.length
            ? "promotionImages"
            : "assets"
    );

    useEffect(() => {
        if (media?.archived?.length) {
            setActiveTab("archived");
        } else if (media?.images?.length) {
            setActiveTab("images");
        } else if (media?.videos?.length) {
            setActiveTab("videos");
        } else if (media?.promotionImages?.length) {
            setActiveTab("promotionImages");
        } else if (media?.assets?.length) {
            setActiveTab("assets");
        } else if (media?.archived?.length) {
            setActiveTab("archived");
        }
    }, [media]);

    const [selectedMedia, setSelectedMedia] = useState<string[]>(() => {
        return Array.isArray(postMedia)
            ? postMedia.map((media) => media.id)
            : [];
    });

    useEffect(() => {
        if (Array.isArray(postMedia) && postMedia.length > 0) {
            setSelectedMedia(postMedia.map((media) => media.id));
        }
    }, [postMedia]);

    const MAX_MEDIA = 10;
    const MIN_MEDIA = platform.toLowerCase() === "instagram" ? 1 : 0;

    // Combine all media into a single list for easy lookup
    const allMedia: UnifiedMedia[] = useMemo(() => {
        if (!media) return [];

        const mapAssetsToUnifiedMedia = (asset: Assets): UnifiedMedia => ({
            ...asset,
            mediaType:
                asset.assetType === "Video"
                    ? "video"
                    : asset.assetType === "Logo"
                    ? "logo"
                    : "image",
        });

        const mapArchiveToUnifiedMedia = (
            archive: ArchiveMedia
        ): UnifiedMedia => ({
            ...archive,
            mediaType: archive.type === "Removed Video" ? "video" : "image",
        });

        return [
            ...(media.images || []).map(
                (image) => ({ ...image, mediaType: "image" } as UnifiedMedia)
            ),
            ...(media.videos || []).map(
                (video) => ({ ...video, mediaType: "video" } as UnifiedMedia)
            ),
            ...(media.promotionImages || []).map(
                (promotionImage) =>
                    ({ ...promotionImage, mediaType: "image" } as UnifiedMedia)
            ),
            ...(media.assets || []).map(mapAssetsToUnifiedMedia),
            ...(media.archived || []).map(mapArchiveToUnifiedMedia),
        ];
    }, [media]);

    // Determine if a video or images are selected
    const hasVideoSelected = useMemo(
        () =>
            selectedMedia.some((id) => {
                const mediaItem = allMedia.find((item) => item.id === id);
                return mediaItem?.mediaType === "video";
            }),
        [selectedMedia, allMedia]
    );

    const hasImageSelected = useMemo(
        () =>
            selectedMedia.some((id) => {
                const mediaItem = allMedia.find((item) => item.id === id);
                return mediaItem?.mediaType === "image";
            }),
        [selectedMedia, allMedia]
    );

    // Unified selection handler
    const handleSelect = (item: UnifiedMedia) => {
        if (item.mediaType === "video") {
            if (hasImageSelected) {
                toast.error(
                    <CustomToast
                        message="You can select either one video or up to 10 images."
                        type="error"
                    />
                );
                return;
            }
            if (selectedMedia.includes(item.id)) {
                // Deselect the video
                setSelectedMedia((prev) => prev.filter((id) => id !== item.id));
            } else {
                if (selectedMedia.length >= 1) {
                    toast.error(
                        <CustomToast
                            message="You can only select one video."
                            type="error"
                        />
                    );
                    return;
                }
                // Select the video
                setSelectedMedia([item.id]);
            }
        } else if (item.mediaType === "image" || item.mediaType === "logo") {
            if (hasVideoSelected) {
                toast.error(
                    <CustomToast
                        message="You can select either one video or up to 10 images."
                        type="error"
                    />
                );
                return;
            }
            if (selectedMedia.includes(item.id)) {
                // Deselect the image
                setSelectedMedia((prev) => prev.filter((id) => id !== item.id));
            } else {
                if (selectedMedia.length >= MAX_MEDIA) {
                    toast.error(
                        <CustomToast
                            message={`You can select up to ${MAX_MEDIA} images.`}
                            type="error"
                        />,
                        {
                            autoClose: 3000,
                        }
                    );
                    return;
                }
                // Select the image
                setSelectedMedia((prev) => [...prev, item.id]);
            }
        }
    };

    const saveMedia = async () => {
        setIsMediaPickerOpen(false);
        setLoading(true);

        try {
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            const selectedMediaData: MediaData[] = selectedMedia
                .map((id) => {
                    let mediaItem: any = null;
                    let type: string = "";

                    // Check in images
                    const imageItem = media?.images?.find(
                        (item) => item.id === id
                    );
                    if (imageItem) {
                        mediaItem = imageItem;
                        type = "Listing Image";
                    }

                    // Check in videos
                    if (!mediaItem) {
                        const videoItem = media?.videos?.find(
                            (item) => item.id === id
                        );
                        if (videoItem) {
                            mediaItem = videoItem;
                            type = "Listing Video";
                        }
                    }

                    // Check in promotionImages
                    if (!mediaItem) {
                        const promotionImageItem = media?.promotionImages?.find(
                            (item) => item.id === id
                        );
                        if (promotionImageItem) {
                            mediaItem = promotionImageItem;
                            type = "Promotion Image";
                        }
                    }

                    // Check in assets
                    if (!mediaItem) {
                        const assetItem = media?.assets?.find(
                            (item) => item.id === id
                        );
                        if (assetItem) {
                            mediaItem = assetItem;
                            type =
                                assetItem.assetType &&
                                assetItem.assetType.toLowerCase() === "video"
                                    ? "Asset Video"
                                    : "Asset Image";
                        }
                    }

                    // Check in archived
                    if (!mediaItem) {
                        const archivedItem = media?.archived?.find(
                            (item) => item.id === id
                        );
                        if (archivedItem) {
                            mediaItem = archivedItem;
                            type = archivedItem.type;
                        }
                    }

                    if (mediaItem && mediaItem.url && type) {
                        return {
                            id: mediaItem.id,
                            url: mediaItem.url,
                            type,
                        };
                    }

                    // If mediaItem not found or missing data, exclude from payload
                    return undefined;
                })
                .filter((data): data is MediaData => data !== undefined);

            if (selectedMedia.length < MIN_MEDIA) {
                toast.error(
                    <CustomToast
                        message={`Please select at least ${MIN_MEDIA} media item(s).`}
                        type="error"
                    />,
                    {
                        autoClose: 3000,
                    }
                );
                setLoading(false);
                return;
            }

            const payload = {
                media: selectedMediaData,
                postIds: [postId],
            };

            const response = await axios.patch(`${backendUrl}/post`, payload, {
                headers,
            });

            if (response.status === 200) {
                toast.success(
                    <CustomToast
                        message="Media saved successfully."
                        type="success"
                    />,
                    {
                        autoClose: 3000,
                    }
                );
            }

            setPost((prevPost: Post | null) => {
                if (prevPost) {
                    return {
                        ...prevPost,
                        media: response.data,
                    };
                }
                return prevPost;
            });

            getPostData();
        } catch (error: unknown) {
            toast.error(
                <CustomToast
                    message="Failed to save media. Please try again."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        } finally {
            setLoading(false);
        }
    };

    if (!media || media === null) {
        return null;
    }

    return (
        <Dialog open={isMediaPickerOpen} onOpenChange={setIsMediaPickerOpen}>
            <DialogContent
                className={`max-w-4xl bg-white ${
                    isMobile ? "w-full h-full p-4" : ""
                }`}
            >
                <DialogHeader className="relative">
                    <DialogTitle>Select Media</DialogTitle>
                    <button
                        onClick={() => setIsMediaPickerOpen(false)}
                        className="absolute -top-4 right-0 text-primary hover:text-secondary focus:outline-none"
                    >
                        <X size={24} />
                    </button>
                </DialogHeader>
                <Tabs
                    value={activeTab}
                    onValueChange={(value: string) =>
                        setActiveTab(
                            value as
                                | "images"
                                | "videos"
                                | "promotionImages"
                                | "assets"
                                | "archived"
                        )
                    }
                    className="mt-4"
                >
                    <TabsList>
                        {media?.images && media?.images?.length > 0 && (
                            <TabsTrigger value="images">Images</TabsTrigger>
                        )}
                        {media?.videos && media?.videos?.length > 0 && (
                            <TabsTrigger value="videos">Videos</TabsTrigger>
                        )}
                        {media?.promotionImages &&
                            media?.promotionImages?.length > 0 && (
                                <TabsTrigger value="promotionImages">
                                    Promotion Images
                                </TabsTrigger>
                            )}
                        {media?.assets && media?.assets?.length > 0 && (
                            <TabsTrigger value="assets">
                                Shared Media
                            </TabsTrigger>
                        )}
                        {media?.archived && media?.archived?.length > 0 && (
                            <TabsTrigger value="archived">
                                Archived Media
                            </TabsTrigger>
                        )}
                    </TabsList>

                    {/* Images Tab */}
                    <TabsContent value="images">
                        <PostMediaSelector
                            images={media.images}
                            selectedMedia={selectedMedia}
                            setSelectedMedia={setSelectedMedia}
                            onSelect={(id: string) => {
                                const item = allMedia.find(
                                    (media) => media.id === id
                                );
                                if (item) handleSelect(item);
                            }}
                            maxMedia={MAX_MEDIA}
                            minMedia={MIN_MEDIA}
                            type="image"
                            activeTab={activeTab}
                            hasVideoSelected={hasVideoSelected}
                            hasImageSelected={hasImageSelected}
                            activeResourceType={activeResourceType}
                            setActiveResourceType={setActiveResourceType}
                            activePromotionType={activePromotionType}
                            setActivePromotionType={setActivePromotionType}
                        />
                    </TabsContent>

                    {/* Videos Tab */}
                    <TabsContent value="videos">
                        <PostMediaSelector
                            videos={media.videos}
                            selectedMedia={selectedMedia}
                            setSelectedMedia={setSelectedMedia}
                            onSelect={(id: string) => {
                                const item = allMedia.find(
                                    (media) => media.id === id
                                );
                                if (item) handleSelect(item);
                            }}
                            maxMedia={MAX_MEDIA}
                            minMedia={MIN_MEDIA}
                            type="video"
                            activeTab={activeTab}
                            hasVideoSelected={hasVideoSelected}
                            hasImageSelected={hasImageSelected}
                            activeResourceType={activeResourceType}
                            setActiveResourceType={setActiveResourceType}
                            activePromotionType={activePromotionType}
                            setActivePromotionType={setActivePromotionType}
                        />
                    </TabsContent>

                    {/* Promotion Images Tab */}
                    <TabsContent value="promotionImages">
                        <PostMediaSelector
                            promotionImages={media.promotionImages}
                            selectedMedia={selectedMedia}
                            setSelectedMedia={setSelectedMedia}
                            onSelect={(id: string) => {
                                const item = allMedia.find(
                                    (media) => media.id === id
                                );
                                if (item) handleSelect(item);
                            }}
                            maxMedia={MAX_MEDIA}
                            minMedia={MIN_MEDIA}
                            type="image"
                            activeTab={activeTab}
                            hasVideoSelected={hasVideoSelected}
                            hasImageSelected={hasImageSelected}
                            activeResourceType={activeResourceType}
                            setActiveResourceType={setActiveResourceType}
                            activePromotionType={activePromotionType}
                            setActivePromotionType={setActivePromotionType}
                        />
                    </TabsContent>

                    {/* Assets Tab */}
                    <TabsContent value="assets">
                        <PostMediaSelector
                            assets={media.assets}
                            selectedMedia={selectedMedia}
                            setSelectedMedia={setSelectedMedia}
                            onSelect={(id: string) => {
                                const item = allMedia.find(
                                    (media) => media.id === id
                                );
                                if (item) handleSelect(item);
                            }}
                            maxMedia={MAX_MEDIA}
                            minMedia={MIN_MEDIA}
                            type="asset"
                            activeTab={activeTab}
                            hasVideoSelected={hasVideoSelected}
                            hasImageSelected={hasImageSelected}
                            activeResourceType={activeResourceType}
                            setActiveResourceType={setActiveResourceType}
                            activePromotionType={activePromotionType}
                            setActivePromotionType={setActivePromotionType}
                        />
                    </TabsContent>

                    {/* Archived Tab */}
                    <TabsContent value="archived">
                        <PostMediaSelector
                            archived={media.archived}
                            selectedMedia={selectedMedia}
                            setSelectedMedia={setSelectedMedia}
                            onSelect={(id: string) => {
                                const item = allMedia.find(
                                    (media) => media.id === id
                                );
                                if (item) handleSelect(item);
                            }}
                            maxMedia={MAX_MEDIA}
                            minMedia={MIN_MEDIA}
                            type="archived"
                            activeTab={activeTab}
                            hasVideoSelected={hasVideoSelected}
                            hasImageSelected={hasImageSelected}
                            activeResourceType={activeResourceType}
                            setActiveResourceType={setActiveResourceType}
                            activePromotionType={activePromotionType}
                            setActivePromotionType={setActivePromotionType}
                        />
                    </TabsContent>
                </Tabs>

                <div className="flex justify-between mt-4">
                    <Button
                        variant="secondary"
                        onClick={() => setIsMediaPickerOpen(false)}
                    >
                        Close
                    </Button>
                    <Button onClick={saveMedia}>Save</Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default PostMediaPicker;
