import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToast from "./CustomToast";
import InputField from "./InputField";
import LoadingScreen from "../../screens/LoadingScreen";

interface Suggestion {
    description: string;
    place_id: string;
}

interface SelectedAddress {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
}

interface AddressAutocompleteProps {
    onAddressSelect: (address: SelectedAddress) => void;
    label: string;
    className?: string;
    placeholder?: string;
    value?: string;
    onInputChange?: (value: string) => void;
    errorMessage?: string | null;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
    onAddressSelect,
    label,
    className,
    placeholder,
    value,
    onInputChange,
    errorMessage = null,
}) => {
    const [addressInput, setAddressInput] = useState<string>("");
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const containerRef = useRef<HTMLDivElement>(null);

    const autocompleteService =
        useRef<google.maps.places.AutocompleteService | null>(null);
    const geocoder = useRef<google.maps.Geocoder | null>(null);
    const [cache] = useState<Map<string, Suggestion[]>>(new Map());

    // Debounced fetch suggestions function
    const debouncedFetchSuggestions = debounce(
        (
            input: string,
            autocomplete: google.maps.places.AutocompleteService | null,
            cache: Map<string, Suggestion[]>,
            setSuggestions: React.Dispatch<React.SetStateAction<Suggestion[]>>
        ) => {
            if (!autocomplete || !input) {
                setSuggestions([]);
                return;
            }

            if (cache.has(input)) {
                setSuggestions(cache.get(input)!);
                return;
            }

            autocomplete.getPlacePredictions(
                { input, types: ["address"] },
                (predictions, status) => {
                    if (
                        status !==
                            window.google.maps.places.PlacesServiceStatus.OK ||
                        !predictions
                    ) {
                        setSuggestions([]);
                        return;
                    }

                    const newSuggestions: Suggestion[] = predictions.map(
                        (prediction) => ({
                            description: prediction.description,
                            place_id: prediction.place_id,
                        })
                    );

                    cache.set(input, newSuggestions);
                    setSuggestions(newSuggestions);
                }
            );
        },
        700
    );

    useEffect(() => {
        const loadGoogleMapsScript = () => {
            if (!window.google) {
                const script = document.createElement("script");
                script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
                script.async = true;
                script.defer = true;
                script.onload = initializeServices;
                script.onerror = () => {
                    toast.error(
                        <CustomToast
                            message="Failed to load Google Maps script."
                            type="error"
                        />,
                        { autoClose: 3000 }
                    );
                };
                document.body.appendChild(script);
            } else {
                initializeServices();
            }
        };

        const initializeServices = () => {
            if (window.google) {
                autocompleteService.current =
                    new window.google.maps.places.AutocompleteService();
                geocoder.current = new window.google.maps.Geocoder();
                console.log("Geocoder initialized");
            }
        };

        loadGoogleMapsScript();
    }, []);

    const handleAddressInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const input = e.target.value;
        if (onInputChange) {
            onInputChange(input);
        } else {
            setAddressInput(input);
        }
        debouncedFetchSuggestions(
            input,
            autocompleteService.current,
            cache,
            setSuggestions
        );
        setShowSuggestions(true);
    };

    const getComponent = (
        addressComponents: google.maps.GeocoderAddressComponent[],
        types: string[], // Array of types in priority order
        nameType: "long_name" | "short_name" = "long_name"
    ): string => {
        for (const type of types) {
            const component = addressComponents.find((comp) =>
                comp.types.includes(type)
            );
            if (component) {
                console.log(
                    `Matched Component for type [${type}]:`,
                    component[nameType]
                );
                return component[nameType];
            }
        }
        console.log(`No component matched for types [${types.join(", ")}]`);
        return "";
    };

    const handleSuggestionSelect = (suggestion: Suggestion) => {
        if (!geocoder.current) {
            toast.error(
                <CustomToast
                    message="Geocoder not initialized. Please try again."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }

        setLoading(true);

        geocoder.current.geocode(
            { placeId: suggestion.place_id },
            (results, status) => {
                if (
                    status === window.google.maps.GeocoderStatus.OK &&
                    results &&
                    results[0]
                ) {
                    const addressComponents = results[0].address_components;
                    console.log("Address Components:", addressComponents);

                    // Extract address parts with expanded types
                    const address1 = [
                        getComponent(addressComponents, ["street_number"]),
                        getComponent(addressComponents, ["route"]),
                    ]
                        .filter(Boolean)
                        .join(" ");

                    const address2 = [
                        getComponent(addressComponents, [
                            "sublocality",
                            "sublocality_level_1",
                        ]),
                        getComponent(addressComponents, ["neighborhood"]),
                    ]
                        .filter(Boolean)
                        .join(", ");

                    // Expanded types for city to handle cases where 'locality' might be missing
                    const city = getComponent(addressComponents, [
                        "locality",
                        "sublocality",
                        "administrative_area_level_3",
                        "administrative_area_level_2",
                    ]);

                    // Prioritize administrative_area_level_1 for state, fallback to others if necessary
                    const state = getComponent(
                        addressComponents,
                        [
                            "administrative_area_level_1",
                            "administrative_area_level_2",
                            "administrative_area_level_3",
                            "administrative_area_level_4",
                            "administrative_area_level_5",
                        ],
                        "short_name"
                    );

                    const zipCode = getComponent(addressComponents, [
                        "postal_code",
                    ]);

                    const selectedAddress: SelectedAddress = {
                        address1: address1.trim(),
                        address2: address2.trim(),
                        city,
                        state,
                        zipCode,
                    };

                    // Update the input to the selected suggestion
                    if (onInputChange) {
                        onInputChange(suggestion.description);
                    } else {
                        setAddressInput(suggestion.description);
                    }
                    setShowSuggestions(false);

                    // Pass the selected address back to the parent component
                    onAddressSelect(selectedAddress);
                } else {
                    toast.error(
                        <CustomToast
                            message="Unable to retrieve address details. Please try again."
                            type="error"
                        />,
                        { autoClose: 3000 }
                    );
                }
                setLoading(false);
            }
        );
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            containerRef.current &&
            !containerRef.current.contains(event.target as Node)
        ) {
            setShowSuggestions(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="flex flex-col w-full relative" ref={containerRef}>
            <InputField
                label={label}
                placeholder={
                    placeholder ? placeholder : "Start typing your address..."
                }
                type="text"
                value={value !== undefined ? value : addressInput}
                onChange={handleAddressInputChange}
                className="w-full"
                errorMessage={errorMessage}
            />
            {showSuggestions && suggestions.length > 0 && (
                <ul
                    className={`absolute top-full mt-1 z-10 w-full bg-white border border-gray-300 rounded-md max-h-60 overflow-y-auto ${className} 
              sm:text-sm
              md:text-base
            `}
                >
                    {suggestions.map((suggestion) => (
                        <li
                            key={suggestion.place_id}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleSuggestionSelect(suggestion)}
                        >
                            {suggestion.description}
                        </li>
                    ))}
                </ul>
            )}
            {loading && <LoadingScreen />}
        </div>
    );
};

export default AddressAutocomplete;
