import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../../misc/Card";
import { Button } from "../../misc/Button";
import { Elements } from "@stripe/react-stripe-js";
import { Appearance, loadStripe } from "@stripe/stripe-js";
import PaymentInfo from "./PaymentInfo";
import LoadingScreen from "../../../screens/LoadingScreen";
import { toast } from "react-toastify";
import CustomToast from "../../misc/CustomToast";
import { auth } from "../../../firebase/firebase-config";
import { getIdToken } from "firebase/auth";
import axios from "axios";
import { Dialog, DialogContent } from "../../misc/Dialog";
import ChangePlan from "./ChangePlan";
import { CurrentPlan } from "../types";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY!);
const appearance: Appearance = {
    theme: "stripe",
};

const Subscription = () => {
    const navigate = useNavigate();
    const [clientSecret, setClientSecret] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [currentPlan, setCurrentPlan] = useState<CurrentPlan>();
    const [changePlansOpen, setChangePlansOpen] = useState<boolean>(false);

    // Idle timeout duration in milliseconds (5 minutes)
    const IDLE_TIMEOUT = 5 * 60 * 1000;

    useEffect(() => {
        let idleTimer: NodeJS.Timeout;

        // Function to reset the idle timer
        const resetIdleTimer = () => {
            if (idleTimer) clearTimeout(idleTimer);
            idleTimer = setTimeout(() => {
                toast.info(
                    <CustomToast
                        message="You have been inactive for a while. Redirecting to Settings."
                        type="success"
                    />,
                    { autoClose: 3000 }
                );
                navigate("/settings");
            }, IDLE_TIMEOUT);
        };

        // Events that will reset the idle timer
        const events = [
            "mousemove",
            "keydown",
            "mousedown",
            "touchstart",
            "scroll",
        ];

        events.forEach((event) => {
            window.addEventListener(event, resetIdleTimer);
        });

        // Initialize the idle timer when the component mounts
        resetIdleTimer();

        // Clean up event listeners and timer when the component unmounts
        return () => {
            events.forEach((event) => {
                window.removeEventListener(event, resetIdleTimer);
            });
            if (idleTimer) clearTimeout(idleTimer);
        };
    }, [navigate, IDLE_TIMEOUT]);

    const fetchSetupIntent = async () => {
        try {
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            const currentUser = auth.currentUser;
            if (!currentUser) throw new Error("User not authenticated");
            const token = await getIdToken(currentUser, true);
            console.log("Token:", token);

            const response = await axios.post(
                `${backendUrl}/stripe/setup-intent`,
                {},
                { headers: { Authorization: `Bearer ${token}` } }
            );
            console.log(response.data.clientSecret);
            setClientSecret(response.data.clientSecret);
        } catch (error) {
            console.error("Error creating payment intent:", error);
            toast.error(
                <CustomToast
                    message="Error creating payment intent. Please try again."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        }
    };

    const fetchCurrentPlan = async () => {
        try {
            if (!auth.currentUser) throw new Error("User not authenticated");
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            const token = await getIdToken(auth.currentUser, true);
            const selectedPlan: CurrentPlan = await axios
                .get(`${backendUrl}/settings/current-plan`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => response.data);
            setCurrentPlan(selectedPlan);

            console.log("Current plan:", selectedPlan);
        } catch (error) {
            toast.error(
                <CustomToast
                    message="Failed to fetch current plan. Please try again."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        }
    };

    useEffect(() => {
        const initialize = async () => {
            try {
                setLoading(true);
                await Promise.all([fetchSetupIntent(), fetchCurrentPlan()]);
            } catch (error) {
                console.error("Initialization error:", error);
            } finally {
                setLoading(false);
            }
        };

        initialize();
    }, []);

    return (
        <div className="space-y-6 px-4 md:px-8">
            {loading ? (
                <LoadingScreen />
            ) : (
                <div className="pb-4">
                    <h2 className="text-2xl font-bold text-primary mt-2">
                        Subscription Information
                    </h2>
                    <Dialog
                        open={changePlansOpen}
                        onOpenChange={setChangePlansOpen}
                    >
                        <DialogContent className="w-full p-0" wide={true}>
                            <ChangePlan
                                setChangePlansOpen={setChangePlansOpen}
                                fetchCurrentPlan={fetchCurrentPlan}
                                status={currentPlan?.status}
                            />
                        </DialogContent>
                    </Dialog>
                    <Card className="w-full max-w-4xl mx-auto">
                        <div className="p-4">
                            <Button
                                onClick={() => navigate("/settings")}
                                className="mb-4 flex items-center"
                                id="back-to-settings"
                            >
                                <ArrowLeft className="mr-2 h-5 w-5" /> Back to
                                Settings
                            </Button>
                        </div>
                        <CardHeader className="pb-0">
                            <CardTitle>Current Plan</CardTitle>
                            <CardDescription className="text-center">
                                <div className="flex flex-col">
                                    <span>
                                        {`You are currently on the ${currentPlan?.plan?.name} plan`}
                                    </span>
                                    {currentPlan?.status === "active" ? (
                                        <span>
                                            Your plan is active and will renew
                                            on{" "}
                                            {new Date(
                                                currentPlan?.end * 1000 +
                                                    86400000
                                            ).toLocaleDateString("en-US", {
                                                month: "long",
                                                day: "numeric",
                                                year: "numeric",
                                            })}
                                        </span>
                                    ) : null}
                                    {currentPlan?.status === "canceled" ? (
                                        <span className="text-red-500">
                                            Your plan is cancelled and will end
                                            on{" "}
                                            {new Date(
                                                currentPlan?.end * 1000
                                            ).toLocaleDateString("en-US", {
                                                month: "long",
                                                day: "numeric",
                                                year: "numeric",
                                            })}
                                        </span>
                                    ) : null}
                                </div>
                            </CardDescription>
                        </CardHeader>
                        <CardContent className="text-primary">
                            <p className="text-2xl font-bold text-center">
                                {`$${
                                    (Number(currentPlan?.plan?.price) || 0) /
                                    100
                                }/month`}
                            </p>
                            <div className="flex flex-col items-center">
                                {currentPlan?.status !== "active" ? (
                                    <span className="text-xs text-neutral-500 mt-4">
                                        Please contact us via
                                        <strong
                                            className="text-primary hover:text-secondary cursor-pointer"
                                            onClick={() => {
                                                navigate("/help", {
                                                    state: { tab: "contact" },
                                                });
                                            }}
                                        >
                                            {" "}
                                            help{" "}
                                        </strong>
                                        page to re-subscribe.
                                    </span>
                                ) : null}
                                <Button
                                    className={`${
                                        currentPlan?.status !== "active"
                                            ? "mt-2"
                                            : "mt-4"
                                    } w-full md:w-auto`}
                                    onClick={() => setChangePlansOpen(true)}
                                    disabled={currentPlan?.status !== "active"}
                                >
                                    {currentPlan?.status === "active"
                                        ? "Change Plan"
                                        : "Resubscribe"}
                                </Button>
                            </div>
                        </CardContent>
                    </Card>

                    <Card className="w-full max-w-4xl mx-auto">
                        <CardHeader>
                            <CardTitle>Payment Method</CardTitle>
                            <CardDescription className="text-center">
                                Update your payment details
                            </CardDescription>
                        </CardHeader>
                        <CardContent className="space-y-4 mb-2">
                            {clientSecret ? (
                                <Elements
                                    stripe={stripePromise}
                                    options={{ clientSecret, appearance }}
                                >
                                    <PaymentInfo />
                                </Elements>
                            ) : (
                                <p className="text-center text-red-500">
                                    Unable to load payment information. Please
                                    try again later.
                                </p>
                            )}
                        </CardContent>
                    </Card>
                </div>
            )}
        </div>
    );
};

export default Subscription;
