import React, { useState } from "react";
import { format, addDays, startOfWeek, isSameDay, compareAsc } from "date-fns";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import DayView from "./DayView"; // Assuming you have a DayView component
import { CalendarProps } from "../../types/calendar-props";
import PostCard from "./PostCard"; // Import the new PostCard component

interface WeeklyViewProps extends CalendarProps {
    currentDate: Date;
}

const WeeklyView: React.FC<WeeklyViewProps> = ({ posts, currentDate }) => {
    const [selectedDay, setSelectedDay] = useState<Date | null>(null);

    const weekStart = startOfWeek(currentDate, { weekStartsOn: 1 }); // Start on Monday
    const weekDays = Array.from({ length: 7 }, (_, i) => addDays(weekStart, i));

    const handleMoreClick = (day: Date) => {
        setSelectedDay(day);
    };

    const closeDayView = () => {
        setSelectedDay(null);
    };

    const renderDayColumn = (day: Date) => {
        const dayPosts = posts
            .filter((post) => isSameDay(new Date(post.scheduledFor), day))
            .sort((a, b) =>
                compareAsc(new Date(a.scheduledFor), new Date(b.scheduledFor))
            );

        const isToday = isSameDay(day, new Date());

        const postsToShow = dayPosts.slice(0, 8);
        const hasMorePosts = dayPosts.length > 8;

        return (
            <div
                id={`${format(day, "EEE-d")}-column`}
                key={day.toString()}
                className="flex-1 w-full min-w-[14.28%] sxl:min-w-[14.28%]"
                style={{
                    backgroundColor: isToday
                        ? "rgba(240, 223, 175, 0.50)"
                        : "transparent",
                    borderRadius: isToday ? "8px" : "0",
                }}
            >
                <h3
                    className={`text-sm font-semibold mb-2 py-1 px-2.5 ${
                        isToday ? "bg-lightSecondary" : "bg-white"
                    }`}
                    style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 10,
                        borderBottom: "1px solid #E9C468",
                    }}
                >
                    {format(day, "EEE d")}
                </h3>
                <div className="space-y-4 px-2.5">
                    {postsToShow.map((post, index) => (
                        <PostCard
                            date={format(day, "EEE-d")}
                            idx={index}
                            key={post.id}
                            post={post}
                        />
                    ))}
                    {hasMorePosts && (
                        <div
                            className="flex justify-center items-center cursor-pointer mt-2"
                            onClick={() => handleMoreClick(day)}
                        >
                            <HiOutlineDotsHorizontal
                                size={20}
                                className="text-primary"
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="flex flex-col sxl:flex-row">
                {weekDays.map(renderDayColumn)}
            </div>
            {selectedDay && (
                <DayView
                    day={selectedDay}
                    posts={posts}
                    onClose={closeDayView}
                />
            )}
        </>
    );
};

export default WeeklyView;
