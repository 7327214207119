import { AnimatePresence, motion } from "framer-motion";
import { Button } from "../misc/Button";
import { Home, Share2 } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface WelcomeListingProps {
    welcome: boolean;
}

const WelcomeListing: React.FC<WelcomeListingProps> = ({ welcome }) => {
    const navigate = useNavigate();

    const buttonHandler = () => {
        if (welcome) sessionStorage.removeItem("welcome");
        navigate("/create-listing");
    };

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute inset-0 bg-black bg-opacity-60 backdrop-blur-sm flex items-center justify-center z-40 w-full h-full"
            >
                <motion.div
                    initial={{ scale: 0.9, y: 50 }}
                    animate={{ scale: 1, y: 0 }}
                    exit={{ scale: 0.9, y: 50 }}
                    transition={{ type: "spring", stiffness: 300, damping: 30 }}
                    className="bg-white rounded-2xl p-8 max-w-md w-full relative shadow-2xl"
                >
                    <motion.div
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.2 }}
                    >
                        <h2 className="text-3xl font-bold mb-6 text-center  text-primary">
                            Welcome to Marabot!
                        </h2>
                    </motion.div>
                    <motion.p
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.3 }}
                        className="text-center mb-8 text-gray-600"
                    >
                        We’re glad you’re here — create your first listing to
                        get started!
                    </motion.p>
                    <motion.div
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.4 }}
                        className="space-y-4"
                    >
                        <Button onClick={buttonHandler} className="w-full ">
                            <Home className="mr-2 h-5 w-5" />
                            Create Your First Listing
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                navigate("/help", {
                                    state: { tab: "tutorials" },
                                });
                            }}
                            className="w-full"
                        >
                            <Share2 className="mr-2 h-5 w-5" />
                            See How It Works
                        </Button>
                    </motion.div>
                </motion.div>
            </motion.div>
        </AnimatePresence>
    );
};

export default WelcomeListing;
