import React from "react";
import { Card, CardContent, CardFooter } from "./Card";
import { FiAlertCircle } from "react-icons/fi";
import { Button } from "./Button";
import { useNavigate } from "react-router-dom";

interface DataLoadingErrorProps {
    heading: string;
    text: string;
    setCurrentStep?: React.Dispatch<React.SetStateAction<number>>;
    step?: number;
}

const DataLoadingError: React.FC<DataLoadingErrorProps> = ({
    heading,
    text,
    setCurrentStep,
    step,
}) => {
    const navigate = useNavigate();
    return (
        <div className="min-h-[400px] flex items-center justify-center p-4">
            <Card className="w-full max-w-md">
                <CardContent className="pt-6">
                    <div className="flex flex-col items-center gap-4 text-center text-primary">
                        <FiAlertCircle className="h-12 w-12 text-destructive text-red-600" />
                        <h2 className="text-2xl font-semibold">{heading}</h2>
                        <p className="text-muted-foreground">{text}</p>
                    </div>
                </CardContent>
                <CardFooter className="justify-center">
                    <Button
                        variant="outline"
                        onClick={() =>
                            setCurrentStep && step
                                ? setCurrentStep(step)
                                : navigate(-1)
                        }
                    >
                        Go Back Now
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
};

export default DataLoadingError;
