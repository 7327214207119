import React, { useState, useEffect } from "react";
import {
    FaEye,
    FaEyeSlash,
    FaTimesCircle,
    FaCheckCircle,
} from "react-icons/fa";
import { FaDollarSign } from "react-icons/fa6";

interface PasswordRequirements {
    char: boolean;
    charCount: boolean;
    special: boolean;
}

interface InputFieldProps {
    label: string;
    placeholder: string;
    type: string;
    value: string | number;
    onChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    showPasswordToggle?: boolean;
    errorMessage?: string | null;
    onBlur?: () => void;
    onFocus?: () => void;
    showPasswordRequirements?: boolean;
    passwordRequirements?: PasswordRequirements;
    className?: string;
    onKeyDown?: (
        e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    inputRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
    name?: string;
    rows?: number;
    required?: boolean;
    autocomplete?: boolean;
    price?: boolean;
    disabled?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
    label,
    placeholder,
    type,
    value,
    onChange,
    showPasswordToggle,
    errorMessage,
    onBlur,
    onFocus,
    showPasswordRequirements = false,
    passwordRequirements,
    className,
    onKeyDown,
    inputRef,
    name,
    rows = 4, // Default rows for textarea
    required,
    autocomplete = true,
    price = false,
    disabled = false,
}) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    useEffect(() => {
        if (type !== "password") {
            setIsPasswordVisible(false);
        }
    }, [type]);

    // Determine if the field is a textarea
    const isTextarea = type === "textarea";

    return (
        <div className={`flex flex-col w-full ${className ? className : ""}`}>
            <label
                htmlFor={label.toLowerCase()}
                className="text-sm font-medium tracking-normal leading-none text-primary mb-1"
            >
                {label}
            </label>
            <div
                className={`flex gap-1 items-center px-4 py-2 mt-1 w-full bg-white rounded-xl border border-solid border-stone-300 text-neutral-400 ${
                    isTextarea ? "h-auto" : "h-12"
                } max-md:px-3 cursor-pointer hover:bg-neutral-100 focus-within:bg-neutral-100`}
                onClick={() =>
                    document.getElementById(label.toLowerCase())?.focus()
                }
            >
                {!isTextarea && price && (
                    <FaDollarSign className="text-secondary" />
                )}
                {isTextarea ? (
                    <textarea
                        id={label.toLowerCase()}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onKeyDown={onKeyDown}
                        className="flex-1 resize-none bg-transparent border-none outline-none text-primary placeholder-neutral-400"
                        ref={inputRef as React.Ref<HTMLTextAreaElement>}
                        name={name}
                        rows={rows}
                        disabled={disabled}
                    />
                ) : (
                    <input
                        type={
                            showPasswordToggle && isPasswordVisible
                                ? "text"
                                : type
                        }
                        id={label.toLowerCase().replace(/\s+/g, "-")}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onKeyDown={onKeyDown}
                        className="flex-1 bg-transparent border-none outline-none text-primary placeholder-neutral-400"
                        ref={inputRef as React.Ref<HTMLInputElement>}
                        name={name}
                        required={required || false}
                        autoComplete={autocomplete ? "on" : "off"}
                        disabled={disabled}
                    />
                )}
                {showPasswordToggle && !isTextarea && (
                    <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="flex items-center justify-center ml-2"
                    >
                        {isPasswordVisible ? (
                            <FaEye className="text-neutral-600" />
                        ) : (
                            <FaEyeSlash className="text-neutral-600" />
                        )}
                    </button>
                )}
            </div>
            {errorMessage && (
                <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
            )}
            {showPasswordRequirements &&
                passwordRequirements &&
                !isTextarea && (
                    <div className="flex flex-col mt-2 space-y-2">
                        <div className="flex items-center gap-2">
                            {passwordRequirements.char ? (
                                <FaCheckCircle className="text-green-600" />
                            ) : (
                                <FaTimesCircle className="text-red-600" />
                            )}
                            <span className="text-sm text-neutral-700">
                                Password must contain at least 1 letter.
                            </span>
                        </div>
                        <div className="flex items-center gap-2">
                            {passwordRequirements.special ? (
                                <FaCheckCircle className="text-green-600" />
                            ) : (
                                <FaTimesCircle className="text-red-600" />
                            )}
                            <span className="text-sm text-neutral-700">
                                Password must contain at least 1 number or
                                special character.
                            </span>
                        </div>
                        <div className="flex items-center gap-2">
                            {passwordRequirements.charCount ? (
                                <FaCheckCircle className="text-green-600" />
                            ) : (
                                <FaTimesCircle className="text-red-600" />
                            )}
                            <span className="text-sm text-neutral-700">
                                Password must be at least 10 characters long.
                            </span>
                        </div>
                    </div>
                )}
        </div>
    );
};

export default InputField;
