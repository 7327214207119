import { Check, Edit, Search, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Button } from "../../components/misc/Button";
import { Badge } from "../../components/calendar/Badge";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/misc/Table";
import { Input } from "../../components/misc/Input";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "../../components/misc/Dialog";
import { Limits, Plan, User, UserRole, SubscriptionStatus } from "../types";
import EditUserOverlay from "./EditUserOverlay";
import { toast } from "react-toastify";
import CustomToast from "../../components/misc/CustomToast";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";
import LoadingScreen from "../../screens/LoadingScreen";
import ReconfirmOverlay from "../../components/misc/ReconfirmOverlay";
import { Tabs, TabsList, TabsTrigger } from "../../components/misc/Tabs";
import LimitsTabOverlay from "./LimitsTabOverlay";

type ConfirmAction =
    | "pause"
    | "resume"
    | "mls"
    | "removeMLS"
    | "verify-user"
    | null;

const ManageUsers = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [teamAdmins, setTeamAdmins] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [isAdd, setIsAdd] = useState<boolean>(false);
    const [actionToConfirm, setActionToConfirm] = useState<ConfirmAction>(null);
    const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<"userData" | "userLimits">(
        "userData"
    );
    const [allPlans, setAllPlans] = useState<Plan[]>([]);

    // Centralized state for user data and limits
    const [userData, setUserData] = useState<Partial<User> | null>(null);
    const [limits, setLimits] = useState<Limits | null>(null); // Define Limits type accordingly

    // Fetch Users
    const fetchUsers = async () => {
        setLoading(true);
        try {
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            const response = await axios.get(`${backendUrl}/admin/users`, {
                headers,
            });

            if (response.status !== 200) {
                throw new Error(
                    "Failed to fetch users. Please try again later."
                );
            }

            console.log("users", response.data);

            setUsers(response.data);
        } catch (error: unknown) {
            console.error("Failed to fetch users:", error);
            toast.error(
                <CustomToast message="Failed to fetch users." type="error" />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    // Fetch Plans
    const fetchPlans = async () => {
        try {
            setLoading(true);

            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            const response = await axios.get(`${backendUrl}/onboarding/plans`, {
                headers,
            });

            if (response.status !== 200) {
                throw new Error("Failed to fetch plans.");
            }

            setAllPlans(response.data);
        } catch (error: unknown) {
            console.error("Failed to fetch plans:", error);
            toast.error(
                <CustomToast message="Failed to fetch plans." type="error" />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
        fetchPlans();
    }, []);

    useEffect(() => {
        const admins = users.filter((user) => user.role === "TEAM_ADMIN");
        setTeamAdmins(admins || []);
    }, [users]);

    // Filtering Users Based on Search Term
    const filteredUsers = users.filter(
        (user) =>
            `${user.nameFirst} ${user.nameLast}`
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Separate Active and Inactive Users based on Subscription Status
    const activeUsers = filteredUsers.filter(
        (user) =>
            user.account.activeSubscription &&
            user.account.activeSubscription.status === SubscriptionStatus.Active
    );

    const inactiveUsers = filteredUsers.filter(
        (user) =>
            user.account.activeSubscription &&
            user.account.activeSubscription.status ===
                SubscriptionStatus.Inactive
    );

    // Handle Edit User
    const handleEditUser = (user: User) => {
        setUserData({ ...user });
        setSelectedUser(user);
        setLimits(user.limits || null);
    };

    // Handle Action (Pause/Resume/MLS)
    const handleAction = (action: ConfirmAction, userId: string) => {
        setActionToConfirm(action);
        setIsOverlayOpen(true);
        setSelectedUserId(userId);
    };

    // Close Overlay
    const closeOverlay = () => {
        setIsOverlayOpen(false);
        setActionToConfirm(null);
    };

    // Confirmation Handler based on the Action
    const confirmAction = async () => {
        if (!actionToConfirm || !selectedUserId) return;

        const userId = selectedUserId;
        const userEmail = users.find((user) => user.id === userId)?.email;
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            setLoading(true);
            closeOverlay();

            let response;
            let updatedUsers: User[] | undefined;

            switch (actionToConfirm) {
                case "resume":
                    response = await axios.patch(
                        `${backendUrl}/admin/resume-user`,
                        { userId },
                        { headers }
                    );
                    if (response.status !== 200)
                        throw new Error("Failed to resume user.");
                    updatedUsers = users.map((user) =>
                        user.id === userId
                            ? { ...user, status: "ACTIVE" }
                            : user
                    );
                    toast.success(
                        <CustomToast
                            message="User resumed successfully."
                            type="success"
                        />,
                        { autoClose: 3000 }
                    );
                    break;

                case "pause":
                    response = await axios.patch(
                        `${backendUrl}/admin/pause-user`,
                        { userId },
                        { headers }
                    );
                    if (response.status !== 200)
                        throw new Error("Failed to pause user.");
                    updatedUsers = users.map((user) =>
                        user.id === userId
                            ? { ...user, status: "PAUSED" }
                            : user
                    );
                    toast.success(
                        <CustomToast
                            message="User paused successfully."
                            type="success"
                        />,
                        { autoClose: 3000 }
                    );
                    break;

                case "mls":
                    response = await axios.patch(
                        `${backendUrl}/admin/update-user`,
                        { userId, mlsApproved: true },
                        { headers }
                    );
                    if (response.status !== 200)
                        throw new Error("Failed to approve MLS.");
                    updatedUsers = users.map((user) =>
                        user.id === userId
                            ? { ...user, mlsApproved: true }
                            : user
                    );
                    toast.success(
                        <CustomToast
                            message="MLS approved successfully."
                            type="success"
                        />,
                        { autoClose: 3000 }
                    );
                    break;

                case "removeMLS":
                    response = await axios.patch(
                        `${backendUrl}/admin/remove-mls`,
                        { userId },
                        { headers }
                    );
                    if (response.status !== 200)
                        throw new Error("Failed to remove MLS.");

                    updatedUsers = users.map((user) =>
                        user.id === userId
                            ? { ...user, mlsApproved: false }
                            : user
                    );
                    toast.success(
                        <CustomToast
                            message="MLS removed successfully."
                            type="success"
                        />,
                        { autoClose: 3000 }
                    );
                    break;

                case "verify-user":
                    response = await axios.post(
                        `${backendUrl}/admin/verify-user`,
                        { email: userEmail },
                        { headers }
                    );
                    if (response.status !== 200)
                        throw new Error("Failed to verify user.");
                    updatedUsers = users;
                    toast.success(
                        <CustomToast
                            message="User Verified successfully."
                            type="success"
                        />,
                        { autoClose: 3000 }
                    );
                    break;

                default:
                    break;
            }

            setUsers(updatedUsers || []);
        } catch (error: unknown) {
            console.error(`Failed to ${actionToConfirm} user:`, error);
            toast.error(
                <CustomToast
                    message={`Failed to ${actionToConfirm} user.`}
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    // Get Overlay Content based on Action
    const getOverlayContent = () => {
        switch (actionToConfirm) {
            case "pause":
                return {
                    title: "Pause User Account",
                    message:
                        "Are you sure you want to pause this user account?",
                    messageTwo:
                        "This will pause the user, and they won't be able to perform any actions on the site. However, their scheduled posts will still be posted.",
                    confirmText: "Pause",
                };
            case "resume":
                return {
                    title: "Resume User Account",
                    message:
                        "Are you sure you want to resume this user account?",
                    messageTwo:
                        "This will resume the user, and they will be able to perform actions on the site.",
                    confirmText: "Resume",
                };
            case "mls":
                return {
                    title: "Approve MLS",
                    message:
                        "Are you sure you want to approve MLS for this user?",
                    messageTwo:
                        "This will approve MLS for the user, and they will be able to access MLS data.",
                    confirmText: "Approve MLS",
                };
            case "removeMLS":
                return {
                    title: "Remove MLS",
                    message:
                        "Are you sure you want to remove MLS for this user?",
                    messageTwo:
                        "This will remove MLS for the user, and they will not be able to access MLS data.",
                    confirmText: "Remove MLS",
                };
            case "verify-user":
                return {
                    title: "Verify User",
                    message: "Are you sure you want to verify this user?",
                    messageTwo:
                        "This will verufy the user on firebase and they will be able to perform actions on the site.",
                    confirmText: "Verify",
                };
            default:
                return {
                    title: "",
                    message: "",
                    messageTwo: "",
                    confirmText: "",
                };
        }
    };

    const { title, message, messageTwo, confirmText } = getOverlayContent();

    // Handle Save Action
    const handleSave = async () => {
        if (!userData) {
            toast.error(
                <CustomToast message="User data is missing." type="error" />,
                { autoClose: 3000 }
            );
            return;
        }

        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            setLoading(true);

            // Update user details
            const userResponse = isAdd
                ? await axios.post(`${backendUrl}/admin/add-user`, userData, {
                      headers,
                  })
                : await axios.patch(
                      `${backendUrl}/admin/update-user/`,
                      {
                          userId: userData.id,
                          nameFirst: userData.nameFirst,
                          nameLast: userData.nameLast,
                          email: userData.email,
                          role: userData.role,
                          mlsApproved: userData.mlsApproved,
                          onboarded: userData.onboarded,
                          licenseNumber: userData.licenseNumber,
                          companyName: userData.companyName,
                          phoneNumber: userData.phoneNumber,
                          broker: userData.broker,
                          brokerLicense: userData.brokerLicense,
                          brokerEmail: userData.brokerEmail,
                          mls:
                              typeof userData.mls === "string"
                                  ? userData.mls
                                  : userData?.mls?._id.toString(),
                          otherMLS: userData.otherMLS,
                          timeZone: userData.timeZone,
                          userPlan: {
                              ...userData.plan,
                              _id: userData.plan?.id,
                          },
                          limits: limits ? { ...limits } : undefined,
                      },
                      { headers }
                  );

            if (userResponse.status !== 200 && userResponse.status !== 201) {
                throw new Error("Failed to save user details.");
            }

            toast.success(
                <CustomToast
                    message="User details and limits saved successfully."
                    type="success"
                />,
                { autoClose: 3000 }
            );

            // Refresh the user list
            fetchUsers();

            // Close the dialog
            setSelectedUser(null);
            setIsAdd(false);
            setUserData(null);
            setLimits(null);
        } catch (error: unknown) {
            console.error("Failed to save user:", error);
            toast.error(
                <CustomToast
                    message="Failed to save user details and limits."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col h-full p-4">
            {loading && <LoadingScreen />}
            <ReconfirmOverlay
                isOpen={isOverlayOpen}
                onClose={closeOverlay}
                onConfirm={confirmAction}
                title={title}
                message={message}
                messageTwo={messageTwo}
                confirmText={confirmText}
            />
            <div className="flex flex-col flex-1 bg-white py-6 px-10 rounded shadow overflow-y-auto">
                {/* Header */}
                <h2 className="text-2xl font-bold text-primary mb-4">
                    Manage Users
                </h2>

                {/* Search and Add Button */}
                <div className="flex justify-between items-center mb-4">
                    <div className="relative w-full max-w-md">
                        <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                        <Input
                            placeholder="Search users..."
                            value={searchTerm}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setSearchTerm(e.target.value)}
                            className="pl-8 w-full"
                        />
                    </div>
                    <Button
                        className="ml-4"
                        onClick={() => {
                            setUserData({
                                nameFirst: "",
                                nameLast: "",
                                email: "",
                                role: UserRole.USER,
                                mlsApproved: false,
                                onboarded: false,
                                licenseNumber: "",
                                companyName: "",
                                phoneNumber: "",
                                broker: "",
                                brokerLicense: "",
                                brokerEmail: "",
                                mls: "",
                                otherMLS: "",
                                timeZone: "",
                                plan: allPlans[0],
                            });
                            setLimits(null);
                            setIsAdd(true);
                            setSelectedUser(null);
                        }}
                    >
                        Add New User
                    </Button>
                </div>

                {/* Active Users Table */}
                <div className="mb-8">
                    <h3 className="text-xl font-semibold text-gray-700 mb-4">
                        Active Users
                    </h3>
                    {activeUsers.length === 0 ? (
                        <p>No active users found.</p>
                    ) : (
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Name</TableHead>
                                    <TableHead>Email</TableHead>
                                    <TableHead>Role</TableHead>
                                    <TableHead>MLS</TableHead>
                                    <TableHead>MLS Approved</TableHead>
                                    <TableHead>Status</TableHead>
                                    <TableHead>Actions</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {activeUsers.map((user) => (
                                    <TableRow key={user.id}>
                                        <TableCell>{`${user.nameFirst} ${user.nameLast}`}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>
                                            {user.role
                                                ?.split("_")
                                                .map(
                                                    (word) =>
                                                        word.charAt(0) +
                                                        word
                                                            .slice(1)
                                                            .toLowerCase()
                                                )
                                                .join(" ")}
                                        </TableCell>
                                        <TableCell>
                                            {typeof user.mls === "string"
                                                ? user.mls
                                                : user?.mls?.name ||
                                                  user.otherMLS}
                                        </TableCell>
                                        <TableCell className="flex items-center justify-center">
                                            {user.mlsApproved ? (
                                                <Check className="text-green-500" />
                                            ) : (
                                                <X className="text-red-500" />
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {user.status ? (
                                                <Badge
                                                    variant={
                                                        user.status === "ACTIVE"
                                                            ? "primary"
                                                            : user.status ===
                                                              "PAUSED"
                                                            ? "secondary"
                                                            : "default"
                                                    }
                                                >
                                                    {user.status}
                                                </Badge>
                                            ) : null}
                                        </TableCell>
                                        <TableCell className="flex flex-row items-center">
                                            <Button
                                                size="sm"
                                                className="mr-2"
                                                onClick={() =>
                                                    handleEditUser(user)
                                                }
                                            >
                                                <Edit className="w-4 h-4 mr-2" />
                                                Edit
                                            </Button>
                                            {user.status === "ACTIVE" ? (
                                                <Button
                                                    size="sm"
                                                    onClick={() =>
                                                        handleAction(
                                                            "pause",
                                                            user.id
                                                        )
                                                    }
                                                >
                                                    Pause
                                                </Button>
                                            ) : (
                                                <Button
                                                    size="sm"
                                                    onClick={() =>
                                                        handleAction(
                                                            "resume",
                                                            user.id
                                                        )
                                                    }
                                                >
                                                    Resume
                                                </Button>
                                            )}
                                            {!user.mlsApproved ? (
                                                <Button
                                                    variant="secondary"
                                                    size="sm"
                                                    className="ml-2"
                                                    onClick={() =>
                                                        handleAction(
                                                            "mls",
                                                            user.id
                                                        )
                                                    }
                                                >
                                                    MLS
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="secondary"
                                                    size="sm"
                                                    className="ml-2"
                                                    onClick={() =>
                                                        handleAction(
                                                            "removeMLS",
                                                            user.id
                                                        )
                                                    }
                                                >
                                                    Remove MLS
                                                </Button>
                                            )}
                                            <Button
                                                variant="destructive"
                                                size="sm"
                                                className="ml-2"
                                                onClick={() =>
                                                    handleAction(
                                                        "verify-user",
                                                        user.id
                                                    )
                                                }
                                            >
                                                Verify User
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </div>

                {/* Inactive Users Table */}
                <div>
                    <h3 className="text-xl font-semibold text-gray-700 mb-4">
                        Inactive Users
                    </h3>
                    {inactiveUsers.length === 0 ? (
                        <p>No inactive users found.</p>
                    ) : (
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Name</TableHead>
                                    <TableHead>Email</TableHead>
                                    <TableHead>Role</TableHead>
                                    <TableHead>MLS</TableHead>
                                    <TableHead>MLS Approved</TableHead>
                                    <TableHead>Status</TableHead>
                                    <TableHead>Actions</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {inactiveUsers.map((user) => (
                                    <TableRow key={user.id}>
                                        <TableCell>{`${user.nameFirst} ${user.nameLast}`}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>
                                            {user.role
                                                ?.split("_")
                                                .map(
                                                    (word) =>
                                                        word.charAt(0) +
                                                        word
                                                            .slice(1)
                                                            .toLowerCase()
                                                )
                                                .join(" ")}
                                        </TableCell>
                                        <TableCell>
                                            {typeof user.mls === "string"
                                                ? user.mls
                                                : user?.mls?.name ||
                                                  user.otherMLS}
                                        </TableCell>
                                        <TableCell className="flex items-center justify-center">
                                            {user.mlsApproved ? (
                                                <Check className="text-green-500" />
                                            ) : (
                                                <X className="text-red-500" />
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {user.status ? (
                                                <Badge
                                                    variant={
                                                        user.status === "ACTIVE"
                                                            ? "primary"
                                                            : user.status ===
                                                              "PAUSED"
                                                            ? "secondary"
                                                            : "default"
                                                    }
                                                >
                                                    {user.status}
                                                </Badge>
                                            ) : null}
                                        </TableCell>
                                        <TableCell className="flex flex-row items-center">
                                            <Button
                                                size="sm"
                                                className="mr-2"
                                                onClick={() =>
                                                    handleEditUser(user)
                                                }
                                            >
                                                <Edit className="w-4 h-4 mr-2" />
                                                Edit
                                            </Button>
                                            {user.status === "ACTIVE" ? (
                                                <Button
                                                    size="sm"
                                                    onClick={() =>
                                                        handleAction(
                                                            "pause",
                                                            user.id
                                                        )
                                                    }
                                                >
                                                    Pause
                                                </Button>
                                            ) : (
                                                <Button
                                                    size="sm"
                                                    onClick={() =>
                                                        handleAction(
                                                            "resume",
                                                            user.id
                                                        )
                                                    }
                                                >
                                                    Resume
                                                </Button>
                                            )}
                                            {!user.mlsApproved ? (
                                                <Button
                                                    variant="secondary"
                                                    size="sm"
                                                    className="ml-2"
                                                    onClick={() =>
                                                        handleAction(
                                                            "mls",
                                                            user.id
                                                        )
                                                    }
                                                >
                                                    MLS
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="secondary"
                                                    size="sm"
                                                    className="ml-2"
                                                    onClick={() =>
                                                        handleAction(
                                                            "removeMLS",
                                                            user.id
                                                        )
                                                    }
                                                >
                                                    Remove MLS
                                                </Button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </div>
            </div>

            {/* Single Dialog Instance */}
            {(selectedUser || isAdd) && (
                <Dialog
                    open={Boolean(selectedUser) || isAdd}
                    onOpenChange={(isOpen) => {
                        if (!isOpen) {
                            setSelectedUser(null);
                            setIsAdd(false);
                            setUserData(null);
                            setLimits(null);
                        }
                    }}
                >
                    <DialogContent className="sm:max-w-[800px] bg-white">
                        <DialogHeader>
                            <DialogTitle>
                                {isAdd ? "Add New User" : "Edit User"}
                            </DialogTitle>
                        </DialogHeader>
                        <Tabs
                            value={activeTab}
                            onValueChange={(value: string) =>
                                setActiveTab(value as "userData" | "userLimits")
                            }
                            className="mt-4"
                        >
                            <TabsList>
                                <TabsTrigger value="userData">
                                    User Data
                                </TabsTrigger>
                                <TabsTrigger value="userLimits">
                                    User Limits
                                </TabsTrigger>
                            </TabsList>

                            {/* Pass userData and setUserData to EditUserOverlay */}
                            <EditUserOverlay
                                userData={userData}
                                setUserData={setUserData}
                                teamAdmins={teamAdmins}
                                isAdd={isAdd}
                                allPlans={allPlans}
                            />

                            {/* Pass limits and setLimits to LimitsTabOverlay */}
                            <LimitsTabOverlay
                                limits={limits}
                                setLimits={setLimits}
                            />
                        </Tabs>

                        {/* Dialog Footer with Save and Cancel Buttons */}
                        <div className="flex justify-end mt-4">
                            <Button
                                variant="secondary"
                                className="mr-2"
                                onClick={() => {
                                    setSelectedUser(null);
                                    setIsAdd(false);
                                    setUserData(null);
                                    setLimits(null);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button onClick={handleSave}>Save</Button>
                        </div>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    );
};

export default ManageUsers;
