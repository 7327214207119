import React, { useEffect, useMemo, useState } from "react";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../components/misc/Tabs";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "../components/misc/Card";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import {
    HourlyMLSUsage,
    MLSUsage,
    MonthlyOpenAIUsage,
    MonthlyRentcastUsage,
    OpenAIUsage,
    RentcastUsage,
} from "./types";
import { getAuthHeader } from "../utils/authHeader";
import axios from "axios";
import LoadingScreen from "../screens/LoadingScreen";

// Utility function to format timestamps to readable dates
const formatTimestamp = (timestamp: number): string => {
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) return "Invalid Date";
    return `${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:00`;
};

// Utility function to format year and month to a readable string
const formatYearMonth = (year: number, month: number): string => {
    const date = new Date(year, month - 1);
    return date.toLocaleString("default", { month: "short", year: "numeric" });
};

const Statistics = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const [mlsUsage, setMlsUsage] = useState<MLSUsage[] | null>([]);
    const [hourlyMlsUsage, setHourlyMlsUsage] = useState<HourlyMLSUsage | null>(
        null
    );

    const [rentcastUsage, setRentcastUsage] = useState<RentcastUsage[] | null>(
        []
    );
    const [monthlyRentcastUsage, setMonthlyRentcastUsage] = useState<
        MonthlyRentcastUsage[]
    >([]);

    const [monthlyOpenaiUsage, setMonthlyOpenaiUsage] = useState<
        MonthlyOpenAIUsage[]
    >([]);

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            // Fetch all data concurrently
            const [
                mlsRes,
                hourlyMlsRes,
                rentcastRes,
                monthlyRentcastRes,
                openaiRes,
                monthlyOpenaiRes,
            ] = await Promise.all([
                axios.get<MLSUsage[]>(`${backendUrl}/admin/mls-usage`, {
                    headers,
                }),
                axios.get<HourlyMLSUsage>(
                    `${backendUrl}/admin/mls-hourly-usage`,
                    { headers }
                ),
                axios.get<RentcastUsage[]>(
                    `${backendUrl}/admin/rentcast-usage`,
                    { headers }
                ),
                axios.get<MonthlyRentcastUsage[]>(
                    `${backendUrl}/admin/rentcast-monthly-usage`,
                    { headers }
                ),
                axios.get<OpenAIUsage>(`${backendUrl}/admin/openai-usage`, {
                    headers,
                }),
                axios.get<MonthlyOpenAIUsage[]>(
                    `${backendUrl}/admin/openai-monthly-usage`,
                    { headers }
                ),
            ]);

            // Debugging logs
            console.log("mlsRes", mlsRes.data);
            console.log("hourlyMlsRes", hourlyMlsRes.data);
            console.log("rentcastRes", rentcastRes.data);
            console.log("monthlyRentcastRes", monthlyRentcastRes.data);
            console.log("openaiRes", openaiRes.data);
            console.log("monthlyOpenaiRes", monthlyOpenaiRes.data);

            // Set data if responses are successful
            if (mlsRes.status === 200) setMlsUsage(mlsRes.data || []);
            else throw new Error("Failed to fetch MLS usage");

            if (hourlyMlsRes.status === 200)
                setHourlyMlsUsage(hourlyMlsRes.data);
            else throw new Error("Failed to fetch hourly MLS usage");

            if (rentcastRes.status === 200)
                setRentcastUsage(rentcastRes.data || []);
            else throw new Error("Failed to fetch Rentcast usage");

            if (monthlyRentcastRes.status === 200)
                setMonthlyRentcastUsage(monthlyRentcastRes.data);
            else throw new Error("Failed to fetch monthly Rentcast usage");

            // OpenAI usage is handled via monthly aggregated data
            if (monthlyOpenaiRes.status === 200)
                setMonthlyOpenaiUsage(monthlyOpenaiRes.data);
            else throw new Error("Failed to fetch monthly OpenAI usage");
        } catch (err: any) {
            console.error("Error fetching data:", err);
            setError(err.message || "An error occurred while fetching data.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();

        const interval = setInterval(() => {
            fetchData();
        }, 60000); // 1 minute

        return () => clearInterval(interval);
    }, []);

    // Prepare data for Rentcast chart
    const rentcastChartData = useMemo(
        () =>
            monthlyRentcastUsage.map((item) => ({
                month: new Date(item.timestamp).toLocaleString("default", {
                    month: "short",
                    year: "numeric",
                }),
                count: item.count,
            })),
        [monthlyRentcastUsage]
    );

    // Prepare data for OpenAI chart
    const openaiChartData = useMemo(
        () =>
            monthlyOpenaiUsage.map((item) => ({
                month: formatYearMonth(item.year, item.month),
                totalCost: item.totalCost,
            })),
        [monthlyOpenaiUsage]
    );

    // Prepare data for MLS chart by combining dataUsage and mediaUsage
    const mlsChartData = useMemo(() => {
        if (!hourlyMlsUsage) return [];

        // Combine dataUsage and mediaUsage based on timestamp
        const combinedData: { time: string; Data: number; Media: number }[] =
            hourlyMlsUsage.dataUsage.map((dataItem) => {
                // Use 'timestamp' if available; else, fallback to 'createdAt'
                const timestamp =
                    dataItem.timestamp ||
                    new Date(dataItem.createdAt).getTime();
                const mediaItem = hourlyMlsUsage.mediaUsage.find(
                    (item) => item.timestamp === dataItem.timestamp
                );
                return {
                    time: formatTimestamp(timestamp),
                    Data: dataItem.count,
                    Media: mediaItem ? mediaItem.count : 0,
                };
            });

        return combinedData;
    }, [hourlyMlsUsage]);

    return (
        <div className="flex flex-col h-full p-4 space-y-6">
            <h2 className="text-2xl font-bold text-primary">Statistics</h2>

            {loading && <LoadingScreen />}
            {error && <p className="text-red-500">Error: {error}</p>}

            {!loading && !error && (
                <>
                    {/* Tabs with Graphs and Tables */}
                    <Tabs defaultValue="rentcast">
                        <TabsList className="mb-4">
                            <TabsTrigger value="rentcast">
                                Rentcast Usage
                            </TabsTrigger>
                            <TabsTrigger value="mls">MLS Usage</TabsTrigger>
                            <TabsTrigger value="openai">
                                OpenAI Usage
                            </TabsTrigger>
                        </TabsList>

                        {/* Rentcast Tab */}
                        <TabsContent value="rentcast">
                            <div className="space-y-6">
                                {/* Rentcast Usage Chart */}
                                <Card>
                                    <CardHeader>
                                        <CardTitle>
                                            Monthly Rentcast Usage
                                        </CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <ResponsiveContainer
                                            width="100%"
                                            height={400}
                                        >
                                            <LineChart data={rentcastChartData}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    dataKey="month"
                                                    label={{
                                                        value: "Month",
                                                        position:
                                                            "insideBottomRight",
                                                        offset: -5,
                                                    }}
                                                />
                                                <YAxis
                                                    label={{
                                                        value: "Usage Count",
                                                        angle: -90,
                                                        position: "insideLeft",
                                                    }}
                                                />
                                                <Tooltip />
                                                <Legend
                                                    verticalAlign="top"
                                                    height={36}
                                                />
                                                <Line
                                                    type="monotone"
                                                    dataKey="count"
                                                    name="Rentcast Usage"
                                                    stroke="#8884d8"
                                                    activeDot={{ r: 8 }}
                                                />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </CardContent>
                                </Card>

                                {/* Rentcast Usage Table */}
                                <Card>
                                    <CardHeader>
                                        <CardTitle>
                                            Rentcast Usage Details
                                        </CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <table className="min-w-full table-auto">
                                            <thead>
                                                <tr>
                                                    <th className="px-4 py-2">
                                                        User ID
                                                    </th>
                                                    <th className="px-4 py-2">
                                                        Timestamp
                                                    </th>
                                                    <th className="px-4 py-2">
                                                        Created At
                                                    </th>
                                                    <th className="px-4 py-2">
                                                        Updated At
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rentcastUsage &&
                                                rentcastUsage.length > 0 ? (
                                                    rentcastUsage.map(
                                                        (usage) => (
                                                            <tr
                                                                key={usage._id}
                                                                className="text-center"
                                                            >
                                                                <td className="border px-4 py-2">
                                                                    {
                                                                        usage.userId
                                                                    }
                                                                </td>
                                                                <td className="border px-4 py-2">
                                                                    {new Date(
                                                                        usage.timestamp
                                                                    ).toLocaleString()}
                                                                </td>
                                                                <td className="border px-4 py-2">
                                                                    {new Date(
                                                                        usage.createdAt
                                                                    ).toLocaleString()}
                                                                </td>
                                                                <td className="border px-4 py-2">
                                                                    {new Date(
                                                                        usage.updatedAt
                                                                    ).toLocaleString()}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                ) : (
                                                    <tr>
                                                        <td
                                                            colSpan={4}
                                                            className="border px-4 py-2 text-center"
                                                        >
                                                            No Rentcast Usage
                                                            Data
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </CardContent>
                                </Card>
                            </div>
                        </TabsContent>

                        {/* MLS Tab */}
                        <TabsContent value="mls">
                            <div className="space-y-6">
                                {/* MLS Usage Chart */}
                                <Card>
                                    <CardHeader>
                                        <CardTitle>Hourly MLS Usage</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <ResponsiveContainer
                                            width="100%"
                                            height={400}
                                        >
                                            <LineChart data={mlsChartData}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    dataKey="time"
                                                    label={{
                                                        value: "Time",
                                                        position:
                                                            "insideBottomRight",
                                                        offset: -5,
                                                    }}
                                                />
                                                <YAxis
                                                    label={{
                                                        value: "Usage Count",
                                                        angle: -90,
                                                        position: "insideLeft",
                                                    }}
                                                />
                                                <Tooltip />
                                                <Legend
                                                    verticalAlign="top"
                                                    height={36}
                                                />
                                                <Line
                                                    type="monotone"
                                                    dataKey="Data"
                                                    name="Data Usage"
                                                    stroke="#8884d8"
                                                    activeDot={{ r: 8 }}
                                                />
                                                <Line
                                                    type="monotone"
                                                    dataKey="Media"
                                                    name="Media Usage"
                                                    stroke="#82ca9d"
                                                    activeDot={{ r: 8 }}
                                                />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </CardContent>
                                </Card>

                                {/* MLS Usage Table */}
                                <Card>
                                    <CardHeader>
                                        <CardTitle>MLS Usage Details</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <table className="min-w-full table-auto">
                                            <thead>
                                                <tr>
                                                    <th className="px-4 py-2">
                                                        User ID
                                                    </th>
                                                    <th className="px-4 py-2">
                                                        Sync
                                                    </th>
                                                    <th className="px-4 py-2">
                                                        Type
                                                    </th>
                                                    <th className="px-4 py-2">
                                                        Timestamp
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {mlsUsage &&
                                                mlsUsage.length > 0 ? (
                                                    mlsUsage.map((usage) => (
                                                        <tr
                                                            key={usage._id}
                                                            className="text-center"
                                                        >
                                                            <td className="border px-4 py-2">
                                                                {usage.userId ||
                                                                    "N/A"}
                                                            </td>
                                                            <td className="border px-4 py-2">
                                                                {usage.sync
                                                                    ? "Yes"
                                                                    : "No"}
                                                            </td>
                                                            <td className="border px-4 py-2">
                                                                {usage.type}
                                                            </td>
                                                            <td className="border px-4 py-2">
                                                                {new Date(
                                                                    usage.timestamp
                                                                ).toLocaleString()}
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td
                                                            colSpan={4}
                                                            className="border px-4 py-2 text-center"
                                                        >
                                                            No MLS Usage Data
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </CardContent>
                                </Card>
                            </div>
                        </TabsContent>

                        {/* OpenAI Tab */}
                        <TabsContent value="openai">
                            <div className="space-y-6">
                                {/* OpenAI Usage Chart */}
                                <Card>
                                    <CardHeader>
                                        <CardTitle>
                                            Monthly OpenAI Usage
                                        </CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <ResponsiveContainer
                                            width="100%"
                                            height={400}
                                        >
                                            <LineChart data={openaiChartData}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    dataKey="month"
                                                    label={{
                                                        value: "Month",
                                                        position:
                                                            "insideBottomRight",
                                                        offset: -5,
                                                    }}
                                                />
                                                <YAxis
                                                    label={{
                                                        value: "Total Cost ($)",
                                                        angle: -90,
                                                        position: "insideLeft",
                                                    }}
                                                    domain={[0, "auto"]}
                                                />
                                                <Tooltip />
                                                <Legend
                                                    verticalAlign="top"
                                                    height={36}
                                                />
                                                <Line
                                                    type="monotone"
                                                    dataKey="totalCost"
                                                    name="Total Cost"
                                                    stroke="#ffc658"
                                                    activeDot={{ r: 8 }}
                                                />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </CardContent>
                                </Card>

                                {/* OpenAI Usage Table */}
                                <Card>
                                    <CardHeader>
                                        <CardTitle>
                                            OpenAI Usage Details
                                        </CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <table className="min-w-full table-auto">
                                            <thead>
                                                <tr>
                                                    <th className="px-4 py-2">
                                                        User ID
                                                    </th>
                                                    <th className="px-4 py-2">
                                                        Model
                                                    </th>
                                                    <th className="px-4 py-2">
                                                        Input Tokens
                                                    </th>
                                                    <th className="px-4 py-2">
                                                        Output Tokens
                                                    </th>
                                                    <th className="px-4 py-2">
                                                        Cost ($)
                                                    </th>
                                                    <th className="px-4 py-2">
                                                        Month
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {monthlyOpenaiUsage &&
                                                monthlyOpenaiUsage.length >
                                                    0 ? (
                                                    monthlyOpenaiUsage.map(
                                                        (item) => (
                                                            <tr
                                                                key={item._id}
                                                                className="text-center"
                                                            >
                                                                <td className="border px-4 py-2">
                                                                    {
                                                                        item.userId
                                                                    }
                                                                </td>
                                                                <td className="border px-4 py-2">
                                                                    {item.model}
                                                                </td>
                                                                <td className="border px-4 py-2">
                                                                    {
                                                                        item.totalInputTokens
                                                                    }
                                                                </td>
                                                                <td className="border px-4 py-2">
                                                                    {
                                                                        item.totalOutputTokens
                                                                    }
                                                                </td>
                                                                <td className="border px-4 py-2">
                                                                    {item.totalCost.toFixed(
                                                                        2
                                                                    )}
                                                                </td>
                                                                <td className="border px-4 py-2">
                                                                    {formatYearMonth(
                                                                        item.year,
                                                                        item.month
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                ) : (
                                                    <tr>
                                                        <td
                                                            colSpan={6}
                                                            className="border px-4 py-2 text-center"
                                                        >
                                                            No OpenAI Usage Data
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </CardContent>
                                </Card>
                            </div>
                        </TabsContent>
                    </Tabs>
                </>
            )}
        </div>
    );
};

export default Statistics;
