import React from "react";
import { MdEmail } from "react-icons/md";

const VerificationMessage: React.FC = () => {
    return (
        <>
            <div className="flex flex-col mt-11 max-w-full text-2xl font-bold text-center text-black w-[425px] max-md:mt-10">
                <div className="flex gap-3.5 justify-between items-center min-h-[98px]">
                    <MdEmail className="w-10 h-10 text-secondary" />
                    <h1 className="self-stretch my-auto w-[369px] text-primary">
                        Email Verification Required
                    </h1>
                </div>
            </div>
            <p className="self-stretch mt-11 text-2xl font-medium text-center text-black max-md:mt-10 max-md:max-w-full">
                Almost there! We need you to verify your email before signing
                in.
            </p>
        </>
    );
};

export default VerificationMessage;
