import { useLocation, useNavigate } from "react-router-dom";
import { tutorialsData } from "./tutorials-data";
import { Button } from "../../misc/Button";

const TutorialDetail: React.FC = () => {
    const navigate = useNavigate();

    const location = useLocation();

    const state = location.state;
    const tab = state?.tab;

    const path = window.location.pathname;
    const tutorial = tutorialsData.find((t) => t.link === path.split("/")[2]);

    if (!tutorial) {
        return (
            <div className="p-4 text-center text-red-500">
                Tutorial not found.
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-12">
            <Button
                onClick={() => {
                    tab ? navigate("/help", { state: { tab } }) : navigate(-1);
                }}
                className="mb-4"
            >
                Back
            </Button>
            <h2 className="text-3xl font-bold mb-4 text-primary">
                {tutorial.title}
            </h2>
            <p className="text-neutral-500 mb-6">{tutorial.description}</p>
            {tutorial.component ? (
                <tutorial.component />
            ) : (
                <div>
                    <p className="text-gray-600">
                        Detailed content for {tutorial.title} goes here.
                    </p>
                </div>
            )}
        </div>
    );
};

export default TutorialDetail;
