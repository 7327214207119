import React, { useState } from "react";
import { X, ChevronLeft, ChevronRight } from "lucide-react";
import { FacebookMediaGalleryOverlayProps } from "./types";
import { MediaType } from "../../types/media";
import { useIsMobile } from "../../hooks/useIsMobile";

const FacebookMediaGalleryOverlay: React.FC<
    FacebookMediaGalleryOverlayProps
> = ({ media, initialIndex, onClose }) => {
    const [currentIndex, setCurrentIndex] = useState(initialIndex);

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : media.length - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex < media.length - 1 ? prevIndex + 1 : 0
        );
    };

    const isMobile = useIsMobile(900);

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center ${
                isMobile ? "mb-16" : ""
            }`}
            style={isMobile ? { paddingBottom: "4rem" } : {}}
        >
            <button
                onClick={onClose}
                className="absolute top-4 right-4 text-white focus:outline-none"
            >
                <X size={24} />
            </button>
            <button
                onClick={handlePrevious}
                className={`absolute left-4 text-white focus:outline-none ${
                    isMobile ? "left-2" : ""
                }`}
            >
                <ChevronLeft size={36} />
            </button>
            <button
                onClick={handleNext}
                className={`absolute right-4 text-white focus:outline-none ${
                    isMobile ? "right-2" : ""
                }`}
            >
                <ChevronRight size={36} />
            </button>
            <div className="w-full h-full flex items-center justify-center">
                {media[currentIndex].type !== MediaType.ListingVideo &&
                media[currentIndex].type !== MediaType.AssetVideo ? (
                    <img
                        src={
                            media[currentIndex].signedUrl ||
                            media[currentIndex].url
                        }
                        alt={`Media ${currentIndex + 1}`}
                        className="max-w-full max-h-full object-contain"
                    />
                ) : (
                    <video
                        muted
                        src={
                            media[currentIndex].signedUrl ||
                            media[currentIndex].url
                        }
                        controls
                        className="max-w-full max-h-full"
                    />
                )}
            </div>
            <div
                className={`absolute bottom-4 left-1/2 transform -translate-x-1/2 text-white ${
                    isMobile ? "bottom-16 text-sm" : ""
                }`}
            >
                {currentIndex + 1} / {media.length}
            </div>
        </div>
    );
};

export default FacebookMediaGalleryOverlay;
