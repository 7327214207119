import React, { useState } from "react";
import { Button } from "../misc/Button";
import { FaTrash } from "react-icons/fa";
import { DeleteMediaProps } from "./types";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";
import ReconfirmOverlay from "../misc/ReconfirmOverlay";

type ConfirmAction = "deleteMedia" | null;

export const DeleteMediaButton: React.FC<DeleteMediaProps> = ({
    selectedMedia,
    setSelectedMedia,
    setLoading,
    fetchAssets,
}) => {
    const [actionToConfirm, setActionToConfirm] = useState<ConfirmAction>(null);
    const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);

    const handleAction = (action: ConfirmAction) => {
        setIsOverlayOpen(true);
        setActionToConfirm(action);
    };

    const confirmAction = async () => {
        if (!actionToConfirm) return;
        setLoading(true);
        try {
            switch (actionToConfirm) {
                case "deleteMedia": {
                    handleDeleteImages();
                    break;
                }
                default:
                    break;
            }
        } catch (error) {
            console.error("Error confirming action:", error);
        }
        setIsOverlayOpen(false);
        setActionToConfirm(null);
    };

    const handleDeleteImages = async () => {
        setLoading(true);
        const assetIds = selectedMedia.map((media) => media.id);

        if (assetIds.length <= 0) {
            toast.error(
                <CustomToast
                    message="You need to select atleast one image to delete."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );

            setLoading(false);
            return;
        }

        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.delete(`${backendUrl}/assets/`, {
                headers,
                data: { ids: Array.isArray(assetIds) ? assetIds : [assetIds] },
            });

            if (response.status === 200) {
                toast.success(
                    <CustomToast
                        message="Images deleted successfully."
                        type="success"
                    />,
                    {
                        autoClose: 3000,
                    }
                );
                setSelectedMedia([]);

                fetchAssets();
                setLoading(false);
            } else {
                throw new Error("Failed to delete images.");
            }
        } catch (error: unknown) {
            console.error("Failed to delete images:", error);
            setLoading(false);
            toast.error(
                <CustomToast
                    message="Failed to delete images. Please try again later."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        } finally {
            setLoading(false);
        }
    };

    const getOverlayContent = () => {
        switch (actionToConfirm) {
            case "deleteMedia":
                return {
                    title: "Delete Media",
                    message: "Are you sure you want to delete?",
                    messageTwo: "This action will delet the selected media.",
                    confirmText: "Delete",
                };
            default:
                return {
                    title: "",
                    message: "",
                    messageTwo: "",
                    confirmText: "",
                };
        }
    };

    const closeOverlay = () => {
        setIsOverlayOpen(false);
        setActionToConfirm(null);
    };

    const { title, message, messageTwo, confirmText } = getOverlayContent();

    return (
        <div>
            <ReconfirmOverlay
                isOpen={isOverlayOpen}
                onClose={closeOverlay}
                onConfirm={confirmAction}
                title={title}
                message={message}
                messageTwo={messageTwo}
                confirmText={confirmText}
            />
            <span className="mr-2 text-primary">
                {selectedMedia.length} Selected
            </span>
            {selectedMedia.length > 0 ? (
                <Button
                    id="delete-media-btn"
                    onClick={() => handleAction("deleteMedia")}
                    variant="destructive"
                >
                    <FaTrash className="h-4 w-4" />
                    Delete Media
                </Button>
            ) : null}
        </div>
    );
};
