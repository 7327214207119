import React from "react";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";

interface ScrollAreaProps
    extends React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> {}

const ScrollArea = React.forwardRef<
    React.ElementRef<typeof ScrollAreaPrimitive.Root>,
    ScrollAreaProps
>(({ className = "", children, ...props }, ref) => (
    <ScrollAreaPrimitive.Root
        ref={ref}
        type="auto"
        className={`relative overflow-hidden ${className}`}
        {...props}
    >
        <ScrollAreaPrimitive.Viewport className="h-full w-full rounded-[inherit]">
            {children}
        </ScrollAreaPrimitive.Viewport>
        <ScrollBar orientation="vertical" />
        <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
));
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

interface ScrollBarProps
    extends React.ComponentPropsWithoutRef<
        typeof ScrollAreaPrimitive.ScrollAreaScrollbar
    > {
    orientation?: "horizontal" | "vertical";
}

const ScrollBar = React.forwardRef<
    React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
    ScrollBarProps
>(({ className = "", orientation = "vertical", ...props }, ref) => {
    const isVertical = orientation === "vertical";

    // Define the styles based on orientation
    const orientationStyles = isVertical
        ? { width: "12px" } // Vertical scrollbar width
        : { height: "12px" }; // Horizontal scrollbar height

    return (
        <ScrollAreaPrimitive.ScrollAreaScrollbar
            ref={ref}
            orientation={orientation}
            // Apply the track styles
            className={`flex touch-none select-none transition-colors ${className}`}
            style={{
                ...orientationStyles,
                backgroundColor: "#e9c468", // Scrollbar track color
                padding: "2px", // Adjust padding if necessary
            }}
            {...props}
        >
            <ScrollAreaPrimitive.ScrollAreaThumb
                // Apply the thumb styles
                className="relative flex-1 rounded-full"
                style={{
                    backgroundColor: "#193456", // Scrollbar thumb color
                    borderRadius: "5px",
                }}
            />
        </ScrollAreaPrimitive.ScrollAreaScrollbar>
    );
});
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };
