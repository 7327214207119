import { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../../components/misc/Card";
import { Input } from "../../components/misc/Input";
import { Label } from "../../components/misc/Label";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../components/misc/Select";
import { Switch } from "../../components/misc/Switch";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../../components/misc/Tabs";
import { PlanFormProps, planFrequency } from "../types";
import ReconfirmOverlay from "../../components/misc/ReconfirmOverlay";
import { Button } from "../../components/misc/Button";
import { getAuthHeader } from "../../utils/authHeader";
import { toast } from "react-toastify";
import CustomToast from "../../components/misc/CustomToast";
import axios from "axios";
import MarkdownEditor from "../../components/misc/MarkdownEditor";

type ActionType = "delete" | "public" | null;

export const PlanForm: React.FC<PlanFormProps> = ({
    plan,
    setEditingPlan,
    onClose,
}) => {
    const [actionToConfirm, setActionToConfirm] = useState<ActionType>(null);
    const [pendingPublicValue, setPendingPublicValue] =
        useState<boolean>(false);

    const confirmAction = () => {
        if (actionToConfirm === "public") {
            confirmPublic();
        } else if (actionToConfirm === "delete") {
            handleDelete();
        }

        setActionToConfirm(null);
        setPendingPublicValue(false);
    };

    const overlayProps = () => {
        switch (actionToConfirm) {
            case "delete":
                return {
                    title: "Delete Plan",
                    message: "Are you sure you want to delete this plan?",
                    messageTwo: "This action cannot be undone.",
                    confirmText: "Delete Plan",
                };
            case "public":
                return {
                    title: "Make Plan Public",
                    message: "Are you sure you want to make this plan public?",
                    messageTwo:
                        "Making the plan public will allow all users to see and subscribe to it.",
                    confirmText: "Yes, Make Public",
                };
            default:
                return null;
        }
    };

    const currentOverlayProps = overlayProps();

    const confirmPublic = () => {
        setEditingPlan({
            ...plan,
            public: pendingPublicValue,
        });
    };

    const handleDelete = async () => {
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.delete(
                `${backendUrl}/admin/plan/${plan.id}`,
                { headers }
            );

            if (response.status !== 200) {
                throw new Error("Failed to delete plan");
            }

            toast.success(
                <CustomToast
                    message="Plan deleted successfully"
                    type="success"
                />,
                { autoClose: 5000 }
            );

            // Clear the form after successful deletion
            setEditingPlan(null);
            onClose();
        } catch (error) {
            toast.error(
                <CustomToast message="Failed to delete plan" type="error" />,
                { autoClose: 5000 }
            );
        }
    };

    const handlePublicToggle = (checked: boolean) => {
        if (checked) {
            // Attempting to make the plan public, require confirmation
            setPendingPublicValue(true);
            setActionToConfirm("public");
        } else {
            // Making the plan private directly without confirmation
            setEditingPlan({
                ...plan,
                public: false,
            });
        }
    };

    const editPlan = plan?.id && plan?.id?.length > 0;

    const formatPrice = (price: number) => {
        const priceStr = price.toString().padStart(3, "0");
        const dollars = priceStr.slice(0, -2);
        const cents = priceStr.slice(-2);
        return `${dollars}.${cents}`;
    };

    const parsePrice = (value: string) => {
        const digits = value.replace(/\D/g, "");
        if (digits === "") return 0;
        const padded = digits.padStart(3, "0");
        return parseInt(padded, 10);
    };

    useEffect(() => {
        if (!plan.userNotes || plan.userNotes.trim() === "") {
            setEditingPlan({
                ...plan,
                userNotes: "- ",
            });
        }
    }, [plan, setEditingPlan]);

    return (
        <>
            <Tabs defaultValue="general" className="w-full mt-4">
                <TabsList
                    className={`grid w-full ${
                        editPlan ? "grid-cols-3" : "grid-cols-2"
                    }`}
                >
                    <TabsTrigger value="general">
                        General Information
                    </TabsTrigger>
                    <TabsTrigger value="limits">Limits</TabsTrigger>
                    {editPlan && (
                        <TabsTrigger value="advanced">Advanced</TabsTrigger>
                    )}
                </TabsList>
                <TabsContent value="general">
                    <Card>
                        <CardHeader>
                            <CardTitle>General Plan Information</CardTitle>
                            <CardDescription>
                                Set the basic details for this plan.
                            </CardDescription>
                        </CardHeader>
                        <CardContent className="space-y-4">
                            <div className="grid grid-cols-4 items-center gap-4">
                                <Label htmlFor="name" className="text-right">
                                    Name
                                </Label>
                                <Input
                                    id="name"
                                    value={plan.name}
                                    onChange={(e) =>
                                        setEditingPlan({
                                            ...plan,
                                            name: e.target.value,
                                        })
                                    }
                                    className="col-span-3"
                                />
                            </div>
                            <div className="grid grid-cols-4 items-center gap-4">
                                <Label
                                    htmlFor="description"
                                    className="text-right"
                                >
                                    Description
                                </Label>
                                <Input
                                    id="description"
                                    value={plan.description}
                                    onChange={(e) =>
                                        setEditingPlan({
                                            ...plan,
                                            description: e.target.value,
                                        })
                                    }
                                    className="col-span-3"
                                />
                            </div>
                            <div className="flex flex-row items-center">
                                <div className="flex-[0.25] flex flex-row justify-end">
                                    <Label
                                        htmlFor="userNotes"
                                        className="text-right pr-5"
                                    >
                                        User Notes
                                    </Label>
                                </div>
                                <MarkdownEditor
                                    value={plan.userNotes}
                                    onChange={(newValue) =>
                                        setEditingPlan({
                                            ...plan,
                                            userNotes: newValue,
                                        })
                                    }
                                />
                            </div>
                            <div className="grid grid-cols-4 items-center gap-4">
                                <Label htmlFor="price" className="text-right">
                                    Price
                                </Label>
                                <Input
                                    id="price"
                                    type="text" // Use text to better control formatting
                                    value={formatPrice(Number(plan.price))}
                                    onChange={(e) => {
                                        const newPrice = parsePrice(
                                            e.target.value
                                        );
                                        setEditingPlan({
                                            ...plan,
                                            price: newPrice,
                                        });
                                    }}
                                    className="col-span-3"
                                    inputMode="numeric"
                                    pattern="\d*\.?\d{0,2}"
                                />
                            </div>
                            <div className="grid grid-cols-4 items-center gap-4">
                                <Label
                                    htmlFor="frequency"
                                    className="text-right"
                                >
                                    Frequency
                                </Label>
                                <Select
                                    onValueChange={(value: planFrequency) =>
                                        setEditingPlan({
                                            ...plan,
                                            frequency: value as planFrequency,
                                        })
                                    }
                                    defaultValue={plan.frequency}
                                >
                                    <SelectTrigger className="col-span-3">
                                        <SelectValue placeholder="Select a Frequency" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem
                                            value={planFrequency.Monthly}
                                        >
                                            Monthly
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                            <div className="grid grid-cols-4 items-center gap-4">
                                <Label htmlFor="public" className="text-right">
                                    Public
                                </Label>
                                <Switch
                                    id="public"
                                    checked={plan.public}
                                    onCheckedChange={handlePublicToggle}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </TabsContent>
                <TabsContent value="limits">
                    <Card>
                        <CardHeader>
                            <CardTitle>Plan Limits</CardTitle>
                            <CardDescription>
                                Set the usage limits for this plan.
                            </CardDescription>
                        </CardHeader>
                        <CardContent className="mt-0 pt-0">
                            <div className="mb-4">
                                <h4 className="font-medium text-primary">
                                    Plan Type
                                </h4>
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="space-x-2">
                                        <span>Single User</span>
                                        <Switch
                                            id="singleUser"
                                            checked={
                                                plan?.limits?.userLimit > 1
                                            }
                                            onCheckedChange={(checked) =>
                                                setEditingPlan({
                                                    ...plan,
                                                    limits: {
                                                        ...plan.limits,
                                                        userLimit: checked
                                                            ? 2
                                                            : 1,
                                                    },
                                                })
                                            }
                                        />
                                        <span>Multiple Users</span>
                                        {plan?.limits?.userLimit > 1 && (
                                            <div className="mt-2 flex flex-col">
                                                <Label
                                                    className="text-base text-primary"
                                                    htmlFor="userLimit"
                                                >
                                                    User Limit
                                                </Label>
                                                <span className="text-sm text-neutral-500 mb-1">
                                                    Total number of users per
                                                    plan
                                                </span>
                                                <Input
                                                    id="userLimit"
                                                    type="number"
                                                    value={
                                                        plan?.limits?.userLimit
                                                    }
                                                    onChange={(e) =>
                                                        setEditingPlan({
                                                            ...plan,
                                                            limits: {
                                                                ...plan?.limits,
                                                                userLimit:
                                                                    parseInt(
                                                                        e.target
                                                                            .value,
                                                                        10
                                                                    ),
                                                            },
                                                        })
                                                    }
                                                    min={2}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="grid gap-6">
                                <div className="space-y-4">
                                    <h4 className="font-medium">
                                        Posts and Listings
                                    </h4>
                                    <span className="text-sm text-neutral-500">
                                        Reaching a limit will generate an
                                        internal notification.
                                    </span>
                                    <br />
                                    <span className="text-sm text-neutral-500 mt-1">
                                        When Hard Limit is reached, user actions
                                        will be restricted.
                                    </span>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div className="space-y-2">
                                            <Label htmlFor="postWarn">
                                                Post Soft Limit
                                            </Label>
                                            <Input
                                                id="postWarn"
                                                type="number"
                                                value={plan?.limits?.postWarn}
                                                onChange={(e) =>
                                                    setEditingPlan({
                                                        ...plan,
                                                        limits: {
                                                            ...plan.limits,
                                                            postWarn: parseInt(
                                                                e.target.value
                                                            ),
                                                        },
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="postLimit">
                                                Post Hard Limit
                                            </Label>
                                            <Input
                                                id="postLimit"
                                                type="number"
                                                value={plan?.limits?.postLimit}
                                                onChange={(e) =>
                                                    setEditingPlan({
                                                        ...plan,
                                                        limits: {
                                                            ...plan.limits,
                                                            postLimit: parseInt(
                                                                e.target.value
                                                            ),
                                                        },
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="listingWarn">
                                                Active Listing Limit
                                            </Label>
                                            <Input
                                                id="listingActive"
                                                type="number"
                                                value={
                                                    plan?.limits
                                                        ?.activeListingLimit
                                                }
                                                onChange={(e) =>
                                                    setEditingPlan({
                                                        ...plan,
                                                        limits: {
                                                            ...plan?.limits,
                                                            activeListingLimit:
                                                                parseInt(
                                                                    e.target
                                                                        .value
                                                                ),
                                                        },
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="listingWarn">
                                                Listing Soft Limit
                                            </Label>
                                            <Input
                                                id="listingWarn"
                                                type="number"
                                                value={
                                                    plan?.limits?.listingWarn
                                                }
                                                onChange={(e) =>
                                                    setEditingPlan({
                                                        ...plan,
                                                        limits: {
                                                            ...plan.limits,
                                                            listingWarn:
                                                                parseInt(
                                                                    e.target
                                                                        .value
                                                                ),
                                                        },
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="listingLimit">
                                                Listing Hard Limit
                                            </Label>
                                            <Input
                                                id="listingLimit"
                                                type="number"
                                                value={
                                                    plan?.limits?.listingLimit
                                                }
                                                onChange={(e) =>
                                                    setEditingPlan({
                                                        ...plan,
                                                        limits: {
                                                            ...plan.limits,
                                                            listingLimit:
                                                                parseInt(
                                                                    e.target
                                                                        .value
                                                                ),
                                                        },
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-4">
                                    <h4 className="font-medium">
                                        Images and Videos
                                    </h4>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div className="space-y-2">
                                            <Label htmlFor="imageTagsWarn">
                                                Image Tags Soft Limit
                                            </Label>
                                            <Input
                                                id="imageTagsWarn"
                                                type="number"
                                                value={
                                                    plan?.limits?.imageTagsWarn
                                                }
                                                onChange={(e) =>
                                                    setEditingPlan({
                                                        ...plan,
                                                        limits: {
                                                            ...plan?.limits,
                                                            imageTagsWarn:
                                                                parseInt(
                                                                    e.target
                                                                        .value
                                                                ),
                                                        },
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="imageTagsLimit">
                                                Image Tags Hard Limit
                                            </Label>
                                            <Input
                                                id="imageTagsLimit"
                                                type="number"
                                                value={
                                                    plan?.limits?.imageTagsLimit
                                                }
                                                onChange={(e) =>
                                                    setEditingPlan({
                                                        ...plan,
                                                        limits: {
                                                            ...plan?.limits,
                                                            imageTagsLimit:
                                                                parseInt(
                                                                    e.target
                                                                        .value
                                                                ),
                                                        },
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="imageVideosWarn">
                                                Image/Video Soft Limit
                                            </Label>
                                            <Input
                                                id="imageVideosWarn"
                                                type="number"
                                                value={
                                                    plan?.limits
                                                        ?.imageVideosWarn
                                                }
                                                onChange={(e) =>
                                                    setEditingPlan({
                                                        ...plan,
                                                        limits: {
                                                            ...plan?.limits,
                                                            imageVideosWarn:
                                                                parseInt(
                                                                    e.target
                                                                        .value
                                                                ),
                                                        },
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="imageVideosLimit">
                                                Image/Video Hard Limit
                                            </Label>
                                            <Input
                                                id="imageVideosLimit"
                                                type="number"
                                                value={
                                                    plan?.limits
                                                        ?.imageVideosLimit
                                                }
                                                onChange={(e) =>
                                                    setEditingPlan({
                                                        ...plan,
                                                        limits: {
                                                            ...plan?.limits,
                                                            imageVideosLimit:
                                                                parseInt(
                                                                    e.target
                                                                        .value
                                                                ),
                                                        },
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </TabsContent>
                <TabsContent value="advanced">
                    <Card>
                        <CardContent>
                            <h4 className="font-medium text-primary">
                                Advanced Settings
                            </h4>
                            <p className="text-sm text-gray-600">
                                These settings are for advanced users who want
                                to customize their plan further.
                            </p>

                            <div className="grid grid-cols-1 gap-4 mt-4">
                                <Button
                                    variant="destructive"
                                    onClick={() => setActionToConfirm("delete")}
                                >
                                    Delete Plan
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </TabsContent>
            </Tabs>
            {currentOverlayProps && (
                <ReconfirmOverlay
                    isOpen={true}
                    onClose={() => setActionToConfirm(null)}
                    onConfirm={confirmAction}
                    title={currentOverlayProps!.title}
                    message={currentOverlayProps!.message}
                    messageTwo={currentOverlayProps!.messageTwo}
                    confirmText={currentOverlayProps!.confirmText}
                />
            )}
        </>
    );
};
