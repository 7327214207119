import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="max-w-full mt-12">
            <p className=" text-sm text-center text-primary">
                &copy; 2025 Marabot.ai.
            </p>
            <div className="flex justify-center gap-2 mt-2">
                <Link
                    to="https://www.marabot.ai/privacy-policy"
                    target="_blank"
                    className="text-primary hover:text-secondary text-center"
                >
                    Privacy Policy
                </Link>
                <div className="w-[1px] bg-secondary"></div>
                <Link
                    to="https://www.marabot.ai/terms-of-service"
                    target="_blank"
                    className="text-primary hover:text-secondary text-center"
                >
                    Terms of Service
                </Link>
                <div className="w-[1px] bg-secondary"></div>
                <Link
                    to="https://www.marabot.ai/data-deletion-policy"
                    target="_blank"
                    className="text-primary hover:text-secondary text-center"
                >
                    Data Deletion Policy
                </Link>
            </div>
        </footer>
    );
};

export default Footer;
