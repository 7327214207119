import React from "react";
import { Plan } from "../../Admin/types";
import { useIsMobile } from "../../hooks/useIsMobile";
import { PricingTier } from "./PricingTier";

interface PricingPlansProps {
    plans: Plan[];
    selectedPlanId: string | null;
    setSelectedPlanId: (planId: string) => void;
    buttons?: boolean;
}

const PricingPlans: React.FC<PricingPlansProps> = ({
    plans,
    selectedPlanId,
    setSelectedPlanId,
    buttons = true,
}) => {
    const isMobile = useIsMobile(900);

    const getFeatures = (notes: string): string[] => {
        return notes
            .split("\n")
            .map((line) => line.trim())
            .filter((line) => line.startsWith("- "))
            .map((line) => line.substring(2).trim());
    };

    return (
        <section
            className={`grid ${
                isMobile
                    ? "grid-cols-1"
                    : plans.length > 2
                    ? "grid-cols-3"
                    : "grid-cols-2"
            } gap-4 mx-auto md:gap-7 items-stretch`}
        >
            {plans.map((plan) => (
                <PricingTier
                    key={plan.id}
                    name={plan.name}
                    price={plan.price}
                    description={plan.description}
                    features={getFeatures(plan.userNotes)}
                    highlighted={plan.id === selectedPlanId}
                    ctaText={
                        plan.id === selectedPlanId ? "Selected" : "Select Plan"
                    }
                    onSelect={() => setSelectedPlanId(plan?.id || "")}
                    buttons={buttons}
                />
            ))}
        </section>
    );
};

export default PricingPlans;
