import React, { useState } from "react";
import { UserNotesCardProps } from "./types";
import { Card, CardContent, CardTitle } from "../../misc/Card";
import InputField from "../../misc/InputField";
import { Button } from "../../misc/Button";
import LoadingScreen from "../../../screens/LoadingScreen";
import { toast } from "react-toastify";
import CustomToast from "../../misc/CustomToast";
import { getAuthHeader } from "../../../utils/authHeader";
import axios from "axios";

const UserNotesCard: React.FC<UserNotesCardProps> = ({
    propertyData,
    setPropertyData,
    mlsApproved,
}) => {
    const [notes, setNotes] = useState(propertyData?.userNotes || "");
    const [loading, setLoading] = useState(false);

    const handleNoteChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setNotes(e.target.value);
    };

    const handleSaveNotes = async () => {
        setLoading(true);
        try {
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            const response = await axios.patch(
                `${backendUrl}/listing`,
                {
                    listingId: propertyData._id,
                    userNotes: notes,
                    auto:
                        (propertyData.listingType === "MLSListing" &&
                            mlsApproved) ||
                        (propertyData.listingType === "RentcastListing" &&
                            !mlsApproved),
                },
                { headers }
            );

            if (response.status === 200) {
                setPropertyData({ ...propertyData, userNotes: notes });
                toast.success(
                    <CustomToast
                        message="Notes saved successfully"
                        type="success"
                    />,
                    { autoClose: 3000 }
                );
            } else {
                toast.error(
                    <CustomToast
                        message="Error saving notes. Please try again later."
                        type="error"
                    />,
                    { autoClose: 3000 }
                );
            }
        } catch (error: unknown) {
            console.error("Error saving notes", error);
            toast.error(
                <CustomToast
                    message="Error saving notes. Please try again later."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    const hasChanged = notes !== (propertyData?.userNotes || "");

    return (
        <Card>
            {loading && <LoadingScreen />}
            <CardContent className="pt-6">
                <CardTitle className="mb-4">User Notes</CardTitle>
                <div>
                    <InputField
                        label="Custom Notes"
                        name="customNotes"
                        placeholder="Custom Notes"
                        value={notes}
                        onChange={handleNoteChange}
                        type="textarea"
                        rows={8}
                    />
                    {hasChanged && (
                        <Button
                            id="save-notes-btn"
                            className="mt-4 w-full"
                            onClick={handleSaveNotes}
                        >
                            Save Notes
                        </Button>
                    )}
                </div>
                <div className="bg-gray-100 p-3 rounded-md mt-4">
                    <p className="text-sm text-gray-600">
                        <strong className="text-primary">Tip:</strong> This
                        notes field is for your personal use only and will not
                        be used for campaign and/or post generation.
                    </p>
                </div>
            </CardContent>
        </Card>
    );
};

export default UserNotesCard;
