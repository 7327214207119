import React, { useState, useEffect } from "react";
import { Button } from "../../misc/Button";
import PricingPlans from "../../onboarding/PricingPlans";
import { auth } from "../../../firebase/firebase-config";
import { getIdToken } from "firebase/auth";
import axios from "axios";
import LoadingScreen from "../../../screens/LoadingScreen";
import { toast } from "react-toastify";
import CustomToast from "../../misc/CustomToast";
import "react-toastify/dist/ReactToastify.css";
import { Dialog, DialogContent } from "@radix-ui/react-dialog";
import { Plan } from "../../../Admin/types";
import ReconfirmOverlay from "../../misc/ReconfirmOverlay";
import { Card, CardHeader } from "../../misc/Card";
import { X } from "lucide-react";
import { CardContent } from "../../calendar/Card";
import { ChangePlanProps } from "../types";
import { getAuthHeader } from "../../../utils/authHeader";
import { useNavigate } from "react-router-dom";

type ActionType = "cancel" | "save" | null;

const ChangePlan: React.FC<ChangePlanProps> = ({
    setChangePlansOpen,
    fetchCurrentPlan,
    status,
}) => {
    const navigate = useNavigate();

    const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
    const [userPlanId, setUserPlanId] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [plans, setPlans] = useState<Plan[]>([]);
    const [clickedSave, setClickedSave] = useState<boolean>(false);
    const [actionToConfirm, setActionToConfirm] = useState<ActionType>(null);
    const [actionResult, setActionResult] = useState<number | null>(null);

    const confirmAction = () => {
        if (actionToConfirm === "cancel") {
            cancelPlan();
        } else if (actionToConfirm === "save") {
            handleSave();
        }
        setActionToConfirm(null);
    };
    const handleSaveInitiated = async () => {
        const headers = await getAuthHeader();
        const url = `${process.env.REACT_APP_BACKEND_URL}/stripe/preview-change-plan/${selectedPlanId}`;
        const changeResult: number = (await axios.get(url, { headers })).data;
        setActionResult(changeResult);
        setActionToConfirm("save");
    };
    useEffect(() => {
        const fetchPlans = async () => {
            try {
                setLoading(true);
                const currentUser = auth.currentUser;
                if (!currentUser) throw new Error("User not authenticated");
                const token = await getIdToken(currentUser, true);
                const headers = { Authorization: `Bearer ${token}` };
                const backendUrl = process.env.REACT_APP_BACKEND_URL;

                const response = await axios.get(
                    `${backendUrl}/onboarding/plans`,
                    {
                        headers,
                        withCredentials: true,
                    }
                );
                setPlans(response.data);

                const userResponse = await axios.get(
                    `${backendUrl}/onboarding/plan-details`,
                    {
                        headers,
                        withCredentials: true,
                    }
                );

                if (status !== undefined && status !== "canceled") {
                    setUserPlanId(userResponse.data);
                } else {
                    setUserPlanId(null);
                }
            } catch (error) {
                console.error("Error fetching plans:", error);
                toast.error(
                    <CustomToast
                        message="Failed to fetch plans. Please try again."
                        type="error"
                    />,
                    {
                        autoClose: 3000,
                    }
                );
            } finally {
                setLoading(false);
            }
        };

        fetchPlans();
    }, [status]);

    const handleSave = async () => {
        setLoading(true);

        try {
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            const currentUser = auth.currentUser;
            if (!currentUser) throw new Error("User not authenticated");
            const token = await getIdToken(currentUser, true);
            const headers = { Authorization: `Bearer ${token}` };

            const response = await axios.post(
                `${backendUrl}/settings/change-plan`,
                { planId: selectedPlanId },
                {
                    headers: headers,
                    withCredentials: true,
                }
            );

            if (response.status === 201) {
                toast.success(
                    <CustomToast
                        message="Plan selected successfully."
                        type="success"
                    />,
                    {
                        autoClose: 3000,
                    }
                );

                fetchCurrentPlan();
                setChangePlansOpen(false);
            }
        } catch (error) {
            setLoading(false);

            console.error("Error selecting plan:", error);
            toast.error(
                <CustomToast
                    message="Failed to select plan. Please try again."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        } finally {
            setLoading(false);
        }
    };

    const cancelPlan = async () => {
        setLoading(true);

        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.get(
                `${backendUrl}/settings/cancel-plan`,
                {
                    headers,
                }
            );

            if (response.status === 200) {
                toast.success(
                    <CustomToast
                        message="Plan cancelled successfully."
                        type="success"
                    />,
                    {
                        autoClose: 3000,
                    }
                );

                fetchCurrentPlan();
            } else {
                throw new Error("Failed to cancel plan.");
            }
        } catch (error) {
            console.error("Error cancelling plan:", error);
            toast.error(
                <CustomToast
                    message="Failed to cancel plan. Please try again."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        } finally {
            setLoading(false);
            setChangePlansOpen(false);
        }
    };

    const overlayProps = () => {
        switch (actionToConfirm) {
            case "cancel":
                return {
                    title: "Cancel your plan?",
                    message: "Are you sure you want to cancel your plan?",
                    messageTwo:
                        "You will lose access to all features and the app at the end of your billing cycle.",
                    confirmText: "Cancel Plan",
                };
            case "save":
                return {
                    title: "Update your plan?",
                    message: actionResult
                        ? `Are you sure you want to update your plan?
                        This action will ${
                            actionResult > 0 ? "charge " : "credit "
                        }
                        your account ${actionResult > 0 ? "" : "with"} $${(
                              Math.abs(actionResult) / 100
                          ).toFixed(2)}.`
                        : "",
                    messageTwo: "Your limits will be updated immediately.",
                    confirmText: "Update Plan",
                };
            default:
                return null;
        }
    };

    const currentOverlayProps = overlayProps();

    return (
        <div className="relative w-full">
            <X
                className="absolute top-2 right-2 cursor-pointer text-red-500"
                onClick={() => {
                    setChangePlansOpen(false);
                }}
            />
            <Card className="py-6 m-0">
                <CardHeader>
                    <h2 className="text-2xl font-bold text-primary text-center">
                        Change Plan
                    </h2>
                </CardHeader>
                <CardContent>
                    <Dialog open={clickedSave} onOpenChange={setClickedSave}>
                        <DialogContent>
                            <div className="flex flex-col gap-4">
                                <h2 className="text-2xl font-bold">
                                    Update Plan
                                </h2>
                                <p>
                                    Are you sure you want to update your plan?
                                    This may incur a charge.
                                </p>
                                <div className="flex gap-4">
                                    <Button
                                        onClick={() => setClickedSave(false)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button onClick={handleSave}>
                                        Update Plan
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                    {loading && <LoadingScreen />}
                    {currentOverlayProps && (
                        <ReconfirmOverlay
                            isOpen={true}
                            onClose={() => setActionToConfirm(null)}
                            onConfirm={confirmAction}
                            title={currentOverlayProps!.title}
                            message={currentOverlayProps!.message}
                            messageTwo={currentOverlayProps!.messageTwo}
                            confirmText={currentOverlayProps!.confirmText}
                        />
                    )}
                    <div className="flex-grow flex items-center w-full">
                        <PricingPlans
                            plans={plans}
                            selectedPlanId={selectedPlanId}
                            setSelectedPlanId={setSelectedPlanId}
                            buttons={false}
                        />
                    </div>

                    <div
                        className={`w-full flex justify-center ${
                            selectedPlanId && selectedPlanId.length > 0
                                ? "mt-8"
                                : "mt-4"
                        } gap-20`}
                    >
                        {userPlanId !== selectedPlanId && (
                            <div className="flex flex-col items-center space-y-2">
                                <span className="text-xs text-neutral-500">
                                    Please contact us via
                                    <strong
                                        className="text-primary hover:text-secondary cursor-pointer"
                                        onClick={() => {
                                            navigate("/help", {
                                                state: { tab: "contact" },
                                            });
                                        }}
                                    >
                                        {" "}
                                        help{" "}
                                    </strong>
                                    page to upgrade/downgrade your plan.
                                </span>
                                <Button
                                    className="w-1/3"
                                    onClick={handleSaveInitiated}
                                    disabled={true}
                                >
                                    Save
                                </Button>
                            </div>
                        )}
                    </div>
                    <div
                        className={`w-full flex justify-center ${
                            userPlanId !== selectedPlanId ? "mt-6" : "mt-2"
                        } gap-20`}
                    >
                        <Button
                            size="sm"
                            variant="destructive"
                            style={{
                                fontSize: "0.6rem",
                                padding: "0px 8px",
                                width: "auto",
                            }}
                            onClick={() => setActionToConfirm("cancel")}
                        >
                            Cancel Subscription
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default ChangePlan;
