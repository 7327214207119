export interface Media {
    id: string;
    url: string;
    signedUrl?: string;
    type: MediaType;
    dateCreated: Date;
}

export enum MediaType {
    ListingImage = "Listing Image",
    ListingVideo = "Listing Video",
    Promotion = "Promotion",
    AssetImage = "Asset Image",
    AssetVideo = "Asset Video",
}
