import React, { useState } from "react";
import {
    format,
    startOfMonth,
    endOfMonth,
    startOfWeek,
    endOfWeek,
    addDays,
    isSameMonth,
    isSameDay,
    compareAsc,
} from "date-fns";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import DayView from "./DayView";
import { CalendarProps } from "../../types/calendar-props";
import PostCard from "./PostCard"; // Import the PostCard component

interface MonthlyViewProps extends CalendarProps {
    currentDate: Date;
}

const MonthlyView: React.FC<MonthlyViewProps> = ({ posts, currentDate }) => {
    const [selectedDay, setSelectedDay] = useState<Date | null>(null);

    // Start and end dates for the calendar grid
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(currentDate);
    const calendarStart = startOfWeek(monthStart, { weekStartsOn: 1 }); // Monday
    const calendarEnd = endOfWeek(monthEnd, { weekStartsOn: 1 }); // Sunday

    // Generate an array of dates for the calendar grid
    const totalDays = [];
    let day = calendarStart;
    while (compareAsc(day, calendarEnd) <= 0) {
        totalDays.push(day);
        day = addDays(day, 1);
    }

    // Split the days into weeks
    const weeks = [];
    for (let i = 0; i < totalDays.length; i += 7) {
        weeks.push(totalDays.slice(i, i + 7));
    }

    const handleMoreClick = (day: Date) => {
        setSelectedDay(day);
    };

    const closeDayView = () => {
        setSelectedDay(null);
    };

    const renderDayCell = (day: Date) => {
        const dayPosts = posts
            .filter((post) => isSameDay(new Date(post.scheduledFor), day))
            .sort((a, b) =>
                compareAsc(new Date(a.scheduledFor), new Date(b.scheduledFor))
            );

        // Show up to 3 posts
        const postsToShow = dayPosts.slice(0, 2);
        const hasMorePosts = dayPosts.length > 2;

        const isToday = isSameDay(day, new Date());
        const isCurrentMonth = isSameMonth(day, currentDate);

        return (
            <div
                key={day.toString()}
                className={`flex-1 min-w-[14.28%] p-1 border border-gray-200 relative`}
                style={{
                    backgroundColor: isToday
                        ? "rgba(240, 223, 175, 0.50)"
                        : isCurrentMonth
                        ? "transparent"
                        : "#F0F0F0", // Slightly gray background for non-current month days
                    minHeight: "150px",
                }}
            >
                <div className="absolute top-2 left-2 text-xs font-semibold">
                    {format(day, "d")}
                </div>
                <div className="mt-6 space-y-1">
                    {postsToShow.map((post, index) => (
                        <PostCard date={format(day, "EEE-d")} idx={index} key={post.id} post={post} />
                    ))}
                    {hasMorePosts && (
                        <div
                            className="flex justify-center items-center cursor-pointer mt-2"
                            onClick={() => handleMoreClick(day)}
                        >
                            <HiOutlineDotsHorizontal
                                size={20}
                                className="text-primary"
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="flex flex-col">
                {weeks.map((week, index) => (
                    <div key={index} className="flex">
                        {week.map(renderDayCell)}
                    </div>
                ))}
            </div>
            {selectedDay && (
                <DayView
                    day={selectedDay}
                    posts={posts}
                    onClose={closeDayView}
                />
            )}
        </>
    );
};

export default MonthlyView;
