import axios from "axios";

export async function checkEmailExists(email: string): Promise<boolean> {
    try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/check-email`;
        const response = await axios.post(
            url,
            { email },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        console.log("Email check response:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error checking email:", error);
        return false;
    }
}
