import React, { useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../misc/Dialog";
import { Button } from "../../misc/Button";
import { Clock, Home, PauseCircle, RefreshCw, X } from "lucide-react";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { getAuthHeader } from "../../../utils/authHeader";
import { toast } from "react-toastify";
import CustomToast from "../../misc/CustomToast";
import axios from "axios";
import { Input } from "../../misc/Input";
import { useNavigate } from "react-router-dom";
import Spinner from "../../misc/Spinner";

interface NONMLSWarningProps {
    listingId: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    setDisplayed: (displayed: boolean) => void;
    convertError: boolean;
    setConvertError: (error: boolean) => void;
}

const NonMLSWarning: React.FC<NONMLSWarningProps> = ({
    listingId,
    open,
    setOpen,
    setDisplayed,
    convertError,
    setConvertError,
}) => {
    const navigate = useNavigate();

    const [mlsId, setMlsId] = useState("");
    const [converting, setConverting] = useState(false);

    // Handler for converting to MLS Listing
    const handleConvertToMLS = async () => {
        setConverting(true);
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const headers = await getAuthHeader();

        try {
            const response = await axios.post(
                `${backendUrl}/listing/convert/`,
                { listingId, mlsId: convertError ? mlsId : undefined },
                { headers }
            );

            if (response.status === 200) {
                navigate("/listings");
                setOpen(false);
                setDisplayed(true);
                setConvertError(false);
            } else {
                throw new Error("Failed to convert to MLS");
            }
        } catch (error) {
            setConvertError(true);
            console.error("Error converting to MLS:", error);
            toast.error(
                <CustomToast
                    message="Error converting to MLS. Please try again later."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        } finally {
            setConverting(false);
        }
    };

    const isMobile = useIsMobile(900);

    const handleClose = () => {
        setOpen(false);
        setDisplayed(true);
    };

    return (
        <div className="relative">
            <Dialog
                open={open}
                onOpenChange={(isOpen) => {
                    if (!isOpen) {
                        setDisplayed(true);
                    }
                    setOpen(isOpen);
                }}
            >
                <DialogContent className="relative min-h-[200px] flex flex-col justify-between bg-white">
                    {converting && (
                        <div className="absolute inset-0 z-10 bg-white bg-opacity-75 flex items-center justify-center">
                            <Spinner loadingMessage="Converting..." />
                        </div>
                    )}
                    <DialogHeader>
                        <div className="flex items-center justify-between">
                            <DialogTitle className="text-2xl font-semibold text-primary">
                                {convertError
                                    ? "Failed to convert listing"
                                    : "Enhance Your Listing with MLS Connection "}
                                {!convertError && (
                                    <span className="text-sm">
                                        (included in your plan)
                                    </span>
                                )}
                            </DialogTitle>
                            <Button
                                variant="ghost"
                                size="icon"
                                className="h-6 w-6 rounded-full"
                                onClick={handleClose}
                            >
                                <X className="h-4 w-4 text-red-500" />
                                <span className="sr-only">Close</span>
                            </Button>
                        </div>
                    </DialogHeader>
                    <div className="py-6">
                        {!convertError ? (
                            <>
                                <p className="text-center text-gray-700">
                                    Gain access to powerful features and ensure
                                    your listing is always up-to-date.
                                </p>
                                <p className="text-center text-gray-700 mb-6">
                                    Transform your listing by connecting to the
                                    MLS.
                                </p>
                                <div
                                    className={`grid ${
                                        isMobile ? "grid-cols-1" : "grid-cols-2"
                                    } gap-4`}
                                >
                                    <FeatureItem
                                        icon={<Clock className="h-6 w-6" />}
                                        title="Real-time Updates"
                                        description="Listing data and images sync automatically with your MLS."
                                    />
                                    <FeatureItem
                                        icon={<RefreshCw className="h-6 w-6" />}
                                        title="Manual Sync Option"
                                        description="Sync your listing on-demand whenever you need to refresh the data."
                                    />
                                    <FeatureItem
                                        icon={
                                            <PauseCircle className="h-6 w-6" />
                                        }
                                        title="Automatic Campaign Management"
                                        description="The campaign will pause when the status changes from Active to any other state."
                                    />
                                    <FeatureItem
                                        icon={<Home className="h-6 w-6" />}
                                        title="Seamless Sold Process"
                                        description="Automatically handles the sold process when the listing is marked as sold in MLS."
                                    />
                                </div>
                                <div className="bg-gray-100 p-3 mt-10 rounded-md">
                                    <p className="text-sm text-red-500">
                                        <strong className="text-primary">
                                            Note:
                                        </strong>{" "}
                                        Existing campaign posts will not be
                                        changed or deleted. The updated MLS data
                                        will be used during the next campaign
                                        renewal. plan.
                                    </p>
                                </div>
                            </>
                        ) : (
                            <div className="space-y-4">
                                <p className="text-center text-gray-700">
                                    We couldn't automatically convert your
                                    listing. Please enter your MLS ID to
                                    continue.
                                </p>
                                <Input
                                    type="text"
                                    placeholder="Enter MLS ID"
                                    value={mlsId}
                                    onChange={(e) => setMlsId(e.target.value)}
                                    className="w-full"
                                />
                            </div>
                        )}
                    </div>
                    <DialogFooter>
                        <Button
                            onClick={handleConvertToMLS}
                            disabled={convertError && !mlsId}
                        >
                            {convertError
                                ? "Convert with MLS ID"
                                : "Convert to MLS Listing"}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default NonMLSWarning;

function FeatureItem({
    icon,
    title,
    description,
}: {
    icon: React.ReactNode;
    title: string;
    description: string;
}) {
    return (
        <div className="flex items-start space-x-3">
            <div className="flex-shrink-0 text-secondary">{icon}</div>
            <div>
                <h3 className="font-semibold text-primary">{title}</h3>
                <p className="mt-1 text-sm text-gray-500">{description}</p>
            </div>
        </div>
    );
}
