import Dexie, { Table } from "dexie";

export interface ImageRecord {
    key: string;
    blob: Blob;
}

class ImageCacheDB extends Dexie {
    images!: Table<ImageRecord, string>;

    constructor() {
        super("ImageCacheDB");
        this.version(1).stores({
            images: "key",
        });
        console.log("Dexie database initialized.");
    }
}

export const db = new ImageCacheDB();

export const addImage = async (key: string, blob: Blob): Promise<void> => {
    try {
        await db.images.put({ key, blob });
        console.log(`Image added to cache with key: ${key}`);
    } catch (error) {
        console.error(`Failed to add image with key ${key}:`, error);
        throw error;
    }
};

export const getImage = async (key: string): Promise<Blob | undefined> => {
    try {
        const record = await db.images.get(key);
        if (record) {
            console.log(`Image retrieved from cache with key: ${key}`);
            return record.blob;
        } else {
            console.log(`No cached image found with key: ${key}`);
            return undefined;
        }
    } catch (error) {
        console.error(`Failed to get image with key ${key}:`, error);
        throw error;
    }
};

export const clearImage = async (key: string): Promise<void> => {
    try {
        await db.images.delete(key);
        console.log(`Image cleared from cache with key: ${key}`);
    } catch (error) {
        console.error(`Failed to clear image with key ${key}:`, error);
        throw error;
    }
};

export const clearAllImages = async (): Promise<void> => {
    try {
        await db.images.clear();
        console.log("All images cleared from cache.");
    } catch (error) {
        console.error("Failed to clear all images:", error);
        throw error;
    }
};
