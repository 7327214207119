import React from "react";
import { X } from "lucide-react";
import { ContactModalForm } from "./ContactModalForm";

interface ContactModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const ContactModal: React.FC<ContactModalProps> = ({
    isOpen,
    onClose,
}) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-screen items-center justify-center p-4">
                <div
                    className="fixed inset-0 bg-black bg-opacity-25"
                    onClick={onClose}
                />

                <div className="relative bg-white w-full max-w-md rounded-lg shadow-lg">
                    <div className="flex justify-between items-center p-4 border-b">
                        <h3 className="text-lg font-medium text-primary">
                            Contact Sales
                        </h3>
                        <button
                            onClick={onClose}
                            className="text-gray-400 hover:text-gray-500"
                        >
                            <X className="h-5 w-5" />
                        </button>
                    </div>

                    <ContactModalForm onClose={onClose} />
                </div>
            </div>
        </div>
    );
};
