import React, { useEffect, useState, useRef } from "react";
import { MusicSelectorProps } from "./types";
import { Label } from "../../misc/Label";
import { Button } from "../../misc/Button";
import { FaMusic, FaPause, FaPlay } from "react-icons/fa";
import { Switch } from "../../misc/Switch";
import { getAuthHeader } from "../../../utils/authHeader";
import axios from "axios";
import CustomToast from "../../misc/CustomToast";
import { toast } from "react-toastify";

const Music: React.FC<MusicSelectorProps> = ({
    includeMusic,
    setIncludeMusic,
    setLoading,
    music,
    setMusic,
}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef<HTMLAudioElement | null>(null);

    const toggleMusic = () => {
        if (!audioRef.current) return;

        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play().catch((error) => {
                toast.error(
                    <CustomToast
                        message="Failed to play the music."
                        type="error"
                    />,
                    {
                        autoClose: 3000,
                    }
                );
                console.error("Audio play error:", error);
            });
        }

        setIsPlaying((prev) => !prev);
    };

    useEffect(() => {
        const fetchMusic = async () => {
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            try {
                const response = await axios.get(
                    `${backendUrl}/listing/slideshow/music`,
                    {
                        headers,
                    }
                );

                if (response.status === 200) {
                    const musicUrl: string = response.data;
                    setMusic(musicUrl);
                    if (musicUrl) {
                        audioRef.current = new Audio(musicUrl);
                        audioRef.current.loop = true;
                        audioRef.current.addEventListener("ended", () =>
                            setIsPlaying(false)
                        );
                    }
                } else {
                    toast.error(
                        <CustomToast
                            message="An error occurred while fetching music."
                            type="error"
                        />,
                        {
                            autoClose: 3000,
                        }
                    );
                }
            } catch (error: unknown) {
                setLoading(false);
                if (axios.isAxiosError(error)) {
                    if (error.response?.status !== 404) {
                        toast.error(
                            <CustomToast
                                message="An error occurred while fetching music."
                                type="error"
                            />,
                            {
                                autoClose: 3000,
                            }
                        );
                    }
                } else {
                    toast.error(
                        <CustomToast
                            message="An unexpected error occurred."
                            type="error"
                        />,
                        {
                            autoClose: 3000,
                        }
                    );
                }
            } finally {
                setLoading(false);
            }
        };
        fetchMusic();
    }, [setLoading, setMusic]);

    // Cleanup the audio on unmount
    useEffect(() => {
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null;
            }
        };
    }, []);

    return (
        <div className="flex items-center space-x-4">
            <Switch
                id="music"
                checked={includeMusic}
                onCheckedChange={(checked) => {
                    setIncludeMusic(checked);
                    if (!checked && isPlaying) {
                        toggleMusic();
                    }
                }}
            />
            <Label htmlFor="music">
                Include Background Music - Add music to the Slideshow
            </Label>
            {includeMusic && music && (
                <Button variant="outline" onClick={toggleMusic}>
                    <FaMusic className="h-4 w-4 text-primary" />
                    <span>Alison</span>
                    {isPlaying ? (
                        <FaPause className="h-4 w-4 text-secondary" />
                    ) : (
                        <FaPlay className="h-4 w-4 text-secondary" />
                    )}
                </Button>
            )}
        </div>
    );
};

export default Music;
