import LoadingScreen from "./LoadingScreen";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getAuthHeader } from "../utils/authHeader";
import axios from 'axios';

export const ConnectionsRedirect = () => {
    const navigate = useNavigate();
    useEffect( () => {
        const refreshSocials = async () => {
            let headers;
            Promise.all([
                headers = await getAuthHeader(),
                await new Promise(r => setTimeout(r, 4000))
            ])
            
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            await axios.get(`${backendUrl}/socials/sync-profiles`, {headers});
            navigate('/settings/connections');
        };
        
        refreshSocials();
    });
    return (
        <div>
            <LoadingScreen loadingMessage="Syncing your connections"/>
        </div>
    );
};