import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RiBillLine, RiBillFill } from "react-icons/ri";
import { useIsMobile } from "../../hooks/useIsMobile";
import { TbLayoutDashboard, TbLayoutDashboardFilled } from "react-icons/tb";
import { PiUsers } from "react-icons/pi";
import { LiaUsersCogSolid } from "react-icons/lia";
import { BsFileBarGraph, BsFileBarGraphFill } from "react-icons/bs";
import {
    HiMiniWrenchScrewdriver,
    HiOutlineWrenchScrewdriver,
} from "react-icons/hi2";

const AdminSideBar = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const isMobile = useIsMobile(900);

    const menuItems = [
        {
            name: "Dashboard",
            path: "/admin",
            icon: TbLayoutDashboard,
            filledIcon: TbLayoutDashboardFilled,
        },
        {
            name: "Manage Users",
            path: "/admin/manage-users",
            icon: PiUsers,
            filledIcon: LiaUsersCogSolid,
        },
        {
            name: "Manage Plans",
            path: "/admin/manage-plans",
            icon: RiBillLine,
            filledIcon: RiBillFill,
        },
        {
            name: "Statistics",
            path: "/admin/statistics",
            icon: BsFileBarGraph,
            filledIcon: BsFileBarGraphFill,
        },
        {
            name: "Maintenance Mode",
            path: "/admin/maintenance",
            icon: HiOutlineWrenchScrewdriver,
            filledIcon: HiMiniWrenchScrewdriver,
        },
    ];

    return (
        <div
            onMouseEnter={() => setIsExpanded(true)}
            onMouseLeave={() => setIsExpanded(false)}
            className={`
                bg-primary
                text-white 
                transition-all 
                duration-500 
                ease-in-out
                overflow-hidden
                ${
                    isMobile
                        ? "fixed bottom-0 left-0 w-full h-20"
                        : `h-full ${isExpanded ? "w-64" : "w-16"}`
                }
                z-50
            `}
        >
            <div
                className={`
                    flex ${isMobile ? "flex-row" : "flex-col"}
                    ${isMobile ? "justify-around" : "justify-between"}
                    ${isMobile ? "items-center" : "items-start"}
                    h-full p-2
                `}
            >
                <div
                    className={`
                        flex ${isMobile ? "flex-row w-full" : "flex-col"}
                        ${isMobile ? "justify-around" : "items-start"}
                        ${isMobile ? "" : "space-y-8"}
                        w-full
                    `}
                >
                    {menuItems.map((item, index) => (
                        <div
                            key={index}
                            className={`
                                flex ${isMobile ? "flex-col" : "flex-row"} 
                                items-center cursor-pointer 
                                hover:bg-[#F0DFAF] ${
                                    location.pathname === item.path
                                        ? "text-secondary"
                                        : "text-white"
                                } hover:text-primary p-2 rounded-md transition-colors duration-300 ease-in-out
                                ${isMobile ? "flex-1 justify-center" : "w-full"}
                                ${
                                    !isExpanded && !isMobile
                                        ? "justify-center"
                                        : ""
                                }
                            `}
                            onClick={() => navigate(item.path)}
                            style={{
                                height: isMobile ? "100%" : "auto",
                            }}
                        >
                            <div className="flex-shrink-0">
                                {location.pathname === item.path ? (
                                    <item.filledIcon
                                        size={isMobile ? 24 : 24} // Keep icon size consistent
                                        className="transition-transform duration-300 ease-in-out text-current"
                                    />
                                ) : (
                                    <item.icon
                                        size={isMobile ? 24 : 24} // Keep icon size consistent
                                        className="transition-transform duration-300 ease-in-out text-current"
                                    />
                                )}
                            </div>
                            {isMobile ? (
                                <span className="text-xs mt-1 text-center whitespace-nowrap">
                                    {item.name}
                                </span>
                            ) : (
                                isExpanded && (
                                    <span className="text-lg whitespace-nowrap transition-opacity duration-300 ease-in-out opacity-100 delay-300 ml-4">
                                        {item.name}
                                    </span>
                                )
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AdminSideBar;
