import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "../components/misc/Card";
import { Users } from "lucide-react";
import { DashboardData } from "./types";
import { getAuthHeader } from "../utils/authHeader";
import { toast } from "react-toastify";
import CustomToast from "../components/misc/CustomToast";
import axios from "axios";
import LoadingScreen from "../screens/LoadingScreen";

const Dashboard = () => {
    const [userData, setUserData] = useState<DashboardData | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDashboardData = async () => {
            setLoading(true);

            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            try {
                const response = await axios.get(
                    `${backendUrl}/admin/dashboard/user-count`,
                    {
                        headers,
                    }
                );

                if (response.status === 200) {
                    setUserData(response.data);
                } else {
                    console.error(
                        "Failed to fetch dashboard data. Please try again later."
                    );
                    throw new Error(
                        "Failed to fetch dashboard data. Please try again later."
                    );
                }
            } catch (error: unknown) {
                toast.error(
                    <CustomToast
                        message="Failed to fetch dashboard data. Please try again later."
                        type="error"
                    />,
                    { autoClose: 3000 }
                );
            } finally {
                setLoading(false);
            }
        };

        fetchDashboardData();
    }, []);

    return (
        <div className="p-6 grid gap-6 md:grid-cols-2 lg:grid-cols-4">
            {loading && <LoadingScreen />}
            <Card className="max-w-80 mt-0">
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-sm font-medium">
                        Total Users
                    </CardTitle>
                    <Users className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent className="pt-0">
                    <div className="text-2xl font-bold text-primary">
                        {userData?.totalUsers}
                    </div>
                    <p className="text-xs text-muted-foreground">
                        {userData?.percent} ({userData?.lastMonthUsers}) from
                        last month
                    </p>
                </CardContent>
            </Card>
        </div>
    );
};

export default Dashboard;
