import React from "react";

interface MediaProps {
    src: string;
    type: "image" | "video";
    alt?: string;
    isMobile: boolean;
    onPlay?: () => void;
}

const Media = React.memo(
    React.forwardRef<HTMLVideoElement | HTMLImageElement, MediaProps>(
        ({ src, type, alt, isMobile, onPlay }, ref) => {
            return type === "image" ? (
                <img
                    ref={ref as React.Ref<HTMLImageElement>}
                    src={src}
                    alt={alt}
                    className={`w-full h-full ${
                        isMobile ? "object-cover" : "object-contain"
                    }`}
                    loading="lazy"
                />
            ) : (
                <video
                    ref={ref as React.Ref<HTMLVideoElement>}
                    src={src}
                    className={`w-full h-full ${
                        isMobile ? "object-cover" : "object-fit"
                    }`}
                    controls
                    preload="metadata"
                    muted
                    onPlay={onPlay}
                />
            );
        }
    )
);

Media.displayName = "Media";

export default Media;
