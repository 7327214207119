import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { AgentDetails as AgentDetailsType } from "../../types/onboarding";
import InputField from "../misc/InputField";
import { Button } from "../misc/Button";
import StepProgressBar from "../progress-bar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/images/logo.png";
import CustomToast from "../misc/CustomToast";
import { isValidPhoneNumber } from "../../utils/validationUtil";
import LoadingScreen from "../../screens/LoadingScreen";
import CustomSelect from "../misc/CustomSelect";
import { STATE_CODE_TO_NAME, US_STATES } from "../../utils/states";
import { getAuthHeader } from "../../utils/authHeader";
import AddressAutocomplete from "../misc/AddressAutocomplete";
import { TIME_ZONES, TimeZoneOption } from "./utils/timeZones";
import { ActionMeta, SingleValue } from "react-select";
import { OptionType } from "../../types/option-type";
import { useIsMobile } from "../../hooks/useIsMobile";

interface AgentDetailsProps {
    advanceStep: () => void;
    role: string | null;
}

const AgentDetails: React.FC<AgentDetailsProps> = ({ advanceStep, role }) => {
    const [agentDetails, setAgentDetails] = useState<AgentDetailsType>({
        firstName: "",
        lastName: "",
        realtorLicenseNumber: "",
        companyName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        timeZone: "",
        phoneNumber: "",
    });
    const [initialDetails, setInitialDetails] =
        useState<AgentDetailsType | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [addressSelected, setAddressSelected] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const fetchAgentDetails = async () => {
            try {
                setLoading(true);
                const headers = await getAuthHeader();
                const backendUrl = process.env.REACT_APP_BACKEND_URL;
                const response = await axios.get(
                    `${backendUrl}/onboarding/agent-details`,
                    {
                        headers,
                        withCredentials: true,
                    }
                );
                if (
                    response.data.address1 &&
                    response.data.city &&
                    response.data.state &&
                    response.data.zipCode
                ) {
                    setAddressSelected(true);
                }
                setAgentDetails(response.data);
                setInitialDetails(response.data);

                if (!response.data.timeZone) {
                    setAgentDetails((prevDetails) => ({
                        ...prevDetails,
                    }));
                }
            } catch (error) {
                console.error("Error fetching agent details:", error);
                toast.error(
                    <CustomToast
                        message="Failed to fetch agent details. Please try again."
                        type="error"
                    />,
                    {
                        autoClose: 3000,
                        position: "top-right",
                    }
                );
            } finally {
                setLoading(false);
            }
        };

        fetchAgentDetails();
    }, []);

    const hasChanges = useCallback(() => {
        if (!initialDetails) return false;
        return JSON.stringify(agentDetails) !== JSON.stringify(initialDetails);
    }, [agentDetails, initialDetails]);

    const validateFields = (): boolean => {
        const newErrors: { [key: string]: string } = {};
        const {
            firstName,
            lastName,
            realtorLicenseNumber,
            companyName,
            address1,
            city,
            state,
            zipCode,
            phoneNumber,
            timeZone,
        } = agentDetails;

        if (!firstName?.trim()) newErrors.firstName = "First name is required.";
        if (!lastName?.trim()) newErrors.lastName = "Last name is required.";
        if (!realtorLicenseNumber?.trim())
            newErrors.realtorLicenseNumber = "License number is required.";
        if (!companyName?.trim())
            newErrors.companyName = "Company name is required.";
        if (!address1?.trim()) newErrors.address1 = "Address is required.";
        if (!city?.trim()) newErrors.city = "City is required.";
        if (!state?.trim()) newErrors.state = "State is required.";
        if (!zipCode?.trim()) newErrors.zipCode = "Zip code is required.";
        if (!phoneNumber?.trim()) {
            newErrors.phoneNumber = "Phone number is required.";
        } else if (!isValidPhoneNumber(phoneNumber)) {
            newErrors.phoneNumber = "Invalid phone number format.";
        }
        if (!timeZone?.trim()) newErrors.timeZone = "Time zone is required.";

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            toast.error(
                <CustomToast
                    message="Please fix the highlighted fields."
                    type="error"
                />,
                { autoClose: 3000, position: "top-right" }
            );

            // Scroll to the first error field
            const firstErrorField = document.querySelector(
                `[name="${Object.keys(newErrors)[0]}"]`
            );
            if (firstErrorField) {
                firstErrorField.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
                (firstErrorField as HTMLElement).focus();
            }

            return false;
        }

        return true;
    };

    const handleSaveAndNext = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateFields()) return;

        try {
            setLoading(true);
            if (hasChanges()) {
                const headers = await getAuthHeader();
                const backendUrl = process.env.REACT_APP_BACKEND_URL;

                await axios.patch(
                    `${backendUrl}/onboarding/agent-details`,
                    { ...agentDetails },
                    {
                        headers,
                        withCredentials: true,
                    }
                );
            }

            advanceStep();
        } catch (error) {
            console.error("Error saving agent details:", error);
            toast.error(
                <CustomToast
                    message="Failed to save agent details. Please try again later!"
                    type="error"
                />,
                {
                    autoClose: 3000,
                    position: "top-right",
                }
            );
        } finally {
            setLoading(false);
        }
    };

    // Handle address selection from AddressAutocomplete
    const handleAddressSelect = (selectedAddress: {
        address1: string;
        address2: string;
        city: string;
        state: string;
        zipCode: string;
    }) => {
        setAgentDetails((prevDetails) => ({
            ...prevDetails,
            ...selectedAddress,
        }));
        setAddressSelected(true);
    };

    const progressSteps = [
        { label: "Agent Details", isActive: true, isCompleted: false },
        { label: "Broker Details", isActive: false, isCompleted: false },
        { label: "Choose MLS", isActive: false, isCompleted: false },
    ];

    if (role !== "TEAM_USER") {
        progressSteps.push({
            label: "Select Plan",
            isActive: false,
            isCompleted: false,
        });
        progressSteps.push({
            label: "Payment",
            isActive: false,
            isCompleted: false,
        });
    }

    const handleStateChange = (
        selectedOption: { label: string; value: string } | null
    ) => {
        setAgentDetails((prevDetails) => ({
            ...prevDetails,
            state: selectedOption ? selectedOption.value : "",
        }));
        // Clear any previous error
        setErrors((prevErrors) => ({
            ...prevErrors,
            state: "",
        }));
    };

    const handleTimeZoneChange = (
        option: SingleValue<OptionType>,
        actionMeta: ActionMeta<OptionType>
    ) => {
        setAgentDetails((prevDetails) => ({
            ...prevDetails,
            timeZone: option ? option.value : "",
        }));
        // Clear any previous error
        setErrors((prevErrors) => ({
            ...prevErrors,
            timeZone: "",
        }));
    };

    // Determine the default time zone
    const getDefaultTimeZone = (): TimeZoneOption | null => {
        if (!agentDetails.timeZone) return null;

        const current: TimeZoneOption | undefined = TIME_ZONES.find(
            (tz: TimeZoneOption) => tz.value === agentDetails.timeZone
        );

        if (current) return current;

        return null;
    };

    const isMobile = useIsMobile(900);

    return (
        <main className="flex flex-col justify-center items-center min-h-screen bg-background shadow-[0px_4px_4px_rgba(0,0,0,0.25)]">
            <section
                className={`flex flex-col items-center  py-20 bg-white ${
                    !isMobile ? "rounded-2xl px-14" : "px-10"
                } w-full max-w-[905px] max-md:px-5 max-md:py-10 max-md:w-full relative space-y-6`}
            >
                {loading && <LoadingScreen />}

                <header className="flex flex-col items-center w-full max-md:w-full space-y-4">
                    <img
                        loading="lazy"
                        src={logo}
                        alt="Agent Registration Logo"
                        className="object-contain self-center max-w-full w-[395px] aspect-[8.77] max-md:w-3/4"
                    />
                    <h1 className="my-2 text-primary text-2xl font-bold leading-none text-center">
                        User Onboarding
                    </h1>
                    <StepProgressBar
                        steps={progressSteps.filter((step) => step)}
                    />
                </header>

                <form
                    className="flex flex-col mx-auto mt-2 w-full max-md:w-full space-y-6"
                    onSubmit={handleSaveAndNext}
                >
                    {/* Name Fields */}
                    <div className="flex flex-col md:flex-row gap-6 items-center w-full max-md:flex-col">
                        <span className="text-neutral-500 text-sm w-full">
                            The First and Last name should match your Real
                            Estate License.
                        </span>
                        <InputField
                            name="firstName"
                            label="First Name"
                            placeholder="Ex: John"
                            type="text"
                            value={agentDetails.firstName}
                            onChange={(e) =>
                                setAgentDetails({
                                    ...agentDetails,
                                    firstName: e.target.value,
                                })
                            }
                            errorMessage={errors.firstName}
                            className="w-full"
                            autocomplete={false}
                        />
                        <InputField
                            name="lastName"
                            label="Last Name"
                            placeholder="Ex: Doe"
                            type="text"
                            value={agentDetails.lastName}
                            onChange={(e) =>
                                setAgentDetails({
                                    ...agentDetails,
                                    lastName: e.target.value,
                                })
                            }
                            errorMessage={errors.lastName}
                            className="w-full"
                            autocomplete={false}
                        />
                    </div>

                    {/* Phone Number */}
                    <InputField
                        name="phoneNumber"
                        label="Phone Number"
                        placeholder="Ex: (878) 231-6060"
                        type="text"
                        value={agentDetails.phoneNumber}
                        onChange={(e) =>
                            setAgentDetails({
                                ...agentDetails,
                                phoneNumber: e.target.value,
                            })
                        }
                        errorMessage={errors.phoneNumber}
                        className="w-full md:w-1/2"
                        autocomplete={false}
                    />

                    {/* Real Estate License Number */}
                    <InputField
                        name="realtorLicenseNumber"
                        label="Real Estate License Number"
                        placeholder="License Number"
                        type="text"
                        value={agentDetails.realtorLicenseNumber}
                        onChange={(e) =>
                            setAgentDetails({
                                ...agentDetails,
                                realtorLicenseNumber: e.target.value,
                            })
                        }
                        errorMessage={errors.realtorLicenseNumber}
                        className="mt-0"
                        autocomplete={false}
                    />

                    {/* Company Name */}
                    <InputField
                        name="companyName"
                        label="Company Name"
                        placeholder="Ex: iSpeak AI"
                        type="text"
                        value={agentDetails.companyName}
                        onChange={(e) =>
                            setAgentDetails({
                                ...agentDetails,
                                companyName: e.target.value,
                            })
                        }
                        errorMessage={errors.companyName}
                        className="mt-0"
                        autocomplete={false}
                    />

                    {/* Address Autocomplete */}
                    {!addressSelected ? (
                        <AddressAutocomplete
                            onAddressSelect={handleAddressSelect}
                            label={"Company Address"}
                            className="mt-0"
                            errorMessage={
                                errors.address1 ||
                                errors.city ||
                                errors.state ||
                                errors.zipCode
                            }
                        />
                    ) : (
                        <>
                            <h2 className="text-lg font-semibold text-primary">
                                Enter Property Address
                            </h2>

                            <AddressAutocomplete
                                label="Address 1"
                                placeholder="Ex: 5000 Stonewood Dr"
                                value={agentDetails.address1}
                                onInputChange={(value: string) =>
                                    setAgentDetails({
                                        ...agentDetails,
                                        address1: value,
                                    })
                                }
                                onAddressSelect={(address) => {
                                    setAgentDetails({
                                        ...agentDetails,
                                        ...address,
                                    });
                                    setAddressSelected(true);
                                }}
                                className="mt-2"
                                errorMessage={errors.address1}
                            />

                            <InputField
                                name="address2"
                                label="Address 2"
                                placeholder="Apartment, suite, unit, etc."
                                type="text"
                                value={agentDetails.address2}
                                onChange={(e) =>
                                    setAgentDetails({
                                        ...agentDetails,
                                        address2: e.target.value,
                                    })
                                }
                                errorMessage={errors.address2}
                                className="mt-2"
                                autocomplete={false}
                            />

                            <div className="flex flex-col gap-2 w-full mt-4">
                                <div className="flex flex-col md:flex-row gap-6 w-full">
                                    <InputField
                                        name="city"
                                        label="City"
                                        placeholder="Ex: Wexford"
                                        type="text"
                                        value={agentDetails.city}
                                        onChange={(e) =>
                                            setAgentDetails({
                                                ...agentDetails,
                                                city: e.target.value,
                                            })
                                        }
                                        errorMessage={errors.city}
                                        className="w-full md:w-1/2"
                                        autocomplete={false}
                                    />
                                    <CustomSelect
                                        id="state-select-dropdown"
                                        label="State"
                                        options={US_STATES.map((state) => ({
                                            label: `${state.label} - ${state.value}`,
                                            value: state.value,
                                        }))}
                                        value={
                                            agentDetails.state &&
                                            STATE_CODE_TO_NAME[
                                                agentDetails.state
                                            ]
                                                ? {
                                                      label: `${
                                                          STATE_CODE_TO_NAME[
                                                              agentDetails.state
                                                          ]
                                                      } - ${
                                                          agentDetails.state
                                                      }`,
                                                      value: agentDetails.state,
                                                  }
                                                : null
                                        }
                                        onChange={handleStateChange}
                                        placeholder="Select State"
                                        errorMessage={errors.state}
                                        className="w-full md:w-1/2 min-h-[60px]"
                                        isClearable={true}
                                    />
                                </div>

                                <div className="flex flex-col md:flex-row gap-6 w-full mt-4">
                                    <InputField
                                        name="zipCode"
                                        label="Zip Code"
                                        placeholder="Ex: 15090"
                                        type="text"
                                        value={agentDetails.zipCode}
                                        onChange={(e) =>
                                            setAgentDetails({
                                                ...agentDetails,
                                                zipCode: e.target.value,
                                            })
                                        }
                                        errorMessage={errors.zipCode}
                                        className="w-full md:w-1/2"
                                        autocomplete={false}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    {/* Time Zone Selection */}
                    <CustomSelect
                        id="timezone-select-dropdown"
                        label="Time Zone"
                        options={TIME_ZONES}
                        value={getDefaultTimeZone()}
                        onChange={handleTimeZoneChange}
                        placeholder="Select Time Zone"
                        errorMessage={errors.timeZone}
                        className="w-full md:w-1/2 min-h-[60px]"
                    />

                    {/* Submit Button */}
                    <Button className="mt-7 w-full md:w-auto" type="submit">
                        Next
                    </Button>
                </form>
            </section>
        </main>
    );
};

export default AgentDetails;
