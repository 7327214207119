import { useState } from "react";
import { toast } from "react-toastify";
import CustomToast from "../../misc/CustomToast";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../../misc/Card";
import { Label } from "../../misc/Label";
import { RadioGroup, RadioGroupItem } from "../../misc/RadioGroup";
import { Textarea } from "../../misc/Textarea";
import { Button } from "../../misc/Button";
import { TabsContent } from "../../misc/Tabs";
import { FaBug, FaLightbulb, FaQuestion, FaUserLock } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const issueTypes = [
    { value: "bug", label: "Bug Report", icon: <FaBug /> },
    { value: "feature", label: "Feature Request", icon: <FaLightbulb /> },
    { value: "account", label: "Account Issue", icon: <FaUserLock /> },
    { value: "other", label: "Other", icon: <FaQuestion /> },
];

export default function ContactForm() {
    const [issueType, setIssueType] = useState("");
    const [description, setDescription] = useState("");

    const user = useSelector((state: RootState) => state.auth.user);

    // const [attachment, setAttachment] = useState<File | null>(null);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!issueType || !description) {
            toast.error(
                <CustomToast
                    message="Please fill out all required fields."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }

        const recipient = "support@marabot.ai";
        const subject = encodeURIComponent(`New ${issueType} Submission`);
        const body = encodeURIComponent(
            `UserID: ${user?._id}\nUser Name: ${user?.nameFirst} ${user?.nameLast}\nUser Email: ${user?.email}\n\nIssue Type: ${issueType}\n\nDescription:\n${description}`
        );

        const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${body}`;

        window.open(mailtoLink, "_blank");

        toast.success(
            <CustomToast
                message="Email client opened. Please send your message."
                type="success"
            />,
            { autoClose: 3000 }
        );

        // Reset form
        setIssueType("");
        setDescription("");
        // setAttachment(null);
    };

    // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     if (e.target.files && e.target.files[0]) {
    //         setAttachment(e.target.files[0]);
    //     }
    // };

    return (
        <TabsContent value="contact">
            <Card className="w-full max-w-2xl mx-auto bg-gradient-to-br from-gray-50 to-gray-100 shadow-lg">
                <CardHeader className="space-y-1">
                    <CardTitle className="text-3xl font-bold text-center">
                        How Can We Help?
                    </CardTitle>
                    <CardDescription className="text-center text-lg">
                        We're here to assist you with any issues or suggestions.
                    </CardDescription>
                </CardHeader>
                <form onSubmit={handleSubmit}>
                    <CardContent className="space-y-6">
                        <div className="space-y-2">
                            <Label
                                htmlFor="issue-type"
                                className="text-lg font-semibold text-primary"
                            >
                                What's this about?
                            </Label>
                            <RadioGroup
                                value={issueType}
                                onValueChange={setIssueType}
                                className="grid grid-cols-2 gap-4"
                            >
                                {issueTypes.map((type) => (
                                    <Label
                                        key={type.value}
                                        htmlFor={type.value}
                                        className={`flex items-center space-x-3 p-4 border rounded-lg cursor-pointer transition-all duration-200 ease-in-out ${
                                            issueType === type.value
                                                ? "bg-secondary text-white shadow-md scale-105"
                                                : "bg-background text-primary hover:bg-lightSecondary"
                                        }`}
                                    >
                                        <RadioGroupItem
                                            value={type.value}
                                            id={type.value}
                                            className="sr-only"
                                        />
                                        <span className="text-2xl">
                                            {type.icon}
                                        </span>
                                        <span className="font-semibold">
                                            {type.label}
                                        </span>
                                    </Label>
                                ))}
                            </RadioGroup>
                        </div>
                        <div className="space-y-2">
                            <Label
                                htmlFor="description"
                                className="text-lg font-semibold text-primary"
                            >
                                Tell us more
                            </Label>
                            <Textarea
                                id="description"
                                placeholder="Please provide details about your issue or suggestion..."
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                                className="min-h-[150px] text-base resize-none"
                            />
                        </div>
                    </CardContent>
                    <CardFooter>
                        <Button type="submit" className="w-full text-lg h-12">
                            Next
                        </Button>
                    </CardFooter>
                </form>
            </Card>
        </TabsContent>
    );
}
