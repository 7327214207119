import React, { useEffect, useState } from "react";
import { FacebookPostProps } from "./types";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
    Globe2,
    MessageSquare,
    Share2,
    ThumbsUp,
    PlayCircle,
} from "lucide-react";
import { Button } from "../misc/Button";
import FacebookMediaGalleryOverlay from "./FacebookMediaGalleryOverlay";
import { MediaType } from "../../types/media";
import { useIsMobile } from "../../hooks/useIsMobile";
import { ConnectedSocialsData } from "../campaign/types";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";

const FacebookPost: React.FC<FacebookPostProps> = ({ post, setLoading }) => {
    const user = useSelector((state: RootState) => state.auth.user);

    const mediaCount = post.media?.length || 0;

    const [showGallery, setShowGallery] = useState(false);
    const [galleryInitialIndex, setGalleryInitialIndex] = useState(0);
    const [isExpanded, setIsExpanded] = useState(false);
    const [profileData, setProfileData] = useState<ConnectedSocialsData | null>(
        null
    );

    useEffect(() => {
        const fetchConnections = async () => {
            setLoading(true);
            try {
                const headers = await getAuthHeader();
                const backendUrl = process.env.REACT_APP_BACKEND_URL;

                const response = await axios.get(
                    `${backendUrl}/socials/profiles`,
                    {
                        headers,
                    }
                );

                const responseData = response.data.data || response.data;

                const facebookActive =
                    responseData.activeSocialAccounts.includes("facebook");
                if (facebookActive) {
                    const facebookProfile = responseData.displayNames.find(
                        (profile: any) => profile.platform === "facebook"
                    );

                    if (facebookProfile) {
                        const facebookData: ConnectedSocialsData = {
                            displayName: facebookProfile.displayName,
                            id: facebookProfile.id,
                            pageName: facebookProfile.pageName,
                            platform: facebookProfile.platform,
                            profileUrl: facebookProfile.profileUrl,
                            userImage: facebookProfile.userImage,
                            username: facebookProfile.username,
                        };

                        setProfileData(facebookData);
                    }
                }
            } catch (error) {
                let errorMessage =
                    "Error fetching social profiles. Please try again later.";
                if (axios.isAxiosError(error) && error.response) {
                    errorMessage += ` Server responded with status ${error.response.status}.`;
                }

                console.error(errorMessage);
            } finally {
                setLoading(false);
            }
        };

        fetchConnections();
    }, [setLoading]);

    const renderMediaItem = (index: number, className: string) => {
        const media = post.media[index];
        if (!media) return null;

        return (
            <div
                className={`cursor-pointer ${className} overflow-hidden`}
                onClick={() => handleMediaClick(index)}
            >
                {media.type === MediaType.AssetVideo ||
                media.type === MediaType.ListingVideo ? (
                    <div className="relative w-full h-full">
                        <video
                            muted
                            src={media.signedUrl}
                            className="object-cover w-full h-full"
                            playsInline
                        />
                        <div className="absolute inset-0 flex items-center justify-center">
                            <PlayCircle className="w-12 h-12 text-white opacity-75" />
                        </div>
                    </div>
                ) : (
                    <img
                        src={media.signedUrl}
                        alt={`Media ${index + 1}`}
                        className="object-cover w-full h-full"
                    />
                )}
            </div>
        );
    };

    const handleMediaClick = (index: number) => {
        setGalleryInitialIndex(index);
        setShowGallery(true);
    };

    const renderMedia = () => {
        if (!post.media || mediaCount === 0) return null;

        if (mediaCount === 1) {
            return (
                <div className="w-full aspect-video relative">
                    {renderMediaItem(0, "w-full h-full")}
                </div>
            );
        }

        if (mediaCount === 2) {
            return (
                <div className="grid grid-cols-2 gap-1">
                    {post.media.map((_, index) => (
                        <div key={index} className="aspect-square relative">
                            {renderMediaItem(index, "w-full h-full")}
                        </div>
                    ))}
                </div>
            );
        }

        if (mediaCount === 3) {
            return (
                <div className="grid grid-cols-2 gap-1">
                    {/* Top Media */}
                    <div className="col-span-2 aspect-video relative">
                        {renderMediaItem(0, "w-full h-full")}
                    </div>
                    {/* Bottom Media */}
                    <div className="aspect-square relative">
                        {renderMediaItem(1, "w-full h-full")}
                    </div>
                    <div className="aspect-square relative">
                        {renderMediaItem(2, "w-full h-full")}
                    </div>
                </div>
            );
        }

        if (mediaCount >= 4) {
            const displayCount = mediaCount > 4 ? 4 : mediaCount;
            const extraCount = mediaCount - 4;

            return (
                <div className="grid grid-cols-2 gap-1">
                    {Array.from({ length: displayCount }).map((_, index) => (
                        <div key={index} className="aspect-square relative">
                            {renderMediaItem(index, "w-full h-full")}
                            {index === 3 && extraCount > 0 && (
                                <div
                                    className="absolute inset-0 bg-black/50 flex items-center justify-center cursor-pointer"
                                    onClick={() => handleMediaClick(index)}
                                >
                                    <span className="text-white text-xl font-semibold">
                                        +{extraCount}
                                    </span>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    };

    const isMobile = useIsMobile(900);

    const CHARACTER_LIMIT = isMobile ? 150 : 300;

    // Function to toggle the expanded state
    const toggleExpand = () => {
        setIsExpanded((prev) => !prev);
    };

    // Function to render the post content
    const renderContent = () => {
        const content = post.content?.trim() || "";
        const paragraphs = content.split(/\n\n+/);
        const isContentTruncated = content.length > CHARACTER_LIMIT;

        if (!isContentTruncated) {
            return (
                <>
                    {paragraphs.map((para, index) => (
                        <p key={index} className="mb-2 min-h-[24px]">
                            {para}
                        </p>
                    ))}
                    {post.hasFooter && !isExpanded && (
                        <button
                            onClick={toggleExpand}
                            className="text-blue-500 hover:underline ml-1"
                            aria-label="Show more"
                        >
                            Show More
                        </button>
                    )}
                    {post.hasFooter && isExpanded && post.footer && (
                        <>
                            <div className="mt-2">
                                {post.footer.split(/\n+/).map((para, index) => (
                                    <p key={index}>{para}</p>
                                ))}
                            </div>
                            <button
                                onClick={toggleExpand}
                                className="text-blue-500 hover:underline ml-1"
                                aria-label="Show less"
                            >
                                Show Less
                            </button>
                        </>
                    )}
                </>
            );
        }

        if (isExpanded) {
            return (
                <>
                    {paragraphs.map((para, index) => (
                        <p key={index} className="mb-2 min-h-[24px]">
                            {para}
                        </p>
                    ))}
                    {post.hasFooter && post.footer && (
                        <div className="mt-2">
                            {post.footer.split(/\n+/).map((para, index) => (
                                <p
                                    key={index}
                                    className={
                                        para.trim().toLowerCase() ===
                                        "listed by:"
                                            ? "mt-2"
                                            : ""
                                    }
                                >
                                    {para}
                                </p>
                            ))}
                        </div>
                    )}
                    <button
                        onClick={toggleExpand}
                        className="text-blue-500 hover:underline ml-1"
                        aria-label="Show less"
                    >
                        Show Less
                    </button>
                </>
            );
        } else {
            let truncatedContent = content.slice(0, CHARACTER_LIMIT);
            const lastSpace = truncatedContent.lastIndexOf(" ");
            if (lastSpace > 0) {
                truncatedContent = truncatedContent.slice(0, lastSpace);
            }
            const truncatedParagraphs = truncatedContent
                .split(/\n+/)
                .map((para, index) => (
                    <p key={index} className="mb-2 min-h-[24px]">
                        {para}
                    </p>
                ));
            return (
                <>
                    {truncatedParagraphs}
                    <button
                        onClick={toggleExpand}
                        className="text-blue-500 hover:underline ml-1"
                        aria-label="Read more"
                    >
                        Read More
                    </button>
                </>
            );
        }
    };

    return (
        <div className="max-w-[680px] mx-auto bg-white shadow-xl rounded-lg overflow-hidden">
            {/* Post Header */}
            <div className="p-4 flex items-start justify-between">
                <div className="flex gap-2">
                    {profileData?.userImage ||
                    user?.profileImage ||
                    user?.photoURL ? (
                        <img
                            src={
                                profileData?.userImage ||
                                user?.profileImage ||
                                user?.photoURL ||
                                undefined
                            }
                            width={40}
                            height={40}
                            alt="Profile"
                            className="rounded-full object-cover"
                        />
                    ) : (
                        <span className="text-lg font-semibold flex items-center justify-center w-10 h-10 bg-gray-300 rounded-full">
                            {user &&
                                user.nameFirst &&
                                user.nameLast &&
                                user.nameFirst[0].toUpperCase() +
                                    user.nameLast[0].toUpperCase()}
                        </span>
                    )}

                    <div>
                        <div className="flex items-center gap-1">
                            <span className="font-semibold hover:underline cursor-pointer">
                                {profileData?.pageName &&
                                profileData?.profileUrl ? (
                                    <a
                                        href={profileData.profileUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="font-semibold hover:underline cursor-pointer"
                                    >
                                        {profileData.pageName}
                                    </a>
                                ) : (
                                    `${user?.nameFirst} ${user?.nameLast}`
                                )}
                            </span>
                        </div>
                        <div className="flex items-center gap-1 text-sm text-muted-foreground">
                            <span>
                                {`${new Date(
                                    post.scheduledFor
                                ).toLocaleDateString("en-GB", {
                                    day: "numeric",
                                    month: "long",
                                    timeZone: user?.timeZone,
                                })} at ${new Date(
                                    post.scheduledFor
                                ).toLocaleTimeString("en-GB", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                    timeZone: user?.timeZone,
                                })}`}
                            </span>
                            <Globe2 className="w-3 h-3" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Post Content */}
            <div className="px-4 pb-3">
                <div className="whitespace-pre-line">{renderContent()}</div>
            </div>

            {/* Media Grid */}
            <div className="px-4">{renderMedia()}</div>

            {/* Engagement Stats */}
            <div className="px-4 py-2 flex items-center justify-between text-sm text-muted-foreground border-b">
                <div className="flex items-center gap-1">
                    <div className="flex -space-x-1">
                        <div className="w-4 h-4 rounded-full bg-blue-500 border-2 border-white" />
                        <div className="w-4 h-4 rounded-full bg-red-500 border-2 border-white" />
                        <div className="w-4 h-4 rounded-full bg-yellow-500 border-2 border-white" />
                    </div>
                    <span>44</span>
                </div>
                <div className="flex gap-3">
                    <span>14 comments</span>
                    <span>8 shares</span>
                </div>
            </div>

            {/* Action Buttons */}
            <div className="px-4 py-1 flex items-center justify-between border-b">
                <Button
                    variant="ghost"
                    className="flex-1 flex items-center justify-center gap-2"
                >
                    <ThumbsUp className="w-5 h-5" />
                    Like
                </Button>
                <Button
                    variant="ghost"
                    className="flex-1 flex items-center justify-center gap-2"
                >
                    <MessageSquare className="w-5 h-5" />
                    Comment
                </Button>
                <Button
                    variant="ghost"
                    className="flex-1 flex items-center justify-center gap-2"
                >
                    <Share2 className="w-5 h-5" />
                    Share
                </Button>
            </div>
            {showGallery && (
                <FacebookMediaGalleryOverlay
                    media={post.media}
                    initialIndex={galleryInitialIndex}
                    onClose={() => setShowGallery(false)}
                />
            )}
        </div>
    );
};

export default FacebookPost;
