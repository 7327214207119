import React from "react";
import { FaCheck } from "react-icons/fa6";
import { OverlayThumbnailsProps } from "./types";

const OverlayThumbnails: React.FC<OverlayThumbnailsProps> = ({
    overlays,
    selectedOverlay,
    onSelectOverlay,
    isMobile,
}) => {
    return (
        <div
            className={`grid ${
                isMobile ? "grid-cols-2" : "grid-cols-5"
            } gap-2 mt-4`}
        >
            {overlays.map((overlay) => {
                const isSelected = selectedOverlay === overlay.id;

                return (
                    <div
                        key={overlay.id}
                        className={`relative h-auto cursor-pointer flex items-center justify-center bg-gray-100 ${
                            isSelected ? "ring-2 ring-secondary rounded" : ""
                        }`}
                        onClick={() => onSelectOverlay(overlay.id)}
                    >
                        <img
                            src={overlay.url}
                            alt={overlay.name}
                            className="max-w-full max-h-full"
                        />
                        {/* Show check icon when selected */}
                        {isSelected && (
                            <div className="absolute top-2 right-2 bg-primary flex flex-row justify-center items-center p-1 rounded-xl">
                                <FaCheck className="text-secondary" />
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default OverlayThumbnails;
