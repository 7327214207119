import React, { useEffect } from "react";
import { TabsContent } from "../../components/misc/Tabs";
import { Label } from "../../components/misc/Label";
import { Input } from "../../components/misc/Input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../components/misc/Select";
import { Switch } from "../../components/misc/Switch";
import { MLS, Plan, User, UserRole } from "../types";
import { toast } from "react-toastify";
import CustomToast from "../../components/misc/CustomToast";
import LoadingScreen from "../../screens/LoadingScreen";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";

interface EditUserOverlayProps {
    userData: Partial<User> | null;
    setUserData: React.Dispatch<React.SetStateAction<Partial<User> | null>>;
    teamAdmins: User[];
    isAdd: boolean;
    allPlans: Plan[];
}

const EditUserOverlay: React.FC<EditUserOverlayProps> = ({
    userData,
    setUserData,
    teamAdmins,
    isAdd,
    allPlans,
}) => {
    const [mls, setMls] = React.useState<MLS[] | null>(null);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        const fetchMls = async () => {
            setLoading(true);

            try {
                const headers = await getAuthHeader();
                const backendUrl = process.env.REACT_APP_BACKEND_URL;

                const mlsResponse = await axios.get(
                    `${backendUrl}/onboarding/mls-options`,
                    {
                        headers,
                    }
                );

                if (mlsResponse.status !== 200) {
                    throw new Error("Failed to fetch MLS");
                }

                setMls(mlsResponse.data);
            } catch (error: unknown) {
                console.error("Failed to fetch MLS");
                toast.error(
                    <CustomToast message="Failed to fetch MLS" type="error" />,
                    {
                        autoClose: 3000,
                    }
                );
            } finally {
                setLoading(false);
            }
        };

        fetchMls();
    }, []);

    if (loading) {
        return <LoadingScreen />;
    }

    if (!userData) {
        return null;
    }

    return (
        <TabsContent value="userData">
            <div className="grid gap-4 py-4">
                {/* First Name */}
                <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="nameFirst" className="text-right">
                        First Name
                    </Label>
                    <Input
                        id="nameFirst"
                        value={userData.nameFirst || ""}
                        onChange={(e) =>
                            setUserData({
                                ...userData,
                                nameFirst: e.target.value,
                            })
                        }
                        className="col-span-3"
                        required
                    />
                </div>
                {/* Last Name */}
                <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="nameLast" className="text-right">
                        Last Name
                    </Label>
                    <Input
                        id="nameLast"
                        value={userData.nameLast || ""}
                        onChange={(e) =>
                            setUserData({
                                ...userData,
                                nameLast: e.target.value,
                            })
                        }
                        className="col-span-3"
                        required
                    />
                </div>
                {/* Email */}
                <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="email" className="text-right">
                        Email
                    </Label>
                    <Input
                        type="email"
                        id="email"
                        value={userData.email || ""}
                        onChange={(e) =>
                            setUserData({
                                ...userData,
                                email: e.target.value,
                            })
                        }
                        className="col-span-3"
                        required
                    />
                </div>
                {/* Role */}
                <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="role" className="text-right">
                        Role
                    </Label>
                    <Select
                        onValueChange={(value) =>
                            setUserData({
                                ...userData,
                                role: value as UserRole,
                            })
                        }
                        value={userData.role || UserRole.USER}
                    >
                        <SelectTrigger className="col-span-3">
                            <SelectValue placeholder="Select a role" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value={UserRole.ADMIN}>
                                Admin
                            </SelectItem>
                            <SelectItem value={UserRole.USER}>User</SelectItem>
                            <SelectItem value={UserRole.TEAM_ADMIN}>
                                Team Admin
                            </SelectItem>
                            <SelectItem value={UserRole.TEAM_USER}>
                                Team User
                            </SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                {/* Main Account (Conditional) */}
                {userData.role === UserRole.TEAM_USER && (
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="mainAccount" className="text-right">
                            Main Account
                        </Label>
                        <Select
                            onValueChange={(value) =>
                                setUserData({
                                    ...userData,
                                    accountId: value,
                                })
                            }
                            value={userData.accountId || ""}
                        >
                            <SelectTrigger className="col-span-3">
                                <SelectValue placeholder="Select a Team Admin" />
                            </SelectTrigger>
                            <SelectContent>
                                {teamAdmins.map((admin) => (
                                    <SelectItem key={admin.id} value={admin.id}>
                                        {admin.nameFirst} {admin.nameLast}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                )}
                {/* MLS Approved */}
                <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="mlsApproved" className="text-right">
                        MLS Approved
                    </Label>
                    <Switch
                        id="mlsApproved"
                        checked={userData.mlsApproved || false}
                        onCheckedChange={(checked) =>
                            setUserData({
                                ...userData,
                                mlsApproved: checked,
                            })
                        }
                    />
                </div>
                {/* Onboarded */}
                <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="onboarded" className="text-right">
                        Onboarded
                    </Label>
                    <Switch
                        id="onboarded"
                        checked={userData.onboarded || false}
                        onCheckedChange={(checked) =>
                            setUserData({
                                ...userData,
                                onboarded: checked,
                            })
                        }
                    />
                </div>
                {/* License Number */}
                <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="licenseNumber" className="text-right">
                        License Number
                    </Label>
                    <Input
                        id="licenseNumber"
                        value={userData.licenseNumber || ""}
                        onChange={(e) =>
                            setUserData({
                                ...userData,
                                licenseNumber: e.target.value,
                            })
                        }
                        className="col-span-3"
                    />
                </div>
                {/* Company Name */}
                <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="companyName" className="text-right">
                        Company Name
                    </Label>
                    <Input
                        id="companyName"
                        value={userData.companyName || ""}
                        onChange={(e) =>
                            setUserData({
                                ...userData,
                                companyName: e.target.value,
                            })
                        }
                        className="col-span-3"
                    />
                </div>
                {/* Phone Number */}
                <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="phoneNumber" className="text-right">
                        Phone Number
                    </Label>
                    <Input
                        id="phoneNumber"
                        value={userData.phoneNumber || ""}
                        onChange={(e) =>
                            setUserData({
                                ...userData,
                                phoneNumber: e.target.value,
                            })
                        }
                        className="col-span-3"
                    />
                </div>
                {/* Broker */}
                <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="broker" className="text-right">
                        Broker
                    </Label>
                    <Input
                        id="broker"
                        value={userData.broker || ""}
                        onChange={(e) =>
                            setUserData({
                                ...userData,
                                broker: e.target.value,
                            })
                        }
                        className="col-span-3"
                    />
                </div>
                {/* Broker License */}
                <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="brokerLicense" className="text-right">
                        Broker License
                    </Label>
                    <Input
                        id="brokerLicense"
                        value={userData.brokerLicense || ""}
                        onChange={(e) =>
                            setUserData({
                                ...userData,
                                brokerLicense: e.target.value,
                            })
                        }
                        className="col-span-3"
                    />
                </div>
                {/* Broker Email */}
                <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="brokerEmail" className="text-right">
                        Broker Email
                    </Label>
                    <Input
                        type="email"
                        id="brokerEmail"
                        value={userData.brokerEmail || ""}
                        onChange={(e) =>
                            setUserData({
                                ...userData,
                                brokerEmail: e.target.value,
                            })
                        }
                        className="col-span-3"
                    />
                </div>
                {/* MLS or Other MLS */}
                {!userData?.otherMLS || userData?.otherMLS?.length <= 0 ? (
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="mls" className="text-right">
                            MLS
                        </Label>
                        <Select
                            onValueChange={(value) =>
                                setUserData({
                                    ...userData,
                                    mls: value as string,
                                })
                            }
                            value={
                                typeof userData.mls === "string"
                                    ? userData.mls
                                    : userData.mls?._id.toString() || ""
                            }
                        >
                            <SelectTrigger className="col-span-3">
                                <SelectValue placeholder="Select a MLS" />
                            </SelectTrigger>
                            <SelectContent>
                                {mls?.map((m) => (
                                    <SelectItem
                                        key={m._id.toString()}
                                        value={m._id.toString()}
                                    >
                                        {m.description}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                ) : (
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="otherMLS" className="text-right">
                            Other MLS
                        </Label>
                        <Input
                            id="otherMLS"
                            value={userData.otherMLS || ""}
                            onChange={(e) =>
                                setUserData({
                                    ...userData,
                                    otherMLS: e.target.value,
                                })
                            }
                            className="col-span-3"
                        />
                    </div>
                )}
                {userData.plan ? (
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="plan" className="text-right">
                            Plan
                        </Label>
                        <Select
                            onValueChange={(value) =>
                                setUserData({
                                    ...userData,
                                    plan: allPlans.find(
                                        (plan: Plan) => plan.id === value
                                    ) as Plan,
                                })
                            }
                            value={userData.plan.id || ""}
                        >
                            <SelectTrigger className="col-span-3">
                                <SelectValue placeholder="Select a Plan" />
                            </SelectTrigger>
                            <SelectContent>
                                {allPlans.map((plan) => (
                                    <SelectItem
                                        key={plan.id}
                                        value={plan.id || ""}
                                    >
                                        {plan.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                ) : null}
                {/* Time Zone */}
                <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="timeZone" className="text-right">
                        Time Zone
                    </Label>
                    <Input
                        id="timeZone"
                        value={userData.timeZone || ""}
                        onChange={(e) =>
                            setUserData({
                                ...userData,
                                timeZone: e.target.value,
                            })
                        }
                        className="col-span-3"
                    />
                </div>
            </div>
        </TabsContent>
    );
};

export default EditUserOverlay;
