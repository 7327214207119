import React from "react";
import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../components/misc/Card";
import { AlertCircle } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "../components/misc/Button";

const PausedScreen = () => {
    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-200 flex items-center justify-center p-4">
            <Card className="max-w-md w-full">
                <CardHeader>
                    <CardTitle className="text-2xl font-bold text-center flex items-center justify-center gap-2 text-primary">
                        <AlertCircle className="h-6 w-6 text-secondary" />
                        Account Paused
                    </CardTitle>
                </CardHeader>
                <CardContent className="text-center space-y-4">
                    <p className="text-gray-600">
                        Your account has been temporarily paused. Our admin team
                        will reach out to you shortly to resolve this issue.
                    </p>
                    <p className="text-gray-600">
                        If you believe this is a mistake, please don't hesitate
                        to contact our admin team directly.
                    </p>
                </CardContent>
                <CardFooter className="flex flex-col space-y-2">
                    <Button asChild className="w-full">
                        <Link to="mailto:support@marabot.ai" target="_blank">
                            Contact Admin Team
                        </Link>
                    </Button>
                    <p className="text-sm text-gray-500">
                        Email: support@marabot.ai
                    </p>
                </CardFooter>
            </Card>
        </div>
    );
};

export default PausedScreen;
