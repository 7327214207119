import React, { useEffect } from "react";
import { Button } from "./Button";
import { LoaderCircle } from "lucide-react";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "./CustomToast";

interface ConnectSocialAccountsButtonProps {
    text: string;
    redirectUrl: string;
    // Removed onConnect prop since it's handled internally
}

const ConnectSocialAccountsButton: React.FC<
    ConnectSocialAccountsButtonProps
> = ({ text, redirectUrl }) => {
    const [connectLoading, setConnectLoading] = React.useState(false);

    const handleConnectButtonClick = async () => {
        console.log("handleConnectButtonClick called");

        setConnectLoading(true);

        try {
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            const response = await axios.post(
                `${backendUrl}/socials/link-social-url`,
                { redirectUrl },
                { headers }
            );

            console.log("Social Connect URL Response:", response);

            if (response.status === 200 && response.data) {
                const connectUrl = response.data;
                if (connectUrl) {
                    window.location.href = connectUrl;
                } else {
                    throw new Error("Invalid URL received from the server.");
                }
            } else {
                throw new Error(
                    "Failed to fetch social connect URL. Please try again or go to settings to connect your social accounts."
                );
            }
        } catch (error: unknown) {
            console.error("Fetch Social Connect URL Error:", error);

            const errorMessage =
                "Error fetching social connect URL. Please try again or go to settings to connect your social accounts.";

            toast.error(<CustomToast message={errorMessage} type="error" />, {
                autoClose: 3000,
            });
        } finally {
            setConnectLoading(false);
        }
    };

    useEffect(() => {
        const handleFocus = async () => {
            console.log("Window gained focus. Checking connection status...");

            try {
                /* const headers = await getAuthHeader();
                const backendUrl = process.env.REACT_APP_BACKEND_URL;

                const response = await axios.get(
                    `${backendUrl}/socials/check-connection`,
                    { headers }
                );

                if (response.status === 200 && response.data.connected) {
                    toast.success(
                        <CustomToast
                            message="Social account connected successfully!"
                            type="success"
                        />,
                        { autoClose: 3000 }
                    );
                } else {
                    console.log("Social account not connected yet.");
                }*/
            } catch (error: unknown) {
                console.error("Check Social Connection Error:", error);

                const errorMessage =
                    "Error verifying social connection. Please try again later.";

                toast.error(
                    <CustomToast message={errorMessage} type="error" />,
                    {
                        autoClose: 3000,
                    }
                );
            }
        };

        window.addEventListener("focus", handleFocus);

        return () => {
            window.removeEventListener("focus", handleFocus);
        };
    }, []);

    return (
        <div className="flex justify-center">
            <Button
                onClick={handleConnectButtonClick}
                disabled={connectLoading}
                className="flex items-center justify-center"
            >
                {connectLoading ? (
                    <>
                        <LoaderCircle className="animate-spin h-5 w-5 mr-2" />
                        Connecting...
                    </>
                ) : (
                    text
                )}
            </Button>
        </div>
    );
};

export default ConnectSocialAccountsButton;
