import React from "react";
import { CombinedMedia } from "../types";
import { FaPlay } from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";
import { MediaThumbnailsProps } from "./types";
import { Badge } from "../../../calendar/Badge";
import { useIsMobile } from "../../../../hooks/useIsMobile";

const MediaThumbnails: React.FC<MediaThumbnailsProps> = ({
    mediaItems,
    currentPage,
    itemsPerPage,
    toggleSelectMedia,
    selectedMedia,
    isEditing,
    carouselApi,
    combinedMedia,
    mlsApproved,
    carouselIndexMap, // Remove if not used elsewhere
    onMediaClick,
}) => {
    const isMobile = useIsMobile(1200);

    const itemsPerPageToUse = isMobile ? 4 : itemsPerPage;
    const startIndex = (currentPage - 1) * itemsPerPageToUse;
    const endIndex = startIndex + itemsPerPageToUse;

    const currentItems = mediaItems.slice(startIndex, endIndex);

    return (
        <div
            className={`grid ${isMobile ? "grid-cols-2" : "grid-cols-5"} gap-2`}
        >
            {currentItems.map((item, i) => {
                const globalIndex = startIndex + i; // Compute global index
                const mediaType = item.type;
                const media = item as CombinedMedia;

                const isSelected = selectedMedia.some((m) => m.id === media.id);
                const isDisabled = isEditing && mlsApproved && item.isMls;

                const handleClick = () => {
                    if (isDisabled) return;
                    if (isEditing) {
                        toggleSelectMedia(media);
                    } else {
                        if (carouselApi) {
                            console.log("media", media);
                            console.log("globalIndex", globalIndex);
                            carouselApi.scrollTo(globalIndex);
                            if (onMediaClick) {
                                onMediaClick(globalIndex);
                            }
                        }
                    }
                };

                return (
                    <div
                        id={`media-${i}`}
                        key={media.id || item.name}
                        className={`relative h-32 cursor-pointer ${
                            isSelected ? "ring-2 ring-secondary rounded" : ""
                        } ${isDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
                        onClick={handleClick}
                    >
                        {mlsApproved && isEditing && (
                            <Badge
                                className="absolute top-1 right-1 z-40"
                                variant="secondary"
                            >
                                <span className="text-[10px]">
                                    {item.isMls ? "MLS" : "USER"}
                                </span>
                            </Badge>
                        )}
                        <div className="w-full h-full overflow-hidden rounded">
                            {mediaType === "image" ? (
                                <img
                                    src={item.thumbnail || item.url}
                                    alt={`${item.name}`}
                                    className="w-full h-full object-cover"
                                />
                            ) : (
                                <div className="relative w-full h-full">
                                    <video
                                        src={item.url}
                                        className="w-full h-full object-cover"
                                        muted
                                        preload="metadata"
                                    />
                                    {/* Play icon overlay for videos */}
                                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded">
                                        <FaPlay
                                            className="text-white"
                                            size={24}
                                        />
                                    </div>
                                </div>
                            )}
                            {/* Overlay when item is selected */}
                            {isEditing && (
                                <div className="absolute top-2 left-2 bg-primary flex flex-row justify-center items-center p-1 rounded-xl w-[25px] h-[25px]">
                                    {isSelected && (
                                        <FiCheckCircle className="text-secondary text-[18px]" />
                                    )}
                                </div>
                            )}

                            {/* Disabled overlay */}
                            {isDisabled && (
                                <div className="absolute inset-0 bg-gray-500 bg-opacity-30 rounded"></div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default MediaThumbnails;
