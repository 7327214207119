import React from "react";

const Spinner: React.FC<{ loadingMessage?: string }> = ({ loadingMessage }) => {
    return (
        <div className="flex flex-col items-center justify-center space-y-4">
            {loadingMessage && (
                <h1 className="text-primary font-bold text-xl">
                    {loadingMessage}
                </h1>
            )}
            <div className="loader"></div>
        </div>
    );
};

export default Spinner;
