import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import "./App.css";
import { ToastContainer } from "react-toastify";
import useAuth from "./hooks/useAuth";
import LoadingScreen from "./screens/LoadingScreen";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import { useIsMobile } from "./hooks/useIsMobile";

function App() {
    const { authCheckCompleted } = useAuth();
    const loading = useSelector((state: RootState) => state.auth.loading);

    const isMobile = useIsMobile(700);

    if (loading || !authCheckCompleted) {
        return <LoadingScreen />;
    }

    return (
        <Router>
            <AppRoutes />
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={true}
                closeOnClick={true}
                pauseOnHover={false}
                draggable={false}
                limit={1}
                className={`
                    ${isMobile ? "!fixed" : "!absolute"} 
                    !top-5 
                    !left-1/2 
                    !transform 
                    !-translate-x-1/2 
                    !z-100
                `}
            />
        </Router>
    );
}

export default App;
