import React, { useState } from "react";
import { ChooseCampaignOptionsProps } from "./types";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../misc/Card";
import { Button } from "../misc/Button";
import { Label } from "../misc/Label";
import { Switch } from "../misc/Switch";
import ReconfirmOverlay from "../misc/ReconfirmOverlay";

const ChooseCampaignOptions: React.FC<ChooseCampaignOptionsProps> = ({
    setCurrentStep,
    autoApprove,
    setAutoApprove,
    soldPost,
    setSoldPost,
    useEmojis,
    setUseEmojis,
    createCampaign,
    listingType,
    usePrice,
    setUsePrice,
}) => {
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);

    const handleBack = () => {
        setCurrentStep(3);
    };

    const handleAutoApproveChange = (checked: boolean) => {
        if (!checked) {
            // User is turning off auto approval, show confirmation
            setIsOverlayOpen(true);
        } else {
            // User is turning on auto approval
            setAutoApprove(true);
        }
    };

    const confirmDisableAutoApprove = () => {
        setAutoApprove(false);
        setIsOverlayOpen(false);
    };

    const cancelDisableAutoApprove = () => {
        // Keep auto approval on
        setIsOverlayOpen(false);
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle>Choose Campaign Settings</CardTitle>
                <CardDescription>
                    Select your preferences for campaign post generation
                </CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
                <div className="flex items-center justify-between space-x-2">
                    <Label
                        htmlFor="auto-approve"
                        className="text-primary flex flex-col space-y-1 font-semibold"
                    >
                        <span>Auto Approve Posts</span>
                        <span className="text-black font-normal text-sm text-muted-foreground">
                            {autoApprove
                                ? "All social media posts will be automatically approved on creation."
                                : "You will need to manually approve all your posts."}
                        </span>
                    </Label>
                    <Switch
                        id="auto-approve"
                        checked={autoApprove}
                        onCheckedChange={handleAutoApproveChange}
                    />
                </div>
                {listingType !== "Promotion" ? (
                    <div className="flex items-center justify-between space-x-2">
                        <Label
                            htmlFor="sold-post"
                            className="text-primary flex flex-col space-y-1 font-semibold"
                        >
                            <span>Create Sold Posts</span>
                            <span className="text-black font-normal text-sm text-muted-foreground">
                                Automatically create and schedule a post when a
                                listing is sold
                            </span>
                        </Label>
                        <Switch
                            id="sold-post"
                            checked={soldPost}
                            onCheckedChange={setSoldPost}
                        />
                    </div>
                ) : null}

                <div className="flex items-center justify-between space-x-2">
                    <Label
                        htmlFor="use-emojis"
                        className="text-primary flex flex-col space-y-1 font-semibold"
                    >
                        <span>Use Emojis in Posts</span>
                        <span className="text-black font-normal text-sm text-muted-foreground">
                            Include emojis in your social media posts
                        </span>
                    </Label>
                    <Switch
                        id="use-emojis"
                        checked={useEmojis}
                        onCheckedChange={setUseEmojis}
                    />
                </div>

                {listingType !== "Promotion" ? (
                    <div className="flex items-center justify-between space-x-2">
                        <Label
                            htmlFor="use-price"
                            className="text-primary flex flex-col space-y-1 font-semibold"
                        >
                            <span>Use Price in Posts</span>
                            <span className="text-black font-normal text-sm text-muted-foreground">
                                Include price in your social media posts if
                                available
                            </span>
                        </Label>
                        <Switch
                            id="use-price"
                            checked={usePrice}
                            onCheckedChange={setUsePrice}
                        />
                    </div>
                ) : null}

                <div className="bg-gray-100 p-3 rounded-md">
                    <p className="text-sm text-gray-600">
                        <strong className="text-primary">Note:</strong> Posts
                        will be generated for an initial two-week period. After
                        that, the system will auto-renew each week, generating
                        posts for the upcoming week. Any additional media that
                        is added to the campaign will be included in the next
                        generation cycle. The campaign will continue
                        automatically until you pause{" "}
                        {listingType && listingType === "Promotion"
                            ? "or delete the promotion"
                            : "it or the property sells"}
                    </p>
                </div>

                {/* Reconfirm Overlay for Auto Approve Posts */}
                <ReconfirmOverlay
                    isOpen={isOverlayOpen}
                    onClose={cancelDisableAutoApprove}
                    onConfirm={confirmDisableAutoApprove}
                    title="Confirm Action"
                    message="Are you sure you want to turn off auto post approval?"
                    messageTwo={`Disabling this feature means you'll need to manually 
            approve each generated post. Without approved content, your campaign 
            will pause. We strongly recommend keeping automatic approval turned 
            on to ensure uninterrupted social media posting.`}
                    confirmText="Yes, Turn Off"
                />

                {/* Navigation Buttons */}
                <div className="flex justify-between">
                    <Button variant="secondary" onClick={handleBack}>
                        Back
                    </Button>
                    <Button onClick={createCampaign}>Create</Button>
                </div>
            </CardContent>
        </Card>
    );
};

export default ChooseCampaignOptions;
