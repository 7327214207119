import React from "react";
import { CampaignCardProps } from "./types";
import { Card, CardContent, CardTitle } from "../misc/Card";
import CampaignStatusCard from "../campaign/CampaignStatusCard";
import { CampaignCardListingProps } from "../listings/listing/types";
import { useNavigate } from "react-router-dom";
import { Button } from "../misc/Button";
import { OptionType } from "../../types/option-type";
import { useDispatch } from "react-redux";
import { setSelectedListingAddress } from "../../store/calendarSlice";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { useLocation } from "react-router-dom";

const CampaignCard: React.FC<CampaignCardProps> = ({ promotion }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const campaignCardListing: CampaignCardListingProps = {
        campaignProgress: promotion?.campaignProgress,
        campaignStatus: promotion?.campaignStatus,
        campaignType: promotion?.campaignType,
        generatedPosts: promotion?.generatedPosts,
        publishedPosts: promotion?.publishedPosts,
        scheduledPosts: promotion?.scheduledPosts,
        unapprovedPosts: promotion?.unapprovedPosts,
        contentRefresh: promotion?.contentRefresh,
    };

    const handleViewPromotion = () => {
        const option: OptionType[] = [
            {
                label: "Promotion",
                value: "Promotion",
            },
        ];
        dispatch(setSelectedListingAddress(option));
        navigate("/");
    };

    const handleAddPost = () => {
        const option: OptionType[] = [
            {
                label: "Promotion",
                value: "Promotion",
            },
        ];
        dispatch(setSelectedListingAddress(option));
        navigate("/", {
            state: {
                addPost: true,
            },
        });
    };

    const handleCampaignRetry = async () => {
        const campaignId = promotion?.campaignId;

        if (!campaignId) {
            toast.error(
                <CustomToast message="Campaign Id not found." type="error" />,
                {
                    autoClose: 3000,
                }
            );
            return;
        }

        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.get(
                `${backendUrl}/promotions/restart-campaign/${campaignId}`,
                { headers: headers }
            );

            if (response.status === 200) {
                toast.success(
                    <CustomToast
                        message="Campaign generation process restarted."
                        type="success"
                    />,
                    {
                        autoClose: 3000,
                    }
                );
            }
        } catch (error: unknown) {
            console.error(error);
            toast.error(
                <CustomToast
                    message="Failed to retry campaign."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        }
    };

    return (
        <Card>
            <CardContent className="pt-6">
                <CardTitle className="mb-4">Campaign</CardTitle>
                <CampaignStatusCard listing={campaignCardListing} />
                {Object.keys(promotion).length > 0
                    ? !promotion.campaignStatus && (
                          <Button
                              id="create-campaign-btn"
                              className="mt-4 font-semibold w-full"
                              onClick={() =>
                                  navigate("/create-campaign", {
                                      state: {
                                          listingId: promotion.id,
                                          listingType: "Promotion",
                                          from: location.pathname,
                                      },
                                  })
                              }
                          >
                              Create Campaign
                          </Button>
                      )
                    : null}
                {Object.keys(promotion).length > 0 &&
                promotion.campaignStatus &&
                promotion.campaignStatus !== "ERROR" &&
                promotion.campaignStatus !== "PROCESSING" ? (
                    <>
                        <Button
                            id="view-campaign-btn"
                            className="mt-4 font-semibold w-full"
                            variant="secondary"
                            onClick={handleViewPromotion}
                        >
                            View Campaign Schedule
                        </Button>
                        <Button
                            id="add-post-btn"
                            className="mt-4 font-semibold w-full"
                            onClick={handleAddPost}
                        >
                            Add Post
                        </Button>
                    </>
                ) : null}
                {Object.keys(promotion).length > 0 &&
                promotion.campaignStatus &&
                promotion.campaignStatus === "ERROR" ? (
                    <Button
                        id="retry-campaign-btn"
                        className="mt-4 font-semibold w-full"
                        onClick={handleCampaignRetry}
                    >
                        Retry Campaign
                    </Button>
                ) : null}

                {promotion.campaignStatus &&
                    promotion.campaignStatus === "SOCIAL_PAUSE" && (
                        <div className="mt-2 flex justify-center">
                            <span className="text-center text-red-500">
                                Connect Social Media platform(s) from Settings
                                to resume campaign
                            </span>
                        </div>
                    )}
            </CardContent>
        </Card>
    );
};

export default CampaignCard;
