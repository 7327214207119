import { User } from "../types/user";
import AdminLayout from "../layouts/AdminLayout";
import adminRoutes from "./AdminRoutes";
import { Navigate, Route } from "react-router-dom";
import UserLayout from "../layouts/UserLayout";
import userRoutes from "./UserRoutes";

interface ViewProps {
    isAdmin: boolean;
    isAdminView: boolean;
    user: User;
    mlsApproved: boolean;
    maintenance: boolean;
}

const LoggedInView = ({
    isAdmin,
    isAdminView,
    user,
    mlsApproved,
    maintenance,
}: ViewProps) => {
    if (isAdmin && isAdminView) {
        return (
            <Route
                element={
                    <AdminLayout
                        isAdmin={isAdmin}
                        user={user}
                        isAdminView={isAdminView}
                    />
                }
            >
                {adminRoutes}
            </Route>
        );
    } else {
        return (
            <Route
                element={
                    <UserLayout
                        isAdmin={isAdmin}
                        user={user}
                        isAdminView={isAdminView}
                        maintenance={maintenance}
                    />
                }
            >
                {userRoutes(mlsApproved, user.mlsWelcome)}
                <Route path="*" element={<Navigate to="/" />} />
            </Route>
        );
    }
};

export default LoggedInView;
