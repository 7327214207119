import React, { useEffect, useState } from "react";
import { SelectedAddress } from "../../types/selectedAddress";
import CreateUserListing from "./CreateUserListing";
import ConfirmUserListing from "./ConfirmUserListing";

interface CreateListingsProps {
    mlsApproved: boolean;
    mlsWelcome: boolean;
}

const CreateListings: React.FC<CreateListingsProps> = ({
    mlsApproved,
    mlsWelcome,
}) => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [selectedAddress, setSelectedAddress] =
        useState<SelectedAddress | null>(null);
    const [mlsId, setMlsId] = useState<string>("");

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <CreateUserListing
                        setCurrentStep={setCurrentStep}
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setSelectedAddress}
                        mlsApproved={mlsApproved}
                        mlsId={mlsId}
                        setMlsId={setMlsId}
                        mlsWelcome={mlsWelcome}
                    />
                );
            case 2:
                return (
                    <ConfirmUserListing
                        setCurrentStep={setCurrentStep}
                        selectedAddress={selectedAddress}
                        mlsApproved={mlsApproved}
                        mlsId={mlsId}
                    />
                );
            default:
                return (
                    <CreateUserListing
                        setCurrentStep={setCurrentStep}
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setSelectedAddress}
                        mlsApproved={mlsApproved}
                        mlsId={mlsId}
                        setMlsId={setMlsId}
                        mlsWelcome={mlsWelcome}
                    />
                );
        }
    };

    useEffect(() => {
        console.log("selectedAddress in UserListings:", selectedAddress);
    }, [selectedAddress]);

    return renderStep();
};

export default CreateListings;
