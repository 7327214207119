import React, { useState } from "react";
import { OverlaySelectorProps } from "./types";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import OverlayThumbnails from "./OverlayThumbnail";
import { Button } from "../../misc/Button";
import { useIsMobile } from "../../../hooks/useIsMobile";

const OverlaySelector: React.FC<OverlaySelectorProps> = ({
    overlays,
    selectedOverlay,
    onSelectOverlay,
}) => {
    const isMobile = useIsMobile(900);

    const [currentPage, setCurrentPage] = useState(1);
    const perPage = isMobile ? 4 : 10;
    const totalPages = Math.ceil(overlays.length / perPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    // Slice overlays array for pagination
    const paginatedOverlays = overlays.slice(
        (currentPage - 1) * perPage,
        currentPage * perPage
    );

    return (
        <div className="space-y-4">
            <h4 className="text-sm font-medium">Choose Overlay</h4>
            {overlays.length > 0 ? (
                <>
                    <OverlayThumbnails
                        overlays={paginatedOverlays}
                        selectedOverlay={selectedOverlay}
                        onSelectOverlay={onSelectOverlay}
                        isMobile={isMobile}
                    />
                    <div className="flex justify-between items-center mt-4">
                        <Button
                            variant="outline"
                            onClick={() =>
                                handlePageChange(Math.max(currentPage - 1, 1))
                            }
                            disabled={currentPage === 1}
                        >
                            <FaArrowLeft className="mr-2 h-4 w-4" /> Previous
                        </Button>
                        <span>
                            {isMobile ? "" : "Page "} {currentPage} of{" "}
                            {totalPages}
                        </span>
                        <Button
                            variant="outline"
                            onClick={() =>
                                handlePageChange(
                                    Math.min(currentPage + 1, totalPages)
                                )
                            }
                            disabled={currentPage === totalPages}
                        >
                            Next <FaArrowRight className="ml-2 h-4 w-4" />
                        </Button>
                    </div>
                </>
            ) : (
                <p className="text-neutral-500 col-span-full text-center">
                    No overlays available. Create a new one below.
                </p>
            )}
        </div>
    );
};

export default OverlaySelector;
