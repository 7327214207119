import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RiCalendarScheduleLine, RiCalendarScheduleFill } from "react-icons/ri";
import { RiFolderImageLine, RiFolderImageFill } from "react-icons/ri";
import { IoSettingsOutline, IoSettings } from "react-icons/io5";
import {
    BsMegaphone,
    BsFillMegaphoneFill,
    BsQuestionDiamond,
    BsQuestionDiamondFill,
} from "react-icons/bs";
import { RiHomeGearLine, RiHomeGearFill } from "react-icons/ri";
import { useIsMobile } from "../../hooks/useIsMobile";

const SideBar = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const isMobile = useIsMobile(900);

    const menuItems = [
        {
            name: "My Schedule",
            id: "scheduleButton",
            path: "/",
            icon: RiCalendarScheduleLine,
            filledIcon: RiCalendarScheduleFill,
            resetCalendar: true, // Add a flag to indicate reset
        },
        {
            name: "My Listings",
            id: "listingsButton",
            path: "/listings",
            icon: RiHomeGearLine,
            filledIcon: RiHomeGearFill,
            resetCalendar: false,
        },
        {
            name: "My Promotions",
            id: "promotionsButton",
            path: "/promotions",
            icon: BsMegaphone,
            filledIcon: BsFillMegaphoneFill,
            resetCalendar: false,
        },
        {
            name: "My Content",
            id: "contentButton",
            path: "/my-content",
            icon: RiFolderImageLine,
            filledIcon: RiFolderImageFill,
            resetCalendar: false,
        },
    ];

    const helpItem = {
        name: "Help",
        id: "helpButton",
        path: "/help",
        icon: BsQuestionDiamond,
        filledIcon: BsQuestionDiamondFill,
        resetCalendar: false,
    };

    const settingsItem = {
        name: "My Settings",
        id: "settingsButton",
        path: "/settings",
        icon: IoSettingsOutline,
        filledIcon: IoSettings,
    };

    return (
        <div
            onMouseEnter={() => setIsExpanded(true)}
            onMouseLeave={() => setIsExpanded(false)}
            className={`
                bg-primary
                text-white 
                transition-all 
                duration-500 
                ease-in-out
                overflow-hidden
                z-50
                ${
                    isMobile
                        ? "fixed bottom-0 left-0 w-full h-20"
                        : `h-full ${isExpanded ? "w-64" : "w-16"}`
                }
                z-50
            `}
        >
            <div
                className={`
                    flex ${isMobile ? "flex-row" : "flex-col"}
                    ${isMobile ? "justify-around" : "justify-between"}
                    ${isMobile ? "items-center" : "items-start"}
                    h-full p-2
                `}
            >
                <div
                    className={`
                        flex ${isMobile ? "flex-row w-full" : "flex-col"}
                        ${isMobile ? "justify-around" : "items-start"}
                        ${isMobile ? "" : "space-y-8"}
                        w-full
                    `}
                >
                    {menuItems.map((item, index) => (
                        <div
                            key={index}
                            id={item.id}
                            className={`
                                flex ${isMobile ? "flex-col" : "flex-row"} 
                                items-center cursor-pointer 
                                hover:bg-[#F0DFAF] ${
                                    location.pathname === item.path
                                        ? "text-secondary"
                                        : "text-white"
                                } hover:text-primary p-2 rounded-md transition-colors duration-300 ease-in-out
                                ${isMobile ? "flex-1 justify-center" : "w-full"}
                                ${
                                    !isExpanded && !isMobile
                                        ? "justify-center"
                                        : ""
                                }
                            `}
                            onClick={() => {
                                console.log(item.path);
                                navigate(item.path, {
                                    state: item.resetCalendar
                                        ? { resetCalendar: true }
                                        : {},
                                });
                            }}
                            style={{
                                height: isMobile ? "100%" : "auto",
                            }}
                        >
                            <div className="flex-shrink-0">
                                {location.pathname === item.path ? (
                                    <item.filledIcon
                                        size={isMobile ? 24 : 24} // Keep icon size consistent
                                        className="transition-transform duration-300 ease-in-out text-current"
                                    />
                                ) : (
                                    <item.icon
                                        size={isMobile ? 24 : 24} // Keep icon size consistent
                                        className="transition-transform duration-300 ease-in-out text-current"
                                    />
                                )}
                            </div>
                            {isMobile ? (
                                <span className="text-xs mt-1 text-center whitespace-nowrap">
                                    {item.name}
                                </span>
                            ) : (
                                isExpanded && (
                                    <span className="text-lg whitespace-nowrap transition-opacity duration-300 ease-in-out opacity-100 delay-300 ml-4">
                                        {item.name}
                                    </span>
                                )
                            )}
                        </div>
                    ))}
                </div>
                {/* Settings Item */}
                {!isMobile && (
                    <div className="flex flex-col items-start w-full">
                        <div
                            id={helpItem.id}
                            className={`
                                flex items-center w-full cursor-pointer hover:bg-[#F0DFAF] ${
                                    location.pathname === helpItem.path
                                        ? "text-secondary"
                                        : "text-white"
                                } hover:text-primary p-4 rounded-md transition-colors duration-300 ease-in-out
                                ${!isExpanded ? "justify-center" : ""}
                            `}
                            onClick={() => navigate(helpItem.path)}
                        >
                            <div className="flex-shrink-0">
                                {location.pathname === helpItem.path ? (
                                    <helpItem.filledIcon
                                        size={24} // Adjust size for consistency
                                        className="transition-transform duration-300 ease-in-out text-current"
                                    />
                                ) : (
                                    <helpItem.icon
                                        size={24} // Adjust size for consistency
                                        className="transition-transform duration-300 ease-in-out text-current"
                                    />
                                )}
                            </div>
                            {isExpanded && (
                                <span
                                    className="
                            text-lg 
                            whitespace-nowrap
                            transition-opacity 
                            duration-300 
                            ease-in-out
                            opacity-100 delay-300
                            ml-4
                        "
                                >
                                    {helpItem.name}
                                </span>
                            )}
                        </div>
                        <div
                            id={settingsItem.id}
                            className={`
                            flex items-center w-full cursor-pointer hover:bg-[#F0DFAF] ${
                                location.pathname === settingsItem.path
                                    ? "text-secondary"
                                    : "text-white"
                            } hover:text-primary p-4 rounded-md transition-colors duration-300 ease-in-out
                            ${!isExpanded ? "justify-center" : ""}
                        `}
                            onClick={() => navigate(settingsItem.path)}
                        >
                            <div className="flex-shrink-0">
                                {location.pathname === settingsItem.path ? (
                                    <settingsItem.filledIcon
                                        size={24} // Adjust size for consistency
                                        className="transition-transform duration-300 ease-in-out text-current"
                                    />
                                ) : (
                                    <settingsItem.icon
                                        size={24} // Adjust size for consistency
                                        className="transition-transform duration-300 ease-in-out text-current"
                                    />
                                )}
                            </div>
                            {isExpanded && (
                                <span
                                    className="
                                    text-lg 
                                    whitespace-nowrap
                                    transition-opacity 
                                    duration-300 
                                    ease-in-out
                                    opacity-100 delay-300
                                    ml-4
                                "
                                >
                                    {settingsItem.name}
                                </span>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SideBar;
