import React, { useEffect, useState } from "react";
import { Button } from "../../../misc/Button";
import { FaEdit, FaSave, FaSync, FaTrash } from "react-icons/fa";
import { AiOutlineFileDone } from "react-icons/ai";
import { FaBoxArchive, FaPlay } from "react-icons/fa6";
import { TabButtonProps } from "./types";
import { getAuthHeader } from "../../../../utils/authHeader";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../../../misc/CustomToast";
import ReconfirmOverlay from "../../../misc/ReconfirmOverlay";
import { useNavigate } from "react-router-dom";
import { MdCancel } from "react-icons/md";

type ConfirmAction =
    | "markInactive"
    | "markSold"
    | "deleteListing"
    | "mlsSync"
    | "markActive"
    | "pauseCampaign"
    | "resumeCampaign"
    | "cancel"
    | null;

const TabButtons: React.FC<TabButtonProps> = ({
    isEditing,
    setIsEditing,
    setIsLoading,
    propertyData,
    fetchListing,
    handleSave,
    originalPropertyData,
    setPropertyData,
    isMobile,
    mediaLength,
    mlsApproved,
}) => {
    const navigate = useNavigate();
    const [isMlsListing, setisMlsListing] = useState<boolean>(
        propertyData.listingType === "MLSListing"
    );

    useEffect(() => {
        setisMlsListing(propertyData.listingType === "MLSListing");
    }, [propertyData]);

    const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
    const [actionToConfirm, setActionToConfirm] = useState<ConfirmAction>(null);
    const [isSyncing, setIsSyncing] = useState<boolean>(false);

    // Generic handler to set the action and open the overlay
    const handleAction = (action: ConfirmAction) => {
        setActionToConfirm(action);
        setIsOverlayOpen(true);
    };

    // Confirmation handler based on the action
    const confirmAction = async () => {
        if (!actionToConfirm) return;
        setIsLoading(true);
        try {
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            switch (actionToConfirm) {
                case "cancel": {
                    originalPropertyData &&
                        setPropertyData(originalPropertyData);
                    break;
                }

                case "markInactive": {
                    const response = await axios.patch(
                        `${backendUrl}/listing/`,
                        {
                            status: "Inactive",
                            listingId: propertyData?._id,
                            auto:
                                (propertyData.listingType === "MLSListing" &&
                                    mlsApproved) ||
                                (propertyData.listingType ===
                                    "RentcastListing" &&
                                    !mlsApproved),
                        },
                        { headers }
                    );

                    if (response.status === 200) {
                        toast.success(
                            <CustomToast
                                message="Listing marked as inactive."
                                type="success"
                            />,
                            { autoClose: 3000 }
                        );
                        navigate("/listings");
                    } else {
                        throw new Error("Failed to mark listing as inactive.");
                    }
                    break;
                }

                case "markActive": {
                    const response = await axios.patch(
                        `${backendUrl}/listing/`,
                        {
                            status: "Active",
                            listingId: propertyData?._id,
                            auto:
                                (propertyData.listingType === "MLSListing" &&
                                    mlsApproved) ||
                                (propertyData.listingType ===
                                    "RentcastListing" &&
                                    !mlsApproved),
                        },
                        { headers }
                    );

                    if (response.status === 200) {
                        toast.success(
                            <CustomToast
                                message="Listing marked as active."
                                type="success"
                            />,
                            { autoClose: 3000 }
                        );
                        fetchListing();
                    } else {
                        throw new Error("Failed to mark listing as active.");
                    }
                    break;
                }

                case "markSold": {
                    const response = await axios.patch(
                        `${backendUrl}/listing/`,
                        {
                            status: "Sold",
                            listingId: propertyData?.id,
                            auto:
                                (propertyData.listingType === "MLSListing" &&
                                    mlsApproved) ||
                                (propertyData.listingType ===
                                    "RentcastListing" &&
                                    !mlsApproved),
                        },
                        { headers }
                    );

                    if (response.status === 200) {
                        toast.success(
                            <CustomToast
                                message="Listing marked as sold."
                                type="success"
                            />,
                            { autoClose: 3000 }
                        );
                        navigate("/listings");
                    } else {
                        throw new Error("Failed to mark listing as sold.");
                    }
                    break;
                }

                case "deleteListing": {
                    const response = await axios.post(
                        `${backendUrl}/listing/delete`,
                        {
                            listingId:
                                propertyData?.id ||
                                propertyData?._id.toString(),
                            listingType: propertyData?.listingType,
                        },
                        { headers }
                    );

                    if (response.status === 200) {
                        toast.success(
                            <CustomToast
                                message="Listing deleted successfully."
                                type="success"
                            />,
                            { autoClose: 3000 }
                        );
                        navigate("/listings");
                    } else {
                        throw new Error("Failed to delete listing.");
                    }
                    break;
                }

                case "mlsSync": {
                    setIsSyncing(true);
                    try {
                        const response = await axios.post(
                            `${backendUrl}/listing/sync`,
                            { listingId: propertyData?._id },
                            { headers }
                        );
                        if (response.status === 200) {
                            toast.success(
                                <CustomToast
                                    message="Synced with MLS successfully."
                                    type="success"
                                />,
                                { autoClose: 3000 }
                            );

                            fetchListing();
                        } else {
                            throw new Error("Failed to sync with MLS.");
                        }
                    } catch (error) {
                        console.error("Error syncing with MLS:", error);
                        toast.error(
                            <CustomToast
                                message="Failed to sync with MLS."
                                type="error"
                            />,
                            { autoClose: 3000 }
                        );
                    } finally {
                        setIsSyncing(false);
                    }
                    break;
                }

                case "pauseCampaign": {
                    if (!propertyData?.campaignId)
                        throw new Error("No campaign ID.");

                    const response = await axios.patch(
                        `${backendUrl}/listing/campaign/${propertyData.campaignId}`,
                        { status: "PAUSED" },
                        { headers }
                    );

                    if (response.status === 200) {
                        toast.success(
                            <CustomToast
                                message="Campaign paused successfully."
                                type="success"
                            />,
                            { autoClose: 3000 }
                        );
                        fetchListing();
                    } else {
                        throw new Error("Failed to pause campaign.");
                    }
                    break;
                }

                case "resumeCampaign": {
                    if (!propertyData?.campaignId)
                        throw new Error("No campaign ID.");

                    const response = await axios.patch(
                        `${backendUrl}/listing/campaign/${propertyData.campaignId}`,
                        { status: "ACTIVE" },
                        { headers }
                    );

                    if (response.status === 200) {
                        toast.success(
                            <CustomToast
                                message="Campaign resumed successfully."
                                type="success"
                            />,
                            { autoClose: 3000 }
                        );
                        fetchListing();
                    } else {
                        throw new Error("Failed to resume campaign.");
                    }
                    break;
                }

                default:
                    break;
            }
        } catch (error) {
            console.error(`Error performing action ${actionToConfirm}:`, error);
            toast.error(
                <CustomToast
                    message={`Failed to perform the action.`}
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setIsOverlayOpen(false);
            setActionToConfirm(null);
            setIsLoading(false);
        }
    };

    const closeOverlay = () => {
        setIsOverlayOpen(false);
        setActionToConfirm(null);
    };

    const handleListingDelete = () => {
        handleAction("deleteListing");
    };

    const handleMarkInactive = () => {
        handleAction("markInactive");
    };

    const handleMarkActive = () => {
        handleAction("markActive");
    };

    const handleMarkSold = () => {
        handleAction("markSold");
    };

    const handlePauseCampaign = () => {
        handleAction("pauseCampaign");
    };

    const handleResumeCampaign = () => {
        handleAction("resumeCampaign");
    };

    const handleMLSSync = () => {
        handleAction("mlsSync");
    };

    const handleCancel = () => {
        if (
            propertyData &&
            originalPropertyData &&
            propertyData === originalPropertyData
        ) {
            setIsEditing(false);
        } else if (
            propertyData &&
            originalPropertyData &&
            propertyData !== originalPropertyData
        ) {
            handleAction("cancel");
        }
    };

    // Define title and message based on action
    const getOverlayContent = () => {
        switch (actionToConfirm) {
            case "cancel":
                return {
                    title: "Cancel Changes",
                    message: "Are you sure you want to cancel?",
                    messageTwo: "This action will discard all changes.",
                    confirmText: "Cancel",
                };
            case "markInactive":
                return {
                    title: "Mark as Inactive",
                    message:
                        "Are you sure you want to mark this listing as inactive?",
                    messageTwo:
                        "This will pause all marketing actions for this campaign.",
                    confirmText: "Mark Inactive",
                };
            case "markActive":
                return {
                    title: "Mark as Active",
                    message:
                        "Are you sure you want to mark this listing as active?",
                    messageTwo: "",
                    confirmText: "Mark Active",
                };
            case "markSold":
                return {
                    title: "Mark as Sold",
                    message:
                        "Are you sure you want to mark this listing as sold?",
                    messageTwo: "This action cannot be undone.",
                    confirmText: "Mark Sold",
                };
            case "deleteListing":
                return {
                    title: "Delete Listing",
                    message: "Are you sure you want to delete this listing?",
                    messageTwo: "This action cannot be undone.",
                    confirmText: "Delete Listing",
                };
            case "mlsSync":
                return {
                    title: "Sync with MLS",
                    message:
                        "Are you sure you want to sync this listing with MLS?",
                    messageTwo: "",
                    confirmText: "Sync with MLS",
                };
            case "pauseCampaign":
                return {
                    title: "Pause Campaign",
                    message: "Are you sure you want to pause this campaign?",
                    messageTwo: "",
                    confirmText: "Pause Campaign",
                };
            case "resumeCampaign":
                return {
                    title: "Resume Campaign",
                    message: "Are you sure you want to resume this campaign?",
                    messageTwo: "",
                    confirmText: "Resume Campaign",
                };
            default:
                return {
                    title: "",
                    message: "",
                    messageTwo: "",
                    confirmText: "",
                };
        }
    };

    const { title, message, messageTwo, confirmText } = getOverlayContent();

    return (
        <div className={`${isMobile ? "mt-2" : "w-full mt-0"} z-50`}>
            <ReconfirmOverlay
                isOpen={isOverlayOpen}
                onClose={closeOverlay}
                onConfirm={confirmAction}
                title={title}
                message={message}
                messageTwo={messageTwo}
                confirmText={confirmText}
            />
            {propertyData?.status?.toLowerCase() === "active" ? (
                <>
                    {isEditing ? (
                        !isMlsListing ? (
                            // **Non-MLS Mode & Edit Mode:** Save button on first line, other buttons below
                            <div className="flex flex-col space-y-2">
                                {/* Save Button */}
                                <div
                                    className={`w-full flex ${
                                        isMobile
                                            ? "flex-col items-end justify-center space-y-2"
                                            : "flex-row items-center justify-end space-x-2"
                                    } `}
                                >
                                    <Button
                                        id="cancel-changes-btn"
                                        className={`bg-secondary hover:bg-primary flex items-center justify-center space-x-2 px-4 py-2 ${
                                            isMobile ? "w-full" : "w-fit"
                                        } `}
                                        onClick={() => handleCancel()}
                                        aria-label="Cancel Changes"
                                    >
                                        <MdCancel className="h-5 w-5" />
                                        <span>Cancel</span>
                                    </Button>
                                    <Button
                                        id="save-changes-btn"
                                        className={`bg-secondary hover:bg-primary flex items-center justify-center space-x-2 px-4 py-2 ${
                                            isMobile ? "w-full" : "w-fit"
                                        } `}
                                        onClick={handleSave}
                                        aria-label="Save Changes"
                                    >
                                        <FaSave className="h-5 w-5" />
                                        <span>Save</span>
                                    </Button>
                                </div>

                                {/* Other Buttons */}
                                <div
                                    className={`flex ${
                                        isMobile
                                            ? "flex-col space-y-2"
                                            : "flex-row justify-end space-x-2"
                                    }`}
                                >
                                    {/* Mark Active or Inactive */}
                                    {propertyData?.status === "Inactive" ? (
                                        <Button
                                            id="mark-active-btn"
                                            className="bg-secondary hover:bg-primary flex items-center justify-center space-x-2 px-4 py-2"
                                            onClick={handleMarkActive}
                                            aria-label="Mark as Active"
                                        >
                                            <FaBoxArchive className="h-5 w-5" />
                                            <span>Mark Active</span>
                                        </Button>
                                    ) : (
                                        <Button
                                            id="mark-inactive-btn"
                                            className="bg-secondary hover:bg-primary flex items-center justify-center space-x-2 px-4 py-2"
                                            onClick={handleMarkInactive}
                                            aria-label="Mark as Inactive"
                                        >
                                            <FaBoxArchive className="h-5 w-5" />
                                            <span>Mark Inactive</span>
                                        </Button>
                                    )}

                                    {/* Mark Sold */}
                                    <Button
                                        id="mark-sold-btn"
                                        className="bg-secondary hover:bg-primary flex items-center justify-center space-x-2 px-4 py-2"
                                        onClick={handleMarkSold}
                                        aria-label="Mark as Sold"
                                    >
                                        <AiOutlineFileDone className="h-5 w-5" />
                                        <span>Mark Sold</span>
                                    </Button>

                                    {/* Delete Listing */}
                                    <Button
                                        id="delete-listing-btn"
                                        variant="destructive"
                                        className="flex items-center justify-center space-x-2 px-4 py-2"
                                        onClick={handleListingDelete}
                                        aria-label="Delete Listing"
                                        disabled={mediaLength > 0}
                                    >
                                        <FaTrash className="h-5 w-5" />
                                        <span>Delete Listing</span>
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            // **MLS Mode & Edit Mode:** Existing layout
                            <div className="flex flex-col space-y-2">
                                {/* Top Buttons: Save and Cancel */}
                                <div
                                    className={`flex ${
                                        isMobile
                                            ? "flex-col space-y-2"
                                            : "flex-row justify-end space-x-2"
                                    }`}
                                >
                                    {/* Save Button */}
                                    <Button
                                        onClick={handleSave}
                                        aria-label="Save Changes"
                                        id="save-changes-btn"
                                    >
                                        <FaSave className="h-5 w-5" />
                                        <span>Save</span>
                                    </Button>

                                    {/* Cancel Button */}
                                    <Button
                                        onClick={() => handleCancel()}
                                        aria-label="Cancel Changes"
                                    >
                                        <MdCancel className="h-5 w-5" />
                                        <span>Cancel</span>
                                    </Button>
                                </div>

                                {/* Bottom Buttons: Pause/Resume Campaign and Delete Listing */}
                                <div
                                    className={`flex ${
                                        isMobile
                                            ? "flex-col space-y-2"
                                            : "flex-row justify-end space-x-2"
                                    }`}
                                >
                                    {/* Campaign Buttons */}
                                    {propertyData?.campaignStatus ===
                                    "PAUSED" ? (
                                        <Button
                                            onClick={handleResumeCampaign}
                                            aria-label="Resume Campaign"
                                            id="resume-campaign-btn"
                                        >
                                            <FaPlay className="h-5 w-5" />
                                            <span>Resume Campaign</span>
                                        </Button>
                                    ) : (
                                        propertyData?.campaignStatus ===
                                            "ACTIVE" && (
                                            <Button
                                                onClick={handlePauseCampaign}
                                                aria-label="Pause Campaign"
                                                id="pause-campaign-btn"
                                            >
                                                <FaBoxArchive className="h-5 w-5" />
                                                <span>Pause Campaign</span>
                                            </Button>
                                        )
                                    )}

                                    {/* Delete Listing */}
                                    <Button
                                        onClick={handleListingDelete}
                                        aria-label="Delete Listing"
                                        variant="destructive"
                                        id="delete-listing-btn"
                                        disabled={mediaLength > 0}
                                    >
                                        <FaTrash className="h-5 w-5" />
                                        <span>Delete Listing</span>
                                    </Button>
                                </div>
                            </div>
                        )
                    ) : (
                        // **Non-Editing Mode:**
                        <div
                            className={`flex ${
                                isMobile
                                    ? "flex-col space-y-2"
                                    : "flex-row space-x-4 justify-end"
                            }`}
                        >
                            {/* Sync with MLS (Only if not Mobile) */}
                            {!isMobile && isMlsListing && (
                                <Button
                                    id="sync-mls-btn"
                                    className="bg-secondary hover:bg-primary flex items-center justify-center space-x-2 px-4 py-2"
                                    onClick={handleMLSSync}
                                    disabled={isSyncing}
                                    aria-label="Sync with MLS"
                                >
                                    <FaSync
                                        className={`h-5 w-5 ${
                                            isSyncing ? "animate-spin" : ""
                                        }`}
                                    />
                                    <span>
                                        {isSyncing
                                            ? "Syncing..."
                                            : "Sync with MLS"}
                                    </span>
                                </Button>
                            )}
                            {/* Edit Button */}
                            <Button
                                id="edit-listing-btn"
                                className="bg-secondary hover:bg-primary flex items-center justify-center space-x-2 px-4 py-2"
                                onClick={() => setIsEditing(true)}
                                aria-label="Edit Listing"
                            >
                                <FaEdit className="h-5 w-5" />
                                <span>Edit</span>
                            </Button>
                        </div>
                    )}
                </>
            ) : propertyData?.status?.toLowerCase() === "inactive" ? (
                <div className="flex flex-row justify-end space-x-2">
                    <Button
                        id="mark-active-btn"
                        className="bg-secondary hover:bg-primary flex items-center justify-center space-x-2 px-4 py-2"
                        onClick={handleMarkActive}
                        aria-label="Mark as Active"
                    >
                        <FaBoxArchive className="h-5 w-5" />
                        <span>Mark Active</span>
                    </Button>
                    <Button
                        id="delete-listing-btn"
                        variant="destructive"
                        className="flex items-center justify-center space-x-2 px-4 py-2"
                        onClick={handleListingDelete}
                        aria-label="Delete Listing"
                        disabled={mediaLength > 0}
                    >
                        <FaTrash className="h-5 w-5" />
                        <span>Delete Listing</span>
                    </Button>
                </div>
            ) : (
                <div className="flex flex-row justify-end">
                    <Button
                        id="delete-listing-btn"
                        variant="destructive"
                        className="flex items-center justify-center space-x-2 px-4 py-2"
                        onClick={handleListingDelete}
                        aria-label="Delete Listing"
                        disabled={mediaLength > 0}
                    >
                        <FaTrash className="h-5 w-5" />
                        <span>Delete Listing</span>
                    </Button>
                </div>
            )}
        </div>
    );
};

export default TabButtons;
