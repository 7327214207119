import React from "react";
import { TabsContent } from "../../components/misc/Tabs";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../../components/misc/Card";
import { Label } from "../../components/misc/Label";
import { Input } from "../../components/misc/Input";
import { Limits } from "../types";

interface LimitsTabOverlayProps {
    limits: Limits | null;
    setLimits: React.Dispatch<React.SetStateAction<Limits | null>>;
}

const LimitsTabOverlay: React.FC<LimitsTabOverlayProps> = ({
    limits,
    setLimits,
}) => {
    if (limits === null) {
        return (
            <TabsContent value="userLimits">
                <p>No limits data available.</p>
            </TabsContent>
        );
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setLimits({
            ...limits,
            [name]: Number(value),
        });
    };

    return (
        <TabsContent value="userLimits" className="w-full flex justify-center">
            <Card className="w-full max-w-2xl">
                <CardHeader>
                    <CardTitle>Edit Limits</CardTitle>
                    <CardDescription>
                        Adjust the warning and limit values for various
                        features.
                    </CardDescription>
                </CardHeader>
                <CardContent className="grid gap-6">
                    {Object.entries(limits)
                        .filter(
                            ([key]) =>
                                ![
                                    "_id",
                                    "created_at",
                                    "updatedAt",
                                    "modified_at",
                                    "__v",
                                    "createdAt",
                                ].includes(key)
                        )
                        .map(([key, value]) => (
                            <div
                                key={key}
                                className="grid grid-cols-2 items-center gap-4"
                            >
                                <Label
                                    htmlFor={key}
                                    className="text-right capitalize"
                                >
                                    {key.replace(/([A-Z])/g, " $1").trim()}:
                                </Label>
                                <Input
                                    type="number"
                                    id={key}
                                    name={key}
                                    value={value}
                                    onChange={handleChange}
                                    min={0}
                                    className="w-full"
                                />
                            </div>
                        ))}
                </CardContent>
            </Card>
        </TabsContent>
    );
};

export default LimitsTabOverlay;
