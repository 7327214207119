import { useEffect, useState } from "react";
import debounce from "lodash.debounce";

export const useIsMobile = (width: number) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = debounce(() => {
            setIsMobile(window.innerWidth <= width);
        }, 200);

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
            handleResize.cancel();
        };
    }, [width]);

    return isMobile;
};
