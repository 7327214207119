import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/firebase-config";
import { setIsAdminView } from "../../store/authSlice";
import AgentDetails from "../../components/onboarding/AgentDetails";
import BrokerDetails from "../../components/onboarding/BrokerDetails";
import MLSDetails from "../../components/onboarding/MLSDetails";
import PlanDetails from "../../components/onboarding/PlanDetails";
import Payment from "../../components/onboarding/Payment";
import AddTeamUsers from "../../components/onboarding/AddTeamUsers";
import { Button } from "../../components/misc/Button";

interface Props {
    role: string | null;
    isAdmin: boolean;
}

const OnboardingScreen: React.FC<Props> = ({ role, isAdmin }) => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const advanceStep = () => setCurrentStep(currentStep + 1);
    const goBackStep = () => setCurrentStep(currentStep - 1);

    useEffect(() => {
        if (role === "TEAM_ADMIN") {
            setCurrentStep(6);
        }
    }, [role]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const goToAdminPanel = () => {
        dispatch(setIsAdminView(true));
        sessionStorage.setItem("isAdminView", "true");
        navigate("/admin");
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate("/login");
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <AgentDetails advanceStep={advanceStep} role={role} />;
            case 2:
                return (
                    <BrokerDetails
                        advanceStep={advanceStep}
                        goBackStep={goBackStep}
                        role={role}
                    />
                );
            case 3:
                return (
                    <MLSDetails
                        advanceStep={advanceStep}
                        goBackStep={goBackStep}
                        role={role}
                    />
                );
            case 4:
                return (
                    <PlanDetails
                        isOnboarding={true}
                        advanceStep={advanceStep}
                        goBackStep={goBackStep}
                        role={role}
                    />
                );
            case 5:
                return (
                    <Payment
                        isOnboarding={true}
                        advanceStep={advanceStep}
                        goBackStep={goBackStep}
                        role={role}
                    />
                );
            case 6:
                return <AddTeamUsers role={role} />;
            default:
                return <AgentDetails advanceStep={advanceStep} role={role} />;
        }
    };

    return (
        <div className="relative">
            <div className="absolute top-4 right-4 flex flex-col space-y-2 z-50">
                {isAdmin && (
                    <Button
                        className="bg-primary text-white px-4 py-2 rounded"
                        onClick={goToAdminPanel}
                    >
                        Admin Panel
                    </Button>
                )}
                <Button
                    className="px-4 py-2 rounded border border-gray-300"
                    onClick={handleLogout}
                >
                    Logout
                </Button>
            </div>
            {renderStep()}
        </div>
    );
};

export default OnboardingScreen;
