import { Route } from "react-router-dom";
import HomeScreen from "../screens/user/HomeScreen";
import ListingsScreen from "../screens/user/ListingsScreen";
import PromotionsScreen from "../screens/user/PromotionsScreen";
import ContentScreen from "../screens/user/ContentScreen";
import SettingsScreen from "../screens/user/SettingsScreen";
import Post from "../components/post/Post";
import UserListing from "../components/listings/listing/UserListing";
import CreateListings from "../components/listings/CreateListings";
import CreateCampaign from "../screens/user/CreateCampaign";
import SocialConnections from "../components/settings/Connections/SocialConnections";
import NotificationPreferences from "../components/settings/NotificationPreferences";
import ManageTeam from "../components/settings/ManageTeam";
import PersonalInformation from "../components/settings/PersonalInformation";
import Subscription from "../components/settings/Subscription/Subscription";
import HelpScreen from "../screens/user/HelpScreen";
import { ConnectionsRedirect } from "../screens/ConnectionsRedirect";
import { SocketProvider } from "../context/SocketContext";
import TutorialDetail from "../components/help/tutorials/TutorialDetails";

const userRoutes = (mlsApproved: boolean | null, mlsWelcome: boolean) => (
    <>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/help" element={<HelpScreen />} />
        <Route path="/help/*" element={<TutorialDetail />} />
        <Route
            path="/listings"
            element={
                <ListingsScreen
                    mlsApproved={mlsApproved}
                    mlsWelcome={mlsWelcome}
                />
            }
        />
        <Route
            path="/create-listing"
            element={
                <CreateListings
                    mlsApproved={mlsApproved || false}
                    mlsWelcome={mlsWelcome}
                />
            }
        />
        <Route
            path="/listing"
            element={
                <SocketProvider>
                    <UserListing mlsApproved={mlsApproved} />
                </SocketProvider>
            }
        />
        <Route path="/create-campaign" element={<CreateCampaign />} />
        <Route path="/promotions" element={<PromotionsScreen />} />
        <Route
            path="/my-content"
            element={<ContentScreen mlsApproved={mlsApproved} />}
        />
        <Route path="/settings" element={<SettingsScreen />} />
        <Route
            path="/settings/connections"
            element={
                <SocketProvider>
                    <SocialConnections />
                </SocketProvider>
            }
        />
        <Route path="/connections/redirect" element={<ConnectionsRedirect />} />
        <Route
            path="/settings/notification-preferences"
            element={<NotificationPreferences />}
        />
        <Route path="/settings/manage-team" element={<ManageTeam />} />
        <Route
            path="/settings/personal-information"
            element={<PersonalInformation />}
        />
        <Route path="/settings/subscription" element={<Subscription />} />
        <Route path="/post" element={<Post />} />
    </>
);

export default userRoutes;
