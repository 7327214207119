import React, { useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { DivIcon, LatLngExpression, Marker as LeafletMarker } from "leaflet";
import { FaMapPin } from "react-icons/fa6";
import { renderToString } from "react-dom/server";

interface MapProps {
    latitude: number;
    longitude: number;
    address: string;
}

// Custom marker icon using a div with React icon
const customMarkerIcon: DivIcon = L.divIcon({
    className: "custom-marker-icon",
    html: `<div style="font-size: 32px; color: red;">${renderToString(
        <FaMapPin />
    )}</div>`,
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
});

// Helper component to set custom attribution
const CustomAttribution: React.FC = () => {
    const map = useMap(); // Access the map instance
    useEffect(() => {
        map.attributionControl.setPrefix("");
        map.attributionControl.addAttribution(
            '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
        );
    }, [map]);

    return null;
};

const LeafletMap: React.FC<MapProps> = ({ latitude, longitude, address }) => {
    const markerRef = useRef<LeafletMarker | null>(null);

    // Automatically open the popup when the marker renders
    useEffect(() => {
        if (markerRef.current) {
            markerRef.current.openPopup();
        }
    }, [address]);

    if (!latitude || !longitude || latitude === 0 || longitude === 0) {
        return null;
    }

    const position: LatLngExpression = [latitude, longitude];

    return (
        <div className="h-full w-full">
            <MapContainer
                center={position}
                zoom={13}
                className="h-full w-full"
                attributionControl={true}
            >
                <CustomAttribution />
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker
                    position={position}
                    icon={customMarkerIcon}
                    ref={markerRef}
                >
                    <Popup>{address}</Popup>
                </Marker>
            </MapContainer>
        </div>
    );
};

export default LeafletMap;
