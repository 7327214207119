import React, { useEffect, useRef, useState } from "react";
import { ListingList, NewPostProps, PostRelatedMedia } from "./types";
import { FaTimes } from "react-icons/fa";
import { Button } from "../misc/Button";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../misc/Select";
import { Textarea } from "../misc/Textarea";
import { Popover, PopoverContent, PopoverTrigger } from "../misc/Popover";
import { cn } from "../../utils/classMerger";
import { CalendarIcon, Clock } from "lucide-react";
import { format } from "date-fns";
import CustomDatePicker from "../misc/CutomDatePicker";
import { Label } from "../misc/Label";
import { Card, CardContent, CardFooter, CardTitle } from "../misc/Card";
import { getAuthHeader } from "../../utils/authHeader";
import LoadingScreen from "../../screens/LoadingScreen";
import NoItems from "../misc/NoItems";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import axios from "axios";
import { useIsMobile } from "../../hooks/useIsMobile";
import MediaPickerDialog from "./MediaPickerDialog";
import { EMOJI_CATEGORIES } from "../misc/AllEmojis";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { DateTime } from "luxon";

const NewPost: React.FC<NewPostProps> = ({ setAddPostOverlay }) => {
    const user = useSelector((state: RootState) => state.auth.user);

    const navigate = useNavigate();
    const isMobile = useIsMobile(900);

    const [loading, setLoading] = useState<boolean>(true);

    const [listings, setListings] = useState<ListingList[]>([]);
    const [platforms, setPlatforms] = useState<string[]>([]);
    const [media, setMedia] = useState<PostRelatedMedia | null>(null);

    const [selectedListing, setSelectedListing] = useState<ListingList>();
    const [selectedPlatform, setSelectedPlatform] = useState<string>();

    const [content, setContent] = useState<string>("");

    const [date, setDate] = useState<Date>(new Date());
    const [displayHour, setDisplayHour] = useState<number>(12);
    const [time, setTime] = useState({ hour: 12, minute: 0 });
    const [period, setPeriod] = useState<string>("PM");

    const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
    const [isMediaPickerOpen, setIsMediaPickerOpen] = useState<boolean>(false);

    // Store selected media IDs here
    const [selectedMediaIds, setSelectedMediaIds] = useState<string[]>([]);

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const popoverRef = useRef<HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        const fetchListingList = async () => {
            setLoading(true);

            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            try {
                const response = await axios.get(
                    `${backendUrl}/post/listing-list`,
                    {
                        headers,
                    }
                );

                if (response.status !== 200) {
                    throw new Error(
                        "Failed to fetch listings and promotion list."
                    );
                }

                setListings(response.data);
            } catch (error: unknown) {
                console.error("Error fetching listings: ", error);
                toast.error(
                    <CustomToast
                        message="Failed to fetch listings and promotion list. Please try again later."
                        type="error"
                    />,
                    { autoClose: 3000 }
                );
            } finally {
                setLoading(false);
            }
        };

        const fetchUserPlatforms = async () => {
            setLoading(true);
            try {
                const headers = await getAuthHeader();
                const backendUrl = process.env.REACT_APP_BACKEND_URL;

                const response = await axios.get(
                    `${backendUrl}/socials/profiles`,
                    {
                        headers,
                    }
                );

                if (response.status !== 200) {
                    throw new Error("Failed to fetch social profiles.");
                }

                const responseData = response.data.data || response.data;

                setPlatforms(responseData.activeSocialAccounts);
            } catch (error) {
                console.error("Error fetching social profiles: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchListingList();
        fetchUserPlatforms();
    }, []);

    const handlePlatformChange = (value: string) => {
        setSelectedPlatform(value);
    };

    const chooseImageHandler = async () => {
        if (!selectedListing) {
            toast.error(
                <CustomToast
                    message="Please select a listing or promotion first."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }

        if (!selectedPlatform) {
            toast.error(
                <CustomToast
                    message="Please select a platform first."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }

        if (!content || content.length <= 0) {
            toast.error(
                <CustomToast
                    message="Please enter content for the post."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }

        fetchMedia();
    };

    useEffect(() => {
        setMedia(null);
        setSelectedMediaIds([]);
    }, [selectedListing]);

    const fetchMedia = async () => {
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        try {
            setLoading(true);

            const response = await axios.post(
                `${backendUrl}/post/listing-promotion-media`,
                {
                    type: selectedListing?.listingType,
                    id: selectedListing?.id,
                },
                {
                    headers,
                }
            );

            if (response.status !== 200) {
                throw new Error("Failed to fetch media.");
            }
            console.log("Media response: ", response.data);
            setMedia(response.data);
            // Open media picker once media is fetched
            setIsMediaPickerOpen(true);
        } catch (error: unknown) {
            console.error("Error fetching media: ", error);
            toast.error(
                <CustomToast
                    message="Failed to fetch media. Please try again later."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    const convertTo24Hour = (hour: number, period: string): number => {
        if (period === "PM" && hour !== 12) {
            return hour + 12;
        } else if (period === "AM" && hour === 12) {
            return 0;
        } else {
            return hour;
        }
    };

    const handleCreatePost = async () => {
        if (!user) return;

        if (!selectedListing || !selectedPlatform) {
            toast.error(
                <CustomToast
                    message="Please select a listing/promotion and platform."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }

        if (!content || content.length <= 0) {
            toast.error(
                <CustomToast
                    message="Please enter content for the post."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }

        if (
            selectedPlatform.toLowerCase() === "instagram" &&
            selectedMediaIds.length <= 0
        ) {
            toast.error(
                <CustomToast
                    message="Please select media for the post."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }

        // check if date is in the past
        const hour24 = convertTo24Hour(time.hour, period);

        const scheduledDateTime = DateTime.fromObject(
            {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate(),
                hour: hour24,
                minute: time.minute,
                second: 0,
                millisecond: 0,
            },
            { zone: user?.timeZone || "local" }
        );

        if (
            !scheduledDateTime.isValid ||
            scheduledDateTime.toMillis() <= DateTime.local().toMillis()
        ) {
            toast.error(
                <CustomToast
                    message="Scheduled date and time must be in the future."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }

        // Convert selectedMediaIds into media payload
        const selectedMediaData = selectedMediaIds
            .map((id) => {
                let mediaItem: any = null;
                let type: string = "";

                // Check images
                const imageItem = media?.images?.find((item) => item.id === id);
                if (imageItem) {
                    mediaItem = imageItem;
                    type = "Listing Image";
                }

                // Check videos
                if (!mediaItem) {
                    const videoItem = media?.videos?.find(
                        (item) => item.id === id
                    );
                    if (videoItem) {
                        mediaItem = videoItem;
                        type = "Listing Video";
                    }
                }

                // Check assets
                if (!mediaItem) {
                    const assetItem = media?.assets?.find(
                        (item) => item.id === id
                    );
                    if (assetItem) {
                        mediaItem = assetItem;
                        type =
                            assetItem.assetType &&
                            assetItem.assetType.toLowerCase() === "video"
                                ? "Asset Video"
                                : "Asset Image";
                    }
                }

                // Check promotions
                if (!mediaItem) {
                    const promotionItem = media?.promotionImages?.find(
                        (item) => item.id === id
                    );
                    if (promotionItem) {
                        mediaItem = promotionItem;
                        type = "Asset Image";
                    }
                }

                if (mediaItem && mediaItem.url && type) {
                    return {
                        id: mediaItem.id,
                        url: mediaItem.url,
                        type,
                    };
                }

                return undefined;
            })
            .filter(
                (data): data is { id: string; url: string; type: string } =>
                    data !== undefined
            );

        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        // Construct payload for creating a new post
        const payload = {
            campaignId: selectedListing.campaignId,
            platform: {
                name:
                    selectedPlatform.charAt(0).toUpperCase() +
                    selectedPlatform.slice(1).toLowerCase(),
                jobId: "",
            },
            scheduledFor: getScheduledDateTime(
                date,
                time,
                period,
                user?.timeZone
            ),
            media: selectedMediaData,
            content,
        };

        try {
            setLoading(true);

            const response = await axios.post(
                `${backendUrl}/post/adhoc`,
                payload,
                {
                    headers,
                }
            );

            if (response.status === 201) {
                toast.success(
                    <CustomToast
                        message="Post created successfully."
                        type="success"
                    />,
                    { autoClose: 3000 }
                );
                setAddPostOverlay(false);

                navigate("/post", {
                    state: { postId: response.data.id },
                });
            } else {
                throw new Error("Failed to create post.");
            }
        } catch (error) {
            console.error("Error creating post: ", error);
            toast.error(
                <CustomToast message="Failed to create post." type="error" />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    const handleHourChange = (value: string) => {
        const hour = parseInt(value, 10);
        setDisplayHour(hour);
        setTime((prev) => ({ ...prev, hour }));
    };

    const handleMinuteChange = (value: string) => {
        const minute = parseInt(value, 10);
        setTime((prev) => ({ ...prev, minute }));
    };

    const handlePeriodChange = (value: string) => {
        setPeriod(value);
    };

    const hourOptions = Array.from({ length: 12 }, (_, i) => i + 1);
    const minuteOptions = Array.from({ length: 60 }, (_, i) => i);

    const getScheduledDateTime = (
        date: Date,
        time: { hour: number; minute: number },
        period: string,
        timeZone: string // User's IANA timezone string
    ): string => {
        // Convert 12-hour time to 24-hour format
        const hours24 =
            period === "PM" && time.hour !== 12
                ? time.hour + 12
                : period === "AM" && time.hour === 12
                ? 0
                : time.hour;

        // Create a Luxon DateTime object in the user's timezone
        const scheduledDateTime = DateTime.fromObject(
            {
                year: date.getFullYear(),
                month: date.getMonth() + 1, // Luxon months are 1-based
                day: date.getDate(),
                hour: hours24,
                minute: time.minute,
                second: 0,
                millisecond: 0,
            },
            { zone: timeZone }
        );

        const scheduledDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            hours24,
            time.minute,
            0,
            0
        );

        return scheduledDateTime.toUTC().toISO() || scheduledDate.toISOString();
    };

    const mediaCount = selectedMediaIds.length;

    const handleInsertEmoji = (emoji: string) => {
        if (!textareaRef.current) return;

        const start = textareaRef.current.selectionStart;
        const end = textareaRef.current.selectionEnd;
        const newContent =
            content.substring(0, start) + emoji + content.substring(end);

        setContent(newContent);

        // Force React to rerender with the new content before setting the selection
        setTimeout(() => {
            if (!textareaRef.current) return;
            textareaRef.current.focus();
            const newPosition = start + emoji.length;
            textareaRef.current.setSelectionRange(newPosition, newPosition);
        }, 0);
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                popoverRef.current &&
                !popoverRef.current.contains(event.target as Node)
            ) {
                setIsPopoverOpen(false);
            }
        }

        if (isPopoverOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isPopoverOpen]);

    return (
        <div
            id="new-post-overlay"
            className={`${
                isMediaPickerOpen ? "hidden" : isMobile ? "relative" : "fixed"
            } inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50`}
        >
            {loading && <LoadingScreen />}
            {listings.length <= 0 && !loading && (
                <NoItems
                    title="No Listings or Promotions Found"
                    description="You need to create a listing or a promotion before you can create a post."
                    descriptionTwo="Click the button below to go to the listings page to create a listing."
                    buttonText="Go to Listings"
                    clickHandler={() => navigate("/listings")}
                />
            )}

            <div
                className={`relative ${
                    isMobile ? "w-full h-full" : "w-auto"
                } rounded-lg bg-white p-6 shadow-lg`}
            >
                <FaTimes
                    className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 cursor-pointer"
                    onClick={() => setAddPostOverlay(false)}
                />
                <Card className="pt-4 mt-4">
                    <CardTitle>Create an AdHoc Post</CardTitle>
                    <CardContent>
                        <div className="grid gap-4 py-4">
                            <Label className="text-primary font-semibold">
                                Choose an active listing or promotion
                            </Label>
                            <Select
                                onValueChange={(value) =>
                                    setSelectedListing(
                                        listings.find((l) => l.id === value)
                                    )
                                }
                                value={selectedListing?.id}
                            >
                                <SelectTrigger id="select-listing-promotion">
                                    <SelectValue placeholder="Select a listing/promotion" />
                                </SelectTrigger>
                                <SelectContent id="post-type-select-content">
                                    {listings.map((listing) => (
                                        <SelectItem
                                            key={listing.id}
                                            value={listing.id}
                                        >
                                            {listing.address || "Promotion"}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>

                            <div className="space-y-2">
                                <Label className="text-primary font-semibold">
                                    Choose Social Media Platform
                                </Label>
                                <Select
                                    value={selectedPlatform}
                                    onValueChange={(value) =>
                                        handlePlatformChange(value)
                                    }
                                >
                                    <SelectTrigger id="platform-select">
                                        <SelectValue placeholder="Select platform" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {platforms.map((platform) => (
                                            <SelectItem
                                                key={platform}
                                                value={platform}
                                            >
                                                {platform
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    platform.slice(1)}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>

                            <Textarea
                                ref={textareaRef}
                                placeholder="Enter your post content..."
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                className="min-h-[250px]"
                            />

                            <div className="flex justify-between items-center text-sm text-muted-foreground">
                                {isMobile ? null : (
                                    <div className="flex justify-between items-center">
                                        <Button
                                            id="insert-emoji-btn"
                                            onClick={() =>
                                                setIsPopoverOpen(!isPopoverOpen)
                                            }
                                        >
                                            😊 Insert Emoji
                                        </Button>
                                    </div>
                                )}

                                {/* Custom Popover */}
                                {isPopoverOpen && (
                                    <div
                                        className="absolute bg-white border shadow-lg rounded mt-2 p-2 w-80 z-50"
                                        style={{ top: "150px", left: "50px" }}
                                        ref={popoverRef}
                                    >
                                        <div className="flex justify-end">
                                            <button
                                                onClick={() =>
                                                    setIsPopoverOpen(false)
                                                }
                                                className="p-1 hover:bg-gray-100 rounded"
                                            >
                                                <FaTimes />
                                            </button>
                                        </div>
                                        <div className="max-h-[300px] overflow-auto p-2">
                                            {Object.entries(
                                                EMOJI_CATEGORIES
                                            ).map(([category, emojis]) => (
                                                <div
                                                    key={category}
                                                    className="mb-4"
                                                >
                                                    <h3 className="mb-2 text-sm font-medium text-primary">
                                                        {category
                                                            .replace(/-/g, " ")
                                                            .replace(
                                                                /\b\w/g,
                                                                (c) =>
                                                                    c.toUpperCase()
                                                            )}
                                                    </h3>
                                                    <div className="grid grid-cols-6 gap-2">
                                                        {emojis.map((emoji) => (
                                                            <button
                                                                key={emoji}
                                                                onClick={() =>
                                                                    handleInsertEmoji(
                                                                        emoji
                                                                    )
                                                                }
                                                                className="h-8 w-8 flex items-center justify-center hover:bg-gray-100 rounded"
                                                            >
                                                                {emoji}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                <span>{content.length}/2000 characters</span>
                            </div>

                            <Button
                                id="choose-media-btn"
                                className="w-full"
                                onClick={() => {
                                    // If we already have media fetched and selected, open picker again
                                    // Otherwise, fetch media
                                    if (media) {
                                        setIsMediaPickerOpen(true);
                                    } else {
                                        chooseImageHandler();
                                    }
                                }}
                            >
                                {mediaCount > 0
                                    ? `${mediaCount} media selected`
                                    : "Choose Media"}
                            </Button>

                            <div
                                className={`flex space-x-2 ${
                                    isMobile
                                        ? "flex-col items-center space-y-2"
                                        : "flex-row"
                                }`}
                            >
                                <Popover
                                    open={isCalendarOpen}
                                    onOpenChange={setIsCalendarOpen}
                                >
                                    <PopoverTrigger asChild>
                                        <Button
                                            variant="outline"
                                            className={cn(
                                                "w-[240px] justify-start text-left font-normal",
                                                !date && "text-muted-foreground"
                                            )}
                                        >
                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                            {date ? (
                                                format(date, "PPP")
                                            ) : (
                                                <span>Change date</span>
                                            )}
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent
                                        className="w-auto p-0"
                                        align="start"
                                    >
                                        <CustomDatePicker
                                            date={date}
                                            setDate={setDate}
                                        />
                                    </PopoverContent>
                                </Popover>
                                <div className="flex items-center space-x-2">
                                    {!isMobile ? (
                                        <Clock className="h-4 w-4 text-muted-foreground" />
                                    ) : null}

                                    <Select
                                        value={displayHour.toString()}
                                        onValueChange={handleHourChange}
                                    >
                                        <SelectTrigger className="w-[70px]">
                                            <SelectValue placeholder="Hour" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {hourOptions.map((hour) => (
                                                <SelectItem
                                                    key={hour}
                                                    value={hour.toString()}
                                                >
                                                    {hour
                                                        .toString()
                                                        .padStart(2, "0")}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <Select
                                        value={time.minute.toString()}
                                        onValueChange={handleMinuteChange}
                                    >
                                        <SelectTrigger className="w-[70px]">
                                            <SelectValue placeholder="Min" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {minuteOptions.map((minute) => (
                                                <SelectItem
                                                    key={minute}
                                                    value={minute.toString()}
                                                >
                                                    {minute
                                                        .toString()
                                                        .padStart(2, "0")}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <Select
                                        value={period}
                                        onValueChange={handlePeriodChange}
                                    >
                                        <SelectTrigger
                                            id="am-pm-select"
                                            className="w-[70px]"
                                        >
                                            <SelectValue placeholder="AM/PM" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="AM">
                                                AM
                                            </SelectItem>
                                            <SelectItem value="PM">
                                                PM
                                            </SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                        </div>

                        <CardFooter className="flex justify-between p-0 mt-4">
                            <Button
                                id="cancel-btn"
                                variant="outline"
                                onClick={() => setAddPostOverlay(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                id="create-post-btn"
                                onClick={handleCreatePost}
                            >
                                Create Post
                            </Button>
                        </CardFooter>
                    </CardContent>
                </Card>
            </div>

            {media && selectedPlatform && selectedListing && (
                <MediaPickerDialog
                    isOpen={isMediaPickerOpen}
                    onClose={() => setIsMediaPickerOpen(false)}
                    media={media}
                    selectedMediaIds={selectedMediaIds}
                    setSelectedMediaIds={setSelectedMediaIds}
                    isMobile={isMobile}
                    platform={selectedPlatform}
                    listingId={selectedListing.id}
                    fetchMedia={fetchMedia}
                />
            )}
        </div>
    );
};

export default NewPost;
