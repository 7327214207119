import React, { useState } from "react";
import { TabsContent } from "../../misc/Tabs";
import { faqData } from "./faq-data";
import { motion } from "framer-motion";
import { Input } from "../../misc/Input";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "../../misc/Accordion";

const FAQ = () => {
    const [searchTerm, setSearchTerm] = useState("");

    const filteredFAQs = faqData.filter(
        (faq) =>
            faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
            faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <TabsContent value="faqs">
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="max-w-3xl mx-auto"
                >
                    <h1 className="text-4xl font-bold text-center mb-8 text-primary">
                        Frequently Asked Questions
                    </h1>
                    <div className="mb-8">
                        <Input
                            type="text"
                            placeholder="Search FAQs..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full"
                        />
                    </div>
                    <Accordion type="single" collapsible className="space-y-4">
                        {filteredFAQs.map((faq, index) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{
                                    duration: 0.3,
                                    delay: index * 0.1,
                                }}
                            >
                                <AccordionItem
                                    value={`item-${index}`}
                                    className="bg-white rounded-lg shadow-md"
                                >
                                    <AccordionTrigger className="px-4 py-3 text-left text-gray-900 hover:text-gray-600 transition-colors duration-200">
                                        {faq.question}
                                    </AccordionTrigger>
                                    <AccordionContent className="px-4 py-3 text-gray-700">
                                        {faq.answer}
                                    </AccordionContent>
                                </AccordionItem>
                            </motion.div>
                        ))}
                    </Accordion>
                    {filteredFAQs.length === 0 && (
                        <p className="text-center text-gray-500 mt-8">
                            No matching FAQs found. Please try a different
                            search term.
                        </p>
                    )}
                </motion.div>
            </div>
        </TabsContent>
    );
};

export default FAQ;
