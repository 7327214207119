import React, { useEffect, useState, KeyboardEvent, ChangeEvent } from "react";
import { Button } from "../misc/Button";
import logo from "../../assets/images/logo.png";
import { auth } from "../../firebase/firebase-config";
import { getIdToken } from "firebase/auth";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import "react-toastify/dist/ReactToastify.css";
import LoadingScreen from "../../screens/LoadingScreen";
import { UserLimitInfo } from "./UserLimitInfo";
import { TeamMembersList } from "./TeamMemberList";
import { FaPlus } from "react-icons/fa";
import InputField from "../misc/InputField";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsOnboarded } from "../../store/authSlice";
import { isValidEmail } from "../../utils/validationUtil";
import { useIsMobile } from "../../hooks/useIsMobile";
import { InviteUserDto } from "../../types/invite-user.dto";

interface AddTeamUsersProps {
    role: string | null;
}

interface EmailStatus {
    email: string;
    error: string | null;
}

const AddTeamUsers: React.FC<AddTeamUsersProps> = ({ role }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [users, setUsers] = useState<InviteUserDto[]>([]);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [userLimit, setUserLimit] = useState<number>(0);
    const [added, setAdded] = useState<boolean>(false);
    const [addedValue, setAddedValue] = useState<EmailStatus[]>([]);

    const getAuthHeader = async () => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            const token = await getIdToken(currentUser, true);
            return { Authorization: `Bearer ${token}` };
        }
        throw new Error("User is not authenticated");
    };

    useEffect(() => {
        const fetchAddUserLimit = async () => {
            try {
                setLoading(true);
                const headers = await getAuthHeader();
                const backendUrl = process.env.REACT_APP_BACKEND_URL;
                const response = await axios.get(
                    `${backendUrl}/onboarding/add-limit`,
                    {
                        headers,
                        withCredentials: true,
                    }
                );
                setUserLimit(response.data);
            } catch (error) {
                console.error("Error fetching agent details:", error);
                toast.error(
                    <CustomToast
                        message="Failed to fetch agent details. Please try again."
                        type="error"
                    />,
                    { autoClose: 3000 }
                );
            } finally {
                setLoading(false);
            }
        };

        fetchAddUserLimit();
    }, []);

    const handleAddUser = () => {
        if (!firstName || !lastName || !email) {
            toast.error(
                <CustomToast message="All fields are required." type="error" />,
                { autoClose: 3000 }
            );
            return;
        }

        if (!isValidEmail(email)) {
            toast.error(
                <CustomToast
                    message="Please enter a valid email address."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }

        if (users.length < userLimit) {
            setUsers([
                ...users,
                {
                    nameFirst: firstName,
                    nameLast: lastName,
                    email: email.trim(),
                },
            ]);
            setFirstName("");
            setLastName("");
            setEmail("");
        } else {
            toast.error(
                <CustomToast
                    message={`You can only add up to ${userLimit} members.`}
                    type="error"
                />,
                { autoClose: 3000 }
            );
        }
    };

    const handleKeyDown = (
        e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleAddUser();
        }
    };

    const handleNext = async (skip: Boolean) => {
        setLoading(true);

        if (!skip && users.length <= 0) {
            toast.error(
                <CustomToast
                    message="Please add at least one user."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            setLoading(false);
            return;
        }

        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const payload = skip ? {} : users;
        const response = await axios.post(
            `${backendUrl}/onboarding/add-team-users`,
            payload,
            { headers, withCredentials: true }
        );

        if (response.status === 201) {
            setLoading(false);
            toast.success(
                <CustomToast
                    message="Users added successfully"
                    type="success"
                />,
                { autoClose: 3000 }
            );

            if (skip) {
                handleExit();
            }
            setAdded(true);
            setAddedValue(response.data);
        } else {
            setLoading(false);
            toast.error(
                <CustomToast
                    message="Error adding users and activating account. Please contact support."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        }
    };

    const handleExit = async () => {
        dispatch(setIsOnboarded(true));
        window.location.reload();
        navigate("/connect-socials");
    };

    const isMobile = useIsMobile(900);

    return (
        <main
            className={`flex overflow-hidden flex-col justify-center items-center h-screen ${
                isMobile ? "bg-white" : "bg-background"
            } shadow-[0px_4px_4px_rgba(0,0,0,0.25)] px-4`}
        >
            <section className="flex flex-col items-center px-8 py-20 bg-white rounded-2xl w-full max-w-[905px] max-md:px-5 max-md:py-10 justify-between">
                {loading && <LoadingScreen />}
                <div className="flex flex-col items-center w-full">
                    <img
                        loading="lazy"
                        src={logo}
                        alt="Agent Registration Logo"
                        className="object-contain self-center max-w-full w-full aspect-[8.77] max-md:w-3/4"
                    />
                    <div className="flex flex-col mt-8 w-full max-w-[695px]">
                        <h1 className="text-2xl font-medium tracking-tighter text-primary">
                            Add Team Members
                        </h1>
                        {!added && (
                            <form className="flex flex-col mt-2.5 w-full gap-4 justify-center">
                                <div className="flex flex-col md:flex-row justify-between gap-4 w-full">
                                    <InputField
                                        label="First Name"
                                        placeholder="Ex: John"
                                        type="text"
                                        value={firstName}
                                        onChange={(
                                            e: ChangeEvent<
                                                | HTMLInputElement
                                                | HTMLTextAreaElement
                                            >
                                        ) => setFirstName(e.target.value)}
                                        className="w-full"
                                    />
                                    <InputField
                                        label="Last Name"
                                        placeholder="Ex: Doe"
                                        type="text"
                                        value={lastName}
                                        onChange={(
                                            e: ChangeEvent<
                                                | HTMLInputElement
                                                | HTMLTextAreaElement
                                            >
                                        ) => setLastName(e.target.value)}
                                        className="w-full"
                                    />
                                </div>
                                <InputField
                                    label="Email"
                                    placeholder="Enter email"
                                    type="email"
                                    value={email}
                                    onChange={(
                                        e: ChangeEvent<
                                            | HTMLInputElement
                                            | HTMLTextAreaElement
                                        >
                                    ) => setEmail(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                />

                                <Button
                                    variant="secondary"
                                    type="button"
                                    onClick={handleAddUser}
                                >
                                    <FaPlus />
                                    <span className="ml-2">Add User</span>
                                </Button>
                            </form>
                        )}
                    </div>
                    <UserLimitInfo users={users} totalLimit={userLimit} />
                    <TeamMembersList
                        users={users}
                        setUsers={setUsers}
                        addedValue={addedValue}
                        added={added}
                    />
                </div>
                {added ? (
                    <Button className="w-1/3" onClick={handleExit}>
                        Next
                    </Button>
                ) : (
                    <div className="w-full flex flex-col md:flex-row justify-between mt-8 gap-4">
                        <Button
                            className="w-full md:w-1/3"
                            onClick={() => handleNext(true)}
                        >
                            Skip
                        </Button>
                        <Button
                            className="w-full md:w-1/3"
                            onClick={() => handleNext(false)}
                        >
                            Submit
                        </Button>
                    </div>
                )}
            </section>
        </main>
    );
};

export default AddTeamUsers;
