import React from "react";
import { Card } from "../../misc/Card";
import { Avatar, AvatarImage, AvatarFallback } from "../../misc/Avatar";
import { SocialCardProps } from "../dto/types";

interface SocialCardExtendedProps extends SocialCardProps {
}

const SocialCard: React.FC<SocialCardExtendedProps> = ({
    title,
    icon,
    connected,
    userData,
    isOnboarding,
}) => {
    return (
        <Card
            className={`p-4 flex flex-col items-center bg-white text-primary rounded-lg shadow-md transition-colors duration-300 relative`}
        >
            <div className="flex flex-col items-center w-full">
                <div className="flex items-center justify-center mb-2 rounded-full bg-white p-1">
                    {icon}
                </div>
                <h3 className="text-lg font-semibold">{title}</h3>
                {connected ? (
                    <div className="w-full flex flex-col items-center">
                        <p className={`text-sm mt-1 text-green-600`}>
                            Connected
                        </p>
                        <div className="flex items-center justify-center space-x-3 mb-2">
                            <Avatar>
                                {userData.userImage ? (
                                    <AvatarImage
                                        src={userData.userImage}
                                        alt={userData.displayName}
                                    />
                                ) : (
                                    <AvatarFallback>
                                        {userData.displayName.charAt(0)}
                                    </AvatarFallback>
                                )}
                            </Avatar>

                            <div>
                                <div className="font-semibold">
                                    {userData.displayName}
                                </div>
                                {title === "Facebook" && connected ? (
                                    <div className="text-xs text-muted-foreground">
                                        Page Name: {userData.pageName}
                                    </div>
                                ) : (
                                    <div className="text-xs text-muted-foreground">
                                        Username: {userData.username}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <p className={`text-sm mt-1 text-red-600`}>Not Connected</p>
                )}
            </div>
        </Card>
    );
};

export default SocialCard;
