import React, { useEffect, useState } from "react";
import { TabsContent } from "../misc/Tabs";
import { Card, CardContent } from "../misc/Card";
import {
    categories,
    PromotionTabProps,
    PromotionType,
    samplePosts,
} from "./types";
import { Switch } from "../misc/Switch";
import { FaCircleInfo, FaComment, FaShare, FaThumbsUp } from "react-icons/fa6";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from "../misc/Dialog";
import { FaTimes, FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Label } from "../misc/Label";
import { Popover, PopoverContent, PopoverTrigger } from "../misc/Popover";
import { Button } from "../misc/Button";
import { Paintbrush } from "lucide-react";
import { colors } from "../../utils/colors";
import { useIsMobile } from "../../hooks/useIsMobile";
import { getImage } from "../../utils/dexieImageCache";

const PromotionTab: React.FC<PromotionTabProps> = ({
    isEditing,
    selectedCategories,
    setSelectedCategories,
    promotion,
    setPromotion,
}) => {
    const { user } = useSelector((state: RootState) => state.auth);

    const [open, setOpen] = useState(false);
    const [infoOverlay, setInfoOverlay] = useState<boolean>(false);
    const [selectedInfo, setSelectedInfo] = useState<string | null>(null);

    const userName = user?.nameFirst + " " + user?.nameLast || "Jane Doe";
    const [userAvatar, setUserAvatar] = useState<string | null>(null);

    useEffect(() => {
        const fetchUserAvatar = async () => {
            const avatar =
                (await getImage(user?.profileImageKey || "")) ||
                user?.photoURL ||
                user?.profileImage ||
                null;
            setUserAvatar(
                avatar instanceof Blob ? URL.createObjectURL(avatar) : avatar
            );
        };
        fetchUserAvatar();
    }, [user]);

    // Function to toggle category selection
    const handleCategoryToggle = (categoryId: PromotionType) => {
        setSelectedCategories(
            selectedCategories.includes(categoryId)
                ? selectedCategories.filter((id) => id !== categoryId)
                : [...selectedCategories, categoryId]
        );
    };

    const handleCategoryInfo = (categoryLabel: string) => {
        setInfoOverlay(true);
        setSelectedInfo(categoryLabel);
    };

    const selectedPost = samplePosts.find(
        (post) => post.label === selectedInfo
    );

    const isMobile = useIsMobile(1300);

    return (
        <TabsContent value="categories" className="relative">
            <Card>
                <CardContent className="relative pt-6">
                    <h3
                        className={`relative font-bold text-xl text-primary ${
                            isMobile ? "text-lg mb-4" : "mb-6"
                        }`}
                    >
                        Promotion Categories
                    </h3>
                    {promotion ? (
                        <div
                            className={`${
                                isMobile
                                    ? "relative w-full px-4 my-0"
                                    : "absolute top-6 right-4 shadow-md space-y-2 p-4 rounded-md"
                            } bg-white `}
                        >
                            <Label className="font-bold text-base text-primary">
                                Campaign Color
                            </Label>
                            <div className="flex items-center gap-4 flex-wrap">
                                <div
                                    className="w-8 h-8 rounded-md border shadow-sm"
                                    style={{ backgroundColor: promotion.color }}
                                />
                                {isEditing && (
                                    <Popover open={open} onOpenChange={setOpen}>
                                        <PopoverTrigger asChild>
                                            <Button
                                                variant="outline"
                                                className="flex items-center gap-2 h-8 w-full sm:w-auto"
                                            >
                                                <Paintbrush className="w-4 h-4" />
                                                Select Color
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent className="w-80 p-4">
                                            <div className="grid grid-cols-5 gap-2">
                                                {colors.map((color) => (
                                                    <button
                                                        key={color}
                                                        className="w-6 h-6 rounded-sm border shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                                                        style={{
                                                            backgroundColor:
                                                                color,
                                                        }}
                                                        onClick={() => {
                                                            setPromotion({
                                                                ...promotion,
                                                                color,
                                                            });
                                                            setOpen(false); // Close the popover
                                                        }}
                                                    />
                                                ))}
                                            </div>
                                            <p className="mt-4 text-sm text-muted-foreground">
                                                The campaign color is used to
                                                quickly identify social media
                                                posts that are connected to this
                                                listing's marketing campaign.
                                                This color will not be visible
                                                on social media.
                                            </p>
                                        </PopoverContent>
                                    </Popover>
                                )}
                            </div>
                        </div>
                    ) : null}

                    <div
                        className={`py-4 px-2 grid gap-4 ${
                            isMobile
                                ? "grid-cols-1"
                                : `grid-cols-2 ${isEditing ? "mt-28" : "mt-16"}`
                        }`}
                    >
                        {categories.map((category) => {
                            const Icon = category.icon;
                            const isSelected = selectedCategories.includes(
                                category.id as PromotionType
                            );
                            return (
                                <div
                                    id={category.id}
                                    key={category.id}
                                    className={`flex ${
                                        isMobile
                                            ? "flex-col items-center"
                                            : "flex-row items-center"
                                    } space-x-0 md:space-x-3 p-3 rounded-lg transition-colors ${
                                        selectedCategories.includes(
                                            category.id as PromotionType
                                        )
                                            ? isEditing
                                                ? "bg-lightSecondary"
                                                : "bg-secondary"
                                            : "bg-gray-50"
                                    } cursor-pointer hover:bg-lightSecondary`}
                                    onClick={() =>
                                        isEditing &&
                                        handleCategoryToggle(
                                            category.id as PromotionType
                                        )
                                    }
                                >
                                    <div
                                        className={`p-1 rounded ${
                                            isSelected
                                                ? "bg-primary text-secondary"
                                                : "bg-secondary text-white"
                                        } flex-shrink-0`}
                                    >
                                        <Icon className={`w-4 h-4`} />
                                    </div>
                                    <div
                                        className={`flex-grow ${
                                            isMobile ? "text-left mt-2" : ""
                                        }`}
                                    >
                                        <label
                                            htmlFor={category.id}
                                            className={`text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 ${
                                                isMobile ? "ml-0" : "ml-2"
                                            }`}
                                        >
                                            {category.label}
                                        </label>
                                    </div>
                                    {isEditing ? (
                                        <Switch
                                            id={category.id}
                                            checked={isSelected}
                                            onChange={() =>
                                                handleCategoryToggle(
                                                    category.id as PromotionType
                                                )
                                            }
                                            className={`mt-2 ${
                                                isMobile ? "self-end" : ""
                                            }`}
                                        />
                                    ) : (
                                        <FaCircleInfo
                                            className={`${
                                                selectedCategories.includes(
                                                    category.id as PromotionType
                                                )
                                                    ? "text-white"
                                                    : "text-secondary"
                                            } w-6 h-6 hover:text-primary mt-2`}
                                            onClick={() => {
                                                handleCategoryInfo(
                                                    category.label
                                                );
                                            }}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </CardContent>
            </Card>
            {/* Category Info Overlay */}
            {infoOverlay ? (
                <Dialog
                    open={infoOverlay}
                    onOpenChange={() => {
                        setInfoOverlay(false);
                    }}
                >
                    <DialogContent
                        className={`relative  bg-white ${
                            isMobile
                                ? "p-4 w-full h-full m-0 flex flex-col items-center justify-center"
                                : "p-10 w-auto flex flex-col items-center justify-center"
                        }`}
                    >
                        <button
                            className="absolute top-4 right-4 text-secondary hover:text-primary"
                            onClick={() => {
                                setInfoOverlay(false);
                            }}
                        >
                            <FaTimes className="h-6 w-6" />
                        </button>
                        <DialogHeader className="mb-4">
                            <DialogTitle>{selectedInfo}</DialogTitle>
                            <DialogDescription className="pt-2">
                                This is a sample post. Actual posts will use
                                user uploaded images first, or if none are
                                available will use an image from Marabot’s stock
                                photo library.
                            </DialogDescription>
                        </DialogHeader>

                        {selectedPost ? (
                            <div
                                className={`max-w-md bg-white shadow-md rounded-lg overflow-hidden my-4 ${
                                    isMobile ? "w-full" : ""
                                }`}
                            >
                                {/* Header */}
                                <div className="flex items-center px-4 py-3">
                                    {userAvatar ? (
                                        <img
                                            className="h-10 w-10 rounded-full object-cover"
                                            src={userAvatar}
                                            alt={`${userName}'s avatar`}
                                        />
                                    ) : (
                                        <FaUserCircle className="h-10 w-10 text-gray-400" />
                                    )}
                                    <div className="ml-3">
                                        <span className="block text-gray-800 font-semibold">
                                            {userName}
                                        </span>
                                        <span className="block text-gray-500 text-sm">
                                            {new Date().toLocaleDateString()}
                                        </span>
                                    </div>
                                </div>

                                {/* Post Image */}
                                <img
                                    className="w-full h-full object-fill"
                                    src={selectedPost.img}
                                    alt={selectedPost.label}
                                />

                                {/* Post Text */}
                                <div className="px-4 py-3">
                                    <p className="text-gray-700">
                                        {selectedPost.text}
                                    </p>
                                </div>

                                {/* Action Buttons */}
                                <div className="flex items-center justify-between px-4 py-2 border-t">
                                    <button className="flex items-center justify-center text-gray-600 hover:text-blue-500">
                                        <FaThumbsUp className="h-5 w-5 text-secondary mr-1" />
                                        <span className="flex items-center text-primary">
                                            Like
                                        </span>
                                    </button>
                                    <button className="flex items-center text-gray-600 hover:text-blue-500">
                                        <FaComment className="h-5 w-5 text-secondary mr-1" />
                                        <span className="flex items-center text-primary">
                                            Comment
                                        </span>
                                    </button>
                                    <button className="flex items-center text-gray-600 hover:text-blue-500">
                                        <FaShare className="h-5 w-5 text-secondary mr-1" />
                                        <span className="flex items-center text-primary">
                                            Share
                                        </span>
                                    </button>
                                </div>
                            </div>
                        ) : null}
                    </DialogContent>
                </Dialog>
            ) : null}
        </TabsContent>
    );
};

export default PromotionTab;
