import React, { useEffect, useMemo } from "react";
import { TabsContent } from "../misc/Tabs";
import { ResourcesProps, ResourceType, resourceTypes } from "./types";
import { Card, CardContent } from "../misc/Card";
import CustomSelect from "../misc/CustomSelect";
import { OptionType } from "../../types/option-type";
import MediaThumbnails from "../promotions/MediaThumbnail";
import { itemsPerPage } from "../promotions/types";
import { Button } from "../misc/Button";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import DragAndDropMedia from "../misc/DragAndDrop";
import { Assets } from "../../types/asset-type";
import { DeleteMediaButton } from "../promotions/DeleteMediaButton";
import { calculateTotalPages } from "../promotions/util";
import { useIsMobile } from "../../hooks/useIsMobile";

const ResourcesTab: React.FC<ResourcesProps> = ({
    resources,
    isEditing,
    carouselApi,
    carouselIndexMap,
    fetchResources,
    setLoading,
    activeResourceType,
    setActiveResourceType,
    onThumbnailClick,
    isUploading,
    setIsUploading,
}) => {
    const isMobile = useIsMobile(900);

    const perPage = isMobile ? 4 : itemsPerPage;

    const [selectedMedia, setSelectedMedia] = React.useState<Assets[]>([]);
    const [currentPages, setCurrentPages] = React.useState<{
        [key in ResourceType]?: number;
    }>(() => {
        const initialPages: { [key in ResourceType]?: number } = {};
        resourceTypes.forEach((type) => {
            initialPages[type] = 1;
        });
        return initialPages;
    });

    useEffect(() => {
        setCurrentPages((prevPages) => {
            const updatedPages = { ...prevPages };
            resourceTypes.forEach((type) => {
                if (!(type in updatedPages)) {
                    updatedPages[type] = 1;
                }
            });
            return updatedPages;
        });

        // Reset activePromotionType if it's no longer valid
        if (activeResourceType && !resourceTypes.includes(activeResourceType)) {
            if (resourceTypes.length > 0) {
                setActiveResourceType(ResourceType.Image);
            }
        }
    }, [activeResourceType, setActiveResourceType]);

    const resourceTypeOptions: OptionType[] = resourceTypes.map((type) => {
        const count = resources?.filter(
            (asset) => asset.assetType === type
        ).length;

        return {
            value: type,
            label: `${
                type.charAt(0).toUpperCase() +
                type.slice(1).replace(/([A-Z])/g, " $1")
            } (${count})`,
        };
    });

    const filteredAssets = useMemo(() => {
        if (!activeResourceType) return [];
        return resources?.filter(
            (asset) => asset.assetType === activeResourceType
        );
    }, [activeResourceType, resources]);

    const currentPage = activeResourceType
        ? filteredAssets?.length || [].length > 0
            ? currentPages[activeResourceType] || 1
            : 0
        : 0;

    const totalPages = useMemo(() => {
        if (!activeResourceType) return 1;
        return calculateTotalPages(filteredAssets.length || 0, perPage);
    }, [activeResourceType, filteredAssets, perPage]);

    // Toggle media selection
    const toggleSelectMedia = (media: Assets) => {
        setSelectedMedia(
            selectedMedia.some((m) => m.id === media.id)
                ? selectedMedia.filter((m) => m.id !== media.id)
                : [...selectedMedia, media]
        );
    };

    const handlePageChange = (newPage: number) => {
        if (activeResourceType) {
            setCurrentPages((prevPages) => ({
                ...prevPages,
                [activeResourceType]: newPage,
            }));
        }
    };

    useEffect(() => {
        if (activeResourceType) {
            setCurrentPages((prevPages) => ({
                ...prevPages,
                [activeResourceType]: 1,
            }));
        }
    }, [activeResourceType, resources]);

    return (
        <TabsContent value="resource">
            <Card>
                <CardContent className="pt-6 bg-neutral-100">
                    <div className="flex justify-center">
                        <div className={isMobile ? "w-[80%]" : "w-[30%]"}>
                            <CustomSelect
                                id="resource-type-select-dropdown"
                                options={resourceTypeOptions}
                                value={
                                    activeResourceType
                                        ? resourceTypeOptions.find(
                                              (option) =>
                                                  option.value ===
                                                  activeResourceType
                                          )
                                        : null
                                }
                                onChange={(selectedOption) => {
                                    setActiveResourceType(
                                        selectedOption?.value as ResourceType
                                    );
                                }}
                                placeholder="Select Promotion Type"
                                isClearable={false}
                                className="mb-6 w-full"
                                bg="#F5F5F5"
                            />
                        </div>
                    </div>
                    {/* Asset Thumbnails and Controls */}
                    {resourceTypes.length > 0 ? (
                        <div className="p-4 bg-white">
                            <MediaThumbnails
                                assets={filteredAssets?.slice(
                                    (currentPage - 1) * perPage,
                                    currentPage * perPage
                                )}
                                currentPage={currentPage}
                                toggleSelectMedia={toggleSelectMedia}
                                selectedMedia={selectedMedia}
                                isEditing={isEditing}
                                carouselApi={carouselApi}
                                carouselAssetIndexMap={carouselIndexMap}
                                isMobile={isMobile}
                                onThumbnailClick={onThumbnailClick}
                            />
                            <div className="flex justify-between items-center mt-4">
                                <Button
                                    variant="outline"
                                    onClick={() =>
                                        handlePageChange(
                                            Math.max(currentPage - 1, 1)
                                        )
                                    }
                                    disabled={currentPage <= 1}
                                >
                                    <FaArrowLeft className="mr-2 h-4 w-4" />{" "}
                                    Previous
                                </Button>
                                <span>
                                    {isMobile ? "" : "Page"} {currentPage} of{" "}
                                    {totalPages}
                                </span>
                                <Button
                                    variant="outline"
                                    onClick={() =>
                                        handlePageChange(
                                            Math.min(
                                                currentPage + 1,
                                                totalPages
                                            )
                                        )
                                    }
                                    disabled={currentPage === totalPages}
                                >
                                    Next{" "}
                                    <FaArrowRight className="ml-2 h-4 w-4" />
                                </Button>
                            </div>
                            {isEditing && selectedMedia.length > 0 ? (
                                <div className="my-2 flex justify-end">
                                    <DeleteMediaButton
                                        selectedMedia={selectedMedia}
                                        setLoading={setLoading}
                                        fetchAssets={fetchResources}
                                        setSelectedMedia={setSelectedMedia}
                                    />
                                </div>
                            ) : null}
                            <DragAndDropMedia
                                type={
                                    activeResourceType === ResourceType.Video
                                        ? "assetVideo"
                                        : activeResourceType ===
                                          ResourceType.Logo
                                        ? "logo"
                                        : "assetImage"
                                }
                                onUploadComplete={fetchResources}
                                assetType={activeResourceType}
                                isUploading={isUploading}
                                setIsUploading={setIsUploading}
                            />
                        </div>
                    ) : (
                        <span>No Options Available</span>
                    )}
                </CardContent>
            </Card>
        </TabsContent>
    );
};

export default ResourcesTab;
