import { MLSFeatures } from "../components/listings/types";
import { AllListings } from "./allListings";
import { Images } from "./listingImages";
import { ListingVideos } from "./listingVideos";

export interface Features {
    architectureType: string;
    cooling: boolean;
    coolingType?: string;
    exteriorType: string;
    fireplace: boolean;
    fireplaceType?: string;
    floorCount: number;
    foundationType: string;
    garage: boolean;
    garageSpaces?: number;
    garageType?: string;
    heating: boolean;
    heatingType?: string;
    pool: boolean;
    poolType?: string;
    roofType: string;
    roomCount: number;
    unitCount: number;
    viewType?: string;
}

export interface ComfirmListing {
    _id: string;
    longitude: number;
    latitude: number;
    formattedAddress: string;
    propertyType: string;
    bedrooms: string;
    bathrooms: string;
    squareFootage: string;
    lotSize: string;
    yearBuilt: string;
    currentPrice?: number;
    legalDescription: string;
    features: Features | MLSFeatures;
    footer: {
        agentName: string;
        agentPhone: string;
        agentAgency: string;
        agencyPhone: string;
        sourceMLS: string;
        sourceMLSId: string;
    };
    status?: string;
}

export interface UserListing extends ComfirmListing, AllListings {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    county?: string;
    overlayUrl: string;
    droneVideo?: string;
    listingUrl?: string;
    images?: Images[];
    videos?: ListingVideos[];
    additionalContent?: string;
    userNotes?: string;
    listingType: ListingType;
}

export enum ListingType {
    MLSListing = "MLSListing",
    RentcastListing = "RentcastListing",
    Promotion = "Promotion",
}
