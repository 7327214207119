import React, { ComponentType, useState } from "react";
import {
    signInWithGoogle,
    signInWithFacebook,
    signInWithEmail,
} from "../firebase/auth";
import InputField from "../components/misc/InputField";
import Checkbox from "../components/misc/Checkbox";
import Button from "../components/Button";
import logo from "../assets/images/logo.png";
import { createButton } from "react-social-login-buttons";
import { FaFacebookF } from "react-icons/fa";
import { toast } from "react-toastify";
import CustomToast from "../components/misc/CustomToast";
import "react-toastify/dist/ReactToastify.css";
import { isValidEmail } from "../utils/validationUtil";
import LoadingScreen from "./LoadingScreen";
import { getAuthHeader } from "../utils/authHeader";
import axios from "axios";
import Footer from "../components/misc/Footer";
import { useIsMobile } from "../hooks/useIsMobile";

const LoginScreen: React.FC = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [loading, setLoading] = useState(false);
    const [platform, setPlatform] = useState<"google" | "facebook">();

    const handleGoogleLogin = async () => {
        setLoading(true);
        setPlatform("google");
        try {
            const user = await signInWithGoogle();
            console.log("Google Sign-In Success:", user);
            toast.success(
                <CustomToast
                    message="Google login successful!"
                    type="success"
                />,
                { autoClose: 3000 }
            );

            await createUserOnBackend();

            //REFRESH PAGE
            window.location.reload();
        } catch (error) {
            setLoading(false);
            console.error("Google Sign-In Error:", error);
            toast.error(
                <CustomToast
                    message="Login failed: Google authentication error."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false); // Set loading to false
        }
    };

    const handleFacebookLogin = async () => {
        setLoading(true);
        setPlatform("facebook");
        try {
            const user = await signInWithFacebook();
            console.log("Facebook Sign-In Success:", user);
            toast.success(
                <CustomToast
                    message="Facebook login successful!"
                    type="success"
                />,
                { autoClose: 3000 }
            );

            await createUserOnBackend();

            //REFRESH PAGE
            window.location.reload();
        } catch (error) {
            setLoading(false);
            console.error("Facebook Sign-In Error:", error);
            toast.error(
                <CustomToast
                    message="Login failed: Facebook authentication error."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false); // Set loading to false
        }
    };

    const handleEmailSignIn = async () => {
        if (!email || !password) {
            toast.error(
                <CustomToast
                    message="Please enter your email and password."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }

        if (!isValidEmail(email)) {
            toast.error(
                <CustomToast
                    message="Please enter a valid email address."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }

        setLoading(true);
        try {
            const user = await signInWithEmail(email, password, rememberMe);
            console.log("Email Sign-In Success:", user);
            toast.success(
                <CustomToast message="Login successful!" type="success" />,
                { autoClose: 3000 }
            );
        } catch (error) {
            setLoading(false);
            console.error("Email Sign-In Error:", error);
            toast.error(
                <CustomToast
                    message="Incorrect email or password. Please try again."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false); // Set loading to false
        }
    };

    const createUserOnBackend = async () => {
        const headers = await getAuthHeader();
        try {
            const url = `${process.env.REACT_APP_BACKEND_URL}/initialize-new-user`;
            const res = await axios.post(
                url,
                {},
                {
                    headers,
                }
            );

            if (res.status === 201) {
                toast.success(
                    <CustomToast
                        message="User created successfully!"
                        type="success"
                    />,
                    {
                        autoClose: 3000,
                    }
                );

                //REFRESH PAGE
                window.location.reload();
            } else {
                throw new Error("Failed to create user on backend.");
            }
            console.log("User creation on backend successful");
        } catch (error) {
            console.error("Error creating user on backend:", error);
            toast.error(
                <CustomToast message="User created failed!" type="error" />,
                {
                    autoClose: 3000,
                }
            );
        }
    };

    const facebookButtonConfig = {
        text: "Sign in with Facebook",
        icon: FaFacebookF as ComponentType<{
            size: string | number;
            color: string;
        }>,
        iconFormat: (name: string) => `fa fa-${name}`,
        style: {
            background: "#1877F2",
            color: "#fff",
            display: "flex",
            justifyContent: "center",
        },
        activeStyle: { background: "#166fe5" },
    };

    const GoogleSvgIcon = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 326667 333333"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
            imageRendering="optimizeQuality"
            fillRule="evenodd"
            clipRule="evenodd"
            className="w-10 h-6 mr-3"
        >
            <path
                d="M326667 170370c0-13704-1112-23704-3518-34074H166667v61851h91851c-1851 15371-11851 38519-34074 54074l-311 2071 49476 38329 3428 342c31481-29074 49630-71852 49630-122593m0 0z"
                fill="#4285f4"
            />
            <path
                d="M166667 333333c44999 0 82776-14815 110370-40370l-52593-40742c-14074 9815-32963 16667-57777 16667-44074 0-81481-29073-94816-69258l-1954 166-51447 39815-673 1870c27407 54444 83704 91852 148890 91852z"
                fill="#34a853"
            />
            <path
                d="M71851 199630c-3518-10370-5555-21482-5555-32963 0-11482 2036-22593 5370-32963l-93-2209-52091-40455-1704 811C6482 114444 1 139814 1 166666s6482 52221 17777 74814l54074-41851m0 0z"
                fill="#fbbc04"
            />
            <path
                d="M166667 64444c31296 0 52406 13519 64444 24816l47037-45926C249260 16482 211666 1 166667 1 101481 1 45185 37408 17777 91852l53889 41853c13520-40185 50927-69260 95001-69260m0 0z"
                fill="#ea4335"
            />
        </svg>
    );

    const googleButtonConfig = {
        text: "Sign in with Google",
        icon: GoogleSvgIcon,
        iconFormat: (name: string) => `fa fa-${name}`,
        style: {
            background: "#fff",
            color: "#000",
            display: "flex",
            justifyContent: "center",
            boxShadow: "0px 0px 3px rgba(0,0,0,0.084)",
        },
        activeStyle: { background: "#f1f1f1" },
    };

    const MyFacebookLoginButton = createButton(facebookButtonConfig);
    const MyGoogleLoginButton = createButton(googleButtonConfig);

    const loadingMessage =
        platform === "google"
            ? "Continue signing in with Google on the popup window."
            : platform === "facebook"
            ? "Continue signing in with Facebook on the popup window."
            : undefined;

    const isMobile = useIsMobile(700);
    return (
        <main className="flex overflow-y-auto flex-col justify-center items-center min-h-screen bg-background shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-md:px-5">
            {loading && <LoadingScreen loadingMessage={loadingMessage} />}
            <section
                className={`flex flex-col items-center py-10 max-w-full bg-white w-[605px] ${
                    isMobile
                        ? "h-screen m-0 justify-between px-10"
                        : "mb-4 px-14 rounded-2xl"
                }`}
            >
                <header className="flex flex-col max-w-full text-2xl font-bold leading-none text-center text-primary w-[395px]">
                    <img
                        loading="lazy"
                        src={logo}
                        alt="Marabot Logo"
                        className="object-contain w-full aspect-[8.77]"
                    />
                    <h1 className="mt-2">Log in to your Account</h1>
                </header>
                <div className="flex flex-col mt-8 max-w-full text-xl w-[393px]">
                    <MyGoogleLoginButton onClick={handleGoogleLogin} />
                    <MyFacebookLoginButton onClick={handleFacebookLogin} />
                </div>
                <hr className="mt-8 w-full border border-secondary border-solid min-h-[1px]" />
                <form className="flex flex-col space-y-4 mt-8 max-w-full w-[393px]">
                    <InputField
                        label="Email address"
                        placeholder="Email address"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <InputField
                        label="Password"
                        placeholder="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        showPasswordToggle
                    />
                    <div
                        className={`flex ${
                            isMobile ? "gap-2" : "gap-7"
                        } items-center mt-6 w-full max-sm:flex max-sm:flex-col`}
                    >
                        <Checkbox
                            label="Remember Me"
                            checked={rememberMe}
                            onChange={() => setRememberMe(!rememberMe)}
                            variant="custom"
                        />
                        <a
                            href="/forgot-password"
                            className="self-stretch my-auto text-base text-right text-secondary w-[173px] max-sm:self-center max-sm:text-center"
                        >
                            Forgot Password?
                        </a>
                    </div>
                    <Button
                        id="login-button"
                        text="Log in"
                        onClick={(e) => {
                            e.preventDefault();
                            handleEmailSignIn();
                        }}
                    />
                </form>
                <p className="gap-6 self-stretch mt-8 max-w-full text-base font-light leading-none text-center text-gray-700 w-full">
                    Don&lsquo;t have an account?{" "}
                    <a href="/signup" className="font-bold text-secondary">
                        Create an Account
                    </a>
                </p>
                <Footer />
            </section>
        </main>
    );
};

export default LoginScreen;
