export const EMOJI_CATEGORIES = {
    "face-smiling": [
        "\u{1f600}",
        "\u{1f603}",
        "\u{1f604}",
        "\u{1f601}",
        "\u{1f606}",
        "\u{1f605}",
        "\u{1f923}",
        "\u{1f602}",
        "\u{1f642}",
        "\u{1f643}",
        "\u{1f609}",
        "\u{1f60a}",
        "\u{1f607}",
    ],
    "face-affection": [
        "\u{1f970}",
        "\u{1f60d}",
        "\u{1f929}",
        "\u{1f618}",
        "\u{1f617}",
        "\u{263a}",
        "\u{1f61a}",
        "\u{1f619}",
    ],
    "face-tongue": [
        "\u{1f60b}",
        "\u{1f61b}",
        "\u{1f61c}",
        "\u{1f92a}",
        "\u{1f61d}",
        "\u{1f911}",
    ],
    "face-hand": ["\u{1f917}", "\u{1f92d}", "\u{1f92b}", "\u{1f914}"],
    "face-neutral-skeptical": [
        "\u{1f910}",
        "\u{1f928}",
        "\u{1f610}",
        "\u{1f611}",
        "\u{1f636}",
        "\u{1f60f}",
        "\u{1f612}",
        "\u{1f644}",
        "\u{1f62c}",
        "\u{1f925}",
    ],
    "face-sleepy": [
        "\u{1f60c}",
        "\u{1f614}",
        "\u{1f62a}",
        "\u{1f924}",
        "\u{1f634}",
    ],
    "face-unwell": [
        "\u{1f637}",
        "\u{1f912}",
        "\u{1f915}",
        "\u{1f922}",
        "\u{1f92e}",
        "\u{1f927}",
        "\u{1f975}",
        "\u{1f976}",
        "\u{1f974}",
        "\u{1f635}",
        "\u{1f92f}",
    ],
    "face-hat": ["\u{1f920}", "\u{1f973}"],
    "face-glasses": ["\u{1f60e}", "\u{1f913}", "\u{1f9d0}"],
    "face-concerned": [
        "\u{1f615}",
        "\u{1f61f}",
        "\u{1f641}",
        "\u{2639}",
        "\u{1f62e}",
        "\u{1f62f}",
        "\u{1f632}",
        "\u{1f633}",
        "\u{1f97a}",
        "\u{1f626}",
        "\u{1f627}",
        "\u{1f628}",
        "\u{1f630}",
        "\u{1f625}",
        "\u{1f622}",
        "\u{1f62d}",
        "\u{1f631}",
        "\u{1f616}",
        "\u{1f623}",
        "\u{1f61e}",
        "\u{1f613}",
        "\u{1f629}",
        "\u{1f62b}",
    ],
    "face-negative": [
        "\u{1f624}",
        "\u{1f621}",
        "\u{1f620}",
        "\u{1f92c}",
        "\u{1f608}",
        "\u{1f47f}",
        "\u{1f480}",
        "\u{2620}",
    ],
    "face-costume": [
        "\u{1f4a9}",
        "\u{1f921}",
        "\u{1f479}",
        "\u{1f47a}",
        "\u{1f47b}",
        "\u{1f47d}",
        "\u{1f47e}",
        "\u{1f916}",
    ],
    "cat-face": [
        "\u{1f63a}",
        "\u{1f638}",
        "\u{1f639}",
        "\u{1f63b}",
        "\u{1f63c}",
        "\u{1f63d}",
        "\u{1f640}",
        "\u{1f63f}",
        "\u{1f63e}",
    ],
    "monkey-face": ["\u{1f648}", "\u{1f649}", "\u{1f64a}"],
    emotion: [
        "\u{1f48b}",
        "\u{1f48c}",
        "\u{1f498}",
        "\u{1f49d}",
        "\u{1f496}",
        "\u{1f497}",
        "\u{1f493}",
        "\u{1f49e}",
        "\u{1f495}",
        "\u{1f49f}",
        "\u{2763}",
        "\u{1f494}",
        "\u{2764}",
        "\u{1f9e1}",
        "\u{1f49b}",
        "\u{1f49a}",
        "\u{1f499}",
        "\u{1f49c}",
        "\u{1f5a4}",
        "\u{1f4af}",
        "\u{1f4a2}",
        "\u{1f4a5}",
        "\u{1f4ab}",
        "\u{1f4a6}",
        "\u{1f4a8}",
        "\u{1f573}",
        "\u{1f4a3}",
        "\u{1f4ac}",
        "\u{1f441}\u{fe0f}\u{200d}\u{1f5e8}\u{fe0f}",
        "\u{1f5e8}",
        "\u{1f5ef}",
        "\u{1f4ad}",
        "\u{1f4a4}",
    ],
    "people-body": [],
    "hand-fingers-open": [
        "\u{1f44b}",
        "\u{1f91a}",
        "\u{1f590}",
        "\u{270b}",
        "\u{1f596}",
    ],
    "hand-fingers-partial": [
        "\u{1f44c}",
        "\u{270c}",
        "\u{1f91e}",
        "\u{1f91f}",
        "\u{1f918}",
        "\u{1f919}",
    ],
    "hand-single-finger": [
        "\u{1f448}",
        "\u{1f449}",
        "\u{1f446}",
        "\u{1f595}",
        "\u{1f447}",
        "\u{261d}",
    ],
    "hand-fingers-closed": [
        "\u{1f44d}",
        "\u{1f44e}",
        "\u{270a}",
        "\u{1f44a}",
        "\u{1f91b}",
        "\u{1f91c}",
    ],
    hands: [
        "\u{1f44f}",
        "\u{1f64c}",
        "\u{1f450}",
        "\u{1f932}",
        "\u{1f91d}",
        "\u{1f64f}",
    ],
    "hand-prop": ["\u{270d}", "\u{1f485}", "\u{1f933}"],
    "body-parts": [
        "\u{1f4aa}",
        "\u{1f9b5}",
        "\u{1f9b6}",
        "\u{1f442}",
        "\u{1f443}",
        "\u{1f9e0}",
        "\u{1f9b7}",
        "\u{1f9b4}",
        "\u{1f440}",
        "\u{1f441}",
        "\u{1f445}",
        "\u{1f444}",
    ],
    person: [
        "\u{1f476}",
        "\u{1f9d2}",
        "\u{1f466}",
        "\u{1f467}",
        "\u{1f9d1}",
        "\u{1f471}",
        "\u{1f468}",
        "\u{1f9d4}",
        "\u{1f471}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f468}\u{200d}\u{1f9b0}",
        "\u{1f468}\u{200d}\u{1f9b1}",
        "\u{1f468}\u{200d}\u{1f9b3}",
        "\u{1f468}\u{200d}\u{1f9b2}",
        "\u{1f469}",
        "\u{1f471}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f469}\u{200d}\u{1f9b0}",
        "\u{1f469}\u{200d}\u{1f9b1}",
        "\u{1f469}\u{200d}\u{1f9b3}",
        "\u{1f469}\u{200d}\u{1f9b2}",
        "\u{1f9d3}",
        "\u{1f474}",
        "\u{1f475}",
    ],
    "person-gesture": [
        "\u{1f64d}",
        "\u{1f64d}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f64d}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f64e}",
        "\u{1f64e}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f64e}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f645}",
        "\u{1f645}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f645}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f646}",
        "\u{1f646}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f646}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f481}",
        "\u{1f481}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f481}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f64b}",
        "\u{1f64b}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f64b}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f647}",
        "\u{1f647}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f647}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f926}",
        "\u{1f926}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f926}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f937}",
        "\u{1f937}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f937}\u{200d}\u{2640}\u{fe0f}",
    ],
    "person-role": [
        "\u{1f468}\u{200d}\u{2695}\u{fe0f}",
        "\u{1f469}\u{200d}\u{2695}\u{fe0f}",
        "\u{1f468}\u{200d}\u{1f393}",
        "\u{1f469}\u{200d}\u{1f393}",
        "\u{1f468}\u{200d}\u{1f3eb}",
        "\u{1f469}\u{200d}\u{1f3eb}",
        "\u{1f468}\u{200d}\u{2696}\u{fe0f}",
        "\u{1f469}\u{200d}\u{2696}\u{fe0f}",
        "\u{1f468}\u{200d}\u{1f33e}",
        "\u{1f469}\u{200d}\u{1f33e}",
        "\u{1f468}\u{200d}\u{1f373}",
        "\u{1f469}\u{200d}\u{1f373}",
        "\u{1f468}\u{200d}\u{1f527}",
        "\u{1f469}\u{200d}\u{1f527}",
        "\u{1f468}\u{200d}\u{1f3ed}",
        "\u{1f469}\u{200d}\u{1f3ed}",
        "\u{1f468}\u{200d}\u{1f4bc}",
        "\u{1f469}\u{200d}\u{1f4bc}",
        "\u{1f468}\u{200d}\u{1f52c}",
        "\u{1f469}\u{200d}\u{1f52c}",
        "\u{1f468}\u{200d}\u{1f4bb}",
        "\u{1f469}\u{200d}\u{1f4bb}",
        "\u{1f468}\u{200d}\u{1f3a4}",
        "\u{1f469}\u{200d}\u{1f3a4}",
        "\u{1f468}\u{200d}\u{1f3a8}",
        "\u{1f469}\u{200d}\u{1f3a8}",
        "\u{1f468}\u{200d}\u{2708}\u{fe0f}",
        "\u{1f469}\u{200d}\u{2708}\u{fe0f}",
        "\u{1f468}\u{200d}\u{1f680}",
        "\u{1f469}\u{200d}\u{1f680}",
        "\u{1f468}\u{200d}\u{1f692}",
        "\u{1f469}\u{200d}\u{1f692}",
        "\u{1f46e}",
        "\u{1f46e}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f46e}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f575}",
        "\u{1f575}\u{fe0f}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f575}\u{fe0f}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f482}",
        "\u{1f482}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f482}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f477}",
        "\u{1f477}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f477}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f934}",
        "\u{1f478}",
        "\u{1f473}",
        "\u{1f473}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f473}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f472}",
        "\u{1f9d5}",
        "\u{1f935}",
        "\u{1f470}",
        "\u{1f930}",
        "\u{1f931}",
    ],
    "person-fantasy": [
        "\u{1f47c}",
        "\u{1f385}",
        "\u{1f936}",
        "\u{1f9b8}",
        "\u{1f9b8}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f9b8}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f9b9}",
        "\u{1f9b9}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f9b9}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f9d9}",
        "\u{1f9d9}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f9d9}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f9da}",
        "\u{1f9da}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f9da}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f9db}",
        "\u{1f9db}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f9db}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f9dc}",
        "\u{1f9dc}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f9dc}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f9dd}",
        "\u{1f9dd}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f9dd}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f9de}",
        "\u{1f9de}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f9de}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f9df}",
        "\u{1f9df}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f9df}\u{200d}\u{2640}\u{fe0f}",
    ],
    "person-activity": [
        "\u{1f486}",
        "\u{1f486}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f486}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f487}",
        "\u{1f487}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f487}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f6b6}",
        "\u{1f6b6}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f6b6}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f3c3}",
        "\u{1f3c3}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f3c3}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f483}",
        "\u{1f57a}",
        "\u{1f574}",
        "\u{1f46f}",
        "\u{1f46f}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f46f}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f9d6}",
        "\u{1f9d6}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f9d6}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f9d7}",
        "\u{1f9d7}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f9d7}\u{200d}\u{2640}\u{fe0f}",
    ],
    "person-sport": [
        "\u{1f93a}",
        "\u{1f3c7}",
        "\u{26f7}",
        "\u{1f3c2}",
        "\u{1f3cc}",
        "\u{1f3cc}\u{fe0f}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f3cc}\u{fe0f}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f3c4}",
        "\u{1f3c4}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f3c4}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f6a3}",
        "\u{1f6a3}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f6a3}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f3ca}",
        "\u{1f3ca}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f3ca}\u{200d}\u{2640}\u{fe0f}",
        "\u{26f9}",
        "\u{26f9}\u{fe0f}\u{200d}\u{2642}\u{fe0f}",
        "\u{26f9}\u{fe0f}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f3cb}",
        "\u{1f3cb}\u{fe0f}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f3cb}\u{fe0f}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f6b4}",
        "\u{1f6b4}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f6b4}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f6b5}",
        "\u{1f6b5}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f6b5}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f938}",
        "\u{1f938}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f938}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f93c}",
        "\u{1f93c}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f93c}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f93d}",
        "\u{1f93d}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f93d}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f93e}",
        "\u{1f93e}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f93e}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f939}",
        "\u{1f939}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f939}\u{200d}\u{2640}\u{fe0f}",
    ],
    "person-resting": [
        "\u{1f9d8}",
        "\u{1f9d8}\u{200d}\u{2642}\u{fe0f}",
        "\u{1f9d8}\u{200d}\u{2640}\u{fe0f}",
        "\u{1f6c0}",
        "\u{1f6cc}",
    ],
    family: [
        "\u{1f46d}",
        "\u{1f46b}",
        "\u{1f46c}",
        "\u{1f48f}",
        "\u{1f469}\u{200d}\u{2764}\u{fe0f}\u{200d}\u{1f48b}\u{200d}\u{1f468}",
        "\u{1f468}\u{200d}\u{2764}\u{fe0f}\u{200d}\u{1f48b}\u{200d}\u{1f468}",
        "\u{1f469}\u{200d}\u{2764}\u{fe0f}\u{200d}\u{1f48b}\u{200d}\u{1f469}",
        "\u{1f491}",
        "\u{1f469}\u{200d}\u{2764}\u{fe0f}\u{200d}\u{1f468}",
        "\u{1f468}\u{200d}\u{2764}\u{fe0f}\u{200d}\u{1f468}",
        "\u{1f469}\u{200d}\u{2764}\u{fe0f}\u{200d}\u{1f469}",
        "\u{1f46a}",
        "\u{1f468}\u{200d}\u{1f469}\u{200d}\u{1f466}",
        "\u{1f468}\u{200d}\u{1f469}\u{200d}\u{1f467}",
        "\u{1f468}\u{200d}\u{1f469}\u{200d}\u{1f467}\u{200d}\u{1f466}",
        "\u{1f468}\u{200d}\u{1f469}\u{200d}\u{1f466}\u{200d}\u{1f466}",
        "\u{1f468}\u{200d}\u{1f469}\u{200d}\u{1f467}\u{200d}\u{1f467}",
        "\u{1f468}\u{200d}\u{1f468}\u{200d}\u{1f466}",
        "\u{1f468}\u{200d}\u{1f468}\u{200d}\u{1f467}",
        "\u{1f468}\u{200d}\u{1f468}\u{200d}\u{1f467}\u{200d}\u{1f466}",
        "\u{1f468}\u{200d}\u{1f468}\u{200d}\u{1f466}\u{200d}\u{1f466}",
        "\u{1f468}\u{200d}\u{1f468}\u{200d}\u{1f467}\u{200d}\u{1f467}",
        "\u{1f469}\u{200d}\u{1f469}\u{200d}\u{1f466}",
        "\u{1f469}\u{200d}\u{1f469}\u{200d}\u{1f467}",
        "\u{1f469}\u{200d}\u{1f469}\u{200d}\u{1f467}\u{200d}\u{1f466}",
        "\u{1f469}\u{200d}\u{1f469}\u{200d}\u{1f466}\u{200d}\u{1f466}",
        "\u{1f469}\u{200d}\u{1f469}\u{200d}\u{1f467}\u{200d}\u{1f467}",
        "\u{1f468}\u{200d}\u{1f466}",
        "\u{1f468}\u{200d}\u{1f466}\u{200d}\u{1f466}",
        "\u{1f468}\u{200d}\u{1f467}",
        "\u{1f468}\u{200d}\u{1f467}\u{200d}\u{1f466}",
        "\u{1f468}\u{200d}\u{1f467}\u{200d}\u{1f467}",
        "\u{1f469}\u{200d}\u{1f466}",
        "\u{1f469}\u{200d}\u{1f466}\u{200d}\u{1f466}",
        "\u{1f469}\u{200d}\u{1f467}",
        "\u{1f469}\u{200d}\u{1f467}\u{200d}\u{1f466}",
        "\u{1f469}\u{200d}\u{1f467}\u{200d}\u{1f467}",
    ],
    "person-symbol": ["\u{1f5e3}", "\u{1f464}", "\u{1f465}", "\u{1f463}"],
    component: [],
    "hair-style": ["\u{1f9b0}", "\u{1f9b1}", "\u{1f9b3}", "\u{1f9b2}"],
    "animals-nature": [],
    "animal-mammal": [
        "\u{1f435}",
        "\u{1f412}",
        "\u{1f98d}",
        "\u{1f436}",
        "\u{1f415}",
        "\u{1f429}",
        "\u{1f43a}",
        "\u{1f98a}",
        "\u{1f99d}",
        "\u{1f431}",
        "\u{1f408}",
        "\u{1f981}",
        "\u{1f42f}",
        "\u{1f405}",
        "\u{1f406}",
        "\u{1f434}",
        "\u{1f40e}",
        "\u{1f984}",
        "\u{1f993}",
        "\u{1f98c}",
        "\u{1f42e}",
        "\u{1f402}",
        "\u{1f403}",
        "\u{1f404}",
        "\u{1f437}",
        "\u{1f416}",
        "\u{1f417}",
        "\u{1f43d}",
        "\u{1f40f}",
        "\u{1f411}",
        "\u{1f410}",
        "\u{1f42a}",
        "\u{1f42b}",
        "\u{1f999}",
        "\u{1f992}",
        "\u{1f418}",
        "\u{1f98f}",
        "\u{1f99b}",
        "\u{1f42d}",
        "\u{1f401}",
        "\u{1f400}",
        "\u{1f439}",
        "\u{1f430}",
        "\u{1f407}",
        "\u{1f43f}",
        "\u{1f994}",
        "\u{1f987}",
        "\u{1f43b}",
        "\u{1f428}",
        "\u{1f43c}",
        "\u{1f998}",
        "\u{1f9a1}",
        "\u{1f43e}",
    ],
    "animal-bird": [
        "\u{1f983}",
        "\u{1f414}",
        "\u{1f413}",
        "\u{1f423}",
        "\u{1f424}",
        "\u{1f425}",
        "\u{1f426}",
        "\u{1f427}",
        "\u{1f54a}",
        "\u{1f985}",
        "\u{1f986}",
        "\u{1f9a2}",
        "\u{1f989}",
        "\u{1f99a}",
        "\u{1f99c}",
    ],
    "animal-amphibian": ["\u{1f438}"],
    "animal-reptile": [
        "\u{1f40a}",
        "\u{1f422}",
        "\u{1f98e}",
        "\u{1f40d}",
        "\u{1f432}",
        "\u{1f409}",
        "\u{1f995}",
        "\u{1f996}",
    ],
    "animal-marine": [
        "\u{1f433}",
        "\u{1f40b}",
        "\u{1f42c}",
        "\u{1f41f}",
        "\u{1f420}",
        "\u{1f421}",
        "\u{1f988}",
        "\u{1f419}",
        "\u{1f41a}",
    ],
    "animal-bug": [
        "\u{1f40c}",
        "\u{1f98b}",
        "\u{1f41b}",
        "\u{1f41c}",
        "\u{1f41d}",
        "\u{1f41e}",
        "\u{1f997}",
        "\u{1f577}",
        "\u{1f578}",
        "\u{1f982}",
        "\u{1f99f}",
        "\u{1f9a0}",
    ],
    "plant-flower": [
        "\u{1f490}",
        "\u{1f338}",
        "\u{1f4ae}",
        "\u{1f3f5}",
        "\u{1f339}",
        "\u{1f940}",
        "\u{1f33a}",
        "\u{1f33b}",
        "\u{1f33c}",
        "\u{1f337}",
    ],
    "plant-other": [
        "\u{1f331}",
        "\u{1f332}",
        "\u{1f333}",
        "\u{1f334}",
        "\u{1f335}",
        "\u{1f33e}",
        "\u{1f33f}",
        "\u{2618}",
        "\u{1f340}",
        "\u{1f341}",
        "\u{1f342}",
        "\u{1f343}",
    ],
    "food&drink": [],
    "food-fruit": [
        "\u{1f347}",
        "\u{1f348}",
        "\u{1f349}",
        "\u{1f34a}",
        "\u{1f34b}",
        "\u{1f34c}",
        "\u{1f34d}",
        "\u{1f96d}",
        "\u{1f34e}",
        "\u{1f34f}",
        "\u{1f350}",
        "\u{1f351}",
        "\u{1f352}",
        "\u{1f353}",
        "\u{1f95d}",
        "\u{1f345}",
        "\u{1f965}",
    ],
    "food-vegetable": [
        "\u{1f951}",
        "\u{1f346}",
        "\u{1f954}",
        "\u{1f955}",
        "\u{1f33d}",
        "\u{1f336}",
        "\u{1f952}",
        "\u{1f96c}",
        "\u{1f966}",
        "\u{1f344}",
        "\u{1f95c}",
        "\u{1f330}",
    ],
    "food-prepared": [
        "\u{1f35e}",
        "\u{1f950}",
        "\u{1f956}",
        "\u{1f968}",
        "\u{1f96f}",
        "\u{1f95e}",
        "\u{1f9c0}",
        "\u{1f356}",
        "\u{1f357}",
        "\u{1f969}",
        "\u{1f953}",
        "\u{1f354}",
        "\u{1f35f}",
        "\u{1f355}",
        "\u{1f32d}",
        "\u{1f96a}",
        "\u{1f32e}",
        "\u{1f32f}",
        "\u{1f959}",
        "\u{1f95a}",
        "\u{1f373}",
        "\u{1f958}",
        "\u{1f372}",
        "\u{1f963}",
        "\u{1f957}",
        "\u{1f37f}",
        "\u{1f9c2}",
        "\u{1f96b}",
    ],
    "food-asian": [
        "\u{1f371}",
        "\u{1f358}",
        "\u{1f359}",
        "\u{1f35a}",
        "\u{1f35b}",
        "\u{1f35c}",
        "\u{1f35d}",
        "\u{1f360}",
        "\u{1f362}",
        "\u{1f363}",
        "\u{1f364}",
        "\u{1f365}",
        "\u{1f96e}",
        "\u{1f361}",
        "\u{1f95f}",
        "\u{1f960}",
        "\u{1f961}",
    ],
    "food-marine": ["\u{1f980}", "\u{1f99e}", "\u{1f990}", "\u{1f991}"],
    "food-sweet": [
        "\u{1f366}",
        "\u{1f367}",
        "\u{1f368}",
        "\u{1f369}",
        "\u{1f36a}",
        "\u{1f382}",
        "\u{1f370}",
        "\u{1f9c1}",
        "\u{1f967}",
        "\u{1f36b}",
        "\u{1f36c}",
        "\u{1f36d}",
        "\u{1f36e}",
        "\u{1f36f}",
    ],
    drink: [
        "\u{1f37c}",
        "\u{1f95b}",
        "\u{2615}",
        "\u{1f375}",
        "\u{1f376}",
        "\u{1f37e}",
        "\u{1f377}",
        "\u{1f378}",
        "\u{1f379}",
        "\u{1f37a}",
        "\u{1f37b}",
        "\u{1f942}",
        "\u{1f943}",
        "\u{1f964}",
    ],
    dishware: [
        "\u{1f962}",
        "\u{1f37d}",
        "\u{1f374}",
        "\u{1f944}",
        "\u{1f52a}",
        "\u{1f3fa}",
    ],
    "travel&places": [],
    "place-map": [
        "\u{1f30d}",
        "\u{1f30e}",
        "\u{1f30f}",
        "\u{1f310}",
        "\u{1f5fa}",
        "\u{1f5fe}",
        "\u{1f9ed}",
    ],
    "place-geographic": [
        "\u{1f3d4}",
        "\u{26f0}",
        "\u{1f30b}",
        "\u{1f5fb}",
        "\u{1f3d5}",
        "\u{1f3d6}",
        "\u{1f3dc}",
        "\u{1f3dd}",
        "\u{1f3de}",
    ],
    "place-building": [
        "\u{1f3df}",
        "\u{1f3db}",
        "\u{1f3d7}",
        "\u{1f9f1}",
        "\u{1f3d8}",
        "\u{1f3da}",
        "\u{1f3e0}",
        "\u{1f3e1}",
        "\u{1f3e2}",
        "\u{1f3e3}",
        "\u{1f3e4}",
        "\u{1f3e5}",
        "\u{1f3e6}",
        "\u{1f3e8}",
        "\u{1f3e9}",
        "\u{1f3ea}",
        "\u{1f3eb}",
        "\u{1f3ec}",
        "\u{1f3ed}",
        "\u{1f3ef}",
        "\u{1f3f0}",
        "\u{1f492}",
        "\u{1f5fc}",
        "\u{1f5fd}",
    ],
    "place-religious": [
        "\u{26ea}",
        "\u{1f54c}",
        "\u{1f54d}",
        "\u{26e9}",
        "\u{1f54b}",
    ],
    "place-other": [
        "\u{26f2}",
        "\u{26fa}",
        "\u{1f301}",
        "\u{1f303}",
        "\u{1f3d9}",
        "\u{1f304}",
        "\u{1f305}",
        "\u{1f306}",
        "\u{1f307}",
        "\u{1f309}",
        "\u{2668}",
        "\u{1f30c}",
        "\u{1f3a0}",
        "\u{1f3a1}",
        "\u{1f3a2}",
        "\u{1f488}",
        "\u{1f3aa}",
    ],
    "transport-ground": [
        "\u{1f682}",
        "\u{1f683}",
        "\u{1f684}",
        "\u{1f685}",
        "\u{1f686}",
        "\u{1f687}",
        "\u{1f688}",
        "\u{1f689}",
        "\u{1f68a}",
        "\u{1f69d}",
        "\u{1f69e}",
        "\u{1f68b}",
        "\u{1f68c}",
        "\u{1f68d}",
        "\u{1f68e}",
        "\u{1f690}",
        "\u{1f691}",
        "\u{1f692}",
        "\u{1f693}",
        "\u{1f694}",
        "\u{1f695}",
        "\u{1f696}",
        "\u{1f697}",
        "\u{1f698}",
        "\u{1f699}",
        "\u{1f69a}",
        "\u{1f69b}",
        "\u{1f69c}",
        "\u{1f3ce}",
        "\u{1f3cd}",
        "\u{1f6f5}",
        "\u{1f6b2}",
        "\u{1f6f4}",
        "\u{1f6f9}",
        "\u{1f68f}",
        "\u{1f6e3}",
        "\u{1f6e4}",
        "\u{1f6e2}",
        "\u{26fd}",
        "\u{1f6a8}",
        "\u{1f6a5}",
        "\u{1f6a6}",
        "\u{1f6d1}",
        "\u{1f6a7}",
    ],
    "transport-water": [
        "\u{2693}",
        "\u{26f5}",
        "\u{1f6f6}",
        "\u{1f6a4}",
        "\u{1f6f3}",
        "\u{26f4}",
        "\u{1f6e5}",
        "\u{1f6a2}",
    ],
    "transport-air": [
        "\u{2708}",
        "\u{1f6e9}",
        "\u{1f6eb}",
        "\u{1f6ec}",
        "\u{1f4ba}",
        "\u{1f681}",
        "\u{1f69f}",
        "\u{1f6a0}",
        "\u{1f6a1}",
        "\u{1f6f0}",
        "\u{1f680}",
        "\u{1f6f8}",
    ],
    hotel: ["\u{1f6ce}", "\u{1f9f3}"],
    time: [
        "\u{231b}",
        "\u{23f3}",
        "\u{231a}",
        "\u{23f0}",
        "\u{23f1}",
        "\u{23f2}",
        "\u{1f570}",
        "\u{1f55b}",
        "\u{1f567}",
        "\u{1f550}",
        "\u{1f55c}",
        "\u{1f551}",
        "\u{1f55d}",
        "\u{1f552}",
        "\u{1f55e}",
        "\u{1f553}",
        "\u{1f55f}",
        "\u{1f554}",
        "\u{1f560}",
        "\u{1f555}",
        "\u{1f561}",
        "\u{1f556}",
        "\u{1f562}",
        "\u{1f557}",
        "\u{1f563}",
        "\u{1f558}",
        "\u{1f564}",
        "\u{1f559}",
        "\u{1f565}",
        "\u{1f55a}",
        "\u{1f566}",
    ],
    "sky-weather": [
        "\u{1f311}",
        "\u{1f312}",
        "\u{1f313}",
        "\u{1f314}",
        "\u{1f315}",
        "\u{1f316}",
        "\u{1f317}",
        "\u{1f318}",
        "\u{1f319}",
        "\u{1f31a}",
        "\u{1f31b}",
        "\u{1f31c}",
        "\u{1f321}",
        "\u{2600}",
        "\u{1f31d}",
        "\u{1f31e}",
        "\u{2b50}",
        "\u{1f31f}",
        "\u{1f320}",
        "\u{2601}",
        "\u{26c5}",
        "\u{26c8}",
        "\u{1f324}",
        "\u{1f325}",
        "\u{1f326}",
        "\u{1f327}",
        "\u{1f328}",
        "\u{1f329}",
        "\u{1f32a}",
        "\u{1f32b}",
        "\u{1f32c}",
        "\u{1f300}",
        "\u{1f308}",
        "\u{1f302}",
        "\u{2602}",
        "\u{2614}",
        "\u{26f1}",
        "\u{26a1}",
        "\u{2744}",
        "\u{2603}",
        "\u{26c4}",
        "\u{2604}",
        "\u{1f525}",
        "\u{1f4a7}",
        "\u{1f30a}",
    ],
    activities: [],
    event: [
        "\u{1f383}",
        "\u{1f384}",
        "\u{1f386}",
        "\u{1f387}",
        "\u{1f9e8}",
        "\u{2728}",
        "\u{1f388}",
        "\u{1f389}",
        "\u{1f38a}",
        "\u{1f38b}",
        "\u{1f38d}",
        "\u{1f38e}",
        "\u{1f38f}",
        "\u{1f390}",
        "\u{1f391}",
        "\u{1f9e7}",
        "\u{1f380}",
        "\u{1f381}",
        "\u{1f397}",
        "\u{1f39f}",
        "\u{1f3ab}",
    ],
    "award-medal": [
        "\u{1f396}",
        "\u{1f3c6}",
        "\u{1f3c5}",
        "\u{1f947}",
        "\u{1f948}",
        "\u{1f949}",
    ],
    sport: [
        "\u{26bd}",
        "\u{26be}",
        "\u{1f94e}",
        "\u{1f3c0}",
        "\u{1f3d0}",
        "\u{1f3c8}",
        "\u{1f3c9}",
        "\u{1f3be}",
        "\u{1f94f}",
        "\u{1f3b3}",
        "\u{1f3cf}",
        "\u{1f3d1}",
        "\u{1f3d2}",
        "\u{1f94d}",
        "\u{1f3d3}",
        "\u{1f3f8}",
        "\u{1f94a}",
        "\u{1f94b}",
        "\u{1f945}",
        "\u{26f3}",
        "\u{26f8}",
        "\u{1f3a3}",
        "\u{1f3bd}",
        "\u{1f3bf}",
        "\u{1f6f7}",
        "\u{1f94c}",
    ],
    game: [
        "\u{1f3af}",
        "\u{1f3b1}",
        "\u{1f52e}",
        "\u{1f9ff}",
        "\u{1f3ae}",
        "\u{1f579}",
        "\u{1f3b0}",
        "\u{1f3b2}",
        "\u{1f9e9}",
        "\u{1f9f8}",
        "\u{2660}",
        "\u{2665}",
        "\u{2666}",
        "\u{2663}",
        "\u{265f}",
        "\u{1f0cf}",
        "\u{1f004}",
        "\u{1f3b4}",
    ],
    "arts-crafts": [
        "\u{1f3ad}",
        "\u{1f5bc}",
        "\u{1f3a8}",
        "\u{1f9f5}",
        "\u{1f9f6}",
    ],
    objects: [],
    clothing: [
        "\u{1f453}",
        "\u{1f576}",
        "\u{1f97d}",
        "\u{1f97c}",
        "\u{1f454}",
        "\u{1f455}",
        "\u{1f456}",
        "\u{1f9e3}",
        "\u{1f9e4}",
        "\u{1f9e5}",
        "\u{1f9e6}",
        "\u{1f457}",
        "\u{1f458}",
        "\u{1f459}",
        "\u{1f45a}",
        "\u{1f45b}",
        "\u{1f45c}",
        "\u{1f45d}",
        "\u{1f6cd}",
        "\u{1f392}",
        "\u{1f45e}",
        "\u{1f45f}",
        "\u{1f97e}",
        "\u{1f97f}",
        "\u{1f460}",
        "\u{1f461}",
        "\u{1f462}",
        "\u{1f451}",
        "\u{1f452}",
        "\u{1f3a9}",
        "\u{1f393}",
        "\u{1f9e2}",
        "\u{26d1}",
        "\u{1f4ff}",
        "\u{1f484}",
        "\u{1f48d}",
        "\u{1f48e}",
    ],
    sound: [
        "\u{1f507}",
        "\u{1f508}",
        "\u{1f509}",
        "\u{1f50a}",
        "\u{1f4e2}",
        "\u{1f4e3}",
        "\u{1f4ef}",
        "\u{1f514}",
        "\u{1f515}",
    ],
    music: [
        "\u{1f3bc}",
        "\u{1f3b5}",
        "\u{1f3b6}",
        "\u{1f399}",
        "\u{1f39a}",
        "\u{1f39b}",
        "\u{1f3a4}",
        "\u{1f3a7}",
        "\u{1f4fb}",
    ],
    "musical-instrument": [
        "\u{1f3b7}",
        "\u{1f3b8}",
        "\u{1f3b9}",
        "\u{1f3ba}",
        "\u{1f3bb}",
        "\u{1f941}",
    ],
    phone: [
        "\u{1f4f1}",
        "\u{1f4f2}",
        "\u{260e}",
        "\u{1f4de}",
        "\u{1f4df}",
        "\u{1f4e0}",
    ],
    computer: [
        "\u{1f50b}",
        "\u{1f50c}",
        "\u{1f4bb}",
        "\u{1f5a5}",
        "\u{1f5a8}",
        "\u{2328}",
        "\u{1f5b1}",
        "\u{1f5b2}",
        "\u{1f4bd}",
        "\u{1f4be}",
        "\u{1f4bf}",
        "\u{1f4c0}",
        "\u{1f9ee}",
    ],
    "light-video": [
        "\u{1f3a5}",
        "\u{1f39e}",
        "\u{1f4fd}",
        "\u{1f3ac}",
        "\u{1f4fa}",
        "\u{1f4f7}",
        "\u{1f4f8}",
        "\u{1f4f9}",
        "\u{1f4fc}",
        "\u{1f50d}",
        "\u{1f50e}",
        "\u{1f56f}",
        "\u{1f4a1}",
        "\u{1f526}",
        "\u{1f3ee}",
    ],
    "book-paper": [
        "\u{1f4d4}",
        "\u{1f4d5}",
        "\u{1f4d6}",
        "\u{1f4d7}",
        "\u{1f4d8}",
        "\u{1f4d9}",
        "\u{1f4da}",
        "\u{1f4d3}",
        "\u{1f4d2}",
        "\u{1f4c3}",
        "\u{1f4dc}",
        "\u{1f4c4}",
        "\u{1f4f0}",
        "\u{1f5de}",
        "\u{1f4d1}",
        "\u{1f516}",
        "\u{1f3f7}",
    ],
    money: [
        "\u{1f4b0}",
        "\u{1f4b4}",
        "\u{1f4b5}",
        "\u{1f4b6}",
        "\u{1f4b7}",
        "\u{1f4b8}",
        "\u{1f4b3}",
        "\u{1f9fe}",
        "\u{1f4b9}",
        "\u{1f4b1}",
        "\u{1f4b2}",
    ],
    mail: [
        "\u{2709}",
        "\u{1f4e7}",
        "\u{1f4e8}",
        "\u{1f4e9}",
        "\u{1f4e4}",
        "\u{1f4e5}",
        "\u{1f4e6}",
        "\u{1f4eb}",
        "\u{1f4ea}",
        "\u{1f4ec}",
        "\u{1f4ed}",
        "\u{1f4ee}",
        "\u{1f5f3}",
    ],
    writing: [
        "\u{270f}",
        "\u{2712}",
        "\u{1f58b}",
        "\u{1f58a}",
        "\u{1f58c}",
        "\u{1f58d}",
        "\u{1f4dd}",
    ],
    office: [
        "\u{1f4bc}",
        "\u{1f4c1}",
        "\u{1f4c2}",
        "\u{1f5c2}",
        "\u{1f4c5}",
        "\u{1f4c6}",
        "\u{1f5d2}",
        "\u{1f5d3}",
        "\u{1f4c7}",
        "\u{1f4c8}",
        "\u{1f4c9}",
        "\u{1f4ca}",
        "\u{1f4cb}",
        "\u{1f4cc}",
        "\u{1f4cd}",
        "\u{1f4ce}",
        "\u{1f587}",
        "\u{1f4cf}",
        "\u{1f4d0}",
        "\u{2702}",
        "\u{1f5c3}",
        "\u{1f5c4}",
        "\u{1f5d1}",
    ],
    lock: [
        "\u{1f512}",
        "\u{1f513}",
        "\u{1f50f}",
        "\u{1f510}",
        "\u{1f511}",
        "\u{1f5dd}",
    ],
    tool: [
        "\u{1f528}",
        "\u{26cf}",
        "\u{2692}",
        "\u{1f6e0}",
        "\u{1f5e1}",
        "\u{2694}",
        "\u{1f52b}",
        "\u{1f3f9}",
        "\u{1f6e1}",
        "\u{1f527}",
        "\u{1f529}",
        "\u{2699}",
        "\u{1f5dc}",
        "\u{2696}",
        "\u{1f517}",
        "\u{26d3}",
        "\u{1f9f0}",
        "\u{1f9f2}",
    ],
    science: [
        "\u{2697}",
        "\u{1f9ea}",
        "\u{1f9eb}",
        "\u{1f9ec}",
        "\u{1f52c}",
        "\u{1f52d}",
        "\u{1f4e1}",
    ],
    medical: ["\u{1f489}", "\u{1f48a}"],
    household: [
        "\u{1f6aa}",
        "\u{1f6cf}",
        "\u{1f6cb}",
        "\u{1f6bd}",
        "\u{1f6bf}",
        "\u{1f6c1}",
        "\u{1f9f4}",
        "\u{1f9f7}",
        "\u{1f9f9}",
        "\u{1f9fa}",
        "\u{1f9fb}",
        "\u{1f9fc}",
        "\u{1f9fd}",
        "\u{1f9ef}",
        "\u{1f6d2}",
    ],
    "other-object": ["\u{1f6ac}", "\u{26b0}", "\u{26b1}", "\u{1f5ff}"],
    symbols: [],
    "transport-sign": [
        "\u{1f3e7}",
        "\u{1f6ae}",
        "\u{1f6b0}",
        "\u{267f}",
        "\u{1f6b9}",
        "\u{1f6ba}",
        "\u{1f6bb}",
        "\u{1f6bc}",
        "\u{1f6be}",
        "\u{1f6c2}",
        "\u{1f6c3}",
        "\u{1f6c4}",
        "\u{1f6c5}",
    ],
    warning: [
        "\u{26a0}",
        "\u{1f6b8}",
        "\u{26d4}",
        "\u{1f6ab}",
        "\u{1f6b3}",
        "\u{1f6ad}",
        "\u{1f6af}",
        "\u{1f6b1}",
        "\u{1f6b7}",
        "\u{1f4f5}",
        "\u{1f51e}",
        "\u{2622}",
        "\u{2623}",
    ],
    arrow: [
        "\u{2b06}",
        "\u{2197}",
        "\u{27a1}",
        "\u{2198}",
        "\u{2b07}",
        "\u{2199}",
        "\u{2b05}",
        "\u{2196}",
        "\u{2195}",
        "\u{2194}",
        "\u{21a9}",
        "\u{21aa}",
        "\u{2934}",
        "\u{2935}",
        "\u{1f503}",
        "\u{1f504}",
        "\u{1f519}",
        "\u{1f51a}",
        "\u{1f51b}",
        "\u{1f51c}",
        "\u{1f51d}",
    ],
    religion: [
        "\u{1f6d0}",
        "\u{269b}",
        "\u{1f549}",
        "\u{2721}",
        "\u{2638}",
        "\u{262f}",
        "\u{271d}",
        "\u{2626}",
        "\u{262a}",
        "\u{262e}",
        "\u{1f54e}",
        "\u{1f52f}",
    ],
    zodiac: [
        "\u{2648}",
        "\u{2649}",
        "\u{264a}",
        "\u{264b}",
        "\u{264c}",
        "\u{264d}",
        "\u{264e}",
        "\u{264f}",
        "\u{2650}",
        "\u{2651}",
        "\u{2652}",
        "\u{2653}",
        "\u{26ce}",
    ],
    "av-symbol": [
        "\u{1f500}",
        "\u{1f501}",
        "\u{1f502}",
        "\u{25b6}",
        "\u{23e9}",
        "\u{23ed}",
        "\u{23ef}",
        "\u{25c0}",
        "\u{23ea}",
        "\u{23ee}",
        "\u{1f53c}",
        "\u{23eb}",
        "\u{1f53d}",
        "\u{23ec}",
        "\u{23f8}",
        "\u{23f9}",
        "\u{23fa}",
        "\u{23cf}",
        "\u{1f3a6}",
        "\u{1f505}",
        "\u{1f506}",
        "\u{1f4f6}",
        "\u{1f4f3}",
        "\u{1f4f4}",
    ],
    gender: ["\u{2640}", "\u{2642}"],
    "other-symbol": [
        "\u{2695}",
        "\u{267e}",
        "\u{267b}",
        "\u{269c}",
        "\u{1f531}",
        "\u{1f4db}",
        "\u{1f530}",
        "\u{2b55}",
        "\u{2705}",
        "\u{2611}",
        "\u{2714}",
        "\u{2716}",
        "\u{274c}",
        "\u{274e}",
        "\u{2795}",
        "\u{2796}",
        "\u{2797}",
        "\u{27b0}",
        "\u{27bf}",
        "\u{303d}",
        "\u{2733}",
        "\u{2734}",
        "\u{2747}",
        "\u{203c}",
        "\u{2049}",
        "\u{2753}",
        "\u{2754}",
        "\u{2755}",
        "\u{2757}",
        "\u{3030}",
        "\u{00a9}",
        "\u{00ae}",
        "\u{2122}",
    ],
    keycap: [
        "\u{0023}\u{fe0f}\u{20e3}",
        "\u{002a}\u{fe0f}\u{20e3}",
        "\u{0030}\u{fe0f}\u{20e3}",
        "\u{0031}\u{fe0f}\u{20e3}",
        "\u{0032}\u{fe0f}\u{20e3}",
        "\u{0033}\u{fe0f}\u{20e3}",
        "\u{0034}\u{fe0f}\u{20e3}",
        "\u{0035}\u{fe0f}\u{20e3}",
        "\u{0036}\u{fe0f}\u{20e3}",
        "\u{0037}\u{fe0f}\u{20e3}",
        "\u{0038}\u{fe0f}\u{20e3}",
        "\u{0039}\u{fe0f}\u{20e3}",
        "\u{1f51f}",
    ],
    alphanum: [
        "\u{1f520}",
        "\u{1f521}",
        "\u{1f522}",
        "\u{1f523}",
        "\u{1f524}",
        "\u{1f170}",
        "\u{1f18e}",
        "\u{1f171}",
        "\u{1f191}",
        "\u{1f192}",
        "\u{1f193}",
        "\u{2139}",
        "\u{1f194}",
        "\u{24c2}",
        "\u{1f195}",
        "\u{1f196}",
        "\u{1f17e}",
        "\u{1f197}",
        "\u{1f17f}",
        "\u{1f198}",
        "\u{1f199}",
        "\u{1f19a}",
        "\u{1f201}",
        "\u{1f202}",
        "\u{1f237}",
        "\u{1f236}",
        "\u{1f22f}",
        "\u{1f250}",
        "\u{1f239}",
        "\u{1f21a}",
        "\u{1f232}",
        "\u{1f251}",
        "\u{1f238}",
        "\u{1f234}",
        "\u{1f233}",
        "\u{3297}",
        "\u{3299}",
        "\u{1f23a}",
        "\u{1f235}",
    ],
    geometric: [
        "\u{1f534}",
        "\u{1f535}",
        "\u{26aa}",
        "\u{26ab}",
        "\u{2b1c}",
        "\u{2b1b}",
        "\u{25fc}",
        "\u{25fb}",
        "\u{25fd}",
        "\u{25fe}",
        "\u{25ab}",
        "\u{25aa}",
        "\u{1f536}",
        "\u{1f537}",
        "\u{1f538}",
        "\u{1f539}",
        "\u{1f53a}",
        "\u{1f53b}",
        "\u{1f4a0}",
        "\u{1f518}",
        "\u{1f532}",
        "\u{1f533}",
    ],
    flags: [],
    flag: [
        "\u{1f3c1}",
        "\u{1f6a9}",
        "\u{1f38c}",
        "\u{1f3f4}",
        "\u{1f3f3}",
        "\u{1f3f3}\u{fe0f}\u{200d}\u{1f308}",
        "\u{1f3f4}\u{200d}\u{2620}\u{fe0f}",
    ],
    "country-flag": [
        "\u{1f1e6}\u{1f1e8}",
        "\u{1f1e6}\u{1f1e9}",
        "\u{1f1e6}\u{1f1ea}",
        "\u{1f1e6}\u{1f1eb}",
        "\u{1f1e6}\u{1f1ec}",
        "\u{1f1e6}\u{1f1ee}",
        "\u{1f1e6}\u{1f1f1}",
        "\u{1f1e6}\u{1f1f2}",
        "\u{1f1e6}\u{1f1f4}",
        "\u{1f1e6}\u{1f1f6}",
        "\u{1f1e6}\u{1f1f7}",
        "\u{1f1e6}\u{1f1f8}",
        "\u{1f1e6}\u{1f1f9}",
        "\u{1f1e6}\u{1f1fa}",
        "\u{1f1e6}\u{1f1fc}",
        "\u{1f1e6}\u{1f1fd}",
        "\u{1f1e6}\u{1f1ff}",
        "\u{1f1e7}\u{1f1e6}",
        "\u{1f1e7}\u{1f1e7}",
        "\u{1f1e7}\u{1f1e9}",
        "\u{1f1e7}\u{1f1ea}",
        "\u{1f1e7}\u{1f1eb}",
        "\u{1f1e7}\u{1f1ec}",
        "\u{1f1e7}\u{1f1ed}",
        "\u{1f1e7}\u{1f1ee}",
        "\u{1f1e7}\u{1f1ef}",
        "\u{1f1e7}\u{1f1f1}",
        "\u{1f1e7}\u{1f1f2}",
        "\u{1f1e7}\u{1f1f3}",
        "\u{1f1e7}\u{1f1f4}",
        "\u{1f1e7}\u{1f1f6}",
        "\u{1f1e7}\u{1f1f7}",
        "\u{1f1e7}\u{1f1f8}",
        "\u{1f1e7}\u{1f1f9}",
        "\u{1f1e7}\u{1f1fb}",
        "\u{1f1e7}\u{1f1fc}",
        "\u{1f1e7}\u{1f1fe}",
        "\u{1f1e7}\u{1f1ff}",
        "\u{1f1e8}\u{1f1e6}",
        "\u{1f1e8}\u{1f1e8}",
        "\u{1f1e8}\u{1f1e9}",
        "\u{1f1e8}\u{1f1eb}",
        "\u{1f1e8}\u{1f1ec}",
        "\u{1f1e8}\u{1f1ed}",
        "\u{1f1e8}\u{1f1ee}",
        "\u{1f1e8}\u{1f1f0}",
        "\u{1f1e8}\u{1f1f1}",
        "\u{1f1e8}\u{1f1f2}",
        "\u{1f1e8}\u{1f1f3}",
        "\u{1f1e8}\u{1f1f4}",
        "\u{1f1e8}\u{1f1f5}",
        "\u{1f1e8}\u{1f1f7}",
        "\u{1f1e8}\u{1f1fa}",
        "\u{1f1e8}\u{1f1fb}",
        "\u{1f1e8}\u{1f1fc}",
        "\u{1f1e8}\u{1f1fd}",
        "\u{1f1e8}\u{1f1fe}",
        "\u{1f1e8}\u{1f1ff}",
        "\u{1f1e9}\u{1f1ea}",
        "\u{1f1e9}\u{1f1ec}",
        "\u{1f1e9}\u{1f1ef}",
        "\u{1f1e9}\u{1f1f0}",
        "\u{1f1e9}\u{1f1f2}",
        "\u{1f1e9}\u{1f1f4}",
        "\u{1f1e9}\u{1f1ff}",
        "\u{1f1ea}\u{1f1e6}",
        "\u{1f1ea}\u{1f1e8}",
        "\u{1f1ea}\u{1f1ea}",
        "\u{1f1ea}\u{1f1ec}",
        "\u{1f1ea}\u{1f1ed}",
        "\u{1f1ea}\u{1f1f7}",
        "\u{1f1ea}\u{1f1f8}",
        "\u{1f1ea}\u{1f1f9}",
        "\u{1f1ea}\u{1f1fa}",
        "\u{1f1eb}\u{1f1ee}",
        "\u{1f1eb}\u{1f1ef}",
        "\u{1f1eb}\u{1f1f0}",
        "\u{1f1eb}\u{1f1f2}",
        "\u{1f1eb}\u{1f1f4}",
        "\u{1f1eb}\u{1f1f7}",
        "\u{1f1ec}\u{1f1e6}",
        "\u{1f1ec}\u{1f1e7}",
        "\u{1f1ec}\u{1f1e9}",
        "\u{1f1ec}\u{1f1ea}",
        "\u{1f1ec}\u{1f1eb}",
        "\u{1f1ec}\u{1f1ec}",
        "\u{1f1ec}\u{1f1ed}",
        "\u{1f1ec}\u{1f1ee}",
        "\u{1f1ec}\u{1f1f1}",
        "\u{1f1ec}\u{1f1f2}",
        "\u{1f1ec}\u{1f1f3}",
        "\u{1f1ec}\u{1f1f5}",
        "\u{1f1ec}\u{1f1f6}",
        "\u{1f1ec}\u{1f1f7}",
        "\u{1f1ec}\u{1f1f8}",
        "\u{1f1ec}\u{1f1f9}",
        "\u{1f1ec}\u{1f1fa}",
        "\u{1f1ec}\u{1f1fc}",
        "\u{1f1ec}\u{1f1fe}",
        "\u{1f1ed}\u{1f1f0}",
        "\u{1f1ed}\u{1f1f2}",
        "\u{1f1ed}\u{1f1f3}",
        "\u{1f1ed}\u{1f1f7}",
        "\u{1f1ed}\u{1f1f9}",
        "\u{1f1ed}\u{1f1fa}",
        "\u{1f1ee}\u{1f1e8}",
        "\u{1f1ee}\u{1f1e9}",
        "\u{1f1ee}\u{1f1ea}",
        "\u{1f1ee}\u{1f1f1}",
        "\u{1f1ee}\u{1f1f2}",
        "\u{1f1ee}\u{1f1f3}",
        "\u{1f1ee}\u{1f1f4}",
        "\u{1f1ee}\u{1f1f6}",
        "\u{1f1ee}\u{1f1f7}",
        "\u{1f1ee}\u{1f1f8}",
        "\u{1f1ee}\u{1f1f9}",
        "\u{1f1ef}\u{1f1ea}",
        "\u{1f1ef}\u{1f1f2}",
        "\u{1f1ef}\u{1f1f4}",
        "\u{1f1ef}\u{1f1f5}",
        "\u{1f1f0}\u{1f1ea}",
        "\u{1f1f0}\u{1f1ec}",
        "\u{1f1f0}\u{1f1ed}",
        "\u{1f1f0}\u{1f1ee}",
        "\u{1f1f0}\u{1f1f2}",
        "\u{1f1f0}\u{1f1f3}",
        "\u{1f1f0}\u{1f1f5}",
        "\u{1f1f0}\u{1f1f7}",
        "\u{1f1f0}\u{1f1fc}",
        "\u{1f1f0}\u{1f1fe}",
        "\u{1f1f0}\u{1f1ff}",
        "\u{1f1f1}\u{1f1e6}",
        "\u{1f1f1}\u{1f1e7}",
        "\u{1f1f1}\u{1f1e8}",
        "\u{1f1f1}\u{1f1ee}",
        "\u{1f1f1}\u{1f1f0}",
        "\u{1f1f1}\u{1f1f7}",
        "\u{1f1f1}\u{1f1f8}",
        "\u{1f1f1}\u{1f1f9}",
        "\u{1f1f1}\u{1f1fa}",
        "\u{1f1f1}\u{1f1fb}",
        "\u{1f1f1}\u{1f1fe}",
        "\u{1f1f2}\u{1f1e6}",
        "\u{1f1f2}\u{1f1e8}",
        "\u{1f1f2}\u{1f1e9}",
        "\u{1f1f2}\u{1f1ea}",
        "\u{1f1f2}\u{1f1eb}",
        "\u{1f1f2}\u{1f1ec}",
        "\u{1f1f2}\u{1f1ed}",
        "\u{1f1f2}\u{1f1f0}",
        "\u{1f1f2}\u{1f1f1}",
        "\u{1f1f2}\u{1f1f2}",
        "\u{1f1f2}\u{1f1f3}",
        "\u{1f1f2}\u{1f1f4}",
        "\u{1f1f2}\u{1f1f5}",
        "\u{1f1f2}\u{1f1f6}",
        "\u{1f1f2}\u{1f1f7}",
        "\u{1f1f2}\u{1f1f8}",
        "\u{1f1f2}\u{1f1f9}",
        "\u{1f1f2}\u{1f1fa}",
        "\u{1f1f2}\u{1f1fb}",
        "\u{1f1f2}\u{1f1fc}",
        "\u{1f1f2}\u{1f1fd}",
        "\u{1f1f2}\u{1f1fe}",
        "\u{1f1f2}\u{1f1ff}",
        "\u{1f1f3}\u{1f1e6}",
        "\u{1f1f3}\u{1f1e8}",
        "\u{1f1f3}\u{1f1ea}",
        "\u{1f1f3}\u{1f1eb}",
        "\u{1f1f3}\u{1f1ec}",
        "\u{1f1f3}\u{1f1ee}",
        "\u{1f1f3}\u{1f1f1}",
        "\u{1f1f3}\u{1f1f4}",
        "\u{1f1f3}\u{1f1f5}",
        "\u{1f1f3}\u{1f1f7}",
        "\u{1f1f3}\u{1f1fa}",
        "\u{1f1f3}\u{1f1ff}",
        "\u{1f1f4}\u{1f1f2}",
        "\u{1f1f5}\u{1f1e6}",
        "\u{1f1f5}\u{1f1ea}",
        "\u{1f1f5}\u{1f1eb}",
        "\u{1f1f5}\u{1f1ec}",
        "\u{1f1f5}\u{1f1ed}",
        "\u{1f1f5}\u{1f1f0}",
        "\u{1f1f5}\u{1f1f1}",
        "\u{1f1f5}\u{1f1f2}",
        "\u{1f1f5}\u{1f1f3}",
        "\u{1f1f5}\u{1f1f7}",
        "\u{1f1f5}\u{1f1f8}",
        "\u{1f1f5}\u{1f1f9}",
        "\u{1f1f5}\u{1f1fc}",
        "\u{1f1f5}\u{1f1fe}",
        "\u{1f1f6}\u{1f1e6}",
        "\u{1f1f7}\u{1f1ea}",
        "\u{1f1f7}\u{1f1f4}",
        "\u{1f1f7}\u{1f1f8}",
        "\u{1f1f7}\u{1f1fa}",
        "\u{1f1f7}\u{1f1fc}",
        "\u{1f1f8}\u{1f1e6}",
        "\u{1f1f8}\u{1f1e7}",
        "\u{1f1f8}\u{1f1e8}",
        "\u{1f1f8}\u{1f1e9}",
        "\u{1f1f8}\u{1f1ea}",
        "\u{1f1f8}\u{1f1ec}",
        "\u{1f1f8}\u{1f1ed}",
        "\u{1f1f8}\u{1f1ee}",
        "\u{1f1f8}\u{1f1ef}",
        "\u{1f1f8}\u{1f1f0}",
        "\u{1f1f8}\u{1f1f1}",
        "\u{1f1f8}\u{1f1f2}",
        "\u{1f1f8}\u{1f1f3}",
        "\u{1f1f8}\u{1f1f4}",
        "\u{1f1f8}\u{1f1f7}",
        "\u{1f1f8}\u{1f1f8}",
        "\u{1f1f8}\u{1f1f9}",
        "\u{1f1f8}\u{1f1fb}",
        "\u{1f1f8}\u{1f1fd}",
        "\u{1f1f8}\u{1f1fe}",
        "\u{1f1f8}\u{1f1ff}",
        "\u{1f1f9}\u{1f1e6}",
        "\u{1f1f9}\u{1f1e8}",
        "\u{1f1f9}\u{1f1e9}",
        "\u{1f1f9}\u{1f1eb}",
        "\u{1f1f9}\u{1f1ec}",
        "\u{1f1f9}\u{1f1ed}",
        "\u{1f1f9}\u{1f1ef}",
        "\u{1f1f9}\u{1f1f0}",
        "\u{1f1f9}\u{1f1f1}",
        "\u{1f1f9}\u{1f1f2}",
        "\u{1f1f9}\u{1f1f3}",
        "\u{1f1f9}\u{1f1f4}",
        "\u{1f1f9}\u{1f1f7}",
        "\u{1f1f9}\u{1f1f9}",
        "\u{1f1f9}\u{1f1fb}",
        "\u{1f1f9}\u{1f1fc}",
        "\u{1f1f9}\u{1f1ff}",
        "\u{1f1fa}\u{1f1e6}",
        "\u{1f1fa}\u{1f1ec}",
        "\u{1f1fa}\u{1f1f2}",
        "\u{1f1fa}\u{1f1f3}",
        "\u{1f1fa}\u{1f1f8}",
        "\u{1f1fa}\u{1f1fe}",
        "\u{1f1fa}\u{1f1ff}",
        "\u{1f1fb}\u{1f1e6}",
        "\u{1f1fb}\u{1f1e8}",
        "\u{1f1fb}\u{1f1ea}",
        "\u{1f1fb}\u{1f1ec}",
        "\u{1f1fb}\u{1f1ee}",
        "\u{1f1fb}\u{1f1f3}",
        "\u{1f1fb}\u{1f1fa}",
        "\u{1f1fc}\u{1f1eb}",
        "\u{1f1fc}\u{1f1f8}",
        "\u{1f1fd}\u{1f1f0}",
        "\u{1f1fe}\u{1f1ea}",
        "\u{1f1fe}\u{1f1f9}",
        "\u{1f1ff}\u{1f1e6}",
        "\u{1f1ff}\u{1f1f2}",
        "\u{1f1ff}\u{1f1fc}",
    ],
    "subdivision-flag": [
        "\u{1f3f4}\u{e0067}\u{e0062}\u{e0065}\u{e006e}\u{e0067}\u{e007f}",
        "\u{1f3f4}\u{e0067}\u{e0062}\u{e0073}\u{e0063}\u{e0074}\u{e007f}",
        "\u{1f3f4}\u{e0067}\u{e0062}\u{e0077}\u{e006c}\u{e0073}\u{e007f}",
    ],
};
