import React from "react";
import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../misc/Card";
import { Link } from "react-router-dom";
import { Button } from "../misc/Button";

const CampaignGenerationMaintenance = () => {
    return (
        <div className="min-h-screen bg-background flex items-center justify-center p-4">
            <Card className="w-full max-w-md">
                <CardHeader>
                    <CardTitle className="text-2xl font-bold text-center">
                        Under Maintenance
                    </CardTitle>
                </CardHeader>
                <CardContent className="space-y-4">
                    <p className="text-center text-gray-600">
                        We are currently working on improving our campaign
                        generation system.
                    </p>
                    <p className="text-sm text-center text-gray-500">
                        The rest of the site is fully operational.
                    </p>
                </CardContent>
                <CardFooter className="flex justify-center">
                    <Link to="/">
                        <Button>Return to Homepage</Button>
                    </Link>
                </CardFooter>
            </Card>
        </div>
    );
};

export default CampaignGenerationMaintenance;
