import React, { useState } from "react";
import { PromotionTabButtonProps } from "./types";
import { Button } from "../misc/Button";
import { FaBoxArchive, FaSquarePlus, FaTrash } from "react-icons/fa6";
import { FaEdit, FaPlay, FaSave } from "react-icons/fa";
import ReconfirmOverlay from "../misc/ReconfirmOverlay";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";
import { MdCancel } from "react-icons/md";

type ActionType = "delete" | "pause" | "resume" | "cancel" | null;

const TabButtons: React.FC<PromotionTabButtonProps> = ({
    isEditing,
    setIsEditing,
    promotion,
    setLoading,
    selectedCategories,
    setSelectedCategories,
    setPromotion,
    selectedMedia,
    setSelectedMedia,
    fetchAssets,
    originalPromotion,
    fetchPromotions,
    setOriginalPromotion,
    isMobile,
    handleSave,
    mediaLength,
}) => {
    const [actionToConfirm, setActionToConfirm] = useState<ActionType>(null);

    const confirmAction = () => {
        if (actionToConfirm === "delete") {
            handlePromotionDelete();
        } else if (actionToConfirm === "pause") {
            handlePauseCampaign();
        } else if (actionToConfirm === "resume") {
            handleResumeCampaign();
        } else if (actionToConfirm === "cancel") {
            setPromotion(originalPromotion);
            setSelectedCategories(originalPromotion?.types || []);
            setSelectedMedia([]);
            setIsEditing(false);
        }
        setActionToConfirm(null);
    };

    const overlayProps = () => {
        switch (actionToConfirm) {
            case "cancel":
                return {
                    title: "Cancel Changes",
                    message: "Are you sure you want to cancel your changes?",
                    messageTwo: "All unsaved changes will be lost.",
                    confirmText: "Cancel Changes",
                };
            case "delete":
                return {
                    title: "Delete Promotion",
                    message: "Are you sure you want to delete this promotion?",
                    messageTwo: "This action cannot be undone.",
                    confirmText: "Delete Promotion",
                };
            case "pause":
                return {
                    title: "Pause Campaign",
                    message: "Are you sure you want to pause this campaign?",
                    messageTwo: "You can resume it later.",
                    confirmText: "Pause Campaign",
                };
            case "resume":
                return {
                    title: "Resume Campaign",
                    message: "Are you sure you want to resume this campaign?",
                    messageTwo: "Any paused posts will be resumed from today.",
                    confirmText: "Resume Campaign",
                };
            default:
                return null;
        }
    };

    const handlePromotionDelete = async () => {
        if (!promotion || !promotion.id) {
            toast.error(
                <CustomToast
                    message="Failed to delete promotion. Please try again later."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
            return;
        }

        setLoading(true);

        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.delete(
                `${backendUrl}/promotions/${promotion.id}`,
                {
                    headers,
                }
            );

            if (response.status === 200) {
                setLoading(false);
                toast.success(
                    <CustomToast
                        message="Promotion deleted successfully."
                        type="success"
                    />,
                    {
                        autoClose: 3000,
                    }
                );
                setIsEditing(false);
                fetchPromotions();
                setPromotion(null);
                setSelectedCategories([]);
            } else {
                throw new Error("Failed to delete promotion.");
            }
        } catch (error: unknown) {
            setLoading(false);
            console.error("Failed to delete promotion:", error);
            toast.error(
                <CustomToast
                    message="Failed to delete promotion. Please try again later."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        } finally {
            setLoading(false);
        }
    };

    const handleCreatePromotion = async () => {
        setLoading(true);
        if (selectedCategories.length <= 0) {
            toast.error(
                <CustomToast
                    message="You need to choose at least one category to create a promotion."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );

            setLoading(false);
            return;
        }

        try {
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            console.log(selectedCategories);
            const response = await axios.post(
                `${backendUrl}/promotions`,
                { types: selectedCategories },
                { headers }
            );

            if (!response.data) {
                throw new Error("Response data is null.");
            }

            if (response.status === 201) {
                toast.success(
                    <CustomToast
                        message="Promotion created successfully."
                        type="success"
                    />,
                    {
                        autoClose: 3000,
                    }
                );
                setPromotion(response.data);
                setOriginalPromotion(response.data);
                setIsEditing(false);
            } else {
                throw new Error("Failed to create promotion.");
            }
        } catch (error: unknown) {
            console.error("Failed to create promotion:", error);
            setLoading(false);
            toast.error(
                <CustomToast
                    message="Failed to create promotion. Please try again later."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        } finally {
            setLoading(false);
        }
    };

    const handlePauseCampaign = async () => {
        if (!promotion?.campaignId) return;

        setLoading(true);

        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.patch(
                `${backendUrl}/promotions/campaign/${promotion.campaignId}`,
                { status: "PAUSED" },
                { headers }
            );

            if (response.status === 200) {
                setLoading(false);
                toast.success(
                    <CustomToast
                        message="Campaign paused successfully."
                        type="success"
                    />,
                    { autoClose: 3000 }
                );
                fetchPromotions();
            } else {
                throw new Error("Failed to pause campaign.");
            }
        } catch (error: unknown) {
            setLoading(false);
            console.error("Error pausing campaign", error);
            toast.error(
                <CustomToast
                    message="Error pausing campaign. Please try again later."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    const handleResumeCampaign = async () => {
        if (!promotion?.campaignId) return;

        setLoading(true);

        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.patch(
                `${backendUrl}/promotions/campaign/${promotion.campaignId}`,
                { status: "ACTIVE" },
                { headers }
            );

            if (response.status === 200) {
                setLoading(false);
                toast.success(
                    <CustomToast
                        message="Campaign resumed successfully."
                        type="success"
                    />,
                    { autoClose: 3000 }
                );
                fetchPromotions();
            } else {
                throw new Error("Failed to resume campaign.");
            }
        } catch (error: unknown) {
            setLoading(false);
            console.error("Error resuming campaign", error);
            toast.error(
                <CustomToast
                    message="Error resuming campaign. Please try again later."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        if (
            selectedCategories &&
            originalPromotion &&
            selectedCategories === originalPromotion.types
        ) {
            setIsEditing(false);
        } else if (
            selectedCategories &&
            originalPromotion &&
            selectedCategories !== originalPromotion.types
        ) {
            setActionToConfirm("cancel");
        }
    };

    const currentOverlayProps = overlayProps();

    return (
        <div className={`${isMobile ? "mt-0" : "w-full mt-0"}`}>
            {currentOverlayProps && (
                <ReconfirmOverlay
                    isOpen={true}
                    onClose={() => setActionToConfirm(null)}
                    onConfirm={confirmAction}
                    title={currentOverlayProps!.title}
                    message={currentOverlayProps!.message}
                    messageTwo={currentOverlayProps!.messageTwo}
                    confirmText={currentOverlayProps!.confirmText}
                />
            )}
            {isEditing ? (
                promotion ? (
                    // Edit mode with existing promotion
                    <div className="flex flex-col space-y-2">
                        {/* Top Buttons: Save and Cancel */}
                        <div
                            className={`flex ${
                                isMobile
                                    ? "flex-col space-y-2"
                                    : "flex-row justify-end space-x-2"
                            }`}
                        >
                            {/* Save Button */}
                            <Button
                                onClick={handleSave}
                                aria-label="Save Changes"
                                id="save-changes-btn"
                                className="bg-secondary hover:bg-primary flex items-center justify-center space-x-2 px-4 py-2"
                            >
                                <FaSave className="h-5 w-5" />
                                <span>Save</span>
                            </Button>

                            {/* Cancel Button */}
                            <Button
                                onClick={() => handleCancel()}
                                aria-label="Cancel Changes"
                                className="bg-secondary hover:bg-primary flex items-center justify-center space-x-2 px-4 py-2"
                            >
                                <MdCancel className="h-5 w-5" />
                                <span>Cancel</span>
                            </Button>
                        </div>

                        {/* Bottom Buttons: Pause/Resume Campaign and Delete Promotion */}
                        <div
                            className={`flex ${
                                isMobile
                                    ? "flex-col space-y-2"
                                    : "flex-row justify-end space-x-2"
                            }`}
                        >
                            {/* Campaign Buttons */}
                            {promotion?.campaignStatus === "PAUSED" ? (
                                <Button
                                    onClick={() => setActionToConfirm("resume")}
                                    aria-label="Resume Campaign"
                                    id="resume-campaign-btn"
                                    className="bg-secondary hover:bg-primary flex items-center justify-center space-x-2 px-4 py-2"
                                >
                                    <FaPlay className="h-5 w-5" />
                                    <span>Resume Campaign</span>
                                </Button>
                            ) : (
                                promotion?.campaignStatus === "ACTIVE" && (
                                    <Button
                                        onClick={() =>
                                            setActionToConfirm("pause")
                                        }
                                        aria-label="Pause Campaign"
                                        id="pause-campaign-btn"
                                        className="bg-secondary hover:bg-primary flex items-center justify-center space-x-2 px-4 py-2"
                                    >
                                        <FaBoxArchive className="h-5 w-5" />
                                        <span>Pause Campaign</span>
                                    </Button>
                                )
                            )}

                            {/* Delete Promotion */}
                            <Button
                                onClick={() => setActionToConfirm("delete")}
                                aria-label="Delete Promotion"
                                variant="destructive"
                                id="delete-promotion-btn"
                                className="flex items-center justify-center space-x-2 px-4 py-2"
                                disabled={mediaLength > 0}
                            >
                                <FaTrash className="h-5 w-5" />
                                <span>Delete Promotion</span>
                            </Button>
                        </div>
                    </div>
                ) : (
                    // Edit mode without existing promotion
                    <div
                        className={`flex ${
                            isMobile
                                ? "flex-col space-y-2"
                                : "flex-row justify-end space-x-2"
                        }`}
                    >
                        <Button
                            className="bg-secondary hover:bg-primary flex items-center justify-center space-x-2 px-4 py-2"
                            onClick={handleCreatePromotion}
                            aria-label="Create Promotion"
                        >
                            <FaSquarePlus className="h-5 w-5" />
                            <span>Create Promotion</span>
                        </Button>
                    </div>
                )
            ) : !isMobile ? (
                // Non-editing mode
                <div
                    className={`flex ${
                        isMobile
                            ? "flex-col space-y-2"
                            : "flex-row space-x-4 justify-end"
                    }`}
                >
                    {/* Edit Button */}
                    <Button
                        className="bg-secondary hover:bg-primary flex items-center justify-center space-x-2 px-4 py-2"
                        onClick={() => setIsEditing(true)}
                        aria-label="Edit Promotion"
                    >
                        <FaEdit className="h-5 w-5" />
                        <span>Edit</span>
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

export default TabButtons;
