import React from "react";

interface DividerProps {
  text?: string;
  className?: string;
}

const Divider: React.FC<DividerProps> = ({ className }) => {
  return (
    <div
      className={`flex flex-col pb-1.5 w-full text-lg font-medium tracking-normal whitespace-nowrap text-primary ${className}`}
    >
      <hr className="w-full border-t border-secondary" />
    </div>
  );
};

export default Divider;
