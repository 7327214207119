import React from "react";
import { Assets } from "../../types/asset-type";
import { FiCheckCircle } from "react-icons/fi";
import { FaPlay } from "react-icons/fa";
import VideoThumbnail from "../listings/VideoThumbnail";
import { MediaThumbnailsProps } from "./types";

const MediaThumbnails: React.FC<MediaThumbnailsProps> = ({
    assets,
    toggleSelectMedia,
    selectedMedia,
    isEditing,
    carouselApi,
    carouselAssetIndexMap,
    isMobile,
    onThumbnailClick,
}) => {
    const handleThumbnailClick = (asset: Assets, index: number) => {
        if (isEditing) {
            // Toggle selection for deletion
            toggleSelectMedia(asset);
        } else {
            // Navigate to the corresponding image in the carousel
            if (carouselApi && carouselAssetIndexMap[asset.id] !== undefined) {
                const carouselIndex = carouselAssetIndexMap[asset.id];
                carouselApi.scrollTo(carouselIndex);

                // Invoke the callback to save the selected index
                if (onThumbnailClick) {
                    onThumbnailClick(carouselIndex);
                }
            }
        }
    };

    return (
        <div
            className={`grid ${
                isMobile ? "grid-cols-2" : "grid-cols-5"
            } gap-2 mt-4`}
        >
            {assets?.map((media, idx) => {
                const isSelected = selectedMedia.some((m) => m.id === media.id);

                return (
                    <div
                        id={`media-${idx}`}
                        key={media.id || media.name}
                        className={`relative h-32 cursor-pointer ${
                            isSelected ? "ring-2 ring-secondary rounded" : ""
                        }`}
                        onClick={() => handleThumbnailClick(media, idx)}
                    >
                        {media.assetType === "Video" ? (
                            <VideoThumbnail
                                videoUrl={media.url}
                                className="w-full h-full object-cover rounded"
                            />
                        ) : (
                            <img
                                src={media.url}
                                alt={`${media.name}`}
                                className="w-full h-full object-cover rounded"
                            />
                        )}
                        {media.assetType === "Video" && (
                            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded">
                                <FaPlay className="text-white" size={24} />
                            </div>
                        )}
                        {/* Overlay when item is selected */}
                        {isEditing && (
                            <div className="absolute top-2 left-2 bg-primary flex flex-row justify-center items-center p-1 rounded-xl w-[25px] h-[25px]">
                                {isSelected && (
                                    <FiCheckCircle className="text-secondary text-[18px]" />
                                )}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default MediaThumbnails;
