// PreviewOverlay.tsx

import React, { useEffect, useState, useRef } from "react";
import { PreviewOverlayProps } from "./types";

const BASE_WIDTH = 1280;
const BASE_HEIGHT = 720;
const DEFAULT_GRADIENT_HEIGHT = 100; // When no logo is present
const PADDING = 30;
const FONT_SIZE = 36;
const LOGO_MAX_HEIGHT = 100; // Define maximum logo height

const PreviewOverlay: React.FC<PreviewOverlayProps> = ({
    formattedAddress = "",
    newOverlayHasLogo,
    logoFile,
    logoUrl,
    selectedLogo,
    logos,
    theme = "dark",
    image,
}) => {
    const previewRef = useRef<HTMLDivElement>(null);
    const [scaleFactor, setScaleFactor] = useState<number>(1);

    const singleLineText = formattedAddress.replace(/\s*\n+\s*/g, " ").trim();

    // Decide on text color based on theme
    const textColor = theme === "dark" ? "#FFFFFF" : "#000000";

    useEffect(() => {
        const updateScaleFactor = () => {
            if (previewRef.current) {
                const previewWidth = previewRef.current.offsetWidth;
                // Scale factor relative to the base width (1280)
                const factor = previewWidth / BASE_WIDTH;
                setScaleFactor(factor);
            }
        };

        updateScaleFactor();
        window.addEventListener("resize", updateScaleFactor);
        return () => window.removeEventListener("resize", updateScaleFactor);
    }, []);

    const finalLogoUrl =
        logoUrl || logos.find((l) => l.id === selectedLogo)?.url;

    // Determine gradient height
    const gradientHeight = newOverlayHasLogo
        ? LOGO_MAX_HEIGHT + PADDING * 2
        : DEFAULT_GRADIENT_HEIGHT;

    console.log("gradientHeight", gradientHeight);
    return (
        <div className="space-y-2">
            <h4 className="text-sm font-medium">Preview</h4>

            <div
                ref={previewRef}
                style={{
                    aspectRatio: "16 / 9",
                    width: "100%",
                    maxWidth: "640px",
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative",
                    overflow: "hidden",
                    borderRadius: "8px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
            >
                <div
                    style={{
                        width: `${BASE_WIDTH}px`,
                        height: `${BASE_HEIGHT}px`,
                        transform: `scale(${scaleFactor})`,
                        transformOrigin: "top left",
                        position: "absolute",
                        top: 0,
                        left: 0,
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            left: 0,
                            width: "100%",
                            height: `${gradientHeight}px`,
                            bottom: 0,
                            background:
                                theme === "dark"
                                    ? "linear-gradient(0deg, #000 0%, rgba(0,0,0,0) 100%)"
                                    : "linear-gradient(0deg, #FFF 0%, rgba(255,255,255,0) 100%)",
                            transition:
                                "background 0.3s ease, height 0.3s ease",
                        }}
                    />

                    {newOverlayHasLogo && finalLogoUrl ? (
                        <div
                            style={{
                                position: "absolute",
                                left: PADDING,
                                bottom: PADDING,
                                display: "flex",
                                alignItems: "center",
                                width: `calc(100% - ${PADDING * 2}px)`,
                                justifyContent: "space-between",
                            }}
                        >
                            <img
                                src={finalLogoUrl}
                                alt="Logo"
                                style={{
                                    maxHeight: `${LOGO_MAX_HEIGHT}px`,
                                    maxWidth: "200px",
                                    objectFit: "contain",
                                }}
                            />

                            <div
                                style={{
                                    color: textColor,
                                    fontSize: `${FONT_SIZE}px`,
                                    fontFamily: "Verdana, sans-serif",
                                    fontWeight: "bold",
                                }}
                            >
                                {singleLineText}
                            </div>
                        </div>
                    ) : newOverlayHasLogo ? (
                        <div
                            style={{
                                position: "absolute",
                                left: PADDING,
                                bottom: PADDING,
                                display: "flex",
                                alignItems: "center",
                                width: `calc(100% - ${PADDING * 2}px)`,
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: "#FFF",
                                    color: "#000",
                                    height: `${LOGO_MAX_HEIGHT}px`,
                                    width: "200px",
                                }}
                            >
                                Logo
                            </div>

                            <div
                                style={{
                                    color: textColor,
                                    fontSize: `${FONT_SIZE}px`,
                                    fontFamily: "Verdana, sans-serif",
                                    fontWeight: "bold",
                                }}
                            >
                                {singleLineText}
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                position: "absolute",
                                right: "3%",
                                bottom: "3%",
                                color: textColor,
                                fontSize: `${FONT_SIZE}px`,
                                fontFamily: "Verdana, sans-serif",
                                fontWeight: "bold",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {singleLineText}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PreviewOverlay;
