import React, { useState } from "react";
import { DeleteMediaProps } from "./types";
import { Button } from "../../../misc/Button";
import { getAuthHeader } from "../../../../utils/authHeader";
import { isCombinedAsset } from "./utils";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../../../misc/CustomToast";
import ReconfirmOverlay from "../../../misc/ReconfirmOverlay";

type ConfirmAction = "deleteMedia" | null;

const DeleteMediaButton: React.FC<DeleteMediaProps> = ({
    selectedMedia,
    setIsLoading,
    mlsApproved,
    listingId,
    listingType,
    handleDeleteComplete,
    setSelectedMedia,
}) => {
    const [actionToConfirm, setActionToConfirm] = useState<ConfirmAction>(null);
    const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);

    const handleAction = (action: ConfirmAction) => {
        setIsOverlayOpen(true);
        setActionToConfirm(action);
    };

    const confirmAction = async () => {
        if (!actionToConfirm) return;
        setIsLoading(true);
        try {
            switch (actionToConfirm) {
                case "deleteMedia": {
                    setIsLoading(true);
                    if (selectedMedia.length === 0) return;

                    try {
                        const headers = await getAuthHeader();
                        const backendUrl = process.env.REACT_APP_BACKEND_URL;

                        // Group selectedMedia by type
                        const assetsToDelete = selectedMedia
                            .filter(isCombinedAsset)
                            .map((media) => media.id);
                        const imageIdsToDelete = selectedMedia
                            .filter(
                                (media) =>
                                    media.type === "image" &&
                                    !isCombinedAsset(media)
                            )
                            .map((media) => media.id);
                        const videoIdsToDelete = selectedMedia
                            .filter(
                                (media) =>
                                    media.type === "video" &&
                                    !isCombinedAsset(media)
                            )
                            .map((media) => media.id);

                        const deletePromises = [];

                        if (assetsToDelete.length > 0) {
                            deletePromises.push(
                                await axios.delete(`${backendUrl}/assets`, {
                                    headers,
                                    data: {
                                        ids: assetsToDelete,
                                    },
                                })
                            );
                        }

                        if (imageIdsToDelete.length > 0) {
                            deletePromises.push(
                                await axios.delete(
                                    `${backendUrl}/listing/images/`,
                                    {
                                        headers: headers,
                                        data: {
                                            listingId: listingId,
                                            listingType: listingType,
                                            imageIds: imageIdsToDelete,
                                        },
                                    }
                                )
                            );
                        }

                        if (videoIdsToDelete.length > 0) {
                            deletePromises.push(
                                await axios.delete(
                                    `${backendUrl}/listing/videos/`,
                                    {
                                        headers: headers,
                                        data: {
                                            listingId: listingId,
                                            listingType: listingType,
                                            videos: videoIdsToDelete,
                                        },
                                    }
                                )
                            );
                        }

                        await Promise.all(deletePromises);
                        setIsLoading(false);
                        toast.success(
                            <CustomToast
                                message="Selected items deleted successfully."
                                type="success"
                            />,
                            { autoClose: 3000 }
                        );
                        // Refresh listing data
                        handleDeleteComplete();
                        setSelectedMedia([]);
                    } catch (error) {
                        setIsLoading(false);
                        console.error("Error deleting media:", error);
                        toast.error(
                            <CustomToast
                                message="Failed to delete selected items."
                                type="error"
                            />,
                            { autoClose: 3000 }
                        );
                    }
                    break;
                }
                default:
                    break;
            }

            setIsLoading(false);
            toast.success(
                <CustomToast
                    message="Selected items deleted successfully."
                    type="success"
                />,
                { autoClose: 3000 }
            );
        } catch (error) {
            setIsLoading(false);
            console.error("Error deleting media:", error);
            toast.error(
                <CustomToast
                    message="Failed to delete selected items."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setActionToConfirm(null);
        }
    };

    const getOverlayContent = () => {
        switch (actionToConfirm) {
            case "deleteMedia":
                return {
                    title: "Delete Media",
                    message: "Are you sure you want to delete?",
                    messageTwo: "This action will delet the selected media.",
                    confirmText: "Delete",
                };
            default:
                return {
                    title: "",
                    message: "",
                    messageTwo: "",
                    confirmText: "",
                };
        }
    };

    const closeOverlay = () => {
        setIsOverlayOpen(false);
        setActionToConfirm(null);
    };

    const { title, message, messageTwo, confirmText } = getOverlayContent();

    return (
        <div>
            <ReconfirmOverlay
                isOpen={isOverlayOpen}
                onClose={closeOverlay}
                onConfirm={confirmAction}
                title={title}
                message={message}
                messageTwo={messageTwo}
                confirmText={confirmText}
            />
            <span className="mr-2 text-primary">
                {selectedMedia.length} Selected
            </span>

            {selectedMedia.length > 0 && (
                <Button
                    id="delete-media-btn"
                    onClick={() => handleAction("deleteMedia")}
                    variant="destructive"
                >
                    Delete Selected
                </Button>
            )}
        </div>
    );
};

export default DeleteMediaButton;
