import React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Button } from "./Button";
import { Card, CardContent, CardHeader, CardTitle } from "./Card";

interface CustomDatePickerProps {
  date: Date | undefined;
  setDate: (date: Date) => void;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  date,
  setDate,
}) => {
  const [currentDate, setCurrentDate] = React.useState<Date>(
    date || new Date()
  );

  React.useEffect(() => {
    if (date) {
      setCurrentDate(date);
    }
  }, [date]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const daysInMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  ).getDate();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  ).getDay();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const prevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const nextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };

  const handleDateClick = (day: number) => {
    const selected = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    );
    setDate(selected);
  };

  const renderCalendarDays = () => {
    const days = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="w-9 h-9"></div>);
    }
    for (let i = 1; i <= daysInMonth; i++) {
      const dayDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        i
      );
      dayDate.setHours(0, 0, 0, 0);

      const isSelected =
        date?.getDate() === i &&
        date?.getMonth() === currentDate.getMonth() &&
        date?.getFullYear() === currentDate.getFullYear();

      const isToday = dayDate.getTime() === today.getTime();

      const isBeforeToday = dayDate < today;

      let className = "w-9 h-9 p-0 hover:bg-secondary hover:text-white";
      if (isSelected) {
        className += " bg-primary text-primary-foreground";
      } else if (isToday) {
        className += " bg-lightSecondary";
      }

      days.push(
        <Button
          key={i}
          variant={isSelected ? "default" : "ghost"}
          className={className}
          onClick={() => handleDateClick(i)}
          disabled={isBeforeToday}
          title={isBeforeToday ? "Disabled" : ""}
        >
          {i}
        </Button>
      );
    }
    return days;
  };

  return (
    <Card className="w-full max-w-sm">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-semibold">
          {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
        </CardTitle>
        <div className="space-x-1">
          <Button
            variant="outline"
            size="icon"
            className="h-7 w-7"
            onClick={prevMonth}
            aria-label="Previous month"
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            size="icon"
            className="h-7 w-7"
            onClick={nextMonth}
            aria-label="Next month"
          >
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-7 gap-1 text-center">
          {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day) => (
            <div
              key={day}
              className="text-sm font-medium text-muted-foreground"
            >
              {day}
            </div>
          ))}
        </div>
        <div id="day-grid" className="grid grid-cols-7 gap-1 mt-1">
          {renderCalendarDays()}
        </div>
      </CardContent>
    </Card>
  );
};

export default CustomDatePicker;
