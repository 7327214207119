import React from "react";
import { cva, type VariantProps } from "class-variance-authority";

const badgeVariants = cva(
    "inline-flex items-center rounded-full border px-2 py-1 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-white overflow-hidden text-ellipsis whitespace-nowrap",
    {
        variants: {
            variant: {
                facebook:
                    "border-transparent bg-[#1877F2] text-primary-foreground hover:bg-primary/80",
                instagram:
                    "border-transparent bg-[#D62976] text-secondary-foreground hover:bg-secondary/80",
                primary:
                    "border-transparent bg-primary text-primary-foreground hover:bg-[#DBA507]",
                secondary:
                    "border-transparent bg-[#DBA507] text-secondary-foreground hover:bg-secondary/80 flex-shrink-0 justify-center",
                destructive:
                    "border-transparent bg-gray-400 text-destructive-foreground hover:bg-destructive/80",
                default:
                    "border-transparent bg-primary text-type-foreground hover:bg-secondary",
                outline: "text-foreground",
            },
        },
        defaultVariants: {
            variant: "default",
        },
    }
);

export interface BadgeProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof badgeVariants> {}

const Badge: React.FC<BadgeProps> = ({ variant, className = "", ...props }) => {
    const combinedClassName = `${badgeVariants({ variant })} ${className}`;
    return <div className={combinedClassName} {...props} />;
};

export { Badge };
