import React from "react";
import { GoDotFill } from "react-icons/go";

interface FeatureItemProps {
    text: string;
    size?: "sm" | "md" | "lg";
}

const FeatureItem: React.FC<FeatureItemProps> = ({ text, size = "md" }) => {
    let x = 0;

    switch (size) {
        case "sm":
            x = 4;
            break;
        case "md":
            x = 6;
            break;
        case "lg":
            x = 8;
            break;
    }

    return (
        <li
            className={`flex gap-4 items-center ${
                size === "sm" ? "mt-1" : "mt-3"
            } w-full`}
        >
            <GoDotFill className={`text-primary w-${x} h-${x}`} />
            <span className={`flex-1 shrink self-stretch my-auto basis-0`}>
                {text}
            </span>
        </li>
    );
};

export default FeatureItem;
