import React, { useState } from "react";
import { format, addDays, startOfWeek, isSameDay, compareAsc } from "date-fns";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import DayView from "./DayView";
import { CalendarProps } from "../../types/calendar-props";
import PostCard from "./PostCard";

interface FourWeekViewProps extends CalendarProps {
    currentDate: Date;
}

const FourWeekView: React.FC<FourWeekViewProps> = ({ posts, currentDate }) => {
    const [selectedDay, setSelectedDay] = useState<Date | null>(null);

    // Calculate the start date (beginning of the current week)
    const weekStart = startOfWeek(currentDate, { weekStartsOn: 1 }); // Monday
    // Generate an array of dates for 4 weeks (28 days)
    const totalDays = 28;
    const daysArray = Array.from({ length: totalDays }, (_, i) =>
        addDays(weekStart, i)
    );

    // Split the days into weeks (layers)
    const weeks = [];
    for (let i = 0; i < totalDays; i += 7) {
        weeks.push(daysArray.slice(i, i + 7));
    }

    const handleMoreClick = (day: Date) => {
        setSelectedDay(day);
    };

    const closeDayView = () => {
        setSelectedDay(null);
    };

    const renderDayCell = (day: Date) => {
        const dayPosts = posts
            .filter((post) => isSameDay(new Date(post.scheduledFor), day))
            .sort((a, b) =>
                compareAsc(new Date(a.scheduledFor), new Date(b.scheduledFor))
            );

        // Show up to 3 posts
        const postsToShow = dayPosts.slice(0, 2);
        const hasMorePosts = dayPosts.length > 2;

        const isToday = isSameDay(day, new Date());

        return (
            <div
                key={day.toString()}
                className="flex-1 min-w-[14.28%] p-1 border border-gray-200 relative"
                style={{
                    backgroundColor: isToday
                        ? "rgba(240, 223, 175, 0.50)"
                        : "transparent",
                    minHeight: "180px",
                }}
            >
                <div className="absolute top-2 left-2 text-xs font-semibold">
                    {format(day, "d")}
                </div>
                <div className="mt-6 space-y-1">
                    {postsToShow.map((post, index) => (
                        <PostCard date={format(day, "EEE-d")} idx={index} key={post.id} post={post} />
                    ))}
                    {hasMorePosts && (
                        <div
                            className="flex justify-center items-center cursor-pointer mt-2"
                            onClick={() => handleMoreClick(day)}
                        >
                            <HiOutlineDotsHorizontal
                                size={20}
                                className="text-primary"
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="flex flex-col">
                {weeks.map((week, index) => (
                    <div key={index} className="flex">
                        {week.map(renderDayCell)}
                    </div>
                ))}
            </div>
            {selectedDay && (
                <DayView
                    day={selectedDay}
                    posts={posts}
                    onClose={closeDayView}
                />
            )}
        </>
    );
};

export default FourWeekView;
