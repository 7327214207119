import React, { useCallback, useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import StepProgressBar from "../progress-bar";
import InputField from "../misc/InputField";
import { BrokerDetails as BrokerDetailsType } from "../../types/onboarding";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { Button } from "../misc/Button";
import LoadingScreen from "../../screens/LoadingScreen";
import "react-toastify/dist/ReactToastify.css";
import { isValidEmail } from "../../utils/validationUtil";
import { getAuthHeader } from "../../utils/authHeader";
import { useIsMobile } from "../../hooks/useIsMobile";

interface BrokerDetailsProps {
    advanceStep: () => void;
    goBackStep: () => void;
    role: string | null;
}

const BrokerDetails: React.FC<BrokerDetailsProps> = ({
    advanceStep,
    goBackStep,
    role,
}) => {
    const [brokerDetails, setBrokerDetails] = useState<BrokerDetailsType>({
        broker: "",
        brokerLicense: "",
        brokerEmail: "",
    });

    const [initialDetails, setInitialDetails] =
        useState<BrokerDetailsType | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const fetchBrokerDetails = async () => {
            try {
                setLoading(true);
                const headers = await getAuthHeader();
                const backendUrl = process.env.REACT_APP_BACKEND_URL;
                const response = await axios.get(
                    `${backendUrl}/onboarding/broker-details`,
                    {
                        headers,
                        withCredentials: true,
                    }
                );
                setBrokerDetails({
                    broker: response.data.broker || "",
                    brokerLicense: response.data.brokerLicense || "",
                    brokerEmail: response.data.brokerEmail || "",
                });
                setInitialDetails(response.data);
            } catch (error) {
                console.error("Error fetching broker details:", error);
                toast.error(
                    <CustomToast
                        message="Failed to fetch broker details. Please try again."
                        type="error"
                    />,
                    {
                        autoClose: 3000,
                        position: "top-right",
                    }
                );
            } finally {
                setLoading(false);
            }
        };

        fetchBrokerDetails();
    }, []);

    const hasChanges = useCallback(() => {
        if (!initialDetails) return false;
        return JSON.stringify(brokerDetails) !== JSON.stringify(initialDetails);
    }, [brokerDetails, initialDetails]);

    const validateFields = (): boolean => {
        const newErrors: { [key: string]: string } = {};
        const { broker, brokerLicense, brokerEmail } = brokerDetails;

        if (!broker.trim()) newErrors.broker = "Broker name is required.";
        if (!brokerLicense.trim())
            newErrors.brokerLicense = "Broker license number is required.";
        if (!brokerEmail.trim()) {
            newErrors.brokerEmail = "Broker email address is required.";
        } else if (!isValidEmail(brokerEmail)) {
            newErrors.brokerEmail = "Please enter a valid email address.";
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            toast.error(
                <CustomToast
                    message="Please fix the highlighted fields."
                    type="error"
                />,
                { autoClose: 3000, position: "top-right" }
            );

            // Scroll to the first error field
            const firstErrorField = document.querySelector(
                `[name="${Object.keys(newErrors)[0]}"]`
            );
            if (firstErrorField) {
                firstErrorField.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
                (firstErrorField as HTMLElement).focus();
            }

            return false;
        }

        return true;
    };

    const handleSaveAndNext = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateFields()) return; // Prevent submission if validation fails

        try {
            setLoading(true);
            if (hasChanges()) {
                const headers = await getAuthHeader();
                const backendUrl = process.env.REACT_APP_BACKEND_URL;

                await axios.patch(
                    `${backendUrl}/onboarding/broker-details`,
                    brokerDetails,
                    {
                        headers,
                        withCredentials: true,
                    }
                );
            }

            advanceStep(); // Move to next step
        } catch (error) {
            console.error("Error saving broker details:", error);
            toast.error(
                <CustomToast
                    message="Failed to save broker details. Please try again later!"
                    type="error"
                />,
                {
                    autoClose: 3000,
                    position: "top-right",
                }
            );
        } finally {
            setLoading(false);
        }
    };

    const handleBack = () => {
        goBackStep(); // Set step back to previous step
    };

    const progressSteps = [
        { label: "Agent Details", isActive: true, isCompleted: true },
        { label: "Broker Details", isActive: true, isCompleted: false },
        { label: "Choose MLS", isActive: false, isCompleted: false },
    ];

    if (role !== "TEAM_USER") {
        progressSteps.push({
            label: "Select Plan",
            isActive: false,
            isCompleted: false,
        });
        progressSteps.push({
            label: "Payment",
            isActive: false,
            isCompleted: false,
        });
    }

    const isMobile = useIsMobile(900);

    return (
        <main
            className={`flex flex-col justify-center items-center min-h-screen ${
                isMobile ? "bg-white" : "bg-background"
            } shadow-[0px_4px_4px_rgba(0,0,0,0.25)] px-4 md:px-0`}
        >
            <section className="flex flex-col items-center px-8 py-16 bg-white rounded-2xl min-h-[833px] w-full max-w-[905px] md:px-14 md:py-20">
                {/* Logo and Progress at Top */}
                <div className="w-full flex flex-col items-center mb-8">
                    <img
                        loading="lazy"
                        src={logo}
                        alt="Agent Registration Logo"
                        className="object-contain self-center max-w-full aspect-[8.77] w-64 md:w-[395px]"
                    />
                    <h1 className="my-2 text-primary text-2xl font-bold leading-none text-center">
                        User Onboarding
                    </h1>
                    <StepProgressBar
                        steps={progressSteps.filter((step) => step)}
                    />
                </div>

                {loading && <LoadingScreen />}

                {/* Form in the Middle */}
                <div className="flex-grow flex items-center w-full">
                    <form
                        className="w-full space-y-4 md:space-y-6"
                        onSubmit={handleSaveAndNext}
                    >
                        <InputField
                            name="broker"
                            label="Broker Name"
                            placeholder="Broker Name"
                            type="text"
                            value={brokerDetails.broker}
                            onChange={(e) =>
                                setBrokerDetails({
                                    ...brokerDetails,
                                    broker: e.target.value,
                                })
                            }
                            errorMessage={errors.broker}
                            className="w-full"
                        />
                        <InputField
                            name="brokerLicense"
                            label="Broker License Number"
                            placeholder="License Number"
                            type="text"
                            value={brokerDetails.brokerLicense}
                            onChange={(e) =>
                                setBrokerDetails({
                                    ...brokerDetails,
                                    brokerLicense: e.target.value,
                                })
                            }
                            errorMessage={errors.brokerLicense}
                            className="w-full"
                        />
                        <InputField
                            name="brokerEmail"
                            label="Broker Email Address"
                            placeholder="Email Address"
                            type="email"
                            value={brokerDetails.brokerEmail}
                            onChange={(e) =>
                                setBrokerDetails({
                                    ...brokerDetails,
                                    brokerEmail: e.target.value,
                                })
                            }
                            errorMessage={errors.brokerEmail}
                            className="w-full"
                        />

                        {/* Buttons at the Bottom */}
                        <div className="w-full flex justify-between mt-8 gap-4">
                            <Button
                                className="w-full md:w-1/3"
                                onClick={handleBack}
                                type="button"
                            >
                                Back
                            </Button>
                            <Button className="w-full md:w-1/3" type="submit">
                                Next
                            </Button>
                        </div>
                    </form>
                </div>
            </section>
        </main>
    );
};

export default BrokerDetails;
