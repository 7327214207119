import React from "react";
import { Card, CardContent, CardTitle } from "../../misc/Card";
import { CampaignCardListingProps, CampaignCardProps } from "./types";
import CampaignStatusCard from "../../campaign/CampaignStatusCard";
import { Button } from "../../misc/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomToast from "../../misc/CustomToast";
import { useDispatch } from "react-redux";
import { setSelectedListingAddress } from "../../../store/calendarSlice";
import { OptionType } from "../../../types/option-type";
import { useLocation } from "react-router-dom";
import { useIsMobile } from "../../../hooks/useIsMobile";
import SimpleTooltip from "../../misc/SimpleTooltip";
import { ListingType } from "../../../types/userListing";
import { getAuthHeader } from "../../../utils/authHeader";
import axios from "axios";

const CampaignCard: React.FC<CampaignCardProps> = ({
    allListing,
    mlsApproved,
    isUploading = false,
    setPropertyData,
    setOriginalPropertyData,
    setLoading,
    fetchListing,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleViewCampaign = () => {
        if (allListing?.formattedAddress) {
            const address = allListing.formattedAddress.split(",")[0].trim();

            const option: OptionType[] = [
                {
                    label: address.charAt(0).toUpperCase() + address.slice(1),
                    value: address,
                },
            ];
            dispatch(setSelectedListingAddress(option));
            navigate("/");
        }
    };

    const handleAddPost = () => {
        if (allListing?.formattedAddress) {
            const address = allListing.formattedAddress.split(",")[0].trim();

            const option: OptionType[] = [
                {
                    label: address.charAt(0).toUpperCase() + address.slice(1),
                    value: address,
                },
            ];
            dispatch(setSelectedListingAddress(option));
            navigate("/", {
                state: {
                    addPost: true,
                },
            });
        }
    };

    const CampaignCardListing: CampaignCardListingProps = {
        campaignProgress: allListing?.campaignProgress,
        campaignStatus: allListing?.campaignStatus,
        campaignType: allListing?.campaignType,
        generatedPosts: allListing?.generatedPosts,
        publishedPosts: allListing?.publishedPosts,
        scheduledPosts: allListing?.scheduledPosts,
        unapprovedPosts: allListing?.unapprovedPosts,
        contentRefresh: allListing?.contentRefresh,
    };

    const isMobile = useIsMobile(1200);

    const handleCampaignRetry = async (campaignId: string | undefined) => {
        setLoading(true);
        if (!campaignId) return;

        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.get(
                `${backendUrl}/listing/restart-campaign/${campaignId}`,
                { headers: headers }
            );

            if (response.status === 200) {
                toast.success(
                    <CustomToast
                        message="Campaign generation process restarted."
                        type="success"
                    />,
                    {
                        autoClose: 3000,
                    }
                );

                setPropertyData(response.data);
                setOriginalPropertyData(response.data);
                fetchListing();
            }
        } catch (error: unknown) {
            console.error(error);
            toast.error(
                <CustomToast
                    message="Failed to retry campaign."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={`${isMobile ? "mt-2" : "mt-0"}`}>
            <Card>
                <CardContent className="pt-6">
                    <CardTitle className="mb-4">Campaign</CardTitle>

                    <CampaignStatusCard listing={CampaignCardListing} />
                    {allListing && !allListing.campaignStatus ? (
                        <SimpleTooltip
                            message="Please wait for images to be done uploaded and processed before creating a campaign."
                            visible={isUploading}
                        >
                            <Button
                                className="w-full mt-4"
                                onClick={() => {
                                    if (allListing.imageCount <= 0) {
                                        toast.error(
                                            <CustomToast
                                                message="Please upload images before creating a campaign."
                                                type="error"
                                            />,
                                            {
                                                autoClose: 3000,
                                            }
                                        );
                                    } else {
                                        navigate("/create-campaign", {
                                            state: {
                                                listingId: allListing.id,
                                                listingType:
                                                    mlsApproved &&
                                                    allListing.listingType ===
                                                        ListingType.MLSListing
                                                        ? "MLSListing"
                                                        : "Listing",
                                                from: location.pathname,
                                            },
                                        });
                                    }
                                }}
                                disabled={isUploading}
                            >
                                Create Campaign
                            </Button>
                        </SimpleTooltip>
                    ) : null}
                    {allListing &&
                    allListing.campaignStatus &&
                    allListing.campaignStatus === "ACTIVE" ? (
                        <>
                            <Button
                                id="view-campaign-btn"
                                className="mt-4 w-full font-semibold"
                                onClick={handleViewCampaign}
                                variant="secondary"
                            >
                                View Campaign Schedule
                            </Button>
                            {allListing.status?.toLowerCase() === "active" ? (
                                <Button
                                    id="add-post-btn"
                                    className="mt-4 w-full font-semibold"
                                    onClick={handleAddPost}
                                >
                                    Add Post
                                </Button>
                            ) : null}
                        </>
                    ) : null}

                    {allListing &&
                    allListing.campaignStatus &&
                    allListing.campaignStatus === "ERROR" ? (
                        <Button
                            id={`retry-campaign-${allListing.campaignId}`}
                            className="mt-4 w-full font-semibold"
                            onClick={() => {
                                handleCampaignRetry(allListing.campaignId);
                            }}
                        >
                            Retry Campaign
                        </Button>
                    ) : null}

                    {allListing.campaignStatus &&
                        allListing.campaignStatus === "SOCIAL_PAUSE" && (
                            <div className="mt-2 flex justify-center">
                                <span className="text-center text-red-500">
                                    Connect Social Media platform(s) from
                                    Settings to resume campaign
                                </span>
                            </div>
                        )}
                </CardContent>
            </Card>
        </div>
    );
};

export default CampaignCard;
