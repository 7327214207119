import React, { useEffect, useState } from "react";
import { SlideshowMediaSelectorProps } from "./types";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../misc/Tabs";
import MediaSelector from "../../misc/MediaSelector";
import { MAX_IMAGES, MIN_IMAGES } from "./utils";

const SlideshowMediaSelector: React.FC<SlideshowMediaSelectorProps> = ({
    media,
    handleMediaSelect,
    selectedImages,
}) => {
    const [activeTab, setActiveTab] = useState<"images" | "assets">(
        media?.images?.length ? "images" : "assets"
    );

    const handleImageSelect = (id: string) => {
        handleMediaSelect(id, "image");
    };

    const handleAssetSelect = (id: string) => {
        handleMediaSelect(id, "asset");
    };

    const [selectedMedia, setSelectedMedia] = useState<string[]>([]);

    useEffect(() => {
        setSelectedMedia(selectedImages.map((image) => image.id));
    }, [selectedImages]);

    return (
        <div>
            <Tabs
                value={activeTab}
                onValueChange={(value: string) =>
                    setActiveTab(value as "images" | "assets")
                }
                className="mt-4"
            >
                <TabsList>
                    {media?.images?.length && media?.images?.length > 0 ? (
                        <TabsTrigger value="images">Images</TabsTrigger>
                    ) : null}
                    {media?.assets?.length && media?.assets?.length > 0 ? (
                        <TabsTrigger value="assets">Assets</TabsTrigger>
                    ) : null}
                </TabsList>

                <TabsContent value="images">
                    <MediaSelector
                        images={media.images}
                        selectedMedia={selectedMedia}
                        onSelect={handleImageSelect}
                        maxMedia={MAX_IMAGES}
                        minMedia={MIN_IMAGES}
                        showAssetsUploader={true}
                        type="image"
                        activeTab={activeTab}
                    />
                </TabsContent>

                <TabsContent value="assets">
                    <MediaSelector
                        assets={media.assets}
                        selectedMedia={selectedMedia}
                        onSelect={handleAssetSelect}
                        maxMedia={MAX_IMAGES}
                        minMedia={MIN_IMAGES}
                        showAssetsUploader={true}
                        type="asset"
                        activeTab={activeTab}
                    />
                </TabsContent>
            </Tabs>
        </div>
    );
};

export default SlideshowMediaSelector;
