import { Auth, signOut } from "firebase/auth";
import { MouseEventHandler } from "react";
import { NavigateFunction } from "react-router-dom";
import { clearImage } from "../utils/dexieImageCache";
import { User } from "../types/user";

export const handleLogoutGenerator = (
    auth: Auth,
    navigate: NavigateFunction,
    user: User | null
): MouseEventHandler<HTMLButtonElement> => {
    const handleLogout: MouseEventHandler<HTMLButtonElement> = async () => {
        try {
            const userId = user?._id.toString();

            if (userId) {
                await clearImage(`profileImage-${userId}`);
            }
            await signOut(auth);
            navigate("/login");
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };
    return handleLogout;
};
