import { Circle } from "lucide-react";
import { useState } from "react";
import { ContactModal } from "../misc/ContactModal";
import { Button } from "../misc/Button";

interface PricingTierProps {
    name: string;
    price: string | number;
    description: string;
    features: string[];
    highlighted?: boolean;
    ctaText?: string;
    onSelect: () => void;
    buttons?: boolean;
}

export const PricingTier = ({
    name,
    price,
    description,
    features,
    highlighted = false,
    ctaText,
    onSelect,
    buttons,
}: PricingTierProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClick = () => {
        if (price === "Custom") {
            setIsModalOpen(true);
        } else if (ctaText === "Select Plan") {
            onSelect();
        }
    };

    return (
        <>
            <div
                className={`rounded-lg shadow-lg overflow-hidden h-full flex flex-col transition-transform duration-300 ${
                    highlighted
                        ? "border-2 border-primary transform scale-105"
                        : "border border-transparent"
                }`}
            >
                <div
                    className={`px-6 py-8 text-center ${
                        highlighted ? "bg-primary/5" : "bg-white"
                    }`}
                >
                    <h3 className="text-2xl font-bold text-primary">{name}</h3>
                    <div className="mt-4">
                        <span className="text-4xl font-extrabold text-secondary">
                            {price !== "Custom"
                                ? `${(
                                      parseInt(String(price), 10) / 100
                                  ).toFixed(2)}`
                                : price}
                        </span>
                        {price !== "Custom" && (
                            <span className="text-base font-medium text-gray-500">
                                /month
                            </span>
                        )}
                    </div>
                    <p className="mt-4 text-sm text-gray-500">{description}</p>
                </div>
                <div className="px-6 pt-6 pb-8 bg-gray-50 flex-grow flex flex-col">
                    <ul className="space-y-4 flex-grow">
                        {features.map((feature, index) => (
                            <li key={index} className="flex items-start">
                                <div className="flex-shrink-0">
                                    <Circle className="h-2 w-2 mt-1.5 text-primary fill-primary" />
                                </div>
                                <p className="ml-3 text-sm text-gray-700">
                                    {feature}
                                </p>
                            </li>
                        ))}
                    </ul>
                    <Button
                        variant="ghost"
                        onClick={handleClick}
                        className={`mt-8 w-full rounded-md px-4 py-2 text-sm font-medium ${
                            highlighted
                                ? "bg-primary text-white hover:bg-secondary"
                                : "bg-white text-primary border border-primary hover:bg-secondary hover:text-white"
                        }`}
                        disabled={!buttons}
                    >
                        {price === "Custom" ? "Contact Us" : ctaText}
                    </Button>
                </div>
            </div>
            <ContactModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
        </>
    );
};
