import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../../misc/Card";
import { RadioGroup, RadioGroupItem } from "../../misc/RadioGroup";
import { Label } from "../../misc/Label";
import LogoSelector from "./LogoSelector";
import PreviewOverlay from "./PreviewOverlay";
import { Button } from "../../misc/Button";
import { Loader2 } from "lucide-react";
import { CreateOverlayProps } from "./types";
import CustomToast from "../../misc/CustomToast";
import { toast } from "react-toastify";
import { Overlay } from "../../../types/overlay";
import { getAuthHeader } from "../../../utils/authHeader";
import axios from "axios";
import { SelectedAddress } from "../../../types/selectedAddress";
import { Logo } from "../../../types/logo";
import { v4 as uuidv4 } from "uuid";
import { Switch } from "../../misc/Switch";

const CreateOverlay: React.FC<CreateOverlayProps> = ({
    newOverlayHasLogo,
    setNewOverlayHasLogo,
    logos,
    formattedAddress,
    overlays,
    setOverlays,
    setSelectedOverlay,
    setLogos,
    setCreateOverlayModalOpen,
    listingId,
    listingType,
    setNotSelected,
    image,
}) => {
    const [isCreatingOverlay, setIsCreatingOverlay] = React.useState(false);
    const [selectedLogo, setSelectedLogo] = useState<string | null>(null);
    const [logoFile, setLogoFile] = useState<File | null>(null);
    const [logoUrl, setLogoUrl] = useState<string | null>(null);
    const [theme, setTheme] = useState<"light" | "dark">("light");

    useEffect(() => {
        if (logoFile) {
            const url = URL.createObjectURL(logoFile);
            setLogoUrl(url);

            // Cleanup: Revoke the object URL when the component unmounts or when logoFile changes
            return () => {
                URL.revokeObjectURL(url);
            };
        } else {
            setLogoUrl(null);
        }
    }, [logoFile]);

    const handleLogoSelect = (id: string) => {
        setSelectedLogo((prev) => (prev === id ? null : id));
        setLogoFile(null);
    };

    const handleLogoUpload = (file: File) => {
        setLogoFile(file);
        setSelectedLogo(null);
    };

    // Compile address
    const addressParts = formattedAddress.split(",").map((part) => part.trim());

    const address: SelectedAddress = {
        address1: addressParts[0],
        city: "",
        state: "",
        zipCode: "",
    };

    let remainingParts = addressParts.slice(1);

    if (
        remainingParts.length > 0 &&
        /^[A-Za-z]{2,}$/.test(remainingParts[remainingParts.length - 1])
    ) {
        address.country = remainingParts.pop(); // "USA"
    }

    if (remainingParts.length === 3) {
        address.address2 = remainingParts[0];
        address.city = remainingParts[1];
        const stateZip = remainingParts[2].split(" ");
        address.state = stateZip[0];
        address.zipCode = stateZip[1] || "";
    } else if (remainingParts.length === 2) {
        address.city = remainingParts[0];
        const stateZip = remainingParts[1].split(" ");
        address.state = stateZip[0];
        address.zipCode = stateZip[1] || "";
    } else {
        console.warn("Unexpected address format:", formattedAddress);
    }

    console.log("formattedAddress", formattedAddress);
    console.log("address", address);

    const handleCreateOverlay = async () => {
        setIsCreatingOverlay(true);

        try {
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            let logoId: string | null = null;

            // If a logo is to be included, upload it first
            if (newOverlayHasLogo) {
                if (logoFile) {
                    try {
                        const authHeader = await getAuthHeader();
                        const backendUrl =
                            process.env.REACT_APP_BACKEND_URL || "";

                        const extension = logoFile.name.split(".").pop();

                        const filename = `${uuidv4()}.${extension}`;

                        const signedUrlResponse = await axios.post(
                            `${backendUrl}/assets/signed-url`,
                            {
                                filename,
                                contentType: logoFile.type,
                                type: "image",
                            },
                            { headers: authHeader }
                        );

                        const { signedUrl } = signedUrlResponse.data;

                        await axios.put(signedUrl, logoFile, {
                            headers: {
                                "Content-Type": logoFile.type,
                            },
                        });

                        // After successful upload, inform backend of the new logo
                        const logoResponse = await axios.post(
                            `${backendUrl}/assets/logos`,
                            {
                                filenames: [filename],
                                names: [logoFile.name],
                            },
                            { headers: authHeader }
                        );

                        if (logoResponse.status === 201) {
                            const newLogo: Logo = logoResponse.data[0];

                            setLogos([newLogo, ...logos]);
                            setSelectedLogo(newLogo.id);

                            setLogoFile(null);

                            logoId = newLogo.id;

                            toast.success(
                                <CustomToast
                                    message="Logo uploaded successfully. Generating Overlay..."
                                    type="success"
                                />,
                                { autoClose: 3000 }
                            );
                        } else {
                            throw new Error("Failed to upload logo.");
                        }
                    } catch (error: unknown) {
                        console.error(error);
                        toast.error(
                            <CustomToast
                                message="An unexpected error occurred."
                                type="error"
                            />,
                            {
                                autoClose: 3000,
                            }
                        );
                    }
                } else if (selectedLogo) {
                    // Use the selected existing logo
                    logoId = selectedLogo;
                } else {
                    throw new Error("No logo selected or uploaded.");
                }
            }

            // Prepare overlay data
            console.log("logoId", logoId);
            const overlayData = {
                listingId,
                listingType,
                theme,
                logo: logoId,
            };

            // Create overlay
            const overlayResponse = await axios.post(
                `${backendUrl}/listing/overlay`,
                overlayData,
                { headers }
            );

            if (overlayResponse.status === 201) {
                const newOverlay: Overlay = {
                    id: overlayResponse.data._id,
                    name: `Custom Overlay ${overlays.length + 1}`,
                    url: overlayResponse.data.url,
                    hasLogo: !!logoId,
                };

                console.log("New overlay:", newOverlay);

                setOverlays([newOverlay, ...overlays]);
                setSelectedOverlay(newOverlay.id);
                setNotSelected(false);

                // Reset states
                setLogoFile(null);
                setSelectedLogo(null);
                setNewOverlayHasLogo(false);

                toast.success(
                    <CustomToast
                        message="Overlay created successfully."
                        type="success"
                    />,
                    { autoClose: 3000 }
                );

                // Close the modal
                setCreateOverlayModalOpen(false);
            } else {
                throw new Error("Failed to create overlay.");
            }
        } catch (error: any) {
            console.error("Error creating overlay:", error);
            toast.error(
                <CustomToast
                    message={
                        error.response?.data?.message ||
                        error.message ||
                        "An unexpected error occurred. Please try again."
                    }
                    type="error"
                />,
                { autoClose: 5000 }
            );
        } finally {
            setIsCreatingOverlay(false);
        }
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle className="mb-2">Create New Overlay</CardTitle>
                <CardDescription>
                    Customize your overlay with or without a logo
                </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4 pt-0">
                <div className="flex justify-center">
                    <div className="flex items-center justify-center gap-4 w-fit p-4 bg-white shadow rounded-md">
                        <span className="text-primary font-medium">
                            Dark Theme
                        </span>
                        <Switch
                            id="theme"
                            checked={theme === "light"}
                            onCheckedChange={(checked) =>
                                setTheme(checked ? "light" : "dark")
                            }
                        />
                        <span className="text-primary font-medium">
                            Light Theme
                        </span>
                    </div>
                </div>
                <RadioGroup
                    value={newOverlayHasLogo ? "logo" : "no-logo"}
                    onValueChange={(value) =>
                        setNewOverlayHasLogo(value === "logo")
                    }
                >
                    <div className="flex items-center space-x-2">
                        <RadioGroupItem value="no-logo" id="no-logo" />
                        <Label htmlFor="no-logo">No Logo</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                        <RadioGroupItem value="logo" id="logo" />
                        <Label htmlFor="logo">With Logo</Label>
                    </div>
                </RadioGroup>

                {newOverlayHasLogo && (
                    <>
                        <LogoSelector
                            logos={logos}
                            selectedLogo={selectedLogo}
                            onSelectLogo={handleLogoSelect}
                            onUploadLogo={(file) => {
                                handleLogoUpload(file);
                            }}
                        />
                        {logoFile && (
                            <span className="text-sm text-gray-600">
                                Selected file: {logoFile.name}
                            </span>
                        )}
                    </>
                )}

                {/* Overlay Preview */}
                <PreviewOverlay
                    formattedAddress={formattedAddress}
                    newOverlayHasLogo={newOverlayHasLogo}
                    logoFile={logoFile}
                    logoUrl={logoUrl}
                    selectedLogo={selectedLogo}
                    logos={logos}
                    theme={theme}
                    image={image}
                />

                {/* Create Overlay Button */}
                <Button
                    onClick={handleCreateOverlay}
                    disabled={
                        isCreatingOverlay ||
                        (newOverlayHasLogo && !selectedLogo && !logoFile)
                    }
                >
                    {isCreatingOverlay && (
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Create Overlay
                </Button>
            </CardContent>
        </Card>
    );
};

export default CreateOverlay;
