import React, { useCallback, useEffect, useState } from "react";
import { Button } from "../misc/Button";
import StepProgressBar from "../progress-bar";
import logo from "../../assets/images/logo.png";
import LoadingScreen from "../../screens/LoadingScreen";
import CustomToast from "../misc/CustomToast";
import { toast } from "react-toastify";
import { MlsDetails as ChooseMLSType } from "../../types/onboarding";
import { auth } from "../../firebase/firebase-config";
import { getIdToken } from "firebase/auth";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import InputField from "../misc/InputField";
import { useDispatch } from "react-redux";
import { setIsOnboarded } from "../../store/authSlice";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../misc/CustomSelect";
import { OptionType } from "../../types/option-type";
import { ActionMeta, MultiValue, SingleValue } from "react-select";
import { useIsMobile } from "../../hooks/useIsMobile";

interface ChooseMLSProps {
    advanceStep: () => void;
    goBackStep: () => void;
    role: string | null;
}

interface Mls {
    _id: string;
    name: string;
}

const MLSDetails: React.FC<ChooseMLSProps> = ({
    advanceStep,
    goBackStep,
    role,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [mlsDetails, setMLSDetails] = useState<ChooseMLSType>({
        mlsName: "",
        mlsId: "",
    });
    const [customMlsName, setCustomMlsName] = useState<string>("");
    const [mlsOptions, setMlsOptions] = useState<
        { label: string; value: string }[]
    >([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [initialDetails, setInitialDetails] = useState<ChooseMLSType | null>(
        null
    );
    const [customMlsError, setCustomMlsError] = useState<string>("");

    const getAuthHeader = async () => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            const token = await getIdToken(currentUser, true);
            return { Authorization: `Bearer ${token}` };
        }
        throw new Error("User is not authenticated");
    };

    useEffect(() => {
        const fetchMLSDetails = async () => {
            try {
                setLoading(true);
                const headers = await getAuthHeader();
                const backendUrl = process.env.REACT_APP_BACKEND_URL;

                const mlsResponse = await axios.get(
                    `${backendUrl}/onboarding/mls-options`,
                    {
                        headers,
                        withCredentials: true,
                    }
                );

                const options = mlsResponse.data.map((mls: Mls) => ({
                    label: mls.name,
                    value: mls._id,
                }));

                // Add "None" and "Other" options
                options.unshift({ label: "None", value: "" });
                options.push({ label: "Other", value: "other" });

                setMlsOptions(options);

                const userResponse = await axios.get(
                    `${backendUrl}/onboarding/mls-details`,
                    { headers, withCredentials: true }
                );

                console.log("User MLS details:", userResponse.data);

                setMLSDetails({
                    mlsName: userResponse.data.name,
                    mlsId: userResponse.data._id || "",
                });

                if (userResponse.data._id === "other") {
                    setCustomMlsName(userResponse.data.name);
                }

                setInitialDetails({
                    mlsName: userResponse.data.name,
                    mlsId: userResponse.data._id || "",
                });
            } catch (error) {
                console.error("Error fetching MLS details:", error);
                toast.error(
                    <CustomToast
                        message="Failed to fetch MLS details. Please try again later!"
                        type="error"
                    />,
                    {
                        autoClose: 3000,
                    }
                );
            } finally {
                setLoading(false);
            }
        };

        fetchMLSDetails();
    }, []);

    // Reset customMlsError when mlsId changes and is not "other"
    useEffect(() => {
        if (mlsDetails.mlsId !== "other") {
            setCustomMlsError("");
        }
    }, [mlsDetails.mlsId]);

    const hasChanges = useCallback(() => {
        if (!initialDetails) return false;

        const currentMlsName =
            mlsDetails.mlsId === "other" ? customMlsName : mlsDetails.mlsName;
        const initialMlsName =
            initialDetails.mlsId === "other"
                ? initialDetails.mlsName
                : initialDetails.mlsName;

        return currentMlsName !== initialMlsName;
    }, [mlsDetails, customMlsName, initialDetails]);

    const handleSaveAndNext = async () => {
        // Reset error message
        setCustomMlsError("");

        // Validation: If "Other" is selected, customMlsName must not be empty
        if (mlsDetails.mlsId === "other" && customMlsName.trim() === "") {
            setCustomMlsError("Please enter a custom MLS name.");
            return;
        }

        setLoading(true);
        const dataToSave = {
            mlsName:
                mlsDetails.mlsId === "other"
                    ? customMlsName
                    : mlsDetails.mlsName,
            mlsId: mlsDetails.mlsId,
        };

        console.log("Saving data:", dataToSave);

        try {
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            if (hasChanges()) {
                await axios.patch(
                    `${backendUrl}/onboarding/mls-details`,
                    dataToSave,
                    {
                        headers,
                        withCredentials: true,
                    }
                );
            }

            if (role === "TEAM_USER") {
                const response = await axios.get(
                    `${backendUrl}/onboarding/activate`,
                    {
                        headers,
                    }
                );

                if (response.status === 200) {
                    toast.success(
                        <CustomToast
                            message="Account activated successfully!"
                            type="success"
                        />,
                        { autoClose: 3000 }
                    );

                    if (response.data.onboarded) {
                        dispatch(setIsOnboarded(true));
                        navigate("/");
                    }
                }
            } else {
                advanceStep();
            }
        } catch (error) {
            console.error("Error saving MLS details:", error);
            toast.error(
                <CustomToast
                    message="Failed to save MLS details. Please try again later!"
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        } finally {
            setLoading(false);
        }
    };

    const handleBack = () => {
        goBackStep();
    };

    const handleSelectChange = (
        selectedOption: SingleValue<OptionType> | MultiValue<OptionType>,
        actionMeta: ActionMeta<OptionType>
    ) => {
        // Since isMulti is false, selectedOption will be SingleValue<OptionType>
        const option = selectedOption as SingleValue<OptionType>;
        const value = option?.value || "";
        const label = option?.label || "";
        setMLSDetails({ mlsName: label, mlsId: value });

        if (value !== "other") {
            setCustomMlsName("");
            setCustomMlsError("");
        }
    };

    const progressSteps = [
        { label: "Agent Details", isActive: true, isCompleted: true },
        { label: "Broker Details", isActive: true, isCompleted: true },
        { label: "Choose MLS", isActive: true, isCompleted: false },
    ];

    if (role !== "TEAM_USER") {
        progressSteps.push({
            label: "Select Plan",
            isActive: false,
            isCompleted: false,
        });
        progressSteps.push({
            label: "Payment",
            isActive: false,
            isCompleted: false,
        });
    }

    const isMobile = useIsMobile(900);

    return (
        <main
            className={`flex overflow-hidden flex-col justify-center items-center min-h-screen ${
                isMobile ? "bg-white" : "bg-background"
            } shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-md:px-5`}
        >
            <section className="flex flex-col items-center px-14 py-20 max-w-full bg-white rounded-2xl min-h-[833px] w-[905px] max-md:px-5 max-md:pb-24 justify-between">
                <div className="w-full flex flex-col items-center mb-8">
                    <img
                        loading="lazy"
                        src={logo}
                        alt="Agent Registration Logo"
                        className="object-contain self-center max-w-full aspect-[8.77] w-[395px]"
                    />
                    <h1 className="my-2 text-primary text-2xl font-bold leading-none text-center">
                        User Onboarding
                    </h1>
                    <StepProgressBar
                        steps={progressSteps.filter((step) => step)}
                    />
                </div>
                {loading && <LoadingScreen />}
                <form className="w-full space-y-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        MLS Name
                    </label>
                    <CustomSelect
                        id="mls-select-dropdown"
                        options={mlsOptions}
                        value={
                            mlsOptions.find(
                                (option) => option.value === mlsDetails.mlsId
                            ) ?? null
                        }
                        onChange={handleSelectChange}
                        placeholder="Select MLS"
                    />
                    {mlsDetails.mlsId === "other" && (
                        <>
                            <InputField
                                label="Enter MLS Name"
                                placeholder="Enter custom MLS name"
                                type="text"
                                value={customMlsName}
                                onChange={(e) => {
                                    setCustomMlsName(e.target.value);
                                    if (e.target.value.trim() !== "") {
                                        setCustomMlsError("");
                                    }
                                }}
                            />
                            {customMlsError && (
                                <p className="text-red-500 text-sm mt-1">
                                    {customMlsError}
                                </p>
                            )}
                        </>
                    )}
                </form>

                <div className="w-full flex justify-between mt-8 gap-20">
                    <Button className="w-1/3" onClick={handleBack}>
                        Back
                    </Button>
                    <Button className="w-1/3" onClick={handleSaveAndNext}>
                        {role === "TEAM_USER" ? "Submit" : "Next"}
                    </Button>
                </div>
            </section>
        </main>
    );
};

export default MLSDetails;
