import React, { useEffect, useState } from "react";
import { CombinedImage, CombinedMedia, ImageTagCardProps } from "./types";
import { getAuthHeader } from "../../../utils/authHeader";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../../misc/CustomToast";
import { Card, CardContent, CardTitle } from "../../misc/Card";
import { IoMdPricetag } from "react-icons/io";
import { Button } from "../../misc/Button";
import { FaEdit } from "react-icons/fa";
import InputField from "../../misc/InputField";
import { Badge } from "../../calendar/Badge";
import { useIsMobile } from "../../../hooks/useIsMobile";

const ImageTagCard: React.FC<ImageTagCardProps> = ({
    combinedMedia,
    carouselApi,
    propertyData,
    images,
    setImages,
    setPropertyData,
}) => {
    const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
    const [tagEditing, setTagEditing] = useState(false);

    const isMobile = useIsMobile(1200);

    useEffect(() => {
        if (carouselApi) {
            const onSelect = () => {
                setCurrentMediaIndex(carouselApi.selectedScrollSnap());
            };

            carouselApi.on("select", onSelect);

            // Set initial index
            setCurrentMediaIndex(carouselApi.selectedScrollSnap());

            return () => {
                carouselApi.off("select", onSelect);
            };
        }
    }, [carouselApi]);

    const currentMedia = combinedMedia[currentMediaIndex];

    function isCombinedImage(media: CombinedMedia): media is CombinedImage {
        return media.type === "image" && "tag" in media;
    }

    const handleEditTag = async (imageId: string, newTag: string) => {
        try {
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            const url = `${backendUrl}/listing/image-tag`;

            const response = await axios.patch(
                `${url}`,
                {
                    listingId: propertyData?._id,
                    listingType: propertyData?.listingType,
                    image: { id: imageId, tag: newTag },
                },
                { headers }
            );

            if (response.status === 200) {
                toast.success(
                    <CustomToast
                        message="Tag updated successfully."
                        type="success"
                    />,
                    { autoClose: 3000 }
                );
                setTagEditing(false);
                if (propertyData && propertyData.images) {
                    let newPropertyData = { ...propertyData };
                    newPropertyData.images = propertyData?.images.map((image) =>
                        image.id === imageId ? { ...image, tag: newTag } : image
                    );

                    setPropertyData(newPropertyData);
                }
            } else {
                toast.error(
                    <CustomToast
                        message="Failed to update tag."
                        type="error"
                    />,
                    { autoClose: 3000 }
                );
            }
        } catch (error) {
            console.error("Error updating tag:", error);
            toast.error(
                <CustomToast
                    message="An error occurred while updating the tag."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        }
    };

    return (
        <div>
            {currentMedia && isCombinedImage(currentMedia) && (
                <Card>
                    <CardContent className={isMobile ? "p-2 m-0" : "pt-6"}>
                        <div
                            className={`flex justify-between items-center flex-row`}
                        >
                            <div className="flex items-center">
                                <IoMdPricetag className="text-secondary w-6 h-6 mr-1" />
                                <CardTitle>
                                    Image Tag{isMobile && ": "}
                                    {isMobile && !tagEditing && (
                                        <span className="ml-1 font-semibold text-primary text-base">
                                            {currentMedia.tag}
                                        </span>
                                    )}
                                </CardTitle>
                            </div>
                            {!tagEditing && isMobile && (
                                <Button
                                    id="edit-tag-btn"
                                    className="bg-secondary hover:bg-primary ml-2 p-2"
                                    onClick={() => setTagEditing(true)}
                                >
                                    <FaEdit className="mr-2 h-6 w-6 text-white" />
                                    Edit
                                </Button>
                            )}
                            {!tagEditing && !isMobile && (
                                <Button
                                    id="edit-tag-btn"
                                    onClick={() => setTagEditing(true)}
                                >
                                    <FaEdit className="mr-2 h-6 w-6 text-white" />
                                    Edit Tag
                                </Button>
                            )}
                        </div>
                        <div
                            className={`mt-4 text-center flex ${
                                isMobile
                                    ? "flex-col items-start"
                                    : "flex-row justify-center items-center"
                            } ${isMobile && !tagEditing ? "hidden" : ""}`}
                        >
                            {tagEditing ? (
                                <>
                                    {isMobile && (
                                        <InputField
                                            label="Tag"
                                            placeholder="Tag"
                                            value={currentMedia.tag}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                setImages(
                                                    images.map((img, index) =>
                                                        index ===
                                                        currentMediaIndex
                                                            ? {
                                                                  ...img,
                                                                  tag: value,
                                                              }
                                                            : img
                                                    )
                                                );
                                            }}
                                            type="text"
                                            className="w-full mb-2 text-left"
                                        />
                                    )}
                                    {!isMobile && (
                                        <InputField
                                            label="Tag"
                                            placeholder="Tag"
                                            value={currentMedia.tag}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                setImages(
                                                    images.map((img, index) =>
                                                        index ===
                                                        currentMediaIndex
                                                            ? {
                                                                  ...img,
                                                                  tag: value,
                                                              }
                                                            : img
                                                    )
                                                );
                                            }}
                                            type="text"
                                            className="mr-2 text-left"
                                        />
                                    )}
                                </>
                            ) : (
                                !isMobile && (
                                    <div className="flex flex-row">
                                        <p className="mr-2 font-semibold text-primary">
                                            Tag:
                                        </p>
                                        <Badge variant="secondary">
                                            {currentMedia.tag}
                                        </Badge>
                                    </div>
                                )
                            )}
                        </div>
                        {tagEditing &&
                            (isMobile ? (
                                <div className="w-full mt-2">
                                    <Button
                                        id="save-tag-btn"
                                        className="bg-secondary hover:bg-primary w-full"
                                        onClick={() => {
                                            if (isCombinedImage(currentMedia)) {
                                                handleEditTag(
                                                    currentMedia.id,
                                                    currentMedia.tag
                                                );
                                            } else {
                                                toast.error(
                                                    <CustomToast
                                                        message="Invalid media type."
                                                        type="error"
                                                    />,
                                                    { autoClose: 3000 }
                                                );
                                            }
                                        }}
                                    >
                                        Save Tag
                                    </Button>
                                </div>
                            ) : (
                                <div className="flex justify-center mt-4">
                                    <Button
                                        className="bg-secondary hover:bg-primary"
                                        onClick={() => {
                                            if (isCombinedImage(currentMedia)) {
                                                handleEditTag(
                                                    currentMedia.id,
                                                    currentMedia.tag
                                                );
                                            } else {
                                                toast.error(
                                                    <CustomToast
                                                        message="Invalid media type."
                                                        type="error"
                                                    />,
                                                    { autoClose: 3000 }
                                                );
                                            }
                                        }}
                                    >
                                        Save Tag
                                    </Button>
                                </div>
                            ))}
                    </CardContent>
                </Card>
            )}
        </div>
    );
};

export default ImageTagCard;
