import { PromotionType } from "./types";

export const isPromotionType = (type: string): type is PromotionType => {
    return Object.values(PromotionType).includes(type as PromotionType);
};

export function calculateTotalPages(
    totalAssets: number,
    itemsPerPage: number = 10
): number {
    return Math.ceil(totalAssets / itemsPerPage);
}
