import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getAuthHeader } from "../../utils/authHeader";
import { toast } from "react-toastify";
import CustomToast from "../../components/misc/CustomToast";
import LoadingScreen from "../LoadingScreen";
import ContentTabs from "../../components/content/ContentTabs";
import axios from "axios";
import {
    AllListingMedia,
    ContentProps,
    PromotionsMedia,
    ResourceType,
} from "../../components/content/types";
import MediaCarousel from "../../components/misc/MediaCarousel";
import { CarouselApi } from "../../components/misc/Carousel";
import { CombinedMedia } from "../../components/listings/listing/types";
import { Assets } from "../../types/asset-type";
import { useIsMobile } from "../../hooks/useIsMobile";
import { PromotionType } from "../../components/promotions/types";
import { clampIndex } from "../../components/content/util";

interface CarouselIndices {
    listing: {
        [address: string]: {
            images: number;
            videos: number;
        };
    };
    promotion: {
        [type in PromotionType]?: number;
    };
    resource: {
        [type in ResourceType]?: number;
    };
}

const ContentScreen: React.FC<ContentProps> = ({ mlsApproved }) => {
    const [listingMedia, setListingMedia] = useState<AllListingMedia[]>([]);
    const [promotions, setPromotions] = useState<PromotionsMedia | null>(null);
    const [resources, setResources] = useState<Assets[]>([]);

    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState<
        "listing" | "promotion" | "resource"
    >("listing");
    const [carouselApi, setCarouselApi] = useState<CarouselApi | null>(null);
    const [carouselIndexMap, setCarouselIndexMap] = useState<{
        [id: string]: number;
    }>({});

    const [listingTabs, setListingTabs] = useState<string[]>([]);
    const [activeListingTab, setActiveListingTab] = useState<string>("");
    const [activeMediaTab, setActiveMediaTab] = useState<"images" | "videos">(
        "images"
    );

    const [activePromotionType, setActivePromotionType] =
        useState<PromotionType>(PromotionType.BuyerEducation);

    const [activeResourceType, setActiveResourceType] = useState<ResourceType>(
        ResourceType.Image
    );

    const [carouselIndices, setCarouselIndices] = useState<CarouselIndices>({
        listing: {},
        promotion: {},
        resource: {},
    });

    // Fetch Assets
    const fetchAssets = useCallback(async () => {
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.get(`${backendUrl}/promotions/media`, {
                headers,
            });
            console.log("promotions response", response);

            if (response.status === 200) {
                setPromotions(response.data);
            } else {
                throw new Error(
                    "Failed to fetch assets. Please try again later."
                );
            }
        } catch (error: unknown) {
            console.error("Failed to fetch assets:", error);
        }
    }, []);

    // Fetch Listing Media
    const fetchListingMedia = useCallback(async () => {
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.get(`${backendUrl}/listing/media`, {
                headers,
            });
            console.log(response);

            if (response.status === 200) {
                setListingMedia(response.data);
                const tabs = response.data.map(
                    (listing: AllListingMedia) => listing.address
                );
                setListingTabs(tabs);
                setActiveListingTab(tabs[0] || "");
            } else {
                throw new Error(
                    "Failed to fetch listing media. Please try again later."
                );
            }
        } catch (error: unknown) {
            console.error("Failed to fetch listing media:", error);
        }
    }, []);

    // Fetch Resources
    const fetchResources = useCallback(async () => {
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.get(
                `${backendUrl}/assets/general-assets`,
                { headers }
            );

            if (response.status === 200) {
                setResources(response.data);
            } else {
                throw new Error(
                    "Failed to fetch resources. Please try again later."
                );
            }
        } catch (error: unknown) {
            console.error("Failed to fetch resources:", error);
        }
    }, []);

    // Aggregate Fetch Function
    const fetchAllData = useCallback(async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchAssets(),
                fetchListingMedia(),
                fetchResources(),
            ]);
        } catch (error) {
            console.error("Failed to fetch all data:", error);
            toast.error(
                <CustomToast
                    message="Failed to fetch media. Please try again later."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    }, [fetchAssets, fetchListingMedia, fetchResources]);

    useEffect(() => {
        fetchAllData();
    }, [fetchAllData]);

    // Memoize allItems to prevent it from being recreated on every render
    const filteredMedia: CombinedMedia[] = useMemo(() => {
        if (activeTab === "listing") {
            const listing = listingMedia.find(
                (l) => l.address === activeListingTab
            );
            if (!listing) return [];

            if (activeMediaTab === "images") {
                return listing.images.map((img) => ({ ...img, type: "image" }));
            } else {
                return listing.videos.map((vid) => ({ ...vid, type: "video" }));
            }
        } else if (activeTab === "promotion") {
            if (!promotions?.assets) return [];
            return promotions.assets
                .filter((asset) => asset.assetType === activePromotionType)
                .map((asset) => ({ ...asset, type: "image" }));
        } else {
            console.log("resources", resources);
            console.log("activeResourceType", activeResourceType);
            console.log(resources.map((res) => res.assetType));
            return resources
                .filter((res) => res.assetType === activeResourceType)
                .map((res) => ({
                    ...res,
                    type: res.assetType === "Video" ? "video" : "image",
                }));
        }
    }, [
        activeTab,
        listingMedia,
        promotions,
        resources,
        activeListingTab,
        activeMediaTab,
        activePromotionType,
        activeResourceType,
    ]);

    useEffect(() => {
        const newMap: { [id: string]: number } = {};
        filteredMedia.forEach((media, index) => {
            newMap[media.id] = index;
        });
        setCarouselIndexMap(newMap);
    }, [filteredMedia]);

    useEffect(() => {
        if (!carouselApi) return;

        let desiredIndex = 0;

        if (activeTab === "listing") {
            const listingIndexObj = carouselIndices.listing[
                activeListingTab
            ] || { images: 0, videos: 0 };

            desiredIndex =
                activeMediaTab === "images"
                    ? listingIndexObj.images
                    : listingIndexObj.videos;
        } else if (activeTab === "promotion") {
            const promoIndexObj = carouselIndices.promotion || {};
            desiredIndex = promoIndexObj[activePromotionType] || 0;
        } else {
            const resourceIndexObj = carouselIndices.resource || {};
            desiredIndex = resourceIndexObj[activeResourceType] || 0;
        }

        const boundedIndex = clampIndex(desiredIndex, filteredMedia.length);
        carouselApi.scrollTo(boundedIndex);

        if (boundedIndex !== desiredIndex) {
            setCarouselIndices((prev) => {
                const next = { ...prev };
                if (activeTab === "listing") {
                    const listingIndexObj = next.listing[activeListingTab] || {
                        images: 0,
                        videos: 0,
                    };
                    if (activeMediaTab === "images") {
                        listingIndexObj.images = boundedIndex;
                    } else {
                        listingIndexObj.videos = boundedIndex;
                    }
                    next.listing[activeListingTab] = listingIndexObj;
                } else if (activeTab === "promotion") {
                    const promoIndexObj = { ...next.promotion };
                    promoIndexObj[activePromotionType] = boundedIndex;
                    next.promotion = promoIndexObj;
                } else {
                    const resourceIndexObj = { ...next.resource };
                    resourceIndexObj[activeResourceType] = boundedIndex;
                    next.resource = resourceIndexObj;
                }
                return next;
            });
        }
    }, [
        activeTab,
        activeListingTab,
        activeMediaTab,
        activePromotionType,
        activeResourceType,
        carouselApi,
        carouselIndices,
        filteredMedia.length,
    ]);

    const handleThumbnailClick = (newIndex: number) => {
        const boundedIndex = clampIndex(newIndex, filteredMedia.length);
        carouselApi?.scrollTo(boundedIndex);

        // Update carouselIndices based on activeTab
        setCarouselIndices((prev) => {
            const next = { ...prev };
            if (activeTab === "listing") {
                const listingIndexObj = next.listing[activeListingTab] || {
                    images: 0,
                    videos: 0,
                };
                if (activeMediaTab === "images") {
                    listingIndexObj.images = boundedIndex;
                } else {
                    listingIndexObj.videos = boundedIndex;
                }
                next.listing[activeListingTab] = listingIndexObj;
            } else if (activeTab === "promotion") {
                const promoIndexObj = { ...next.promotion };
                promoIndexObj[activePromotionType] = boundedIndex;
                next.promotion = promoIndexObj;
            } else {
                const resourceIndexObj = { ...next.resource };
                resourceIndexObj[activeResourceType] = boundedIndex;
                next.resource = resourceIndexObj;
            }
            return next;
        });
    };

    const isMobile = useIsMobile(1300);

    return (
        <div
            className={`relative w-full min-h-full mb-4 ${
                isMobile ? "py-4 px-4" : "py-6 px-10"
            }`}
        >
            {loading && <LoadingScreen />}
            <div className="flex flex-col">
                <h1
                    className={`font-bold text-primary ${
                        isMobile ? "text-2xl mb-2" : "text-3xl mb-4"
                    }`}
                >
                    My Content
                </h1>
            </div>
            <div
                className={`${
                    isMobile ? "w-full mx-auto" : "w-[70%] mx-[15%]"
                }`}
            >
                <div className={`${isMobile ? "px-4" : "px-10"}`}>
                    <MediaCarousel
                        setCarouselApi={setCarouselApi}
                        combinedMedia={filteredMedia as CombinedMedia[]}
                        isMobile={isMobile}
                    />
                </div>
                <ContentTabs
                    promotions={promotions}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    listingMedia={listingMedia}
                    listingTabs={listingTabs}
                    activeListingTab={activeListingTab}
                    setActiveListingTab={setActiveListingTab}
                    loading={loading}
                    mlsApproved={mlsApproved || false}
                    carouselApi={carouselApi}
                    fetchAssets={fetchAssets}
                    fetchListingMedia={fetchListingMedia}
                    setLoading={setLoading}
                    carouselIndexMap={carouselIndexMap}
                    resources={resources}
                    fetchResources={fetchResources}
                    isMobile={isMobile}
                    activeMediaTab={activeMediaTab}
                    setActiveMediaTab={setActiveMediaTab}
                    activePromotionType={activePromotionType}
                    setActivePromotionType={setActivePromotionType}
                    activeResourceType={activeResourceType}
                    setActiveResourceType={setActiveResourceType}
                    onThumbnailClick={handleThumbnailClick}
                />
            </div>
        </div>
    );
};

export default ContentScreen;
