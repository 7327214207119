import React, { useEffect, useState } from "react";
import { Card } from "../misc/Card";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Button } from "../misc/Button";
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "./InstagramCarousel";
import { InstagramPostProps } from "./types";
import {
    Bookmark,
    ChevronLeft,
    ChevronRight,
    Heart,
    MessageCircle,
    Send,
} from "lucide-react";
import { formatDistanceToNow } from "date-fns";
import { MediaType } from "../../types/media";
import axios from "axios";
import { getAuthHeader } from "../../utils/authHeader";
import { ConnectedSocialsData } from "../campaign/types";

const InstagramPost: React.FC<InstagramPostProps> = ({ post, setLoading }) => {
    const user = useSelector((state: RootState) => state.auth.user);

    const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
    const [isCaptionExpanded, setIsCaptionExpanded] = useState(false);
    const [profileData, setProfileData] = useState<ConnectedSocialsData | null>(
        null
    );

    useEffect(() => {
        const fetchConnections = async () => {
            setLoading(true);
            try {
                const headers = await getAuthHeader();
                const backendUrl = process.env.REACT_APP_BACKEND_URL;

                const response = await axios.get(
                    `${backendUrl}/socials/profiles`,
                    {
                        headers,
                    }
                );

                const responseData = response.data.data || response.data;

                const facebookActive =
                    responseData.activeSocialAccounts.includes("instagram");
                if (facebookActive) {
                    const facebookProfile = responseData.displayNames.find(
                        (profile: any) => profile.platform === "instagram"
                    );

                    if (facebookProfile) {
                        const facebookData: ConnectedSocialsData = {
                            displayName: facebookProfile.displayName,
                            id: facebookProfile.id,
                            pageName: facebookProfile.pageName,
                            platform: facebookProfile.platform,
                            profileUrl: facebookProfile.profileUrl,
                            userImage: facebookProfile.userImage,
                            username: facebookProfile.username,
                        };

                        setProfileData(facebookData);
                    }
                }
            } catch (error) {
                let errorMessage =
                    "Error fetching social profiles. Please try again later.";
                if (axios.isAxiosError(error) && error.response) {
                    errorMessage += ` Server responded with status ${error.response.status}.`;
                }

                console.error(errorMessage);
            } finally {
                setLoading(false);
            }
        };

        fetchConnections();
    }, [setLoading]);
    const toggleCaption = () => {
        setIsCaptionExpanded((prev) => !prev);
    };

    // Function to handle slide change from Carousel
    const handleSlideChange = (index: number) => {
        setCurrentMediaIndex(index);
    };

    // Function to render the caption
    const renderCaption = () => {
        const content = post.content?.trim() || "";
        const CHARACTER_LIMIT = 125;
        const getContentParagraphs = (text: string) =>
            text.split(/\n+/).map((para, index) => (
                <p key={index} className="mb-2">
                    {para}
                </p>
            ));
        const isContentTruncated = content.length > CHARACTER_LIMIT;

        if (!isContentTruncated) {
            return (
                <>
                    {getContentParagraphs(content)}
                    {post.hasFooter && !isCaptionExpanded && (
                        <button
                            onClick={toggleCaption}
                            className="text-sm text-blue-500 ml-1"
                            aria-label="Show more"
                        >
                            more
                        </button>
                    )}
                    {post.hasFooter && isCaptionExpanded && post.footer && (
                        <>
                            <div className="mt-2">
                                {post.footer.split(/\n+/).map((para, index) => (
                                    <p
                                        key={index}
                                        className={
                                            para.trim().toLowerCase() ===
                                            "listed by:"
                                                ? "mt-2"
                                                : ""
                                        }
                                    >
                                        {para}
                                    </p>
                                ))}
                            </div>
                            <button
                                onClick={toggleCaption}
                                className="text-sm text-blue-500 ml-1"
                                aria-label="Show less"
                            >
                                less
                            </button>
                        </>
                    )}
                </>
            );
        }

        if (isCaptionExpanded) {
            return (
                <>
                    {getContentParagraphs(content)}
                    {post.hasFooter && post.footer && (
                        <div className="mt-2">
                            {post.footer.split(/\n+/).map((para, index) => (
                                <p
                                    key={index}
                                    className={
                                        para.trim().toLowerCase() ===
                                        "listed by:"
                                            ? "mt-2"
                                            : ""
                                    }
                                >
                                    {para}
                                </p>
                            ))}
                        </div>
                    )}
                    <button
                        onClick={toggleCaption}
                        className="text-sm text-blue-500 ml-1"
                        aria-label="Show less"
                    >
                        less
                    </button>
                </>
            );
        } else {
            let truncatedContent = content.slice(0, CHARACTER_LIMIT);
            const lastSpace = truncatedContent.lastIndexOf(" ");
            if (lastSpace > 0) {
                truncatedContent = truncatedContent.slice(0, lastSpace);
            }
            return (
                <>
                    {getContentParagraphs(truncatedContent)}
                    <button
                        onClick={toggleCaption}
                        className="text-sm text-blue-500 ml-1"
                        aria-label="Read more"
                    >
                        more
                    </button>
                </>
            );
        }
    };

    return (
        <Card className="max-w-[470px] mx-auto bg-white shadow-sm rounded-lg overflow-hidden border border-gray-300">
            {/* Post Header */}
            <div className="p-4 flex items-center justify-between border-b">
                <div className="flex items-center gap-3">
                    {profileData?.userImage ||
                    user?.profileImage ||
                    user?.photoURL ? (
                        <img
                            src={
                                profileData?.userImage ||
                                user?.profileImage ||
                                user?.photoURL ||
                                undefined
                            }
                            width={40}
                            height={40}
                            alt="Profile"
                            className="rounded-full object-cover"
                        />
                    ) : (
                        <span className="text-lg font-semibold flex items-center justify-center w-10 h-10 bg-gray-300 rounded-full">
                            {user &&
                                user.nameFirst &&
                                user.nameLast &&
                                user.nameFirst[0].toUpperCase() +
                                    user.nameLast[0].toUpperCase()}
                        </span>
                    )}
                    <div>
                        <span className="font-semibold text-sm hover:underline cursor-pointer">
                            {profileData?.username &&
                            profileData?.profileUrl ? (
                                <a
                                    href={profileData.profileUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="font-semibold hover:underline cursor-pointer"
                                >
                                    {profileData.username}
                                </a>
                            ) : (
                                `${user?.nameFirst} ${user?.nameLast}`
                            )}
                        </span>
                    </div>
                </div>
            </div>

            {/* Media Carousel */}
            <div className="relative" id={`carousel-${post.id}`}>
                <Carousel className="w-full" onSlideChange={handleSlideChange}>
                    <CarouselContent>
                        {post?.media &&
                            Array.isArray(post.media) &&
                            post.media.map((item, index) => (
                                <CarouselItem key={index}>
                                    <div className="relative aspect-square">
                                        {item.type === MediaType.AssetVideo ||
                                        item.type === MediaType.ListingVideo ? (
                                            <video
                                                muted
                                                src={item.signedUrl}
                                                className="object-cover w-full h-full"
                                                controls
                                            />
                                        ) : (
                                            <img
                                                src={item.signedUrl}
                                                alt={`Post media ${index + 1}`}
                                                className="object-cover w-full h-full"
                                            />
                                        )}
                                    </div>
                                </CarouselItem>
                            ))}
                    </CarouselContent>

                    {post.media.length > 1 && (
                        <>
                            <CarouselPrevious>
                                <ChevronLeft />
                            </CarouselPrevious>
                            <CarouselNext>
                                <ChevronRight />
                            </CarouselNext>
                            <div className="absolute top-2 right-2 bg-black/50 text-white rounded-full px-2 py-1 text-xs">
                                {currentMediaIndex + 1} / {post.media.length}
                            </div>
                        </>
                    )}
                </Carousel>
            </div>

            {/* Action Buttons */}
            <div className="p-4 flex items-center justify-between">
                <div className="flex gap-4">
                    <Button
                        variant="ghost"
                        size="icon"
                        aria-label="Like"
                        className="p-2 hover:bg-gray-200 rounded-full"
                    >
                        <Heart className="h-6 w-6" />
                    </Button>
                    <Button
                        variant="ghost"
                        size="icon"
                        aria-label="Comment"
                        className="p-2 hover:bg-gray-200 rounded-full"
                    >
                        <MessageCircle className="h-6 w-6" />
                    </Button>
                    <Button
                        variant="ghost"
                        size="icon"
                        aria-label="Share"
                        className="p-2 hover:bg-gray-200 rounded-full"
                    >
                        <Send className="h-6 w-6" />
                    </Button>
                </div>
                <Button
                    variant="ghost"
                    size="icon"
                    aria-label="Bookmark"
                    className="p-2 hover:bg-gray-200 rounded-full"
                >
                    <Bookmark className="h-6 w-6" />
                </Button>
            </div>

            {/* Likes */}
            <div className="px-4 pb-2">
                <p className="font-semibold text-sm">likes</p>
            </div>

            {/* Caption */}
            <div className="px-4 pb-2">
                <p className="text-sm">
                    <span className="font-semibold mr-1">
                        {profileData?.username}
                    </span>
                    {renderCaption()}
                </p>
            </div>

            {/* Comments Preview */}
            <div className="px-4 pb-2">
                <p className="text-sm text-gray-500 cursor-pointer">
                    View all comments
                </p>
            </div>

            {/* Timestamp */}
            <div className="px-4 pb-4">
                <p className="text-xs text-gray-500 uppercase">
                    {formatDistanceToNow(new Date(post.scheduledFor), {
                        addSuffix: true,
                    })}
                </p>
            </div>
        </Card>
    );
};

export default InstagramPost;
