import React from "react";
import { Card, CardContent } from "../../misc/Card";
import { Button } from "../../misc/Button";
import { FaPlus } from "react-icons/fa";
import { SlideshowGeneratorCardProps } from "./types";
import { Loader2 } from "lucide-react";
import SimpleTooltip from "../../misc/SimpleTooltip";

const SlideshowGeneratorCard: React.FC<SlideshowGeneratorCardProps> = ({
    openSlideshow,
    isGenerating,
    isJobInProgress,
    isUploading,
}) => {
    console.log("SlideshowGeneratorCard");
    console.log("openSlideshow", openSlideshow);
    console.log("isGenerating", isGenerating);
    console.log("isJobInProgress", isJobInProgress);
    return (
        <div>
            <Card className="mt-0">
                <CardContent className="pt-6">
                    <h2 className="text-xl font-semibold mb-4 text-primary text-center">
                        Slideshow Generator
                    </h2>
                    {/* Trigger Button Inside the Card */}
                    <SimpleTooltip
                        visible={isUploading}
                        message="Please wait for images to be done uploaded and processed before creating a slideshow."
                    >
                        <Button
                            id="create-slideshow-btn"
                            size="lg"
                            className="w-full font-semibold"
                            onClick={() => openSlideshow()}
                            disabled={
                                isGenerating || isJobInProgress || isUploading
                            }
                        >
                            {isGenerating || isJobInProgress ? (
                                <>
                                    <Loader2 className="mr-1 h-6 w-6 animate-spin" />
                                    <span>Generating ...</span>
                                </>
                            ) : (
                                <>
                                    <FaPlus className="mr-1 h-6 w-6" />
                                    <span>Create Slideshow</span>
                                </>
                            )}
                        </Button>
                    </SimpleTooltip>
                    {isJobInProgress && (
                        <p className="mt-2 text-sm text-red-500 text-center">
                            Slideshow generation is currently in progress.
                            Please wait until it's complete.
                        </p>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default SlideshowGeneratorCard;
