import React from "react";
import { TabsContent } from "../../../misc/Tabs";
import { Card, CardContent } from "../../../misc/Card";
import { Button } from "../../../misc/Button";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import DragAndDropMedia from "../../../misc/DragAndDrop";
import { VideoTabProps } from "./types";
import { MediaItem } from "../types";
import { calculateTotalPages } from "./utils";
import MediaThumbnails from "./MediaThumbnails";
import DeleteMediaButton from "./DeleteMediaButton";
import { useIsMobile } from "../../../../hooks/useIsMobile";

const VideosTab: React.FC<VideoTabProps> = ({
    videos,
    listingId,
    address1,
    handleUploadComplete,
    toggleSelectMedia,
    selectedMedia,
    isEditing,
    carouselApi,
    combinedMedia,
    mlsApproved,
    setSelectedMedia,
    setIsLoading,
    currentPage,
    setCurrentPage,
    itemsPerPage = 10,
    carouselIndexMap,
    onCarouselScroll,
    propertyStatus,
    isUploading,
    setIsUploading,
    listingType,
}) => {
    const videoItems: MediaItem[] = videos.map((video) => ({
        ...video,
        type: "video",
    }));

    const isMobile = useIsMobile(1200);

    const totalPages = calculateTotalPages("videos", videos, isMobile);
    currentPage = totalPages > 0 ? currentPage : 0;
    return (
        <TabsContent value="videos">
            <Card>
                <CardContent className="pt-6">
                    <MediaThumbnails
                        mediaItems={videoItems}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        toggleSelectMedia={toggleSelectMedia}
                        selectedMedia={selectedMedia}
                        isEditing={isEditing}
                        carouselApi={carouselApi}
                        combinedMedia={combinedMedia}
                        mlsApproved={mlsApproved || false}
                        carouselIndexMap={carouselIndexMap}
                        onMediaClick={(index: number) => {
                            if (onCarouselScroll) {
                                onCarouselScroll(index);
                            }
                        }}
                    />
                    <div className="flex justify-between items-center mt-4">
                        <Button
                            id="prev-page-btn"
                            variant="outline"
                            onClick={() =>
                                setCurrentPage(Math.max(currentPage - 1, 1))
                            }
                            disabled={currentPage === 0 || currentPage === 1}
                        >
                            <FaArrowLeft className="mr-2 h-4 w-4" /> Previous
                        </Button>
                        <span>
                            {isMobile ? "" : "Page"} {currentPage} of{" "}
                            {totalPages}
                        </span>
                        <Button
                            id="next-page-btn"
                            variant="outline"
                            onClick={() =>
                                setCurrentPage(
                                    Math.min(currentPage + 1, totalPages)
                                )
                            }
                            disabled={currentPage === totalPages}
                        >
                            Next <FaArrowRight className="ml-2 h-4 w-4" />
                        </Button>
                    </div>
                    {isEditing && selectedMedia.length > 0 ? (
                        <div className="my-2 flex justify-end">
                            <DeleteMediaButton
                                selectedMedia={selectedMedia}
                                setIsLoading={setIsLoading}
                                mlsApproved={mlsApproved || false}
                                listingId={listingId}
                                listingType={listingType}
                                handleDeleteComplete={handleUploadComplete}
                                setSelectedMedia={setSelectedMedia}
                            />
                        </div>
                    ) : null}
                    {propertyStatus?.toLowerCase() === "active" ? (
                        <DragAndDropMedia
                            type="video"
                            listingId={listingId}
                            propertyAddress1={address1}
                            onUploadComplete={handleUploadComplete}
                            mlsApproved={mlsApproved || false}
                            isUploading={isUploading}
                            setIsUploading={setIsUploading}
                            listingType={listingType}
                        />
                    ) : null}
                </CardContent>
            </Card>
        </TabsContent>
    );
};

export default VideosTab;
