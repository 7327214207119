import React, { useEffect, useState } from "react";
import { SlideshowOptionsProps } from "./types";
import { Card } from "../../misc/Card";
import { Switch } from "../../misc/Switch";
import { Label } from "../../misc/Label";
import OverlaySelector from "./OverlaySelector";
import CustomToast from "../../misc/CustomToast";
import { toast } from "react-toastify";
import { Button } from "../../misc/Button";
import { FaPlus } from "react-icons/fa6";
import CreateOverlay from "./CreateOverlay";
import { getAuthHeader } from "../../../utils/authHeader";
import axios, { AxiosError } from "axios";
import { Overlay } from "../../../types/overlay";
import { Logo } from "../../../types/logo";
import Music from "./Music";

const SlideshowOptions: React.FC<SlideshowOptionsProps> = ({
    includeOverlay,
    setIncludeOverlay,
    includeMusic,
    setIncludeMusic,
    newOverlayHasLogo,
    setNewOverlayHasLogo,
    formattedAddress,
    selectedOverlay,
    setSelectedOverlay,
    setLoading,
    setNotSelected,
    music,
    setMusic,
    listingId,
    listingType,
    image,
}) => {
    const [createOverlayModalOpen, setCreateOverlayModalOpen] =
        React.useState(false);
    const [overlays, setOverlays] = useState<Overlay[]>([]);
    const [logos, setLogos] = useState<Logo[]>([]);

    useEffect(() => {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        const fetchOverlays = async () => {
            setLoading(true);
            try {
                const headers = await getAuthHeader();

                const response = await axios.get(
                    `${backendUrl}/assets/overlays/${listingId}`,
                    {
                        headers,
                    }
                );

                if (response.status === 200) {
                    console.log(response.data);
                    setOverlays(response.data);
                }
            } catch (error: AxiosError | unknown) {
                setLoading(false);
                if (error instanceof AxiosError) {
                    if (error.status !== 404) {
                        toast.error(
                            <CustomToast
                                message="An error occurred while fetching overlays."
                                type="error"
                            />,
                            {
                                autoClose: 3000,
                            }
                        );
                    }
                } else {
                    toast.error(
                        <CustomToast
                            message="An error occurred while fetching overlays."
                            type="error"
                        />,
                        {
                            autoClose: 3000,
                        }
                    );
                }
            } finally {
                setLoading(false);
            }
        };

        const fetchLogos = async () => {
            setLoading(true);
            try {
                const headers = await getAuthHeader();
                const response = await axios.get(`${backendUrl}/assets/logos`, {
                    headers,
                });
                setLogos(response.data);
            } catch (error: AxiosError | unknown) {
                setLoading(false);
                if (error instanceof AxiosError) {
                    if (error.status !== 404) {
                        toast.error(
                            <CustomToast
                                message="An error occurred while fetching logos."
                                type="error"
                            />,
                            {
                                autoClose: 3000,
                            }
                        );
                    }
                } else {
                    toast.error(
                        <CustomToast
                            message="An error occurred while fetching logos."
                            type="error"
                        />,
                        {
                            autoClose: 3000,
                        }
                    );
                }
            } finally {
                setLoading(false);
            }
        };

        fetchOverlays();
        fetchLogos();
    }, [setLoading, listingId]);

    const handleOverlaySelect = (id: string) => {
        setSelectedOverlay(id);
        setNotSelected(false);
    };

    return (
        <Card>
            <div className="space-y-6 p-4">
                <div className="space-y-4">
                    <h3 className="text-lg font-semibold text-primary">
                        Slideshow Options
                    </h3>

                    <div className="flex items-center space-x-4">
                        <Switch
                            id="include-overlay"
                            checked={includeOverlay}
                            onCheckedChange={() => {
                                setIncludeOverlay(!includeOverlay);
                                setNotSelected(true);
                            }}
                        />
                        <Label htmlFor="include-overlay">
                            Include Overlay - Display the Property Address on
                            the Slideshow
                        </Label>
                    </div>

                    {includeOverlay && (
                        <div className="space-y-4">
                            <OverlaySelector
                                overlays={overlays}
                                selectedOverlay={selectedOverlay}
                                onSelectOverlay={handleOverlaySelect}
                            />

                            {!createOverlayModalOpen && (
                                <Button
                                    size="lg"
                                    className="w-full font-semibold"
                                    onClick={() =>
                                        setCreateOverlayModalOpen(true)
                                    }
                                >
                                    <FaPlus className="mr-1 h-6 w-6" />
                                    Create Overlay
                                </Button>
                            )}
                            {createOverlayModalOpen && (
                                <CreateOverlay
                                    logos={logos}
                                    newOverlayHasLogo={newOverlayHasLogo}
                                    setNewOverlayHasLogo={setNewOverlayHasLogo}
                                    formattedAddress={formattedAddress}
                                    overlays={overlays}
                                    setOverlays={setOverlays}
                                    setSelectedOverlay={setSelectedOverlay}
                                    setLogos={setLogos}
                                    setCreateOverlayModalOpen={
                                        setCreateOverlayModalOpen
                                    }
                                    listingId={listingId}
                                    listingType={listingType}
                                    setNotSelected={setNotSelected}
                                    image={image.slideshow || image.url}
                                />
                            )}
                        </div>
                    )}

                    <Music
                        includeMusic={includeMusic}
                        setIncludeMusic={setIncludeMusic}
                        setLoading={setLoading}
                        music={music}
                        setMusic={setMusic}
                    />
                </div>
            </div>
        </Card>
    );
};

export default SlideshowOptions;
