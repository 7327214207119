import React, { useEffect, useState } from "react";
import { ContentTabsProps, promotionTypes } from "./types";
import { Tabs, TabsList, TabsTrigger } from "../misc/Tabs";
import ListingsTab from "./ListingsTab";
import PromotionAssetsTab from "../promotions/PromotionAssetsTab";
import { Assets } from "../../types/asset-type";
import { Button } from "../misc/Button";
import { FaCircleCheck, FaPencil } from "react-icons/fa6";
import ResourcesTab from "./ResourcesTab";

const ContentTabs: React.FC<ContentTabsProps> = ({
    promotions,
    activeTab,
    setActiveTab,
    listingMedia,
    listingTabs,
    activeListingTab,
    setActiveListingTab,
    loading,
    mlsApproved,
    carouselApi,
    fetchAssets,
    fetchListingMedia,
    setLoading,
    carouselIndexMap,
    resources,
    fetchResources,
    isMobile,
    activeMediaTab,
    setActiveMediaTab,
    activePromotionType,
    setActivePromotionType,
    activeResourceType,
    setActiveResourceType,
    onThumbnailClick,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState<Assets[]>([]);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        setSelectedMedia([]);
    }, [setIsEditing]);

    return (
        <Tabs
            value={activeTab}
            onValueChange={(value: string) =>
                setActiveTab(value as "listing" | "promotion" | "resource")
            }
        >
            <div
                className={`flex ${
                    isMobile ? "flex-col" : "flex-row"
                } justify-between mb-4`}
            >
                <TabsList
                    isMobile={isMobile}
                    className={`${isMobile ? "mb-2" : ""}`}
                >
                    <TabsTrigger id="listings-tab" value="listing">
                        Listings
                    </TabsTrigger>
                    <TabsTrigger id="promotions-tab" value="promotion">
                        Promotion
                    </TabsTrigger>
                    <TabsTrigger id="resources-tab" value="resource">
                        Shared Media
                    </TabsTrigger>
                </TabsList>
                <Button
                    id="edit-done-btn"
                    onClick={() => {
                        setIsEditing(!isEditing);
                        setSelectedMedia([]);
                    }}
                    className={`${isMobile ? "w-full" : ""}`}
                >
                    {isEditing ? (
                        <FaCircleCheck className="mr-1 w-4 h-4" />
                    ) : (
                        <FaPencil className="mr-1 w-4 h-4" />
                    )}
                    {isEditing ? "Done" : "Edit"}
                </Button>
            </div>
            <div className="relative">
                <ListingsTab
                    listingMedia={listingMedia}
                    listingTabs={listingTabs}
                    activeListingTab={activeListingTab}
                    setActiveListingTab={setActiveListingTab}
                    activeTab={activeTab}
                    loading={loading}
                    mlsApproved={mlsApproved}
                    carouselApi={carouselApi}
                    setLoading={setLoading}
                    fetchListingMedia={fetchListingMedia}
                    carouselIndexMap={carouselIndexMap}
                    isEditing={isEditing}
                    isMobile={isMobile}
                    activeMediaTab={activeMediaTab}
                    setActiveMediaTab={setActiveMediaTab}
                    onThumbnailClick={onThumbnailClick}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                />
            </div>
            <PromotionAssetsTab
                isEditing={isEditing}
                assets={
                    Array.isArray(promotions?.assets) ? promotions?.assets : []
                }
                promotionTypes={promotionTypes}
                promotionId={promotions?.id}
                selectedMedia={selectedMedia}
                setSelectedMedia={setSelectedMedia}
                carouselApi={carouselApi}
                fetchAssets={fetchAssets}
                activePromotionType={activePromotionType}
                setActivePromotionType={setActivePromotionType}
                carouselAssetIndexMap={carouselIndexMap}
                value="promotion"
                setLoading={setLoading}
                isMobile={isMobile}
                onThumbnailClick={onThumbnailClick}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
            />
            <ResourcesTab
                resources={resources}
                isEditing={isEditing}
                carouselApi={carouselApi}
                carouselIndexMap={carouselIndexMap}
                fetchResources={fetchResources}
                setLoading={setLoading}
                isMobile={isMobile}
                activeResourceType={activeResourceType}
                setActiveResourceType={setActiveResourceType}
                onThumbnailClick={onThumbnailClick}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
            />
        </Tabs>
    );
};

export default ContentTabs;
