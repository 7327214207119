import React, { useEffect, useState, useRef } from "react";
import InputField from "../misc/InputField";
import { Button } from "../misc/Button";
import CustomSelect from "../misc/CustomSelect";
import { TIME_ZONES, TimeZoneOption } from "../onboarding/utils/timeZones";
import { ActionMeta, SingleValue } from "react-select";
import { OptionType } from "../../types/option-type";
import PasswordChangeOverlay from "./PasswordChangeOverlay";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import axios from "axios";
import { UpdatePersonalInfoDTO } from "./dto/update-personal-info.dto";
import { getAuthHeader } from "../../utils/authHeader";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { setUser } from "../../store/authSlice";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { Card, CardHeader } from "../misc/Card";
import { CardContent } from "../calendar/Card";
import { useIsMobile } from "../../hooks/useIsMobile";
import { isValidPhoneNumber } from "../../utils/validationUtil";
import SimpleTooltip from "../misc/SimpleTooltip";

const BROWSER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

const PersonalInformation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Retrieve user from Redux store
    const user = useSelector((state: RootState) => state.auth.user);
    const originalUserRef = useRef(user); // Store original user data

    // Initialize input fields with current user data
    const [name, setName] = useState(
        user ? `${user.nameFirst || ""} ${user.nameLast || ""}`.trim() : ""
    );
    const [phone, setPhone] = useState(user?.phoneNumber || "");
    const [timeZone, setTimeZone] = useState(user?.timeZone || "");
    const [isPasswordOverlayOpen, setIsPasswordOverlayOpen] = useState(false);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [signUpUser, setSignUpUser] = useState<boolean>(false);

    // Update signUpUser and originalUserRef when user changes
    useEffect(() => {
        setSignUpUser(user?.providerId === "password");
        originalUserRef.current = user; // Update original user data if user changes

        // Initialize input fields when user data changes
        setName(
            user ? `${user.nameFirst || ""} ${user.nameLast || ""}`.trim() : ""
        );
        setPhone(user?.phoneNumber || "");
        setTimeZone(user?.timeZone || "");
    }, [user]);

    const formatPhoneNumber = (phoneNumber: string | null | undefined) => {
        if (!phoneNumber) return "Ex: (878) 231-6060";
        const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, "US");
        return parsedPhoneNumber
            ? parsedPhoneNumber.formatNational()
            : phoneNumber;
    };

    const getDefaultTimeZone = (): TimeZoneOption | null => {
        const current: TimeZoneOption | undefined = TIME_ZONES.find(
            (tz: TimeZoneOption) => tz.value === timeZone
        );
        if (current) return current;

        const browserTZ = TIME_ZONES.find(
            (tz: TimeZoneOption) => tz.value === BROWSER_TIMEZONE
        );

        return browserTZ || null;
    };

    const handleTimeZoneChange = (
        option: SingleValue<OptionType>,
        actionMeta: ActionMeta<OptionType>
    ) => {
        setTimeZone(option ? option.value : "");
    };

    const saveChangesHandler = async () => {
        const header = await getAuthHeader();

        // Split the name into first and last
        const nameSplit = name.trim().split(" ", 2);
        if (nameSplit.length < 2) {
            nameSplit.push("");
        }

        // Construct the update DTO with only modified fields
        const updateDTO: UpdatePersonalInfoDTO = {};

        if (
            nameSplit[0] &&
            nameSplit[0] !== originalUserRef.current?.nameFirst
        ) {
            updateDTO.nameFirst = nameSplit[0];
        }

        if (
            nameSplit[1] &&
            nameSplit[1] !== originalUserRef.current?.nameLast
        ) {
            updateDTO.nameLast = nameSplit[1];
        }

        if (
            phone.trim() &&
            phone.trim() !== originalUserRef.current?.phoneNumber
        ) {
            updateDTO.phoneNumber = phone.trim();
        }

        if (timeZone && timeZone !== originalUserRef.current?.timeZone) {
            updateDTO.timeZone = timeZone;
        }

        // If no fields have been modified, do nothing
        if (Object.keys(updateDTO).length === 0) {
            return;
        }

        // Check if phone number is valid if it's being updated
        if (
            updateDTO.phoneNumber &&
            !isValidPhoneNumber(updateDTO.phoneNumber)
        ) {
            toast.error(
                <CustomToast message={"Invalid phone number"} type={"error"} />
            );
            return;
        }

        try {
            await axios.patch(
                `${backendUrl}/settings/personal-info`,
                updateDTO,
                {
                    headers: header,
                }
            );

            const response = await axios.get(
                `${backendUrl}/settings/personal-info`,
                {
                    headers: header,
                }
            );

            dispatch(setUser(response.data)); // Update Redux store

            // Update the original user data
            originalUserRef.current = response.data;

            toast.success(
                <CustomToast
                    message={"Personal information updated successfully"}
                    type={"success"}
                />
            );
        } catch (error) {
            toast.error(
                <CustomToast
                    message={"Error updating personal information"}
                    type={"error"}
                />
            );
        }
    };

    const isMobile = useIsMobile(1200);

    return (
        <div
            className={`flex justify-center ${isMobile ? "h-full" : "mt-4"}  `}
        >
            <Card
                className={`w-full max-w-4xl mx-auto ${
                    isMobile ? "h-full m-0 rounded-none" : "h-auto"
                }`}
            >
                <div className="p-4">
                    <Button
                        id="back-to-settings-btn"
                        onClick={() => navigate("/settings")}
                        className="mb-4 flex items-center"
                    >
                        <ArrowLeft className="mr-2 h-5 w-5" /> Back to Settings
                    </Button>
                </div>
                <CardHeader>
                    <h2 className="text-2xl font-bold text-primary">
                        Personal Information
                    </h2>
                </CardHeader>

                <CardContent className="p-0 px-10 pb-10">
                    <div className="mt-4">
                        <SimpleTooltip
                            message="Please email support to change your name"
                            visible={user?.mlsApproved || false}
                        >
                            <InputField
                                label="Name"
                                placeholder="Name"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                disabled={user?.mlsApproved || false}
                            />
                        </SimpleTooltip>
                    </div>
                    <div className="mt-4">
                        <InputField
                            label="Phone Number"
                            placeholder={formatPhoneNumber(user?.phoneNumber)}
                            type="tel"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                    <div className="mt-4">
                        <CustomSelect
                            id="time-zone-select-dropdown"
                            label="Time Zone"
                            options={TIME_ZONES}
                            value={getDefaultTimeZone()}
                            onChange={handleTimeZoneChange}
                            placeholder={
                                TIME_ZONES.find(
                                    (tz) => tz.value === user?.timeZone
                                )?.label
                            }
                            className="w-full md:w-1/2 min-h-[60px]"
                        />
                        <span className="text-neutral-500 text-sm">
                            Time zone changes will only take effect upon
                            campaign renewal or when creating a new campaign.
                        </span>
                    </div>
                    <div className="flex flex-row items-center justify-between gap-4 mt-6">
                        {signUpUser && (
                            <Button
                                variant="secondary"
                                onClick={() => setIsPasswordOverlayOpen(true)}
                            >
                                Change Password
                            </Button>
                        )}
                        <div className="">
                            <Button onClick={saveChangesHandler}>
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </CardContent>
                <PasswordChangeOverlay
                    isOpen={isPasswordOverlayOpen}
                    onClose={() => setIsPasswordOverlayOpen(false)}
                />
            </Card>
        </div>
    );
};

export default PersonalInformation;
