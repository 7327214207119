import React, { useState } from "react";
import { Button } from "./Button";

interface ContactFormProps {
    onClose: () => void;
}

export const ContactModalForm: React.FC<ContactFormProps> = ({ onClose }) => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        description: "",
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            //   await sendContactEmail(formData);
            onClose();
        } catch (error) {
            console.error("Failed to send email:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const inputClasses =
        "mt-1 block w-full rounded-md border-2 border-primary/30 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50 transition-colors";

    return (
        <form onSubmit={handleSubmit} className="p-6">
            <div className="space-y-4">
                <div>
                    <label
                        htmlFor="name"
                        className="block text-sm font-medium text-primary"
                    >
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        required
                        className={inputClasses}
                        value={formData.name}
                        onChange={(e) =>
                            setFormData((prev) => ({
                                ...prev,
                                name: e.target.value,
                            }))
                        }
                    />
                </div>

                <div>
                    <label
                        htmlFor="email"
                        className="block text-sm font-medium text-primary"
                    >
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        required
                        className={inputClasses}
                        value={formData.email}
                        onChange={(e) =>
                            setFormData((prev) => ({
                                ...prev,
                                email: e.target.value,
                            }))
                        }
                    />
                </div>

                <div>
                    <label
                        htmlFor="description"
                        className="block text-sm font-medium text-primary"
                    >
                        How can we help?
                    </label>
                    <textarea
                        id="description"
                        required
                        rows={4}
                        className={inputClasses}
                        value={formData.description}
                        onChange={(e) =>
                            setFormData((prev) => ({
                                ...prev,
                                description: e.target.value,
                            }))
                        }
                    />
                </div>
            </div>

            <div className="mt-6 flex justify-between space-x-3">
                <Button type="button" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="secondary"
                >
                    {isSubmitting ? "Sending..." : "Send Message"}
                </Button>
            </div>
        </form>
    );
};
