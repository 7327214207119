import { Button } from "./Button";
import { FiAlertCircle } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import { useIsMobile } from "../../hooks/useIsMobile";

interface ReconfirmOverlayProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    message: string;
    messageTwo: string;
    confirmText?: string;
}

export default function ReconfirmOverlay({
    isOpen = false,
    onClose,
    onConfirm,
    title,
    message,
    messageTwo,
    confirmText = "Confirm",
}: ReconfirmOverlayProps) {
    const isMobile = useIsMobile(900);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4 z-50">
            <div
                className={`relative ${
                    isMobile ? "w-full max-w-md" : "w-1/3"
                } rounded-lg bg-white p-6 shadow-lg z-50`}
            >
                <button
                    onClick={onClose}
                    className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 focus:outline-none"
                    aria-label="Close"
                >
                    <ImCross className="h-6 w-6" />
                </button>
                <div className="flex flex-col items-center text-center">
                    <FiAlertCircle className="h-16 w-16 text-yellow-500" />
                    <h2 className="mt-4 text-xl sm:text-2xl font-bold text-primary">
                        {title}
                    </h2>
                    <p className="mt-2 text-gray-600 text-sm sm:text-base">
                        {message}
                    </p>
                    <p className="mt-1 text-gray-600 text-sm sm:text-base">
                        {messageTwo}
                    </p>
                    <div
                        className={`mt-6 w-full ${
                            isMobile
                                ? "flex flex-col space-y-2"
                                : `flex justify-between ${
                                      confirmText
                                          .toLowerCase()
                                          .includes("cancel") ||
                                      title.toLowerCase().includes("cancel")
                                          ? "space-x-0"
                                          : "space-x-4"
                                  }`
                        }`}
                    >
                        <Button
                            onClick={onClose}
                            variant="secondary"
                            className={`w-full sm:w-auto ${
                                confirmText.toLowerCase().includes("cancel") ||
                                title.toLowerCase().includes("cancel")
                                    ? "hidden"
                                    : ""
                            }`}
                        >
                            {confirmText.toLowerCase().includes("cancel")
                                ? "No"
                                : "Cancel"}
                        </Button>
                        <Button
                            onClick={onConfirm}
                            className="w-full sm:w-auto"
                            variant={`${
                                title.toLowerCase().includes("delete") ||
                                title.toLowerCase().includes("remove") ||
                                title.toLowerCase().includes("cancel")
                                    ? "destructive"
                                    : "default"
                            }`}
                        >
                            {confirmText.toLowerCase() === "cancel"
                                ? "Yes"
                                : confirmText}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
