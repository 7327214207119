import React, { useState, useRef, useEffect } from "react";
import { FaBell } from "react-icons/fa";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { User } from "../../types/user";
import { handleLogoutGenerator } from "../../global-actions/handleLogout";
import { auth } from "../../firebase/firebase-config";
import { useDispatch } from "react-redux";
import { setIsAdminView } from "../../store/authSlice";
import { addImage, getImage } from "../../utils/dexieImageCache";

interface TopBarProps {
    isAdmin: boolean;
    user: User | null;
    showButton?: boolean;
    buttonTitle?: string;
    onButtonClick?: () => void;
    isAdminView: boolean;
}

const TopBar: React.FC<TopBarProps> = ({
    isAdmin,
    user,
    buttonTitle,
    showButton,
    onButtonClick,
    isAdminView,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const [profileImageURL, setProfileImageURL] = useState<string | null>(null);
    const previousProfileImageURL = useRef<string | null>(null); // Ref to store previous URL

    const handleLogoClick = () => {
        isAdminView ? navigate("/admin") : navigate("/");
    };

    const handleAdmin = () => {
        dispatch(setIsAdminView(true));
        sessionStorage.setItem("isAdminView", "true");
        navigate("/admin");
        setIsDropdownOpen(false);
    };

    const handleUser = () => {
        dispatch(setIsAdminView(false));
        sessionStorage.setItem("isAdminView", "false");
        navigate("/");
        setIsDropdownOpen(false);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    useEffect(() => {
        let isMounted = true; // To prevent state updates if the component is unmounted
        let objectURL: string | null = null;

        const loadProfileImage = async () => {
            if (user?.profileImageKey) {
                try {
                    const blob = await getImage(user.profileImageKey);
                    if (blob) {
                        objectURL = URL.createObjectURL(blob);
                        if (isMounted) {
                            setProfileImageURL(objectURL);
                            console.log(
                                "Using cached profile image:",
                                objectURL
                            );
                        }
                    } else if (user.photoURL) {
                        const response = await fetch(user.photoURL, {
                            cache: "no-cache",
                        });

                        if (response.ok) {
                            const fetchedBlob = await response.blob();
                            // Cache the new blob
                            await addImage(user.profileImageKey, fetchedBlob);
                            objectURL = URL.createObjectURL(fetchedBlob);
                            if (isMounted) {
                                setProfileImageURL(objectURL);
                                console.log(
                                    "Fetched and cached new profile image:",
                                    objectURL
                                );
                            }
                        } else if (response.status === 429) {
                            console.error(
                                "Rate limited while fetching profile image."
                            );
                            // Optionally implement retry logic here
                            setProfileImageURL(user.photoURL); // Fallback to original URL
                        } else {
                            console.error(
                                "Failed to fetch image:",
                                response.statusText
                            );
                            setProfileImageURL(null);
                        }
                    } else {
                        setProfileImageURL(null);
                    }
                } catch (error) {
                    console.error("Image caching failed:", error);
                    setProfileImageURL(user.photoURL || null);
                }
            } else if (user?.photoURL) {
                setProfileImageURL(user.photoURL);
            } else {
                setProfileImageURL(null);
            }
        };

        loadProfileImage();

        return () => {
            isMounted = false;
            if (
                previousProfileImageURL.current &&
                previousProfileImageURL.current.startsWith("blob:")
            ) {
                URL.revokeObjectURL(previousProfileImageURL.current);
                console.log(
                    "Revoked object URL:",
                    previousProfileImageURL.current
                );
            }
            // Update the ref to the current URL for the next cleanup
            previousProfileImageURL.current = objectURL;
        };
    }, [user]);

    // Close dropdown when interacting outside (click, touch, scroll)
    useEffect(() => {
        const handleInteractionOutside = (event: Event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setIsDropdownOpen(false);
            }
        };

        if (isDropdownOpen) {
            // Add event listeners for various interaction types
            document.addEventListener("mousedown", handleInteractionOutside);
            document.addEventListener("touchstart", handleInteractionOutside);
            window.addEventListener("scroll", handleInteractionOutside, true);
        }

        // Cleanup event listeners when dropdown is closed or component unmounts
        return () => {
            document.removeEventListener("mousedown", handleInteractionOutside);
            document.removeEventListener(
                "touchstart",
                handleInteractionOutside
            );
            window.removeEventListener(
                "scroll",
                handleInteractionOutside,
                true
            );
        };
    }, [isDropdownOpen]);

    const handleLogout = handleLogoutGenerator(auth, navigate, user);

    return (
        <div className="flex items-center justify-between px-6 py-4 bg-white w-full h-full">
            {/* Logo Section */}
            <div
                id="logo-btn"
                className="flex items-center space-x-2 cursor-pointer"
                onClick={handleLogoClick}
            >
                <img src={logo} alt="Marabot Logo" className="h-10" />
            </div>

            {/* Conditional Button - Hidden on Mobile */}
            {showButton && (
                <div className="hidden md:block">
                    <button
                        onClick={onButtonClick}
                        className="px-4 py-2 bg-primary text-white rounded-md shadow-md hover:bg-secondary transition"
                    >
                        {buttonTitle}
                    </button>
                </div>
            )}

            {/* User Account Section */}
            <div className="flex items-center space-x-4 gap-4">
                {/* Notification Icon */}
                <FaBell
                    id="notification-btn"
                    size={24}
                    className="text-2xl text-secondary hover:text-primary cursor-pointer"
                />

                {/* User Profile Icon */}
                <div className="relative" ref={dropdownRef}>
                    <button
                        id="profile-btn"
                        onClick={toggleDropdown}
                        className="flex items-center justify-center w-10 h-10 bg-secondary text-white rounded-full focus:outline-none"
                        aria-haspopup="true"
                        aria-expanded={isDropdownOpen}
                    >
                        {profileImageURL ? (
                            <img
                                src={profileImageURL}
                                alt="User"
                                className="w-10 h-10 rounded-full"
                            />
                        ) : (
                            <span className="text-lg font-semibold">
                                {user &&
                                    user.nameFirst &&
                                    user.nameLast &&
                                    user.nameFirst[0].toUpperCase() +
                                        user.nameLast[0].toUpperCase()}
                            </span>
                        )}
                    </button>

                    {/* Dropdown Menu */}
                    {isDropdownOpen && (
                        <div
                            className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-md z-50"
                            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
                        >
                            <ul>
                                <li>
                                    {isAdmin && !isAdminView ? (
                                        <button
                                            id="admin-mode-btn"
                                            className="w-full text-left px-4 py-2 bg-secondary text-white hover:bg-primary border-b-2 border-primary"
                                            onClick={handleAdmin}
                                        >
                                            Admin Mode
                                        </button>
                                    ) : isAdmin ? (
                                        <button
                                            id="user-mode-btn"
                                            className="w-full text-left px-4 py-2 bg-secondary text-white hover:bg-primary border-b-2 border-primary"
                                            onClick={handleUser}
                                        >
                                            User Mode
                                        </button>
                                    ) : null}
                                </li>

                                <li>
                                    <button
                                        id="profile-btn"
                                        className="w-full text-left px-4 py-2 hover:bg-secondary hover:text-white border-b-2 border-primary"
                                        onClick={() => {
                                            navigate("/help");
                                            setIsDropdownOpen(false);
                                        }}
                                    >
                                        Help
                                    </button>
                                </li>

                                <li>
                                    <button
                                        id="settings-dropdown-btn"
                                        className="w-full text-left px-4 py-2 hover:bg-secondary hover:text-white border-b-2 border-primary"
                                        onClick={() => {
                                            navigate("/settings");
                                            setIsDropdownOpen(false);
                                        }}
                                    >
                                        Settings
                                    </button>
                                </li>
                                <li>
                                    <button
                                        id="logout-btn"
                                        className="w-full text-left px-4 py-2 bg-[#E53935] hover:bg-primary text-white"
                                        onClick={handleLogout}
                                    >
                                        Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TopBar;
