import React from "react";
import { TbAlertSquareFilled } from "react-icons/tb";
import { Loader } from "lucide-react";
import { IoSettingsSharp } from "react-icons/io5";
import { RiAiGenerate } from "react-icons/ri";
import { FaCalendar, FaCheckCircle, FaClock } from "react-icons/fa";
import { GoChecklist } from "react-icons/go";
import { CampaignCardListingProps } from "../listings/listing/types";

interface CampaignStatusCardProps {
    listing: CampaignCardListingProps;
}

const CampaignStatusCard: React.FC<CampaignStatusCardProps> = ({ listing }) => {
    const campaignProgress = listing.campaignProgress;
    const campaignStatus = listing.campaignStatus;

    return (
        <>
            {/* Render campaign status and progress based on props */}
            <div className="flex items-center justify-between">
                <dt className="flex items-center font-medium text-accent">
                    <TbAlertSquareFilled className="mr-2 h-5 w-5 text-secondary" />
                    Campaign Status
                </dt>
                <dd className="font-medium text-primary">
                    {campaignStatus
                        ? campaignStatus
                              .toLowerCase()
                              .split("_")
                              .map(
                                  (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                              )
                              .join(" ")
                        : "Not Created"}
                </dd>
            </div>
            {campaignProgress &&
            (campaignProgress.currentStep !== "Completed" ||
                campaignStatus === "PROCESSING") &&
            campaignStatus?.toLowerCase() !== "active" ? (
                <>
                    {/* Campaign Progress Status */}
                    <div className="flex items-center justify-center my-4">
                        <dt className="flex items-center justify-center font-medium text-accent">
                            <Loader className="mr-2 h-5 w-5 animate-spin text-secondary" />
                            {campaignProgress?.currentStep || "Processing"}
                        </dt>
                    </div>
                    {/* Campaign Total number of posts */}
                    {campaignProgress?.totalPosts &&
                    campaignProgress?.totalPosts > 0 ? (
                        <div className="flex items-center justify-between">
                            <dt className="flex items-center font-medium text-accent">
                                <TbAlertSquareFilled className="mr-2 h-5 w-5 text-secondary" />
                                Total Posts
                            </dt>
                            <dd className="font-medium text-primary">
                                {campaignProgress?.totalPosts}
                            </dd>
                        </div>
                    ) : null}
                    {/* Campaign post generation progress */}
                    {campaignProgress?.postsCreated !== null &&
                    campaignProgress?.postsCreated !== undefined &&
                    campaignProgress?.postsCreated > 0 ? (
                        <div className="flex items-center justify-between">
                            <dt className="flex items-center font-medium text-accent">
                                <TbAlertSquareFilled className="mr-2 h-5 w-5 text-secondary" />
                                Generated Posts
                            </dt>
                            <dd className="font-medium text-primary">
                                {campaignProgress?.postsCreated}
                            </dd>
                        </div>
                    ) : null}
                </>
            ) : (
                <>
                    {/* Campaign Type */}
                    {listing?.campaignType ? (
                        <div className="flex items-center justify-between">
                            <dt className="flex items-center font-medium text-accent">
                                <IoSettingsSharp className="mr-2 h-5 w-5 text-secondary" />
                                Campaign Type
                            </dt>
                            <dd className="text-primary">
                                {listing.campaignType}
                            </dd>
                        </div>
                    ) : null}
                    {/* Generated Posts */}
                    {listing?.generatedPosts !== null &&
                    listing?.generatedPosts !== undefined ? (
                        <div className="flex items-center justify-between">
                            <dt className="flex items-center font-medium text-accent">
                                <RiAiGenerate className="mr-2 h-5 w-5 text-secondary" />
                                Generated Posts
                            </dt>
                            <dd className="text-primary">
                                {listing.generatedPosts}
                            </dd>
                        </div>
                    ) : null}
                    {/* Published Posts */}
                    {listing?.publishedPosts !== null &&
                    listing?.publishedPosts !== undefined ? (
                        <div className="flex items-center justify-between">
                            <dt className="flex items-center font-medium text-accent">
                                <FaCheckCircle className="mr-2 h-5 w-5 text-secondary" />
                                Published Posts
                            </dt>
                            <dd className="text-primary">
                                {listing.publishedPosts}
                            </dd>
                        </div>
                    ) : null}
                    {/* Scheduled Posts */}
                    {listing?.scheduledPosts !== null &&
                    listing?.scheduledPosts !== undefined ? (
                        <div className="flex items-center justify-between">
                            <dt className="flex items-center font-medium text-accent">
                                <FaCalendar className="mr-2 h-5 w-5 text-secondary" />
                                Scheduled Posts
                            </dt>
                            <dd className="text-primary">
                                {listing.scheduledPosts}
                            </dd>
                        </div>
                    ) : null}
                    {/* Unapproved Posts */}
                    {listing?.unapprovedPosts !== null &&
                    listing?.unapprovedPosts !== undefined &&
                    listing.campaignType === "Manual" ? (
                        <div className="flex items-center justify-between">
                            <dt className="flex items-center font-medium text-accent">
                                <GoChecklist className="mr-2 h-5 w-5 text-secondary" />
                                Unapproved Posts
                            </dt>
                            <dd className="text-primary">
                                {listing.unapprovedPosts}
                            </dd>
                        </div>
                    ) : null}
                    {/* Next Content Refresh */}
                    {listing?.contentRefresh && (
                        <div className="flex items-center justify-between">
                            <dt className="flex items-center font-medium text-accent">
                                <FaClock className="mr-2 h-5 w-5 text-secondary" />
                                Next Content Refresh
                            </dt>
                            <dd className="text-primary">
                                {new Date(
                                    listing.contentRefresh
                                ).toLocaleDateString()}
                            </dd>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default CampaignStatusCard;
