import React from "react";
import { Outlet } from "react-router-dom";
import TopBar from "../components/navigation-bars/TopBar";
import { User } from "../types/user";
import { useIsMobile } from "../hooks/useIsMobile";
import AdminSideBar from "../components/navigation-bars/AdminSideBar";

interface Props {
    isAdmin: boolean;
    user: User | null;
    isAdminView: boolean;
}

const AdminLayout: React.FC<Props> = ({ isAdmin, user, isAdminView }) => {
    const isMobile = useIsMobile(900);
    console.log("isAdminView: ", isAdminView);
    return (
        <div className="flex flex-col h-screen">
            {/* TopBar */}
            <header className="h-16 flex items-center px-4 flex-shrink-0">
                <TopBar
                    isAdmin={isAdmin}
                    user={user}
                    isAdminView={isAdminView}
                />
            </header>

            {/* Main Content Area */}
            <div className="flex flex-1 overflow-hidden">
                {/* SideBar on Desktop */}
                {!isMobile && (
                    <aside className="bg-background text-white flex-shrink-0">
                        <AdminSideBar />
                    </aside>
                )}

                {/* Page Content */}
                <main
                    className={`flex-1 bg-background overflow-auto ${
                        isMobile ? "pb-20" : ""
                    }`}
                >
                    <Outlet />
                </main>
            </div>

            {/* SideBar on Mobile */}
            {isMobile && <AdminSideBar />}
        </div>
    );
};

export default AdminLayout;
