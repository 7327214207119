import { useState } from "react";
import { Tutorial } from "./tutorials-data";
import { TutorialCard } from "./TutorialsCard";
import { Search } from "lucide-react";

export function TutorialsList({
    initialTutorials,
}: {
    initialTutorials: Tutorial[];
}) {
    const [searchTerm, setSearchTerm] = useState("");

    const filteredTutorials = initialTutorials.filter((tutorial) =>
        tutorial.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <div className="relative mb-8">
                <input
                    type="text"
                    placeholder="Search tutorials..."
                    className="w-full p-4 pl-12 pr-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {filteredTutorials.map((tutorial) => (
                    <TutorialCard key={tutorial.id} tutorial={tutorial} />
                ))}
            </div>
        </div>
    );
}
